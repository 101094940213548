import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table, SelectColumnFilter } from "../../../utils/Table/TableWithFilter";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import {
	FaHeart,
	FaCamera,
	FaVideo,
	FaUserCog,
	FaRegFilePdf,
	FaRegHeart,
	FaRegCommentAlt,
	FaCommentAlt
} from "react-icons/fa";
import Loader from "../../../layout/Loader";
import BlogStatusIcon from "../../Buttons/BlogStatusIcon";
import { handlePosts } from "../../News/BlogUtils";

export default function MyPosts({ managerMode, userState }) {
	const { t } = useTranslation("common");
	const [myPosts, setMyPosts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const columns = [
		{
			Header: t("common.__status__"),
			accessor: "published",
			Filter: SelectColumnFilter,
			maxWidth: 60,
			width: 60,
			Cell: row => <BlogStatusIcon status={row.row.original.accepted} title={row.row.values.published} />
		},
		{
			Header: t("common.__date__"),
			accessor: "eventDate",
			maxWidth: 80,
			width: 70
		},
		{
			Header: t("news.__title__"),
			accessor: "title",
			Cell: row => (
				<>
					<Link
						to={{
							pathname: `/controlpanel/post`,
							state: { post: row.row.original }
						}}>
						<b>{row.row.values.title}</b>
					</Link>
					<div className="meta">
						<FaCamera className="kpi-icon mr-2" />
						<span className="mr-2">{row.row.original.photoCounter}</span>
						<FaRegFilePdf className="kpi-icon mr-2" />
						<span className="mr-2">{row.row.original.pdfFiles ? row.row.original.pdfFiles.length : 0}</span>
						<FaVideo className="kpi-icon mr-2" />
						<span className="mr-2">{row.row.original.video ? row.row.original.video.length : 0}</span>
						<FaUserCog className="kpi-icon mr-2" />
						{row.row.original.focused_on ? row.row.original.seniors_focused_on.length : 0}
					</div>
				</>
			)
		},
		{
			Header: t("news.__like__"),
			accessor: "like_counter",
			disableFilters: true,
			maxWidth: 80,
			width: 70,
			Cell: row =>
				row.row.original.accepted ? (
					<>
						<span className="center fake-link mr-1">
							{row.row.values.like_counter > 0 ? (
								<span className="like-button mr-2">
									<FaHeart className="kpi-icon" /> {row.row.values.like_counter}
								</span>
							) : (
								<span className="mr-2">
									<FaRegHeart className="kpi-icon red" /> <span className="meta">0</span>
								</span>
							)}
						</span>
						{row.row.original.comment_counter > 0 ? (
							<span className="meta mr-2">
								<FaCommentAlt className="kpi-icon " /> <b>{row.row.original.comment_counter}</b>
							</span>
						) : (
							<span className="mr-2 darkgrey">
								<FaRegCommentAlt className="kpi-icon" /> 0
							</span>
						)}
					</>
				) : null
		}
	];

	useEffect(() => {
		if (managerMode) {
			setIsLoading(true);
			http
				.get(`${url.BASEURL}/me/blog_posts`)
				.then(res => {
					setIsLoading(false);
					const posts = handlePosts(res.data.blog_posts, userState.userConnected.locale);
					setMyPosts(posts);
				})
				.catch(() => {
					setIsLoading(false);
					setMyPosts([]);
				});
		}
		// eslint-disable-next-line
	}, [managerMode]);

	return isLoading ? (
		<Loader />
	) : myPosts.length > 0 ? (
		<Table columns={columns} data={myPosts} />
	) : (
		<p className="meta mt-2">{t("common.__noElement__")}</p>
	);
}
