import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserInterface } from "../interfaces/UserInterface";
import { HealthWorkersTabs } from "../utils/Tabs/TabsToDisplay";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import SelectHomeInput from "../layout/SelectHomeInput";
import HeaderContainer from "../layout/HeaderContainer";
import CreateHealthWorkerBadge from "../components/HealthWorkers/Badges/CreateHealthWorkerBadge";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const HealthWorker_AddBadge = ({ userState, homeId }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { store, dispatch } = useStoreData({ userState, homeId });

	useEffect(() => {
		return function cleanup() {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]}>
			<Tabs tabsToDisplay={HealthWorkersTabs} currentTab={"BADGES"} />
			<div className="big-card">
				{homeId ? (
					<>
						<HeaderContainer
							title={t("badge.__badges__")}
							addButton={t("badge.__addBadge__")}
							link={"/user/hwbagde/addbadge/add"}
							permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]}
							userState={userState}
						/>
						<CreateHealthWorkerBadge
							userLocale={userState.userConnected.locale}
							store={store}
							dispatch={dispatch}
							homeId={homeId}
						/>
					</>
				) : (
					<SelectHomeInput />
				)}
			</div>
		</AccessControl>
	);
};
export default HealthWorker_AddBadge;
