import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { FaBookmark, FaRegEdit } from "react-icons/fa";
import EditTenant from "./EditTenant";

export default function DisplayTenant({ displayTenant, setDisplayTenant }) {
	const { t } = useTranslation("common");
	const [tenant, setTenant] = useState({});
	const tenantId = displayTenant.tenantId;
	const [editTenant, setEditTenant] = useState({ edit: false, tenant: null, success: false });

	useEffect(() => {
		http
			.get(`${url.BASEURL}/tenants/${tenantId}`)
			.then(res => setTenant(res.data.tenant))
			.catch(() => setTenant({}));
	}, [tenantId, editTenant.success]);

	return editTenant.edit ? (
		<EditTenant editTenant={editTenant} setEditTenant={setEditTenant} />
	) : (
		<>
			<div className="small-list-items">
				<span className="float-right mt-1">
					<FaRegEdit
						className="table-icon"
						role="button"
						onClick={() => setEditTenant({ edit: true, tenant: tenant, success: false })}
					/>
				</span>
				<h3>
					<FaBookmark className="mr-1" /> {tenant.name}
				</h3>
				<p className="meta">{tenant.description}</p>
			</div>
			<button
				type="button"
				className="btn btn-sm btn-outline-primary mt-3"
				onClick={() => setDisplayTenant({ show: false, tenantId: null })}
			>
				{t("common.__back__")}
			</button>
		</>
	);
}
