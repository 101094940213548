import React from "react";
import { useTranslation } from "react-i18next";
import { FaVideo } from "react-icons/fa";
import { SeniorInterface } from "../../interfaces/UserInterface";
import { Tooltip } from "./Tooltip";

interface Props {
	senior: SeniorInterface;
	unreadCounter?: number;
	setShowNextVisio: (data: { show: boolean; info: { senior: SeniorInterface }; title: string }) => void;
}

const SeniorVisioIcon = ({ senior, unreadCounter, setShowNextVisio }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<Tooltip text={t("mailbox.__visioToCome__")}>
			<span className="board-alert">
				<FaVideo
					className="board-card-icon"
					role="button"
					onClick={(): void =>
						setShowNextVisio({
							show: true,
							info: { senior: senior },
							title: senior.name ? senior.name : `${senior.last_name} ${senior.first_name}`
						})
					}
				/>
				{unreadCounter ? unreadCounter === 0 ? null : <span className="board-card-icon-notification"></span> : null}
			</span>
		</Tooltip>
	);
};
export default SeniorVisioIcon;
