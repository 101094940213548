import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegTimesCircle, FaRegEdit } from "react-icons/fa";
import CreateType from "./CreateType";
import EditType from "./EditType";
import HandleSuccess from "../../../layout/HandleSuccess";
import HandleError from "../../../layout/HandleError";
import http from "../../../services/Interceptor";
import * as constant from "../../../constants/Url";

const HomesTypes = ({ homeStore, homeDispatch }) => {
	const { t } = useTranslation("common");
	const homeTypes = homeStore.home.homeTypes;
	const [addMode, setAddMode] = useState(false);
	const [editMode, setEditMode] = useState({ edit: false, homeType: null });

	const removeHomeType = id => {
		http
			.delete(`${constant.BASEURL}/home_types/${id}`)
			.then(() => {
				homeDispatch({
					type: "DELETE_HOME_TYPE",
					payload: id
				});
				homeDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__removeHomeTypeSuccess__")
				});
			})
			.catch(err => {
				if (err.response.status === 409) {
					homeDispatch({
						type: "DISPLAY_ERROR",
						payload: t("home.__removeHomeTypeError__")
					});
				} else {
					homeDispatch({
						type: "DISPLAY_ERROR",
						payload: t("common.__delError__")
					});
				}
			});
	};

	const displayEditMode = homeType => {
		homeDispatch({ type: "RESET_ES" });
		setEditMode({ edit: true, homeType: homeType });
	};

	const displayAddMode = boolean => {
		homeDispatch({ type: "RESET_ES" });
		setAddMode(boolean);
	};

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-md-6">
					<h2>{t("home.__homeTypes__")}</h2>
				</div>
				<div className="col-md-6">
					{addMode ? (
						<button type="button" className="right btn btn-sm btn-outline-secondary" onClick={() => displayAddMode(false)}>
							{t("common.__back__")}
						</button>
					) : (
						<button type="button" className="right btn btn-sm btn-outline-primary" onClick={() => displayAddMode(true)}>
							{t("home.__addNewType__")}
						</button>
					)}
				</div>
			</div>

			{addMode ? (
				<CreateType setAddMode={setAddMode} homeStore={homeStore} homeDispatch={homeDispatch} />
			) : editMode.edit ? (
				<EditType editMode={editMode} setEditMode={setEditMode} homeDispatch={homeDispatch} />
			) : (
				<>
					<HandleSuccess success={homeStore.es.success} dispatch={homeDispatch} message={homeStore.es.msg} />
					<HandleError error={homeStore.es.error} dispatch={homeDispatch} message={homeStore.es.msg} />

					{homeTypes.map(type => (
						<div className="small-list-items" key={type.id}>
							<div className="row">
								<div className="col-md-8">
									<p>{type.name}</p>
									<p className="small-meta">{type.description}</p>
								</div>
								<div className="col-md-4">
									<div className=" float-right">
										<FaRegEdit className="table-icon" role="button" onClick={() => displayEditMode(type)} />
										<FaRegTimesCircle className="table-icon" role="button" onClick={() => removeHomeType(type.id)} />
									</div>
								</div>
							</div>
						</div>
					))}
				</>
			)}
		</div>
	);
};

export default HomesTypes;
