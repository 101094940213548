import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../utils/Form/MyInputText";
import MyInputSelect from "../../utils/Form/MyInputSelect";
import SelectHomeInput from "../../layout/SelectHomeInput";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import HeaderContainer from "../../layout/HeaderContainer";
import HandleError from "../../layout/HandleError";

interface Props {
	homeId: number;
	deviceError: any;
	deviceType: any;
	devicesDispatch: React.Dispatch<any>;
}

export interface FormProps {
	type: string;
	serialNumber: string;
}

const AddDevice = ({ homeId, deviceType, deviceError, devicesDispatch }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const methods = useForm<FormProps>();
	const { handleSubmit } = methods;

	const onSubmit = (data: FormProps): void => {
		const deviceData = {
			device: {
				home_id: homeId,
				serial_number_type: data.type,
				serial_number: data.serialNumber
			}
		};
		http
			.post(`${url.BASEURL}/devices`, deviceData)
			.then(res => {
				devicesDispatch({
					type: "ADD_DEVICE",
					payload: res.data.device
				});
				history.push("/support/devices");
			})
			.catch(err => {
				if (
					err.response.request.responseText === JSON.stringify({ errors: { serial_number: ["has already been taken"] } })
				) {
					devicesDispatch({
						type: "DISPLAY_ERROR",
						payload: t("device.__serialNumberAlreadyExist__")
					});
				} else {
					devicesDispatch({
						type: "DISPLAY_ERROR",
						payload: t("common.__addError__")
					});
				}
			});
	};

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("device.__devices__")}
				addButton={t("device.__addDevice__")}
				link={"/support/device/add"}
			/>
			<HandleError error={deviceError.error} dispatch={devicesDispatch} message={deviceError.msg} />

			<FormProvider {...methods}>
				<h5 className="blue">{t("device.__addDevice__")}</h5>

				{!homeId ? (
					<SelectHomeInput />
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form-group">
							<MyInputSelect
								label={t("common.__type__")}
								name="type"
								behavior={{ required: true }}
								list={deviceType}
								value="SN"
							/>
							<MyInputText label={t("device.__serialNumber__")} name="serialNumber" behavior={{ required: true }} />
						</div>
						<div className="mt-4">
							<button type="button" className="btn btn-outline-secondary btn-sm mr-2" onClick={(): void => history.goBack()}>
								{t("common.__cancel__")}
							</button>
							<button type="submit" className="btn btn-primary btn-sm">
								{t("common.__validate__")}
							</button>
						</div>
					</form>
				)}
			</FormProvider>
		</div>
	);
};

export default AddDevice;
