export function ActivitiesDate(string) {
	let newDate = string.split(" ")[0];
	let day = newDate.split("-")[2];
	let month = newDate.split("-")[1];
	let year = newDate.split("-")[0];

	return `${day}/${month}/${year}`;
}

export function ActivitiesTime(string) {
	let newTime = string.split(" ")[1];

	return `${newTime}`;
}
