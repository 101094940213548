import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import ErrorContainer from "../../layout/ErrorContainer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

interface Props {
	fromWelcomePage: boolean;
	userComeFromEventApp?: boolean;
}

const ForgotPassword = ({ fromWelcomePage, userComeFromEventApp }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [httpResponse, setHttpResponse] = useState<{ error: boolean; msg: string | null } | null>(null);
	const [login, setLogin] = useState("");

	const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const data = {
			email: login
		};
		http
			.post(`${url.BASEURL}/lost_password`, data)
			.then(() => setHttpResponse({ error: false, msg: null }))
			.catch(() => setHttpResponse({ error: true, msg: null }));
	};

	return (
		<>
			<div className="mt-2 mb-4">
				<h2 className="center">{t("users.__forgotPassword__")}</h2>
			</div>

			{fromWelcomePage ? (
				<div className="alert alert-warning">
					<FaLock className="mr-2" /> {t("users.__newPasswordTokenWarning__")}
				</div>
			) : null}

			<ErrorContainer
				alert={t("users.__forgotPasswordError__")}
				success={t("users.__forgotPasswordSucces__")}
				httpResponse={httpResponse}
				setHttpResponse={setHttpResponse}
			/>

			{httpResponse ? (
				!httpResponse.error &&
				(userComeFromEventApp ? null : (
					<button type="button" className="btn btn-outline-primary mt-4 center" onClick={(): void => history.push("/")}>
						{t("users.__loginBtn__")}
					</button>
				))
			) : (
				<form onSubmit={onSubmit}>
					<label>{t("login.__writeMailOrLogin__")} :</label>
					<input type="text" className="form-control form-control-sm" onChange={(e): void => setLogin(e.target.value)} />
					<br />
					<button type="submit" className="btn btn-primary mt-2 center">
						{t("common.__validate__")}
					</button>
				</form>
			)}
		</>
	);
};

export default ForgotPassword;
