import React from "react";
import RangePicker from "../utils/DatePicker/RangePicker";

interface Props {
	userLocale: string;
	title: string | null;
	startDate: Date;
	setStartDate: (date: Date) => void;
	endDate: Date;
	setEndDate: (date: Date) => void;
}

const HeaderWithRangeContainer = ({
	userLocale,
	title,
	startDate,
	setStartDate,
	endDate,
	setEndDate
}: Props): JSX.Element => {
	return (
		<div className="row">
			<div className="col-sm-5">{title ? <h2>{title}</h2> : null}</div>
			<div className="col-sm-7 mt-2">
				<div className="form-row right">
					<RangePicker
						userLocale={userLocale}
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
					/>
				</div>
			</div>
		</div>
	);
};
export default HeaderWithRangeContainer;
