import React, { useContext, useState, useEffect } from "react";
import { FaRegTimesCircle, FaRegEdit, FaEye } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import AddHomeGroup from "./AddHomeGroup";
import EditHomeGroup from "./EditHomeGroup";
import http from "../../../services/Interceptor";
import * as constant from "../../../constants/Url";

import { useTranslation } from "react-i18next";
import HandleSuccess from "../../../layout/HandleSuccess";
import HandleError from "../../../layout/HandleError";
import { Tooltip } from "../../Buttons/Tooltip";

export default function HomeGroups({ tenantId, isSuperAdmin }) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const { setGroupId, homeStore, homeDispatch } = useContext(UserContext);
	const groups = homeStore.home.groups;
	const [addMode, setAddMode] = useState(false);
	const [editThisGroup, setEditThisGroup] = useState({
		edit: false,
		group: null
	});

	const handleGroup = id => {
		setGroupId(id);
		history.push(`/manage/organisation/myhomes`);
	};

	const removeGroup = id => {
		http
			.delete(`${constant.BASEURL}/home_groups/${id}`)
			.then(() => {
				homeDispatch({
					type: "DELETE_GROUP",
					payload: id
				});
				homeDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__removeGroupSuccess__")
				});
			})
			.catch(() => {
				homeDispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__delError__")
				});
			});
	};

	useEffect(() => {
		return function cleanup() {
			homeDispatch({
				type: "RESET_ES"
			});
		};
	}, []);

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-md-6">
					<h2>{t("home.__groups__")}</h2>
				</div>
				<div className="col-md-6">
					{addMode ? (
						<button type="button" className="right btn btn-sm btn-outline-secondary" onClick={() => setAddMode(false)}>
							{t("common.__back__")}
						</button>
					) : (
						<button type="button" className="right btn btn-sm btn-outline-primary" onClick={() => setAddMode(true)}>
							{t("home.__addGroup__")}
						</button>
					)}
				</div>
			</div>

			{editThisGroup.edit ? (
				<EditHomeGroup editThisGroup={editThisGroup} setEditThisGroup={setEditThisGroup} homeDispatch={homeDispatch} />
			) : addMode ? (
				<AddHomeGroup setAddMode={setAddMode} homeDispatch={homeDispatch} tenantId={tenantId} isSuperAdmin={isSuperAdmin} />
			) : (
				<>
					<HandleSuccess success={homeStore.es.success} dispatch={homeDispatch} message={homeStore.es.msg} />
					<HandleError error={homeStore.es.error} dispatch={homeDispatch} message={homeStore.es.msg} />

					{groups.map(group => (
						<div className="small-list-items" key={group.id}>
							{group.name}
							<span className="float-right">
								<Tooltip text={t("home.__homes__")}>
									<FaEye className="table-icon" role="button" onClick={() => handleGroup(group.id)} />
								</Tooltip>
								<Tooltip text={t("common.__update__")}>
									<FaRegEdit
										className="table-icon"
										role="button"
										onClick={() => setEditThisGroup({ edit: true, group: group, success: false })}
									/>
								</Tooltip>
								<Tooltip text={t("calendar.__remove__")}>
									<FaRegTimesCircle className="table-icon" role="button" onClick={() => removeGroup(group.id)} />
								</Tooltip>
							</span>
						</div>
					))}
				</>
			)}
		</div>
	);
}
