import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import NewPassword from "./NewPassword";
import ForgotPassword from "./ForgotPassword";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

const NewPasswordContainer = (): JSX.Element => {
	const { t } = useTranslation("common");
	const { token } = useParams<{ token?: string }>();
	const [displayNewPassword, setDisplayNewPassword] = useState(token ? true : false);

	useEffect(() => {
		if (token) {
			http
				.post(`${url.BASEURL}/lost_password/token/validity`, { token: token })
				.then(res => setDisplayNewPassword(res.data.is_valid))
				.catch(() => setDisplayNewPassword(false));
		}
	}, [token]);

	return (
		<div className="login-container mx-auto">
			<div className="center">
				<Link to="/">
					<img
						src={process.env.REACT_APP_EFORLINK_LOGO === "true" ? "/img/Logo-eforlink.png" : "/img/amd/amdlink.png"}
						alt="AMD Link"
						className="login-logo"
					/>
				</Link>
			</div>
			{displayNewPassword ? (
				<NewPassword token={token} title={t("login.__createPassword__")} />
			) : (
				<ForgotPassword fromWelcomePage />
			)}
		</div>
	);
};

export default NewPasswordContainer;
