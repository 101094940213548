// AMD API sends a string with a number so this method filters numbers out
const extractAndFormatNumericValue = (str: string, decimalPlaces = 3): string | null => {
	const numericPart = str.match(/\d+(\.\d+)?/);

	if (numericPart) {
		const parsedValue = parseFloat(numericPart[0]);
		return parsedValue.toFixed(decimalPlaces);
	} else {
		return null;
	}
};

export default extractAndFormatNumericValue;
