import React from "react";
import { UserInterface } from "../interfaces/UserInterface";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import HomeGroups from "../components/Home/HomeGroups/HomeGroups";
import * as p from "../constants/Permissions";

interface Props {
	tenantId: number | null;
	userState: UserInterface;
}

const MyHome_Groups = ({ userState, tenantId }: Props): JSX.Element => {
	const isSuperAdmin = userState.userConnected.is(p.SUPER_ADMIN);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.ADMIN, p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={<HomeGroups tenantId={tenantId} isSuperAdmin={isSuperAdmin} />}
			/>
		</AccessControl>
	);
};
export default MyHome_Groups;
