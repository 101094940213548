import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegCalendarTimes } from "react-icons/fa";
import { Tooltip } from "./Tooltip";

interface Props {
	id: number;
	setShow: (data: { show: boolean; info: { id: number }; success: boolean }) => void;
}

const CancelVisio = ({ setShow, id }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<Tooltip text={t("common.__cancel__")}>
			<FaRegCalendarTimes
				role="button"
				className="notification-res grey-font mr-2"
				onClick={(): void => setShow({ show: true, info: { id: id }, success: false })}
			/>
		</Tooltip>
	);
};
export default CancelVisio;
