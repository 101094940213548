import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export default function VideoPlayer({ uriVideo, contentType }) {
	const { t } = useTranslation("common");
	const playerRef = useRef(null);
	const videoSrc = `/storages/blog_media${uriVideo}`;
	const [error, setError] = useState(false);

	const videoJsOptions = {
		controls: true,
		autoPlay: false,
		playsInline: true,
		muted: true,
		fluid: true,
		bigPlayButton: true,
		sources: [
			{
				src: videoSrc,
				type: contentType
			}
		],
		preload: "metadata",
		playbackRates: [0.5, 1, 1.5, 2],
		children: {
			mediaLoader: true,
			controlBar: {
				children: [
					"playToggle",
					"volumePanel",
					"currentTimeDisplay",
					"timeDivider",
					"durationDisplay",
					"progressControl",
					"liveDisplay",
					"remainingTimeDisplay",
					"customControlSpacer",
					"playbackRateMenuButton",
					"audioTrackButton",
					"fullscreenToggle"
				]
			}
		}
	};

	useEffect(() => {
		if (playerRef) {
			const player = videojs(playerRef.current, videoJsOptions, () => {
				player.on("error", function () {
					setError(true);
					player.dispose();
				});
			});
			return () => {
				player.dispose();
			};
		}
	}, []);

	return (
		<>
			<div data-vjs-player>
				<video
					ref={playerRef}
					className="video-js vjs-default-skin vjs-big-play-button"
					style={{ width: "100%", background: "transparent" }}
				/>
			</div>

			{error && <p className="error-msg">{t("news.__videoError__")}</p>}
		</>
	);
}
