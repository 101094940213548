import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

const MyInputText = props => {
	const { t } = useTranslation("common");
	const {
		register,
		formState: { errors }
	} = useFormContext();
	const [selectedValue, setSelectedValue] = useState(props.value);

	const onChange = e => {
		if (props.onChange !== undefined) {
			props.onChange(e);
		}
		setSelectedValue(e.target.value);
	};

	return (
		<div className="form-group mb-4">
			<label htmlFor={props.name} className="form-label">
				{props.label}
			</label>
			<input
				id={props.name}
				name={props.name}
				type="text"
				{...register(props.name, {
					validate: value => {
						if (props.behavior.required && !value) {
							return t("common.__required__");
						}
						if (props.behavior.pattern && value && !props.behavior.pattern.test(value)) {
							return t("common.__numericPattern__");
						}
						if (props.behavior.minLength && value && value.length < props.behavior.minLength) {
							return t("common.__sevenNumbersRequiredMin__");
						}
						if (props.behavior.maxLength && value && value.length > props.behavior.maxLength) {
							return t("common.__fifteenNumbersRequiredMax__");
						}
					}
				})}
				className="form-control form-control-sm"
				defaultValue={selectedValue || null}
				onChange={e => onChange(e)}
				data-testid={props.dataTestid}
			/>

			{errors[props.name] && <small className="red mt-5">{errors[props.name].message}</small>}
		</div>
	);
};

export default MyInputText;
