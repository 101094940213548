import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleRight, FaInfoCircle, FaUserCog } from "react-icons/fa";
import { SeniorInterface, UserInterface } from "../../../interfaces/UserInterface";
import AmdSeniorSyncContainer from "./AmdSeniorSyncContainer";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

interface Props {
	userState: UserInterface;
	homeId: number;
	partnerId: { home_partner_id: number; remote_id: number; remote_url: string } | null;
}

const AmdSeniorSync = ({ homeId, partnerId, userState }: Props): JSX.Element | null => {
	const { t } = useTranslation("common");
	const [seniors, setSeniors] = useState([]);
	const [editSeniorPartnerId, setEditSeniorPartnerId] = useState({
		show: false,
		info: null,
		title: "",
		success: false
	});
	const [deleteSeniorPartnerId, setDeleteSeniorPartnerId] = useState({
		show: false,
		info: null,
		title: "",
		success: false
	});
	const [displayAmdSeniorInfo, setDisplayAmdSeniorInfo] = useState(false);

	useEffect(() => {
		if (homeId && partnerId) {
			http
				.get(`${url.HOMESURL}/${homeId}/seniors/amd_profile/partner_id`)
				.then(res => {
					const orderedSeniors = res.data.sort((a: any, b: any) => a.last_name.localeCompare(b.last_name));
					const seniorsWithFullName = orderedSeniors.map((senior: SeniorInterface) => {
						return {
							...senior,
							name: `${senior.last_name.toUpperCase()} ${senior.first_name}`
						};
					});
					setSeniors(seniorsWithFullName);
				})
				.catch(() => setSeniors([]));
		}
	}, [homeId, partnerId, editSeniorPartnerId.success, deleteSeniorPartnerId.success]);

	return homeId ? (
		seniors.length > 0 ? (
			<>
				<div className="d-flex justify-content-between mt-3 mb-2">
					<h3>
						<FaUserCog className="mr-2" style={{ width: "auto", height: "1.25rem" }} />
						{t("senior.__seniors__")}
					</h3>
					<FaInfoCircle
						className="moderation-card-icon-ok"
						onClick={(): void => setDisplayAmdSeniorInfo(prevState => !prevState)}
					/>
				</div>
				{displayAmdSeniorInfo ? (
					<div className="header-details">
						<div className="m-3">
							<p>
								<FaAngleRight className="mr-2" />
								<b>{t("diaper.__howToFindAmdProfileInfo__")}</b>
							</p>
							<p className="mt-3 mb-3">{t("diaper.__amdProfilSeniorInfo__")}</p>
							<img src="/img/amd/amd_senior_id.png" className="img-fluid mt-3 mb-3" alt="extrait de la page AMD Profil" />
							<p className="mb-3">{t("diaper.__amdProfilSeniorInfo2__")}</p>
							<p className="mb-3">{t("diaper.__amdProfilSeniorInfo3__")}</p>
							<div className="light-separator"></div>
							<p>{t("diaper.__amdProfilSeniorInfo4__")}</p>
							<img
								src="/img/amd/amd_horaires_dotation.png"
								className="img-fluid mt-3 mb-4"
								alt="extrait de la page AMD Profil"
							/>
						</div>
					</div>
				) : null}
				<AmdSeniorSyncContainer
					seniors={seniors}
					editSeniorPartnerId={editSeniorPartnerId}
					setEditSeniorPartnerId={setEditSeniorPartnerId}
					deleteSeniorPartnerId={deleteSeniorPartnerId}
					setDeleteSeniorPartnerId={setDeleteSeniorPartnerId}
					userState={userState}
				/>
			</>
		) : null
	) : null;
};
export default AmdSeniorSync;
