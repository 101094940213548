import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { getActivityAction } from "../../../utils/Activities/Activities";
import i18n from "../../../i18n/i18next";
import * as r from "./RulesDetails";

interface RuleInterface {
	actions: any[];
	begin?: { hour: number; minute: number };
	end?: { hour: number; minute: number };
	nb_repeat?: number;
	max_seconds_after?: string;
	type: number;
}

export const displayRuleCategory = (category: string): JSX.Element => {
	switch (category) {
		case i18n.t("common:rules.__hydratationRule__"):
			return <span className="colored-tag blue">{category}</span>;
		case i18n.t("common:rules.__mealRule__"):
			return <span className="colored-tag red">{category}</span>;
		case i18n.t("common:rules.__moodRule__"):
			return <span className="colored-tag green">{category}</span>;
		case i18n.t("common:rules.__visitsRule__"):
			return <span className="colored-tag marine">{category}</span>;
		case i18n.t("common:rules.__mealDistributionRule__"):
			return <span className="colored-tag purple">{category}</span>;
		case i18n.t("common:rules.__mealReturnRule__"):
			return <span className="colored-tag purple">{category}</span>;
		case i18n.t("common:rules.__hygieneRule__"):
			return <span className="colored-tag darkred">{category}</span>;
		default:
			return <span className="colored-tag grey">{category}</span>;
	}
};

export const displayRule = (rule: RuleInterface): JSX.Element | null => {
	switch (rule.type) {
		case r.WHEN_ACTION_HAPPEN:
			return (
				<>
					<FaAngleRight className="mr-2" />
					{i18n.t("common:rules.__firstAlertNotification__")}
					<br />
					<FaAngleRight className="mr-2" />
					{i18n.t("common:rules.__indicator__")} : {getActivityAction(rule.actions[0].action_id)}
				</>
			);
		case r.IF_ACTION_REPEATED:
			return (
				<>
					<FaAngleRight className="mr-2" />
					{i18n.t("common:rules.__alertNotificationAfter__")} {rule.nb_repeat ? rule.nb_repeat : null}
					<br />
					<FaAngleRight className="mr-2" />
					{i18n.t("common:rules.__indicator__")} : {getActivityAction(rule.actions[0].action_id)}
				</>
			);
		case r.ACTION_MUST_BE_DONE_IN_RANGE:
			return (
				<>
					<FaAngleRight className="mr-2" />
					{i18n.t("common:rules.__whenAlertNotification__")} :{" "}
					{rule.begin && rule.end ? (
						<>
							{rule.begin.hour}h{rule.begin.minute < 10 ? `0${rule.begin.minute}` : rule.begin.minute}{" "}
							{i18n.t("common:common.__and__")} {rule.end.hour}h
							{rule.end.minute < 10 ? `0${rule.end.minute}` : rule.end.minute}
						</>
					) : null}
					<br />
					<FaAngleRight className="mr-2" />
					{i18n.t("common:rules.__indicator__")} : {getActivityAction(rule.actions[0].action_id)}
				</>
			);
		case r.ACTION_MUST_NOT_BE_DONE_IN_RANGE:
			return (
				<>
					{rule.begin && rule.end ? (
						<>
							<FaAngleRight className="mr-2" />
							{i18n.t("common:rules.__forbiddenAlertNotification__")} : {rule.begin.hour}h
							{rule.begin.minute < 10 ? `0${rule.begin.minute}` : rule.begin.minute} {i18n.t("common:common.__and__")}{" "}
							{rule.end.hour}h{rule.end.minute < 10 ? `0${rule.end.minute}` : rule.end.minute}
							.<br />
						</>
					) : null}
					<FaAngleRight className="mr-2" />
					{i18n.t("common:rules.__indicator__")} : {getActivityAction(rule.actions[0].action_id)}
				</>
			);
		case r.ACTION_MUST_BE_DONE_AFTER_THIS_ACTION:
			return (
				<>
					{rule.max_seconds_after ? (
						<>
							<FaAngleRight className="mr-2" />
							{i18n.t("common:rules.__maxDelayAlertNotification__")} : {r.getTiming(parseInt(rule.max_seconds_after))}.<br />
						</>
					) : null}
					<FaAngleRight className="mr-2" />
					{i18n.t("common:rules.__indicator__")} : {getActivityAction(rule.actions[0].action_id)}
				</>
			);
		case r.ACTION_MUST_NOT_BE_DONE_IN_RANGE_OTHERWISE:
			return (
				<>
					{rule.begin && rule.end ? (
						<>
							<FaAngleRight className="mr-2" />
							{i18n.t("common:rules.__noActionAlertNotification__")} {rule.begin.hour}h
							{rule.begin.minute < 10 ? `0${rule.begin.minute}` : rule.begin.minute} {i18n.t("common:common.__and__")}{" "}
							{rule.end.hour}h{rule.end.minute < 10 ? `0${rule.end.minute}` : rule.end.minute}
							.<br />
						</>
					) : null}
					<FaAngleRight className="mr-2" />
					{i18n.t("common:rules.__indicator__")} : {getActivityAction(rule.actions[0].action_id)} -{" "}
					{getActivityAction(rule.actions[1].action_id)} - {getActivityAction(rule.actions[2].action_id)}
				</>
			);
		default:
			return null;
	}
};
