import React, { createContext, useReducer } from "react";
import RoomsReducer from "./reducers/RoomsReducer";
import HealthWorkersReducer from "./reducers/HealthWorkersReducer";
import SeniorsReducer from "./reducers/SeniorsReducer";
import DevicesReducer from "./reducers/DevicesReducer";
import ErrorAndSuccessReducer from "./reducers/ErrorAndSuccessReducer";

const combineReducers = slices => (state, action) =>
	Object.keys(slices).reduce(
		(acc, prop) => ({
			...acc,
			[prop]: slices[prop](acc[prop], action)
		}),
		state
	);

const esInitialState = {
	error: false,
	success: false,
	msg: null
};

const initialState = {
	es: esInitialState,
	home: {
		homeUnits: [],
		rooms: []
	},
	seniors: {
		seniorsFromHome: []
	},
	users: {
		healthWorkers: [],
		badges: [],
		healthWorkerTypes: []
	}
};

const devicesInitialState = {
	es: esInitialState,
	devices: {
		devices: [],
		types: []
	}
};

export const HomeContext = createContext();

export const HomeContextProvider = ({ children }) => {
	const [esState, esDispatch] = useReducer(ErrorAndSuccessReducer, esInitialState);
	const [devicesStore, devicesDispatch] = useReducer(
		combineReducers({
			devices: DevicesReducer,
			es: ErrorAndSuccessReducer
		}),
		devicesInitialState
	);
	const [store, dispatch] = useReducer(
		combineReducers({
			home: RoomsReducer,
			seniors: SeniorsReducer,
			users: HealthWorkersReducer,
			es: ErrorAndSuccessReducer
		}),
		initialState
	);

	return (
		<HomeContext.Provider
			value={{
				devicesStore,
				devicesDispatch,

				store,
				dispatch,

				esState,
				esDispatch
			}}>
			{children}
		</HomeContext.Provider>
	);
};

export const HomeContextConsumer = HomeContext.Consumer;
