import React, { useEffect, useState } from "react";
import MessagesBoxForManager from "./MessagesBoxForManager";
import MessagesBoxForSupervisor from "./MessagesBoxForSupervisor";
import AccessControl from "../../layout/AccessControl";
import SelectHomeInput from "../../layout/SelectHomeInput";
import * as types from "../../constants/TypesConstants";
import * as p from "../../constants/Permissions";

export default function HandleMessages({
	userState,
	homeId,
	startDate,
	endDate,
	msgState,
	msgDispatch,
	notificationsDispatch
}) {
	const allFamiliesMailboxAccess = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.ALL_FAMILY_USER_MESSAGES]);
	const messages = msgState.messages;
	const filter = msgState.filter;
	const [msgToDisplay, setMsgToDisplay] = useState([]);

	useEffect(() => {
		const filterMessages = filter => {
			switch (filter) {
				case "msg":
					setMsgToDisplay(
						messages.filter(
							msg =>
								msg.type === types.MSG_FROM_FAMILY_TO_USER ||
								msg.type === types.MSG_FROM_USER_TO_FAMILY ||
								msg.type === types.MULTI_MSG_FROM_USER_TO_FAMILY
						)
					);
					break;
				case "visio":
					setMsgToDisplay(
						messages.filter(
							msg =>
								msg.type === types.FAMILY_ASK_FOR_VISIO ||
								msg.type === types.FAMILY_CANCEL_VISIO ||
								msg.type === types.FAMILY_ACCEPT_VISIO ||
								msg.type === types.FAMILY_REJECT_VISIO ||
								msg.type === types.USER_ACCEPT_VISIO ||
								msg.type === types.USER_REJECT_VISIO ||
								msg.type === types.USER_ASK_FOR_VISIO ||
								msg.type === types.USER_CANCEL_VISIO
						)
					);
					break;
				default:
					setMsgToDisplay(messages);
			}
		};
		if (filter) {
			filterMessages(filter);
		}
	}, [messages, filter, homeId]);

	return homeId ? (
		allFamiliesMailboxAccess ? (
			<MessagesBoxForManager
				userState={userState}
				homeId={homeId}
				startDate={startDate}
				endDate={endDate}
				msgState={msgState}
				msgDispatch={msgDispatch}
				notificationsDispatch={notificationsDispatch}
				msgToDisplay={msgToDisplay}
			/>
		) : (
			<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.MY_FAMILY_USER_MESSAGES]}>
				<MessagesBoxForSupervisor
					msgState={msgState}
					msgDispatch={msgDispatch}
					notificationsDispatch={notificationsDispatch}
					userState={userState}
					homeId={homeId}
					startDate={startDate}
					endDate={endDate}
					msgToDisplay={msgToDisplay}
				/>
			</AccessControl>
		)
	) : (
		<SelectHomeInput />
	);
}
