import React, { useEffect, useState } from "react";
import { MailBoxTabs } from "../utils/Tabs/TabsToDisplay";
import { FamilyInterface, UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import SelectHomeInput from "../layout/SelectHomeInput";
import AccessControl from "../layout/AccessControl";
import NewLiveMeeting from "../components/Messages/NewLiveMeeting";
import Loader from "../layout/Loader";
import Tabs from "../utils/Tabs/Tabs";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MessagesBox_CreateLivemeeting = ({ userState, homeId }: Props): JSX.Element => {
	const { store } = useStoreData({ userState, homeId });
	const seniorsFromHome = store.seniors.seniorsFromHome;
	const [families, setFamilies] = useState<FamilyInterface[]>([]);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/family_users?seniors=active`)
				.then(res => {
					const allFamilies = res.data.family_users;
					const seniorsFromFamilyList: any = [];
					allFamilies.map((family: FamilyInterface) => {
						const seniors = family.seniors;
						seniors.map(senior => {
							if (senior.home_id === homeId) {
								seniorsFromFamilyList.push({
									senior_id: senior.id,
									value: family.id,
									label: `${family.last_name} ${family.first_name}`,
									name: `${family.last_name} ${family.first_name}`,
									id: family.id
								});
							}
						});
					});
					setFamilies(seniorsFromFamilyList);
				})
				.catch(() => {
					setFamilies([]);
				});
		}
		// eslint-disable-next-line
	}, [homeId, seniorsFromHome]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.CAN_INITIATE_MESSAGES_TO_FAMILY, p.SUPER_ADMIN, p.ADMIN]}>
			<Tabs tabsToDisplay={MailBoxTabs} currentTab={"INITIATE_VISIO"} />
			<div className="big-card">
				{homeId ? (
					seniorsFromHome.length > 0 && families.length > 0 ? (
						<NewLiveMeeting seniorsFromHome={seniorsFromHome} userState={userState} families={families} />
					) : (
						<Loader />
					)
				) : (
					<SelectHomeInput />
				)}
			</div>
		</AccessControl>
	);
};
export default MessagesBox_CreateLivemeeting;
