import React from "react";
import { useTranslation } from "react-i18next";
import { getActivityAction } from "../../../utils/Activities/Activities";
import { FaAngleRight } from "react-icons/fa";
import { useForm, FormProvider } from "react-hook-form";
import HandleError from "../../../layout/HandleError";
import MyInputText from "../../../utils/Form/MyInputText";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import * as constant from "./RulesDetails";

export default function EditHomeRule({ show, setShow, setUpdate, dispatch, store }) {
	const { t } = useTranslation("common");
	const rule = show.info.rule;
	const methods = useForm();
	const { handleSubmit } = methods;

	const onSubmit = data => {
		dispatch({
			type: "HIDE_ERROR"
		});
		const ruleData = {
			checker_rule: {
				name: data.name
			}
		};
		http
			.put(`${url.BASEURL}/checker_rules/${rule.id}`, ruleData)
			.then(() => {
				setUpdate(prevUpdate => !prevUpdate);
				setShow({ show: false, info: { rule: null } });
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<div>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__editError__")} />
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MyInputText label={t("common.__name__")} name="name" behavior={{ required: true }} value={rule.name} />
					<div>
						{rule.rule.type === constant.WHEN_ACTION_HAPPEN && (
							<span>
								<FaAngleRight />
								{t("rules.__firstAlertNotification__")} <br />
								<FaAngleRight /> {t("rules.__indicator__")}: {getActivityAction(rule.rule.actions[0].action_id)}
							</span>
						)}
						{rule.rule.type === constant.IF_ACTION_REPEATED && (
							<span>
								<FaAngleRight />
								{t("rules.__alertNotificationAfter__")} {rule.rule.nb_repeat}
								<br />
								<FaAngleRight />
								{t("rules.__indicator__")} : {getActivityAction(rule.rule.actions[0].action_id)}
							</span>
						)}
						{rule.rule.type === constant.ACTION_MUST_BE_DONE_IN_RANGE && (
							<span>
								<FaAngleRight />
								{t("rules.__whenAlertNotification__")} {getActivityAction(rule.rule.actions[0].action_id)} entre{" "}
								{rule.rule.begin.hour}h{rule.rule.begin.minute < 10 ? `0${rule.rule.begin.minute}` : rule.rule.begin.minute} et{" "}
								{rule.rule.end.hour}h{rule.rule.end.minute < 10 ? `0${rule.rule.end.minute}` : rule.rule.end.minute}
								.<br />
								<FaAngleRight />
								{t("rules.__indicator__")} : {getActivityAction(rule.rule.actions[0].action_id)}
							</span>
						)}
						{rule.rule.type === constant.ACTION_MUST_NOT_BE_DONE_IN_RANGE && (
							<span>
								<FaAngleRight />
								{t("rules.__forbiddenAlertNotification__")} : {rule.rule.begin.hour}h
								{rule.rule.begin.minute < 10 ? `0${rule.rule.begin.minute}` : rule.rule.begin.minute} et {rule.rule.end.hour}h
								{rule.rule.end.minute < 10 ? `0${rule.rule.end.minute}` : rule.rule.end.minute}
								.<br />
								<FaAngleRight />
								{t("rules.__indicator__")} : {getActivityAction(rule.rule.actions[0].action_id)}
							</span>
						)}
						{rule.rule.type === constant.ACTION_MUST_BE_DONE_AFTER_THIS_ACTION && (
							<span>
								<FaAngleRight />
								{t("rules.__maxDelayAlertNotification__")} {constant.getTiming(parseInt(rule.rule.max_seconds_after))}.<br />
								<FaAngleRight />
								{t("rules.__indicator__")}: {getActivityAction(rule.rule.actions[0].action_id)}
							</span>
						)}
						{rule.rule.type === constant.ACTION_MUST_NOT_BE_DONE_IN_RANGE_OTHERWISE && (
							<span>
								<FaAngleRight />
								{t("rules.__noActionAlertNotification__")} {rule.rule.begin.hour}h
								{rule.rule.begin.minute < 10 ? `0${rule.rule.begin.minute}` : rule.rule.begin.minute} {t("common.__and__")}{" "}
								{rule.rule.end.hour}h{rule.rule.end.minute < 10 ? `0${rule.rule.end.minute}` : rule.rule.end.minute}
								.<br />
								<FaAngleRight />
								{t("rules.__indicator__")} : {getActivityAction(rule.rule.actions[0].action_id)} -{" "}
								{getActivityAction(rule.rule.actions[1].action_id)} - {getActivityAction(rule.rule.actions[2].action_id)}
							</span>
						)}
					</div>
					<button className="btn btn-primary btn-sm mt-4" type="submit">
						{t("common.__save__")}
					</button>
				</form>
			</FormProvider>
		</div>
	);
}
