import React from "react";
import { useParams } from "react-router-dom";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import HomeTimelineSettings from "../components/Home/Homes/HomeTimelineSettings";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import AccessControl from "../layout/AccessControl";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_TimelineSetting = ({ userState, homeId }: Props): JSX.Element => {
	const { seniorId } = useParams<{ seniorId: string }>();
	const { store } = useStoreData({ userState, homeId });

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_TIMELINE_FILTERS]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<HomesSecondaryNav userState={userState} />}
				component={
					<HomeTimelineSettings
						homeId={homeId}
						seniorId={parseInt(seniorId)}
						userState={userState}
						seniorsFromHome={store.seniors.seniorsFromHome}
					/>
				}
			/>
		</AccessControl>
	);
};
export default MyHome_TimelineSetting;
