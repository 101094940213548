import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegTimesCircle, FaUsers } from "react-icons/fa";
import { UserInterface } from "../../../interfaces/UserInterface";
import MyInputEmail from "../../../utils/Form/MyInputEmail";
import { useForm, FormProvider } from "react-hook-form";
import Select from "react-select";
import useStoreData from "../../../hooks/useStoreData";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import ConfirmationModal from "../../../utils/Modals/ConfirmationModal";
import ModalContainer from "../../../utils/ModalContainer";
import { IoIosPerson } from "react-icons/io";
import { Tooltip } from "../../Buttons/Tooltip";
import { BsInfoCircleFill } from "react-icons/bs";
import { TiFlowParallel } from "react-icons/ti";

interface Props {
	homeId: number;
	userState: UserInterface;
}

interface FormProps {
	value: number;
	label: string;
	email: string;
	reportType: string;
	reportFrequency: number;
	reportFrequencyAdvisor: number;
}

interface ReportConfig {
	first_name: string;
	last_name: string;
	supervisor: boolean;
	home_report: HomeReport;
}

interface HomeReport {
	email: string;
	frequency: string;
	home_id: number;
	id: number;
	integral: boolean;
	user_id: number;
}

interface ReportData {
	items: ReportConfig[];
	home_report: HomeReport;
}

interface ModalInfo {
	id: number | null;
}

const AmdReportConfigView = ({ userState, homeId }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const methods = useForm<FormProps>();
	const { store } = useStoreData({ userState, homeId });
	const healthWorkers = store.users.healthWorkers;
	const [email, setEmail] = useState("");
	const [selectedWorker, setSelectedWorker] = useState<any>(null);
	const [selectedReportType, setSelectedReportType] = useState<any>(null);
	const [selectedFrequency, setSelectedFrequency] = useState<{ value: number; label: string } | null>(null);
	const [selectedFrequencyAdv, setSelectedFrequencyAdv] = useState<{ value: number; label: string } | null>(null);
	const [, setHealthWReptConfigData] = useState(null);
	const [getRepConfigData, setGetRepConfigData] = useState<ReportData | null>(null);
	const [error, setError] = useState(false);
	const { handleSubmit, setValue, reset } = methods;
	const [messagePost, setMessagePost] = useState(false);
	const [messageUpdate, setMessageUpdate] = useState(false);
	const [showModal, setShowModal] = useState<{ show: boolean; info: ModalInfo | null }>({ show: false, info: null });
	const [showModalAdv, setShowModalAdv] = useState<{ show: boolean; info: ModalInfo | null }>({
		show: false,
		info: null
	});

	const [isChecked, setIsChecked] = useState(false);

	const healthWorkersList = healthWorkers
		.filter((worker: { active: boolean; home_id: number | null }) => worker.active && worker.home_id !== null)
		.map((worker: { first_name: string; last_name: string; id: number; email: string }) => ({
			label: `${worker.first_name} ${worker.last_name}`,
			email: worker.email,
			value: worker.id
		}));

	const groupManagerName = healthWorkers.filter((worker: any) => worker.group_id && worker.active);

	useEffect(() => {
		if (selectedWorker) {
			setValue("email", selectedWorker.email);
			setValue("label", selectedWorker.label);
			setValue("value", selectedWorker.value);
		}
	}, [selectedWorker]);

	const reportType = [
		{ label: t("reportConfig.__partialReport__"), value: t("reportConfig.__partialReport__") },
		{ label: t("reportConfig.__fullReport__"), value: t("reportConfig.__fullReport__") }
	];

	const reportFrequency = [
		{ label: t("reportConfig.__monthly__"), value: 30 },
		{ label: t("reportConfig.__bi-monthly__"), value: 14 },
		{ label: t("reportConfig.__weekly__"), value: 7 }
	];
	const reportFrequencyAdvisor = [
		{ label: t("reportConfig.__monthly__"), value: 30 },
		{ label: t("reportConfig.__bi-monthly__"), value: 14 },
		{ label: t("reportConfig.__weekly__"), value: 7 }
	];

	const handleSelectReportType = (option: any): void => {
		setSelectedReportType(option);
		setValue("reportType", option.value);
	};
	const handleSelectFrequency = (option: any): void => {
		setSelectedFrequency(option);
		setValue("reportFrequency", option.value);
	};

	const handleSelectFrequencyAdvisor = (option: any): void => {
		setSelectedFrequencyAdv(option);
		groupManagerCreateUpdate(option);
	};

	const onSubmit = async (data: any): Promise<any> => {
		if (homeId) {
			const reportParamsData = {
				home_id: homeId,
				user_id: data.value,
				email: data.email,
				integral: selectedReportType?.value === t("reportConfig.__fullReport__"),
				frequency: selectedFrequency?.value || data.reportFrequency
			};

			try {
				const postResponse = await http.post(`${url.BASEURL}/report_config/${homeId}/create`, reportParamsData);
				setHealthWReptConfigData(postResponse.data);
				setMessagePost(true);
				setError(false);

				const getResponse = await http.get(`${url.BASEURL}/report_config/${homeId}`);
				setGetRepConfigData(getResponse.data);
				reset();
				setSelectedWorker(null);
				setSelectedReportType(null);
				setSelectedFrequency(null);
				setEmail("");
			} catch (error) {
				setError(true);
				console.error("Error during POST/GET:", error);
			}
		}
	};

	const groupManagerCreateUpdate = (option: any): any => {
		if (homeId) {
			if (selectedFrequencyAdv && groupManagerDataOnly() && groupManagerDataOnly().length > 0) {
				const UpdateFrequency = {
					id: getRepConfigData?.items.find(item => item.supervisor)?.home_report.id,
					frequency: option?.value || selectedFrequencyAdv?.value || 30
				};
				http
					.put(`${url.BASEURL}/report_config/${homeId}/update_group_admin`, UpdateFrequency)
					.then(() => {
						setError(false);
						setMessageUpdate(true);
					})
					.catch(error => {
						setError(true);
						console.error("Error updating group manager:", error);
					});
			} else {
				const frequencyValue = 30;

				const reportParamsDataAdv = {
					frequency: frequencyValue
				};

				http
					.post(`${url.BASEURL}/report_config/${homeId}/create_group_admin`, reportParamsDataAdv)
					.then(() => {
						setError(false);
						setMessagePost(true);
						return http.get(`${url.BASEURL}/report_config/${homeId}`);
					})
					.then((getRes: any) => {
						setGetRepConfigData(getRes.data);
						setSelectedFrequencyAdv({ value: 30, label: t("reportConfig.__monthly__") });
					})
					.catch(error => {
						setError(true);
						console.error("Error:", error);
					});
			}
		}
	};
	const groupManagerDataOnly = (): any => {
		if (!getRepConfigData || !getRepConfigData.items) return [];
		const supervisorNames = getRepConfigData?.items
			.filter((data: ReportConfig) => data.supervisor)
			.map((data: ReportConfig) => data);
		return supervisorNames;
	};

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.BASEURL}/report_config/${homeId}`)
				.then((res: any) => {
					setError(false);
					setGetRepConfigData(res.data);
					setSelectedFrequencyAdv(res.data);

					const supervisor = res.data?.items.find((item: ReportConfig) => item.supervisor);
					if (supervisor) {
						setIsChecked(true);
						const frequency = supervisor.home_report?.frequency;
						const matchedFrequency = reportFrequencyAdvisor.find(option => option.value === frequency);
						if (matchedFrequency) {
							setSelectedFrequencyAdv(matchedFrequency);
						}
					} else {
						setIsChecked(false);
						setSelectedFrequencyAdv({ value: 30, label: t("reportConfig.__monthly__") });
					}
				})
				.catch(() => {
					setError(true);
				});
		}
	}, [homeId]);

	const handleDeleteClick = (id: number): void => {
		setShowModal({ show: true, info: { id } });
	};

	const handleDeleteGroupManagerClick = (): void => {
		const supervisorItem = getRepConfigData?.items.find((item): item is ReportConfig => item.supervisor);

		if (supervisorItem) {
			setShowModalAdv({ show: true, info: { id: supervisorItem.home_report.id } });
		} else {
			setShowModalAdv({ show: false, info: { id: null } });
		}
	};

	const handleCloseModalAdv = (): void => {
		setShowModalAdv({ show: false, info: null });
	};
	const handleCloseModal = (): void => {
		setShowModal({ show: false, info: null });
	};

	const usersDelete = (): void => {
		if (homeId && showModal.info && showModal.info.id) {
			const id = showModal.info.id;

			http
				.delete(`${url.BASEURL}/report_config/${homeId}/remove`, {
					data: { id }
				})
				.then(() => {
					setError(false);
					setGetRepConfigData(prevData => {
						if (prevData && prevData.items) {
							const updatedItems = prevData.items.filter((item: ReportConfig) => item.home_report.id !== id);

							return {
								...prevData,
								items: updatedItems
							};
						}
						return prevData;
					});
				})
				.catch(() => {
					setError(true);
					console.error("Error deleting report configuration");
				});

			setShowModal({ show: false, info: null });
		}
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
		const checked = event.target.checked;
		setIsChecked(checked);

		if (checked) {
			groupManagerCreateUpdate(selectedFrequencyAdv);
		} else {
			handleDeleteGroupManagerClick();
		}
	};
	const deleteGroupManager = (): any => {
		if (homeId && showModalAdv.info && showModalAdv.info.id) {
			const id = showModalAdv.info.id;
			http
				.delete(`${url.BASEURL}/report_config/${homeId}/remove`, {
					data: { id }
				})
				.then(() => {
					setError(false);
					setGetRepConfigData(prevData => {
						if (prevData && prevData.items) {
							const updatedItems = prevData.items.filter((item: ReportConfig) => item.home_report.id !== id);

							return {
								...prevData,
								items: updatedItems
							};
						}
						return prevData;
					});

					setSelectedFrequencyAdv({ value: t("reportConfig.__monthly__"), label: t("reportConfig.__monthly__") });
				})
				.catch(() => {
					setError(true);
					console.error("Error deleting report configuration");
				});

			setShowModalAdv({ show: false, info: null });
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setMessagePost(false);
		}, 5000);
		return () => clearTimeout(timer);
	}, [messagePost]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setMessageUpdate(false);
		}, 5000);
		return () => clearTimeout(timer);
	}, [messageUpdate]);

	return (
		<div>
			<FormProvider {...methods}>
				<div>
					<h2 className="text-secondary mb-4">
						{t("reportConfig.__reportConfig__")} <TiFlowParallel className="text-primary ml-2" size={22} />
					</h2>
				</div>
				<div className="my-3">
					{error && <p className="error-msg">{t("common.__errorOccured__")}</p>}
					{messagePost && <p className="success-msg">{t("reportConfig.__reportConfigMessage__")}</p>}
					{messageUpdate && <p className="success-msg">{t("reportConfig.__reportFreqUpdateMsg__")}</p>}
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-md-6">
							<p className="text-secondary">{t("mailbox.__recipient__")}</p>
							<Select
								placeholder={t("common.__selectRecipient__")}
								options={healthWorkersList}
								classNamePrefix="react-select"
								className="mt-4"
								onChange={(selectedOption: any): void => {
									setSelectedWorker(selectedOption);
									setEmail(selectedOption ? selectedOption.email : "");
								}}
								value={selectedWorker}
								isClearable
							/>
						</div>
						<div className="col-md-6">
							<p className="text-secondary">{t("common.__mail__")}</p>
							<MyInputEmail
								name="email"
								behavior={{ required: true }}
								value={email}
								placeholder={t("common.__mail__")}
								onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setEmail(e.target.value)}
							/>
						</div>
						<div className="col-md-6">
							<p className="text-secondary">
								{t("reportConfig.__reportType__")}{" "}
								<Tooltip text={t("reportConfig.__tooltipReport__")}>
									<BsInfoCircleFill className="text-warning ml-2" />
								</Tooltip>
							</p>

							<Select
								placeholder={t("reportConfig.__selectReportType__")}
								options={reportType}
								classNamePrefix="react-select"
								className="mt-4 meta"
								onChange={handleSelectReportType}
								value={selectedReportType}
							/>
						</div>

						<div className="col-md-6">
							<p className="text-secondary">
								{t("reportConfig.__reportFrequency__")}{" "}
								<Tooltip text={t("reportConfig.__tooltipFrequency__")}>
									<BsInfoCircleFill className="text-warning ml-2" />
								</Tooltip>
							</p>
							<Select
								placeholder={t("reportConfig.__selectReportFrequency__")}
								options={reportFrequency}
								classNamePrefix="react-select"
								className="mt-4 meta"
								onChange={handleSelectFrequency}
								value={selectedFrequency}
							/>
						</div>

						<div className="col-12 mt-4">
							<button type="submit" className="btn btn-outline-primary btn-sm  rounded-pill pl-2 pr-2">
								{t("common.__save__")}
							</button>
						</div>
					</div>
				</form>
			</FormProvider>
			<h2 className="mt-4 text-secondary">
				{t("reportConfig.__amdAdvisor__")} {""} <IoIosPerson className="text-primary mb-2 ml-2" size={24} />
			</h2>

			<>
				<div className="mt-4 mb-4">
					<div className="row">
						<div className="col-md-12">
							<div className="content-wrapper">
								<div className="d-flex align-items-center">
									<input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} className="mr-2" />
									{t("reportConfig.__sendReport__")}
									<span style={{ display: "inline-block" }}>
										<Select
											placeholder={t("reportConfig.__selectReportFrequency__")}
											options={reportFrequencyAdvisor}
											className="meta mx-2"
											onChange={handleSelectFrequencyAdvisor}
											isDisabled={!isChecked}
											value={
												selectedFrequencyAdv || { value: t("reportConfig.__monthly__"), label: t("reportConfig.__monthly__") }
											}
										/>
									</span>
									{t("common.__to__")}
									<span className={isChecked ? "font-weight-bold text-primary ml-1" : "font-weight-bold text-secondary ml-1"}>
										{groupManagerName[0] ? groupManagerName[0].first_name + " " + groupManagerName[0].last_name : "à definir"}
									</span>
								</div>

								<ModalContainer show={showModalAdv} setShow={setShowModalAdv}>
									<ConfirmationModal
										show={showModalAdv.show}
										onClose={handleCloseModalAdv}
										onConfirm={deleteGroupManager}
										message={t("reportConfig.__confirmDeletion__")}
									/>
								</ModalContainer>
							</div>
						</div>
					</div>
				</div>
			</>

			<h2 className="mt-2 text-secondary">
				{t("reportConfig.__recipients__")} {""} <FaUsers className="text-primary ml-2" size={28} />
			</h2>
			{getRepConfigData && getRepConfigData.items.filter((data: ReportConfig) => !data.supervisor).length !== 0 ? (
				<div>
					<table role="table" className="table custom-table">
						<tbody role="rowgroup">
							<tr role="row">
								<th className="pb-2" role="columnheader">
									<span>{t("mailbox.__recipient__")}</span>
								</th>
								<th className="pb-2" role="columnheader">
									<span>{t("common.__mail__")}</span>
								</th>
								<th className="pb-2" role="columnheader">
									<span>{t("reportConfig.__reportType__")}</span>
								</th>
								<th className="pb-2" role="columnheader">
									<span>{t("reportConfig.__reportFrequency__")}</span>
								</th>
								<th className="pb-2" role="columnheader">
									<span>{t("common.__remove__")}</span>
								</th>
							</tr>
							{getRepConfigData.items
								.filter((data: ReportConfig) => !data.supervisor)
								.map((data: ReportConfig, index: number): any => (
									<tr role="row" key={index}>
										<td role="cell">
											<span className="text-secondary meta">
												{""} {data.first_name} {data.last_name}
											</span>
										</td>
										<td role="cell">
											<span className="text-secondary meta">{data.home_report.email}</span>
										</td>

										<td role="cell">
											<span className="text-secondary meta mb-4">
												{data.home_report.integral === true
													? t("reportConfig.__fullReport__")
													: t("reportConfig.__partialReport__")}
											</span>
										</td>
										<td role="cell">
											<span className="text-secondary meta">
												{data.home_report.frequency} {t("common.__days__")}
											</span>
										</td>
										<td>
											<FaRegTimesCircle
												className="table-icon red"
												role="button"
												onClick={(): any => handleDeleteClick(data.home_report.id)}
											/>

											<ModalContainer show={showModal} setShow={setShowModal}>
												<ConfirmationModal
													show={showModal.show}
													onClose={handleCloseModal}
													onConfirm={usersDelete}
													message={t("reportConfig.__confirmDeletion__")}
												/>
											</ModalContainer>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			) : (
				<div>{t("reportConfig.__noSelectedRecipients__")}.</div>
			)}
		</div>
	);
};

export default AmdReportConfigView;
