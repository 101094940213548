import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getActivityTitle, getActivityAction, getActivityDetail, mood } from "../../utils/Activities/Activities";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import { getCategoryClassName } from "../../utils/Activities/Activities";
import { FaAngleRight, FaVideo } from "react-icons/fa";
import { getActivityMood } from "../../utils/Activities/Smiley";
import { Link } from "react-router-dom";
import { fromToUrl } from "@e4ia/e4link_modules.datetime";
import Select from "react-select";
import useDateBeforeOrAfter from "../../hooks/useDateBeforeOrAfter";
import RangePicker from "../../utils/DatePicker/RangePicker";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as datetime from "../../utils/ActivitiesDateTime";
import "./Admin.scss";

export default function Admin({ userState, homeId, allSeniors }) {
	const { t } = useTranslation("common");
	const today = new Date();
	const lastWeek = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 7 });
	const [startDate, setStartDate] = useState(lastWeek);
	const [endDate, setEndDate] = useState(today);
	const [rawData, setRawData] = useState([]);
	const [seniors, setSeniors] = useState([]);
	const [seniorId, setSeniorId] = useState(null);

	useEffect(() => {
		if (homeId) {
			const seniorsToSelect = allSeniors.filter(senior => senior.home_id === homeId);
			setSeniors(seniorsToSelect);
		} else {
			setSeniors(allSeniors);
		}
	}, [allSeniors, homeId]);

	const displayHealthWorkerName = hw => {
		if (hw.first_name === "badge_general") {
			return `${hw.last_name}`;
		} else {
			return `${hw.first_name} ${hw.last_name}`;
		}
	};

	useEffect(() => {
		if (seniorId && startDate && endDate) {
			const fromTo = fromToUrl(startDate, endDate);
			http
				.get(`${url.SENIORSURL}/${seniorId}/raw_activities/${fromTo}`)
				.then(res => {
					const result = res.data;
					const activities = result.map(function (activity) {
						return {
							...activity,
							aid_title: `${getActivityTitle(activity.aid)}`,
							aid_action: `${getActivityAction(activity.aid)}`,
							did_string: activity.dval ? `${getActivityDetail(activity.dval, activity.aid)}` : null,
							dt_day: `${datetime.ActivitiesDate(activity.dt)}`,
							dt_time: `${datetime.ActivitiesTime(activity.dt)}`,
							hw_name: activity.hw ? displayHealthWorkerName(activity.hw) : null,
							mood: activity.aid < 6 ? mood(activity.aid) : null
						};
					});
					setRawData(activities);
				})
				.catch(() => setRawData([]));
		}
	}, [seniorId, startDate, endDate]);

	const handleAction = (aid, action, val) => {
		if (aid === 1 || aid === 2 || aid === 3 || aid === 4 || aid === 5) {
			return <>{getActivityMood(aid)}</>;
		} else if (aid === 17) {
			return <FaVideo style={{ height: 24, width: "auto", marginLeft: 10 }} />;
		} else if (aid === 10 || aid === 11 || aid === 12) {
			return <>{action}</>;
		} else {
			return (
				<>
					<span className="blue">
						<b>{action}</b>
					</span>
					{val ? (
						<>
							<br />
							{val}
						</>
					) : null}
				</>
			);
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: t("healthworker.__teamMember__"),
				accessor: "hw_name",
				Filter: SelectColumnFilter,
				Cell: row =>
					row.row.values.hw_name ? (
						<Link to={`/user/healthworker/${row.row.original.hw.id}`}>
							<span className="category-outline-badge blue">{row.row.values.hw_name}</span>
						</Link>
					) : null
			},
			{
				Header: "Id",
				accessor: "hw.id"
			},
			{
				Header: t("common.__day__"),
				accessor: "dt_day"
			},
			{
				Header: t("common.__hour__"),
				accessor: "dt_time"
			},
			{
				Header: "AID",
				accessor: "aid",
				Cell: ({ cell: { value } }) => <div className={getCategoryClassName(value)}>{value}</div>
			},
			{
				Header: t("common.__category__"),
				accessor: "aid_title",
				Filter: SelectColumnFilter,
				filter: "includes",
				Cell: row => <div className={getCategoryClassName(row.row.values.aid)}>{row.row.values.aid_title}</div>
			},
			{
				Header: t("common.__action__"),
				accessor: "aid_action",
				Filter: SelectColumnFilter,
				filter: "includes",
				Cell: row => handleAction(row.row.values.aid, row.row.values.aid_action, row.row.original.did_string)
			}
		],
		[]
	);

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-sm-6">
					<h2>{t("device.__devicesData__")}</h2>
				</div>

				<div className="col-sm-6 mt-2">
					<div className="form-row center">
						<RangePicker
							userLocale={userState.userConnected.locale}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
						/>
					</div>
				</div>
			</div>
			<Select
				placeholder={t("senior.__selectSenior__")}
				options={seniors}
				className="react-select-container mb-2 mt-3"
				classNamePrefix="react-select"
				onChange={e => setSeniorId(e.id)}
			/>
			<br />

			{rawData.length > 0 ? (
				<Table columns={columns} data={rawData} />
			) : (
				<p className="red">
					<b>
						<FaAngleRight /> {t("common.__noDataAvailable__")} {t("common.__changeChoice__")}
					</b>
				</p>
			)}
		</div>
	);
}
