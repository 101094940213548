import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { FaRegTimesCircle, FaCheckCircle, FaVideo, FaCamera, FaUserEdit, FaCalendarDay } from "react-icons/fa";
import DisplayName from "../../utils/DisplayName";
import * as url from "../../constants/Url";
import http from "../../services/Interceptor";
import Parse from "html-react-parser";

export default function NewsModeration({ homeId, notificationsDispatch, usersFromHome, userLocale }) {
	const { t } = useTranslation("common");
	const [newsCounter, setNewsCounter] = useState([]);
	const [newsToModerate, setNewsToModerate] = useState([]);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.BASEURL}/homes/${homeId}/blog_posts/to/moderate/count`)
				.then(res => setNewsCounter(res.data.count))
				.catch(() => setNewsCounter(0));

			http
				.get(`${url.BASEURL}/homes/${homeId}/blog_posts/to/moderate`)
				.then(res => {
					const result = res.data.blog_posts;
					const extendedNews = result.map(function (news) {
						const video = news.medias.filter(media => media.content_type.split("/")[0] === "video");
						const pictures = news.medias.filter(media => media.content_type.split("/")[0] === "image");
						return {
							...news,
							author: DisplayName(news.added_by, usersFromHome),
							videoCounter: video.length,
							photoCounter: pictures.length
						};
					});
					setNewsToModerate(extendedNews);
				})
				.catch(() => setNewsToModerate([]));
		}
	}, [homeId, update, usersFromHome]);

	const newsValidation = (id, boolean) => {
		const data = {
			accepted: boolean
		};
		http
			.put(`${url.BASEURL}/homes/blog_posts/${id}/moderate`, data)
			.then(() => {
				notificationsDispatch({
					type: "MODERATE_BLOG"
				});
				setUpdate(prevState => !prevState);
			})
			.catch(() => {
				notificationsDispatch({
					type: "MODERATION_ERROR"
				});
			});
	};

	return (
		<>
			{newsCounter > 0 ? (
				<p className="meta">
					{t("moderation.__moderationCounter__")} :<b> {newsCounter}</b>
				</p>
			) : (
				<p className="meta mt-3">{t("moderation.__noModerationPending__")}</p>
			)}

			{newsToModerate.length > 0 ? (
				<>
					{newsToModerate.map(news => (
						<div key={news.id} className="form-card">
							<div className="d-flex justify-content-between">
								<Link to={`/blog/post/${news.id}`}>
									<p className="blog-title">{news.title}</p>
								</Link>
								<Link to={`/blog/news/editpost/${news.id}`}>
									<button type="button" className="btn btn-sm btn-outline-primary mt-1">
										{t("common.__update__")}
									</button>
								</Link>
							</div>
							<p className="meta">
								<span className="mr-4">
									<FaCalendarDay className="mr-1" style={{ width: "1rem", height: "auto" }} />
									{handleDate(news.date, "P", userLocale)}
								</span>
								<span className="mr-4">
									<FaUserEdit className="mr-1" style={{ width: "1rem", height: "auto" }} /> {news.author}
								</span>
								<span className="mr-4">
									{news.videoCounter > 0 ? (
										<span className="green">
											<b>
												<FaVideo className="mr-1" style={{ width: "1rem", height: "auto" }} /> {news.videoCounter}
											</b>
										</span>
									) : (
										<>
											<FaVideo className="mr-1" style={{ width: "1rem", height: "auto" }} /> {news.videoCounter}
										</>
									)}
								</span>
								<span className="mr-4">
									{news.photoCounter > 0 ? (
										<span className="green">
											<b>
												<FaCamera className="mr-1" style={{ width: "1rem", height: "auto" }} /> {news.photoCounter}
											</b>
										</span>
									) : (
										<>
											<FaCamera className="mr-1" style={{ width: "1rem", height: "auto" }} /> {news.photoCounter}
										</>
									)}
								</span>
							</p>
							{news.focused_on ? (
								<p className="meta">
									{news.seniors_focused_on.map(senior => (
										<span className="small-tag grey mr-2 mt-2" key={senior.id}>
											<b>
												{senior.last_name.toUpperCase()} {senior.first_name}
											</b>
										</span>
									))}
								</p>
							) : null}
							<div className="light-separator"></div>

							{news.body ? <div className="big-card">{Parse(news.body)}</div> : null}

							<div className="d-flex justify-content-between header-details">
								<p className="mt-1">
									<b>{t("moderation.__moderation__")}</b>
								</p>
								<div>
									<FaRegTimesCircle className="moderation-card-icon-no mr-3" onClick={() => newsValidation(news.id, false)} />
									<FaCheckCircle className="moderation-card-icon-ok mr-2" onClick={() => newsValidation(news.id, true)} />
								</div>
							</div>
						</div>
					))}
				</>
			) : null}
		</>
	);
}
