import React, { useEffect, useState, useContext } from "react";
import { DiaperContext } from "../context/DiaperContext";
import { HomeStoreInterface } from "../interfaces/HomeInterface";
import { UserInterface } from "../interfaces/UserInterface";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import AccessControl from "../layout/AccessControl";
import AddSensor from "../components/DiaperConnected/Sensors/AddSensor";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";
import SelectHomeInput from "../layout/SelectHomeInput";

interface Props {
	homeId: number | null;
	userState: UserInterface;
	homeStore: HomeStoreInterface;
}

const DiaperAddSensor = ({ userState, homeId, homeStore }: Props): JSX.Element => {
	const { diaperStore, diaperDispatch } = useContext(DiaperContext);
	const [types, setTypes] = useState([]);
	const isAdmin = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN]);
	const homes = homeStore.home.allHomes;
	const homeSelected = homes.length > 0 && homeId ? homes.find(h => h.id === homeId) : null;

	useEffect(() => {
		return function cleanup(): void {
			diaperDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (isAdmin && types.length === 0) {
			http
				.get(`${url.BASEURL}/rht/diaper_sensor_types`)
				.then(res => {
					const result = res.data;
					const typesSelection = result.map((type: string) => {
						return {
							name: type,
							id: type
						};
					});
					setTypes(typesSelection);
				})
				.catch(() => setTypes([]));
		}
	}, [isAdmin, types.length]);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.ADMIN, p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					homeId ? (
						<AddSensor
							homeId={homeId}
							homeSelected={homeSelected}
							types={types}
							userState={userState}
							diaperStore={diaperStore}
							diaperDispatch={diaperDispatch}
						/>
					) : (
						<SelectHomeInput />
					)
				}
			/>
		</AccessControl>
	);
};
export default DiaperAddSensor;
