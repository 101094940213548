import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { calendarEntriesTypes, eventRecurringTypes } from "../../constants/EventType";
import MyInputText from "../../utils/Form/MyInputText";
import MyInputNumber from "../../utils/Form/MyInputNumber";
import MyInputSelect from "../../utils/Form/MyInputSelect";
import DateTimePicker from "../../utils/DatePicker/DateTimePicker";
import OnlyTimePicker from "../../utils/DatePicker/OnlyTimePicker";
import OnlyDatePicker from "../../utils/DatePicker/OnlyDatePicker";
import HandleError from "../../layout/HandleError";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as day from "../HealthWorkers/Badges/ValidityTypes";
import HandleSuccess from "../../layout/HandleSuccess";
import ModalEvent from "./ModalEvent";

export default function AddEventContainer({ userState, homeId, eventsDispatch, eventsState }) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const methods = useForm();
	const { handleSubmit } = methods;
	const userLocale = userState.userConnected.locale;
	const today = new Date();
	const [startDate, setStartDate] = useState(today);
	const [startTime, setStartTime] = useState(today);
	const [endDate, setEndDate] = useState(null);
	const [endTime, setEndTime] = useState(today);
	const [recurringType, setRecurringType] = useState(1);
	const [selectedDays, setSelectedDays] = useState([]);
	const [displayEvent, setDisplayEvent] = useState({ show: false, info: null });

	const onSubmit = data => {
		eventsDispatch({
			type: "RESET_ES"
		});
		if (startDate) {
			if (parseInt(data.recurring) === 4 && selectedDays.length === 0) {
				eventsDispatch({
					type: "ERROR_EVENTS",
					payload: t("calendar.__selectedDaysError__")
				});
			} else {
				let eventData = {};

				if (parseInt(data.recurring) === 1) {
					const start = handleDate(startDate, "yyyy-MM-dd' 'HH:mm:ss");
					const end = endDate ? handleDate(endDate, "yyyy-MM-dd' 'HH:mm:ss") : null;

					eventData = {
						home_calendar_entry: {
							begin_datetime: start,
							end_datetime: end,
							detail: data.detail,
							entry_type_id: parseInt(data.type),
							home_id: parseInt(homeId),
							info: data.info,
							recurring: false
						}
					};
				} else if (parseInt(data.recurring) === 2 || parseInt(data.recurring) === 3) {
					const start = handleDate(startDate, "yyyy-MM-dd");
					const end = endDate ? handleDate(endDate, "yyyy-MM-dd") : null;
					const beginTiming = handleDate(startTime, "HH:mm:ss");
					const endTiming = endDate ? handleDate(endTime, "HH:mm:ss") : null;
					eventData = {
						home_calendar_entry: {
							begin_date: start,
							end_date: end,
							begin_time: beginTiming,
							end_time: endTiming,
							detail: data.detail,
							entry_type_id: parseInt(data.type),
							home_id: parseInt(homeId),
							info: data.info,
							recurring: true,
							recurring_type: 1,
							days_of_week: parseInt(data.recurring) === 2 ? [1, 2, 3, 4, 5, 6, 7] : [1, 2, 3, 4, 5]
						}
					};
				} else if (parseInt(data.recurring) === 4) {
					const start = handleDate(startDate, "yyyy-MM-dd");
					const end = endDate ? handleDate(endDate, "yyyy-MM-dd") : null;
					const beginTiming = handleDate(startTime, "HH:mm:ss");
					const endTiming = endDate ? handleDate(endTime, "HH:mm:ss") : null;
					eventData = {
						home_calendar_entry: {
							begin_date: start,
							end_date: end,
							begin_time: beginTiming,
							end_time: endTiming,
							detail: data.detail,
							entry_type_id: parseInt(data.type),
							home_id: parseInt(homeId),
							info: data.info,
							recurring: true,
							recurring_type: 1,
							days_of_week: selectedDays
						}
					};
				} else if (parseInt(data.recurring) === 5) {
					const start = handleDate(startDate, "yyyy-MM-dd");
					const end = endDate ? handleDate(endDate, "yyyy-MM-dd") : null;
					const beginTiming = handleDate(startTime, "HH:mm:ss");
					const endTiming = endTime ? handleDate(endTime, "HH:mm:ss") : null;
					eventData = {
						home_calendar_entry: {
							begin_date: start,
							end_date: end,
							begin_time: beginTiming,
							end_time: endTiming,
							detail: data.detail,
							entry_type_id: parseInt(data.type),
							home_id: parseInt(homeId),
							info: data.info,
							recurring: true,
							recurring_type: 2,
							day_of_month: parseInt(data.month)
						}
					};
				}

				http
					.post(`${url.EVENTURL}`, eventData)
					.then(res => {
						eventsDispatch({
							type: "ADD_EVENT",
							payload: res.data.calendar_entry
						});
						eventsDispatch({
							type: "DISPLAY_SUCCESS",
							payload: t("calendar.__addSuccess__", {
								name: res.data.calendar_entry.info
							})
						});
						setDisplayEvent({ show: true, info: res.data.calendar_entry });
					})
					.catch(() => {
						eventsDispatch({
							type: "ERROR_EVENTS",
							payload: t("calendar.__addError__")
						});
					});
			}
		} else {
			eventsDispatch({
				type: "ERROR_EVENTS",
				payload: t("calendar.__dateError__")
			});
		}
	};

	const handleDays = (e, id) => {
		if (e.target.checked) {
			setSelectedDays([...selectedDays, id]);
		} else {
			setSelectedDays(selectedDays.filter(day => day !== id));
		}
	};

	const defineRecurringDate = recurringType => {
		switch (recurringType) {
			case 1:
				return (
					<>
						<div className="col-md-6">
							<DateTimePicker
								startDate={startDate}
								setStartDate={setStartDate}
								userLocale={userLocale}
								label={t("calendar.__eventDates__")}
							/>
						</div>
						<div className="col-md-6">
							<DateTimePicker
								startDate={endDate}
								setStartDate={setEndDate}
								userLocale={userLocale}
								label={t("calendar.__eventEndDates__")}
							/>
						</div>
					</>
				);
			case 2:
			case 3:
				return (
					<>
						<div className="col-md-6">
							<label>{t("calendar.__firstAppointmentDate__")} : </label>{" "}
							<OnlyDatePicker startDate={startDate} setStartDate={setStartDate} userLocale={userLocale} />
						</div>
						<div className="col-md-6">
							<label>{t("calendar.__lastAppointmentDate__")} : </label>{" "}
							<OnlyDatePicker startDate={endDate} setStartDate={setEndDate} userLocale={userLocale} />
						</div>
						<div className="col-md-6">
							<label>{t("calendar.__appointmentTiming__")} : </label>{" "}
							<OnlyTimePicker startTime={startTime} setStartTime={setStartTime} userLocale={userLocale} />
						</div>
						<div className="col-md-6">
							<label>{t("calendar.__endAppointment__")} : </label>{" "}
							<OnlyTimePicker startTime={endTime} setStartTime={setEndTime} userLocale={userLocale} />
						</div>
					</>
				);
			case 4:
				return (
					<>
						<div className="col-12 mb-3">
							<label>{t("calendar.__daysSelection__")} : </label>
							{day.wantedDays.map(days => (
								<div key={days.id}>
									<input type="checkbox" key={days.id} onChange={e => handleDays(e, days.id)} />{" "}
									<span className="label-checkbox">{days.name}</span>
								</div>
							))}
						</div>
						<div className="col-md-6">
							<label>{t("calendar.__firstAppointmentDate__")} : </label>{" "}
							<OnlyDatePicker startDate={startDate} setStartDate={setStartDate} userLocale={userLocale} />
						</div>
						<div className="col-md-6">
							<label>{t("calendar.__lastAppointmentDate__")} : </label>{" "}
							<OnlyDatePicker startDate={endDate} setStartDate={setEndDate} userLocale={userLocale} />
						</div>
						<div className="col-md-6">
							<label>{t("calendar.__appointmentTiming__")} : </label>{" "}
							<OnlyTimePicker startTime={startTime} setStartTime={setStartTime} userLocale={userLocale} />
						</div>
						<div className="col-md-6">
							<label>{t("calendar.__endAppointment__")} : </label>{" "}
							<OnlyTimePicker startTime={endTime} setStartTime={setEndTime} userLocale={userLocale} />
						</div>
					</>
				);
			case 5:
				return (
					<>
						<div className="col-md-4">
							<MyInputNumber
								label={t("common.__day__")}
								name="month"
								behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
								placeholder="01"
							/>
						</div>
						<div className="col-md-4">
							<label>{t("calendar.__appointmentTiming__")} : </label>{" "}
							<OnlyTimePicker startTime={startTime} setStartTime={setStartTime} userLocale={userLocale} />
						</div>
						<div className="col-md-4">
							<label>{t("calendar.__endAppointment__")} : </label>{" "}
							<OnlyTimePicker startTime={endTime} setStartTime={setEndTime} userLocale={userLocale} />
						</div>
						<div className="col-md-6 mt-2">
							<label>{t("calendar.__firstAppointmentDate__")} : </label>{" "}
							<OnlyDatePicker startDate={startDate} setStartDate={setStartDate} userLocale={userLocale} />
						</div>
						<div className="col-md-6 mt-2">
							<label>{t("calendar.__lastAppointmentDate__")} : </label>{" "}
							<OnlyDatePicker startDate={endDate} setStartDate={setEndDate} userLocale={userLocale} />
						</div>
					</>
				);
			default:
				return;
		}
	};

	return displayEvent.show ? (
		<div className="mt-3">
			<HandleSuccess success={eventsState.success} dispatch={eventsDispatch} message={eventsState.msg} />
			<ModalEvent show={displayEvent} userState={userState} />
			<button
				type="button"
				className="btn btn-sm btn-primary mt-3"
				onClick={() => setDisplayEvent({ show: false, info: null })}>
				{t("calendar.__addAnimation__")}
			</button>
		</div>
	) : (
		<FormProvider {...methods}>
			<HandleError error={eventsState.error} dispatch={eventsDispatch} message={eventsState.msg} />
			<div className="header-details">{t("calendar.__definitions__")}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row mb-4">
					<div className="col-md-6">
						<MyInputSelect
							label={t("calendar.__eventType__")}
							name="type"
							behavior={{ required: true }}
							list={calendarEntriesTypes}
							value={calendarEntriesTypes.id}
						/>
					</div>
					<div className="col-md-6">
						<MyInputText label={t("calendar.__eventName__")} name="info" behavior={{ required: true }} />
					</div>
					<div className="col-12">
						<MyInputText label={t("calendar.__eventDetails__")} name="detail" behavior={{ required: false }} />
					</div>

					<div className="col-12 mb-2">
						<MyInputSelect
							label={t("calendar.__recurring__")}
							name="recurring"
							behavior={{ required: true }}
							list={eventRecurringTypes}
							value={recurringType}
							onChange={e => setRecurringType(parseInt(e.target.value))}
						/>
					</div>

					{defineRecurringDate(recurringType)}
				</div>
				<button className="btn btn-outline-secondary btn-sm mr-2" type="button" onClick={() => history.goBack()}>
					{t("common.__back__")}
				</button>
				<button className="btn btn-primary btn-sm" type="submit">
					{t("common.__save__")}
				</button>
			</form>
		</FormProvider>
	);
}
