import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { HealthWorkersTabs } from "../utils/Tabs/TabsToDisplay";
import { HealthWorkerInterface, UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import HeaderContainer from "../layout/HeaderContainer";
import HandleUserPermissions from "../components/HealthWorkers/HandleUserPermissions";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const HealthWorkers_EditPermissions = ({ homeId, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { id } = useParams<{ id: string }>();
	const { store, dispatch } = useStoreData({ userState, homeId });
	const history = useHistory<{ user: { location: { state: { user: HealthWorkerInterface } } } }>();
	const { user } = history.location.state;
	const isAdminOrSuperAdmin = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN]);

	useEffect(() => {
		return function cleanup(): void {
			dispatch({
				type: "HIDE_ERROR"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_PERMISSIONS]}>
			<Tabs tabsToDisplay={HealthWorkersTabs} currentTab={"HEALTHWORKERS"} />
			<div className="big-card">
				<HeaderContainer
					title={t("healthworker.__team__")}
					addButton={t("healthworker.__addAccount__")}
					link={"/user/adduser/newuser"}
					permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]}
					userState={userState}
				/>
				<HandleUserPermissions
					store={store}
					healthworker={user}
					isAdminOrSuperAdmin={isAdminOrSuperAdmin}
					dispatch={dispatch}
					hwId={parseInt(id)}
				/>
			</div>
		</AccessControl>
	);
};
export default HealthWorkers_EditPermissions;
