import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";

const convertToTimezone = (utcDatetime, timezone) => {
	const userTimezone = timezone ? timezone : "Europe/Paris";
	const date = utcToZonedTime(utcDatetime, userTimezone);
	return date ? format(date, "yyyy-MM-dd' 'HH:mm:ss") : " ";
};

const DiaperReducer = (state, action) => {
	switch (action.type) {
		case "LOADING": {
			return {
				...state,
				loading: true
			};
		}
		case "GET_DIAPERS": {
			return {
				...state,
				diapers: action.payload
			};
		}
		case "GET_HOME_DIAPERS_LIST": {
			return {
				...state,
				homeDiapersList: action.payload
			};
		}
		case "UPDATE_HOME_DIAPERS_LIST": {
			return {
				...state,
				homeDiapersList: action.payload
			};
		}
		case "GET_SENIORS_INCO": {
			const payload = action.payload;
			const seniorsFromHome = payload.map(senior => {
				return {
					...senior,
					lastControl:
						senior.state === 3 ? convertToTimezone(senior.diaperRealTimeStateDateTime, action.payload.timezone) : null,
					lastSaturation: senior.diaper_last_saturation
						? convertToTimezone(senior.diaper_last_saturation, action.payload.timezone)
						: null,
					currentChange: senior.diaper_current_change
						? convertToTimezone(senior.diaper_current_change, action.payload.timezone)
						: null,
					alert: senior.state === 1 ? true : false,
					diaper: senior.diaper_id ? state.diapers.find(diaper => diaper.id === senior.diaper_id) : null,
					name: senior.last_name.toUpperCase() + " " + senior.first_name,
					provisionned: senior.provisions.length > 0 ? true : false
				};
			});
			const seniorsFromHomeWithDotationFirst = seniorsFromHome.sort((a, b) => b.provisionned - a.provisionned);
			return {
				...state,
				seniors: seniorsFromHomeWithDotationFirst,
				loading: false
			};
		}

		case "EDIT_SENIORSWITHDIAPER": {
			const seniorUpdated = state.seniors.map(senior => {
				if (senior.sensor_id === action.payload.sensor_id) {
					return {
						...senior,
						sensor_values: action.payload
					};
				}
				return senior;
			});
			return {
				...state,
				seniors: seniorUpdated
			};
		}

		case "GET_SENSORS":
			return {
				...state,
				sensors: action.payload
			};
		case "GET_SENSORS_WITH_SENIOR": {
			const sensors = action.payload.sensors;
			const seniors = action.payload.seniors;
			const handleSenior = sensor => {
				const senior = seniors.find(senior => senior.sensor_id === sensor.id);
				return senior
					? { id: senior.id, sensor_id: senior.sensor_id, name: `${senior.last_name.toUpperCase()} ${senior.first_name}` }
					: null;
			};
			const seniorsWithSensor = sensors.map(sensor => {
				return {
					...sensor,
					senior: handleSenior(sensor)
				};
			});
			return {
				...state,
				sensors: seniorsWithSensor
			};
		}
		case "ADD_SENSOR":
			return {
				...state,
				sensors: [...state.sensors, action.payload]
			};
		case "EDIT_SENSOR": {
			const updatedSensors = state.sensors.map(sensor => {
				if (sensor.id === action.payload.id) {
					return { ...action.payload };
				} else {
					return sensor;
				}
			});
			return {
				...state,
				sensors: updatedSensors
			};
		}
		case "AFFECT_SENSOR_TO_SENIOR": {
			const sensor = action.payload.sensor;
			const senior = action.payload.senior;
			const seniorsUpdated = state.seniors.map(elt => {
				if (elt.id === senior.id) {
					return { ...senior, sensor_id: sensor.id, sensor_mac_address: sensor.mac_address, sensor_type: sensor.type };
				} else {
					return elt;
				}
			});
			const sensorToAffect = {
				...action.payload.sensor,
				seniorName: action.payload.senior.name,
				senior: senior
			};
			const sensorsUpdated = state.sensors.map(elt => {
				if (elt.id === sensor.id) {
					return sensorToAffect;
				} else {
					return elt;
				}
			});
			return {
				...state,
				seniors: seniorsUpdated,
				sensors: sensorsUpdated
			};
		}

		case "UNAFFECT_SENSOR_TO_SENIOR": {
			const seniorId = action.payload.id;
			const sensorId = action.payload.sensor_id;
			const seniorsUpdated = state.seniors.map(senior => {
				if (senior.id === seniorId) {
					return { ...senior, sensor_id: null, sensor_mac_address: null, sensor_type: null };
				} else {
					return senior;
				}
			});
			const sensorsUpdated = state.sensors.map(sensor => {
				if (sensor.id === sensorId) {
					return { ...state.sensor, senior: null };
				} else {
					return sensor;
				}
			});
			return {
				...state,
				seniors: seniorsUpdated,
				sensors: sensorsUpdated
			};
		}
		case "AMD_GOALS": {
			return {
				...state,
				hasAmdGoals: { isAmdCustomer: action.payload.isAmdCustomer, goals: action.payload.goals }
			};
		}
		case "DELETE_SENSOR":
			return {
				...state,
				sensors: state.sensors.filter(sensor => sensor.id !== action.payload)
			};

		case "DISPLAY_ERROR":
			return {
				...state,
				error: true,
				success: false,
				msg: action.payload
			};

		case "DISPLAY_SUCCESS":
			return {
				...state,
				error: false,
				success: true,
				msg: action.payload
			};

		case "HIDE_ERROR":
			return {
				...state,
				error: false,
				msg: null
			};

		case "HIDE_SUCCESS":
			return {
				...state,
				success: false,
				msg: null
			};

		case "RESET_ES":
			return {
				...state,
				error: false,
				success: false,
				msg: null
			};

		default:
			return state;
	}
};

export default DiaperReducer;
