import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegBell } from "react-icons/fa";
import NotificationsToast from "./NotificationsToast";
import { NotificationSocket } from "../../utils/WebSocket";

export default function SocketComponent({ homeId, socket }) {
	const [newNotification, setNewNotification] = useState(null);
	const [bell, setBell] = useState({ new: false, read: true });
	const history = useHistory();

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			if (socket && homeId) {
				NotificationSocket(socket, homeId, setBell, setNewNotification);
			}
		}
		return () => (mounted = false);
	}, [socket, homeId]);

	useEffect(() => {
		if (bell.new) {
			toast(<NotificationsToast newNotification={newNotification} />);
		}
	}, [bell.new, newNotification]);

	const handleNotificationBell = () => {
		setBell({ new: false, read: true });
		history.push(`/notificationsboard`);
	};

	return (
		<div className="nav-header-notification" onClick={() => handleNotificationBell()} role="button" tabIndex="0">
			<FaRegBell className="nav-icon" />

			{bell.new ? (
				<>
					<span className="nav-notification-icon">
						<i></i>
					</span>
					<ToastContainer position="bottom-right" pauseOnHover />
				</>
			) : !bell.read ? (
				<span className="nav-notification-icon"></span>
			) : null}
		</div>
	);
}
