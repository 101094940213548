import React from "react";
import { useTranslation } from "react-i18next";
import { FaTimesCircle } from "react-icons/fa";
import { NewSeniorInterface } from "../../../interfaces/UserInterface";
import HandleError from "../../../layout/HandleError";
import CreateFamilyUser from "./CreateFamilyUser";
import SearchFamilyUser from "./SearchFamilyUser";

interface Props {
	newFamilyStore: NewSeniorInterface;
	newFamilyDispatch: React.Dispatch<any>;
	isModal: boolean;
	onSubmit: () => void;
	newSeniorDispatch: React.Dispatch<any>;
	display:boolean;
}

const CreateFamilyUsersContainer = ({
	newFamilyStore,
	newFamilyDispatch,
	isModal,
	onSubmit,
	newSeniorDispatch,
	display
}: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const familiesSelected = newFamilyStore.familyUsersSelected.concat(newFamilyStore.newFamilyUsers);

	return (
		<>
			<h2>{t("family.__principals__")}</h2>
			{familiesSelected.length === 0 ? null : (
				<>
					<p className="blue mt-4">
						<b>{t("senior.__selectedFamilies__")} :</b>
					</p>
					{familiesSelected.map(family => (
						<div key={family.email} aria-label="family-name" className="header-details d-flex justify-content-between">
							<p>
								<b>
									{family.last_name} {family.first_name}
								</b>{" "}
								( {family.email} )
							</p>
							<FaTimesCircle
								className="red"
								role="button"
								aria-label="remove-family-button"
								onClick={(): void => newFamilyDispatch({ type: "DEL_SELECTED_FAMILY", payload: family })}
							/>
						</div>
					))}
					<div className="d-flex">
						{familiesSelected.length < 4 ? (
							<button
								type="button"
								onClick={(): void => newFamilyDispatch({ type: "DISPLAY_ADD_BOX" })}
								className="btn btn-outline-secondary btn-sm mr-2">
								{t("senior.__addFamily__")}
							</button>
						) : null}
						{isModal ? (
							<button type="button" onClick={onSubmit} className="btn btn-primary btn-sm">
								{t("common.__save__")}
							</button>
						) : (
							<button type="button" onClick={onSubmit} className="btn btn-primary btn-sm">
								{t("common.__nextStep__")}
							</button>
						)}
					</div>
				</>
			)}
			{newFamilyStore.displayAddFamilyUser || newFamilyStore.displaySearchFamilyUser ? (
				<div className="nav nav-tabs mt-4">
					<li className="nav-item">
						<p
							className={`nav-link  ${newFamilyStore.displayAddFamilyUser && "active"}`}
							onClick={(): void => newFamilyDispatch({ type: "DISPLAY_ADD_BOX" })}>
							{t("senior.__addFamilyUser__")}
						</p>
					</li>
					<li className="nav-item">
						<p
							className={`nav-link  ${newFamilyStore.displaySearchFamilyUser && "active"}`}
							onClick={(): void => newFamilyDispatch({ type: "DISPLAY_SEARCH_BOX" })}>
							{t("family.__searchFamilyUser__")}
						</p>
					</li>
				</div>
			) : null}
			<div data-testid="family-users-selected-block">
				<HandleError
					error={newFamilyStore.error.showError}
					dispatch={newFamilyDispatch}
					message={newFamilyStore.error.errorMsg}
					noSpace
				/>
				<SearchFamilyUser newFamilyStore={newFamilyStore} newFamilyDispatch={newFamilyDispatch} />
				<CreateFamilyUser
					newFamilyStore={newFamilyStore}
					newFamilyDispatch={newFamilyDispatch}
					newSeniorDispatch={newSeniorDispatch}
					display={display}
				/>
			</div>
		</>
	);
};

export default CreateFamilyUsersContainer;
