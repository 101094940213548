const NotificationsListReducer = (state, action) => {
	switch (action.type) {
		case "GET_NOTIFICATIONS":
			return {
				...state,
				data: action.payload,
				error: false
			};

		case "EDIT_NOTIFICATION": {
			const updatedNotification = action.payload;
			const notificationUpdated = state.data.map(notification => {
				if (notification.id === updatedNotification.id) {
					return {
						...notification,
						checked: true,
						checked_by: updatedNotification.checked_by,
						checked_when: updatedNotification.checked_when,
						displayCheck: "Traité"
					};
				}
				return notification;
			});
			return {
				...state,
				data: notificationUpdated,
				error: false
			};
		}

		case "ERROR":
			return {
				...state,
				error: true
			};

		default:
			return state;
	}
};

export default NotificationsListReducer;
