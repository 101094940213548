import React, { memo, useContext, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NotificationsContext } from "../context/NotificationsContext";
import { Link, useLocation } from "react-router-dom";
import {
	FaUserCheck,
	FaLock,
	FaHome,
	FaRegEnvelope,
	FaUserMd,
	FaMapMarkerAlt,
	FaUserCog,
	FaRegCalendarAlt,
	FaBullhorn,
	FaNotesMedical,
	FaExchangeAlt
} from "react-icons/fa";
import http from "../services/Interceptor";
import * as p from "../constants/Permissions";
import * as url from "../constants/Url";
import "./Layout.scss";

export const MainNav = memo(function MainNav({ userState, mainResponsiveMenu, setMainResponsiveMenu, homeId }) {
	const { t } = useTranslation("common");
	const { notificationsState, notificationsDispatch } = useContext(NotificationsContext);
	const location = useLocation();
	const nav = location["pathname"].split("/")[1];
	const hasAdminAccess = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN]);
	const hasGroupAccess = userState.userConnected.group_id ? true : false;
	const hasMailboxAccess = userState.userConnected.is_in([
		p.ALL_FAMILY_USER_MESSAGES,
		p.MY_FAMILY_USER_MESSAGES,
		p.ADMIN,
		p.SUPER_ADMIN
	]);
	const hasPersonalMailboxAccess = userState.userConnected.is(p.MY_FAMILY_USER_MESSAGES);
	const canManageModeration = userState.userConnected.is_in([
		p.ADMIN,
		p.SUPER_ADMIN,
		p.CAN_MODERATE_HOME_BLOG,
		p.CAN_MODERATE_PHOTO_FRAME,
		p.CAN_MODERATE_SENIOR_AVATAR,
		p.CAN_MODERATE_SENIOR_LIFE_STORIES
	]);
	const canManageHealthWorkers = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]);
	const canModerateAvatar = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MODERATE_SENIOR_AVATAR]);
	const canModerateLifestories = userState.userConnected.is_in([
		p.ADMIN,
		p.SUPER_ADMIN,
		p.CAN_MODERATE_SENIOR_LIFE_STORIES
	]);
	const canModeratePhotoFrame = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MODERATE_PHOTO_FRAME]);
	const canModerateBlog = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MODERATE_HOME_BLOG]);
	const protectionManager = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]);
	const canModerateFamilyElts = canModeratePhotoFrame || canModerateAvatar || canModerateLifestories;

	const getMessagesUnreadCounter = useCallback(() => {
		if (hasPersonalMailboxAccess) {
			http
				.get(`${url.HOMESURL}/messages/unread/count`)
				.then(res => {
					notificationsDispatch({
						type: "GET_MESSAGES_COUNTER",
						payload: res.data.unread_message_count
					});
				})
				.catch(() => {
					notificationsDispatch({
						type: "GET_MESSAGES_COUNTER",
						payload: 0
					});
				});
		}
		// eslint-disable-next-line
	}, [hasPersonalMailboxAccess]);

	useEffect(() => {
		if (homeId && canModerateAvatar) {
			http.get(`${url.HOMESURL}/${homeId}/seniors/avatars/to/moderate/count`).then(res => {
				notificationsDispatch({
					type: "GET_AVATARS_COUNTER",
					payload: res.data.count
				});
			});
		}
		// eslint-disable-next-line
	}, [homeId, canModerateAvatar]);

	useEffect(() => {
		if (homeId && canModerateLifestories) {
			http.get(`${url.HOMESURL}/${homeId}/seniors/life_stories/to/moderate/count`).then(res => {
				notificationsDispatch({
					type: "GET_LIFESTORIES_COUNTER",
					payload: res.data.count
				});
			});
		}
		// eslint-disable-next-line
	}, [homeId, canModerateLifestories]);

	useEffect(() => {
		if (homeId && canModeratePhotoFrame) {
			http.get(`${url.HOMESURL}/${homeId}/seniors/photo_frame_items/to/moderate/count`).then(res => {
				notificationsDispatch({
					type: "GET_PICTURES_COUNTER",
					payload: res.data.count
				});
			});
		}
		// eslint-disable-next-line
	}, [homeId, canModeratePhotoFrame]);

	useEffect(() => {
		if (homeId && canModerateBlog) {
			http.get(`${url.HOMESURL}/${homeId}/blog_posts/to/moderate/count`).then(res => {
				notificationsDispatch({
					type: "GET_BLOG_COUNTER",
					payload: res.data.count
				});
			});
		}
		// eslint-disable-next-line
	}, [homeId, canModerateBlog]);

	useEffect(() => {
		if (hasMailboxAccess) {
			getMessagesUnreadCounter();
		}
		// eslint-disable-next-line
	}, [hasMailboxAccess]);

	const handleHomeButton = useCallback(() => {
		if (hasGroupAccess) {
			return (
				<Link to={`/manage/organisation/myhomes`} onClick={() => handleResponsivity()} style={{ textDecoration: "none" }}>
					<div className={hasAdminAccess ? "admin-main-nav-links text-center" : "main-nav-links text-center"}>
						<FaMapMarkerAlt className={nav === "manage" ? "nav-icon-selected" : "nav-icon-link"} />
						<p className="nav-text-link">{t("common.__home__")}</p>
					</div>
				</Link>
			);
		} else {
			return (
				<Link to={`/manage/mainboard`} onClick={() => handleResponsivity()} style={{ textDecoration: "none" }}>
					<div className={hasAdminAccess ? "admin-main-nav-links text-center" : "main-nav-links text-center"}>
						<FaMapMarkerAlt className={nav === "manage" ? "nav-icon-selected" : "nav-icon-link"} />
						<p className="nav-text-link">{t("common.__home__")}</p>
					</div>
				</Link>
			);
		}
		// eslint-disable-next-line
	}, [hasAdminAccess, hasGroupAccess, nav]);

	const handleResponsivity = () => {
		if (mainResponsiveMenu) {
			setMainResponsiveMenu(false);
		}
	};

	return (
		<div className="d-flex flex-column">
			<Link style={{ textDecoration: "none" }} to={`/homeboard`} onClick={() => handleResponsivity()}>
				<div className={hasAdminAccess ? "admin-main-nav-links text-center" : "main-nav-links text-center"}>
					<FaHome className={nav === "homeboard" ? "nav-icon-selected" : "nav-icon-link"} />
					<p className="nav-text-link">{t("common.__dashboard__")}</p>
				</div>
			</Link>
			{hasMailboxAccess ? (
				<Link style={{ textDecoration: "none" }} to="/msg/messagesbox" onClick={() => handleResponsivity()}>
					<div className={hasAdminAccess ? "admin-main-nav-links text-center" : "main-nav-links text-center"}>
						<div className="moderation-alert">
							<FaRegEnvelope className={nav === "msg" ? "nav-icon-selected" : "nav-icon-link"} />
							<p className="nav-text-link">{t("common.__mailbox__")}</p>
							{notificationsState.messageCounter > 0 ? (
								<span className="moderation-card-icon-notification">{notificationsState.messageCounter}</span>
							) : null}
						</div>
					</div>
				</Link>
			) : null}
			{protectionManager ? (
				<Link style={{ textDecoration: "none" }} to="/inco/cc/diaperalert/sensor" onClick={() => handleResponsivity()}>
					<div className={hasAdminAccess ? "admin-main-nav-links text-center" : "main-nav-links text-center"}>
						<FaExchangeAlt className={nav === "inco" ? "nav-icon-selected" : "nav-icon-link"} />
						<p className="nav-text-link">INCO</p>
					</div>
				</Link>
			) : null}
			{canManageModeration ? (
				<Link
					style={{ textDecoration: "none" }}
					to={canModerateFamilyElts ? "/moderation/family" : "/moderation/blog"}
					onClick={() => handleResponsivity()}>
					<div className={hasAdminAccess ? "admin-main-nav-links text-center" : "main-nav-links text-center"}>
						<div className="moderation-alert">
							<FaUserCheck className={nav === "moderation" ? "nav-icon-selected" : "nav-icon-link"} />
							<p className="nav-text-link">{t("moderation.__moderation__")}</p>
							{notificationsState.moderationCounter > 0 ? (
								<span className="moderation-card-icon-notification">{notificationsState.moderationCounter}</span>
							) : null}
						</div>
					</div>
				</Link>
			) : null}
			<Link style={{ textDecoration: "none" }} to="/blog" onClick={() => handleResponsivity()}>
				<div
					id="blog-button"
					className={hasAdminAccess ? "admin-main-nav-links text-center" : "main-nav-links text-center"}>
					<FaBullhorn className={nav === "blog" ? "nav-icon-selected" : "nav-icon-link"} />
					<p className="nav-text-link">{t("common.__blog__")}</p>
				</div>
			</Link>
			<Link style={{ textDecoration: "none" }} to="/planner/calendar" onClick={() => handleResponsivity()}>
				<div className={hasAdminAccess ? "admin-main-nav-links text-center" : "main-nav-links text-center"}>
					<FaRegCalendarAlt className={nav === "planner" ? "nav-icon-selected" : "nav-icon-link"} />
					<p className="nav-text-link">{t("calendar.__calendar__")}</p>
				</div>
			</Link>
			<Link style={{ textDecoration: "none" }} to="/tablet/hometimeline" onClick={() => handleResponsivity()}>
				<div className={hasAdminAccess ? "admin-main-nav-links text-center" : "main-nav-links text-center"}>
					<FaNotesMedical className={nav === "tablet" ? "nav-icon-selected" : "nav-icon-link"} />
					<p className="nav-text-link">{t("homeBoard.__devices__")}</p>
				</div>
			</Link>
			<Link style={{ textDecoration: "none" }} to="/senior/seniors" onClick={() => handleResponsivity()}>
				<div className={hasAdminAccess ? "admin-main-nav-links text-center" : "main-nav-links text-center"}>
					<FaUserCog className={nav === "senior" ? "nav-icon-selected" : "nav-icon-link"} />
					<p className="nav-text-link">{t("homeBoard.__seniors__")}</p>
				</div>
			</Link>
			{canManageHealthWorkers ? (
				<Link style={{ textDecoration: "none" }} to="/user/hw/healthworkers" onClick={() => handleResponsivity()}>
					<div className={hasAdminAccess ? "admin-main-nav-links text-center" : "main-nav-links text-center"}>
						<FaUserMd className={nav === "user" ? "nav-icon-selected" : "nav-icon-link"} />
						<p className="nav-text-link">{t("homeBoard.__team__")}</p>
					</div>
				</Link>
			) : null}
			{handleHomeButton()}
			{hasAdminAccess && (
				<Link style={{ textDecoration: "none" }} to="/support/admin" onClick={() => handleResponsivity()}>
					<div className={hasAdminAccess ? "admin-main-nav-links text-center" : "main-nav-links text-center"}>
						<FaLock className={nav === "support" ? "nav-icon-selected" : "nav-icon-link"} />
						<p className="nav-text-link">Admin</p>
					</div>
				</Link>
			)}
		</div>
	);
});

export default React.memo(MainNav);
