import React, { useState, useEffect, useContext } from "react";
import { BlogContext } from "../../context/BlogContext";
import ControlPanelCounters from "./ControlPanelCounters";
import ControlPanelAppointments from "./ControlPanelAppointments";
import CalendarForPanel from "./CalendarForPanel";
import HandleMessages from "./Messages/HandleMessages";
import VisioRequests from "./Messages/VisioRequests";
import CreatePost from "./Blogging/CreatePost";
import DiaperCards from "../DiaperConnected/Cards/DiaperCards";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as type from "../../constants/TypesConstants";
import "./ControlPanel.scss";
import FullBlogPost from "./Blogging/FullBlogPost";
import FullBlogContainer from "./Blogging/FullBlogContainer";
import { HomeUnitInterface, SeniorInterface, UserInterface } from "../../interfaces/UserInterface";
import NoData from "../../layout/NoData";
import { BlogPostInterface } from "../../interfaces/HomeInterface";
import { DiaperStoreInterface } from "../../interfaces/DiaperInterface";

interface Props {
	homeId: number;
	fetchMessages: boolean;
	fetchCalendar: boolean;
	isDiaperManager: boolean;
	refetch: boolean;
	diaperStore: DiaperStoreInterface;
	diaperDispatch: React.Dispatch<any>;
	userState: UserInterface;
	display: string;
	seniorsFromHome: SeniorInterface[];
	homeUnits: HomeUnitInterface[];
	isMessageManager: boolean;
	post: BlogPostInterface | null;
}

export default function ControlPanelComponent({
	homeId,
	fetchMessages,
	fetchCalendar,
	isDiaperManager,
	refetch,
	diaperStore,
	diaperDispatch,
	userState,
	display,
	seniorsFromHome,
	homeUnits,
	isMessageManager,
	post
}: Props): JSX.Element {
	const [messages, setMessages] = useState([]);
	const [visioRequests, setVisioRequests] = useState([]);
	const { blogStore, blogDispatch } = useContext(BlogContext);
	const alerts = diaperStore.seniors.length > 0 ? diaperStore.seniors.filter((elt: any) => elt.alert) : [];

	useEffect(() => {
		if (homeId && isMessageManager) {
			http
				.get(`${url.HOMESURL}/${homeId}/controlpanel/messages`)
				.then(res => {
					const data = res.data.family_user_messages;
					const visio = data.filter((visio: any) => visio.type === type.FAMILY_ASK_FOR_VISIO);
					const msg = data.filter((msg: any) => msg.type === type.MSG_FROM_FAMILY_TO_USER);
					setVisioRequests(visio);
					setMessages(msg);
				})
				.catch(() => {
					setVisioRequests([]);
					setMessages([]);
				});
		}
		// eslint-disable-next-line
	}, [homeId, fetchMessages, isMessageManager]);

	const displayComponent = (display: string): JSX.Element => {
		switch (display) {
			case "calendar":
				return (
					<CalendarForPanel homeId={homeId} locale={userState.userConnected.locale} seniorsFromHome={seniorsFromHome} />
				);
			case "visiorequest":
				return <VisioRequests data={visioRequests} userState={userState} />;
			case "newmsg":
				return <HandleMessages data={messages} userState={userState} homeId={homeId} />;

			case "diaperalerts":
				return (
					<DiaperCards
						refetch={refetch}
						userState={userState}
						diaperStore={diaperStore}
						diaperDispatch={diaperDispatch}
						homeId={homeId}
						isDiaperManager={isDiaperManager}
						isControlPanel
					/>
				);
			case "addpost":
				return (
					<CreatePost
						homeId={homeId}
						blogStore={blogStore}
						blogDispatch={blogDispatch}
						seniorsFromHome={seniorsFromHome}
						homeUnits={homeUnits}
					/>
				);
			case "post": {
				return post ? <FullBlogPost post={post} blogDispatch={blogDispatch} userState={userState} /> : <NoData />;
			}
			case "blog": {
				return (
					<FullBlogContainer homeId={homeId} userState={userState} seniorsFromHome={seniorsFromHome} managerMode={false} />
				);
			}
			case "manageposts": {
				return <FullBlogContainer homeId={homeId} userState={userState} seniorsFromHome={seniorsFromHome} managerMode />;
			}
			default:
				return isMessageManager ? (
					<ControlPanelAppointments homeId={homeId} fetchCalendar={fetchCalendar} seniorsFromHome={seniorsFromHome} />
				) : isDiaperManager ? (
					<DiaperCards
						refetch={refetch}
						userState={userState}
						diaperStore={diaperStore}
						diaperDispatch={diaperDispatch}
						homeId={homeId}
						isDiaperManager={isDiaperManager}
						isControlPanel
					/>
				) : (
					<NoData />
				);
		}
	};

	return (
		<div className="panel-container">
			<ControlPanelCounters
				messages={messages}
				visioRequests={visioRequests}
				diapersAlertCounter={alerts.length}
				isDiaperManager={isDiaperManager}
				isMessageManager={isMessageManager}
			/>
			{displayComponent(display)}
		</div>
	);
}
