import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import SeniorMemoT from "../../MemoT/SeniorMemoT";
import { FaAngleRight, FaTabletAlt } from "react-icons/fa";
import RangePicker from "../../../utils/DatePicker/RangePicker";
import * as p from "../../../constants/Permissions";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import AllActivities from "../../Timeline/AllActivities";
import useDateBeforeOrAfter from "../../../hooks/useDateBeforeOrAfter";

export default function SeniorActivities({ seniorId, senior, healthWorkers, userState }) {
	const { t } = useTranslation("common");
	const canManageTimeline = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.CAN_MANAGE_TIMELINE_FILTERS]);
	const history = useHistory();
	const today = new Date();
	const yesterday = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 1 });
	const [startDate, setStartDate] = useState(yesterday);
	const [endDate, setEndDate] = useState(today);

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-md-2">
					<Link to={`/senior/profile/${seniorId}`}>
						{senior.avatar_updated ? (
							<img src={`/storages/seniors/${seniorId}/avatar`} alt="avatar" className="img-fluid senior-avatar-activities" />
						) : senior.gender === "F" ? (
							<img src="/img/senior_female.png" alt="avatar" className="senior-avatar-activities img-fluid" />
						) : (
							<img src="/img/senior_male.png" alt="avatar" className="senior-avatar-activities img-fluid" />
						)}
					</Link>
				</div>

				<div className="col-md-6">
					<Link to={`/senior/profile/${senior.id}`}>
						<h2>{senior.label}</h2>
					</Link>
					<p>
						{senior.deviceId ? <FaTabletAlt style={{ color: "black" }} /> : <FaTabletAlt style={{ color: "Gainsboro" }} />}
						<span className="meta ml-2">
							{t("common.__room__")} {senior.room} - {t("senior.__arrivedAt__")}{" "}
							{handleDate(senior.arrival_date, "P", userState.userConnected.locale)}
						</span>
					</p>
					<p className="mt-4">
						<FaAngleRight className="mr-2" />
						<b>{t("senior.__activitiesDates__")} : </b>
					</p>
					<RangePicker
						userLocale={userState.userConnected.locale}
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
					/>
				</div>
				<div className="col-md-4">
					{canManageTimeline ? (
						<Link to={`/manage/ts/timelinesettings/${senior.id}`}>
							<button className="btn btn-sm btn-outline-secondary right mt-2">{t("home.__timelineParams__")}</button>
						</Link>
					) : (
						<button type="button" className="btn btn-sm btn-outline-secondary right" onClick={() => history.goBack()}>
							{t("common.__back__")}
						</button>
					)}
				</div>
			</div>
			<div className="blue-form-card mt-4">
				<h5>{t("memo.__memo__")}</h5>
				<SeniorMemoT
					senior={senior}
					healthWorkers={healthWorkers}
					startDate={startDate}
					endDate={endDate}
					userState={userState}
				/>
			</div>
			<AllActivities userState={userState} id={seniorId} startDate={startDate} endDate={endDate} display="senior" />
		</div>
	);
}
