import React, { useEffect, useState, useReducer } from "react";
import { useTranslation } from "react-i18next";
import useStoreData from "../../hooks/useStoreData";
import SelectHomeInput from "../../layout/SelectHomeInput";
import MainBoardContainer from "./MainBoardContainer";
import MainboardTableContainer from "./MainboardTableContainer";
import BoardFromHomeUnit from "./BoardFromHomeUnit";
import HandleSuccess from "../../layout/HandleSuccess";
import Loader from "../../layout/Loader";
import NoData from "../../layout/NoData";
import MainBoardReducer from "./MainBoardReducer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import "./MainBoard.scss";

export default function MainBoard({ userState, homeId }) {
	const { t } = useTranslation("common");
	const { store, dispatch } = useStoreData({ userState, homeId });

	const initialState = {
		data: [],
		loading: false,
		error: false,
		homeUnitId: null,
		view: "CARDS"
	};

	const [mainBoard, mainBoardDispatch] = useReducer(MainBoardReducer, initialState);
	const [showNextVisio, setShowNextVisio] = useState({ show: false, info: null, title: "" });
	const [notesShow, setNotesShow] = useState({ show: false, info: null, title: null });
	const [showDevice, setShowDevice] = useState({
		show: false,
		info: null,
		title: t("common.__room__"),
		success: false
	});
	const [showAddSeniorModal, setShowAddSeniorModal] = useState({
		show: false,
		info: null,
		title: t("common.__room__"),
		success: false
	});
	const [show, setShow] = useState({
		show: false,
		info: null,
		title: t("common.__room__"),
		success: false
	});

	useEffect(() => {
		if (homeId) {
			mainBoardDispatch({ type: "LOADING" });
			http
				.get(`${url.HOMESURL}/${homeId}/home_units/preload`)
				.then(res => {
					const data = res.data.home_units;
					const sortedData = data.map(homeUnit => {
						return {
							...homeUnit,
							sortedRooms: homeUnit.rooms.sort((a, b) => a.name.localeCompare(b.name))
						};
					});
					mainBoardDispatch({ type: "GET_DATA", payload: sortedData });
				})
				.catch(() => mainBoardDispatch({ type: "DISPLAY_ERROR" }));
		}
		return function cleanup() {
			dispatch({
				type: "RESET_ES"
			});
		};
	}, [homeId, show.success, showAddSeniorModal.success, showDevice.success]);

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-md-6">
					<h2>{t("mainboard.__homeUnitView__")}</h2>
				</div>
				<div className="col-md-6">
					<select
						name="view"
						className="form-control form-control-sm mt-3"
						onChange={e => mainBoardDispatch({ type: "SELECT_VIEW", payload: e.target.value })}
						value={mainBoard.view}>
						<option value={"CARDS"}>{t("mainboard.__homeUnitViewGrid__")}</option>
						<option value={"TABLE"}>{t("mainboard.__homeUnitViewTab__")}</option>
					</select>
				</div>
			</div>
			{mainBoard.error ? <NoData /> : null}
			{homeId ? (
				mainBoard.loading ? (
					<Loader />
				) : (
					<>
						{mainBoard.data.length > 1 ? (
							<div className="board-links mt-3" id="top">
								<span className="meta mr-2">{t("home.__homeUnits__")}: </span>
								{mainBoard.data.map(homeUnit => (
									<button
										className={homeUnit.id === mainBoard.homeUnitId ? "board-badge red" : "board-badge blue"}
										key={homeUnit.id}
										onClick={() => mainBoardDispatch({ type: "SELECT_HOME_UNIT", payload: homeUnit.id })}>
										{homeUnit.name}
									</button>
								))}
								<button
									className={mainBoard.homeUnitId ? "board-badge blue" : "board-badge red"}
									onClick={() => mainBoardDispatch({ type: "SELECT_HOME_UNIT", payload: null })}>
									{t("common.__all__")}
								</button>
							</div>
						) : null}
						<HandleSuccess success={store.es.success} dispatch={dispatch} message={store.es.msg} />
						{mainBoard.homeUnitId ? (
							<BoardFromHomeUnit
								homeUnitId={mainBoard.homeUnitId}
								mainBoard={mainBoard.data}
								view={mainBoard.view}
								show={show}
								setShow={setShow}
								showAddSeniorModal={showAddSeniorModal}
								setShowAddSeniorModal={setShowAddSeniorModal}
								homeId={homeId}
								store={store}
								dispatch={dispatch}
								notesShow={notesShow}
								setNotesShow={setNotesShow}
								userState={userState}
								showDevice={showDevice}
								setShowDevice={setShowDevice}
								showNextVisio={showNextVisio}
								setShowNextVisio={setShowNextVisio}
							/>
						) : mainBoard.view === "CARDS" ? (
							<MainBoardContainer
								mainBoard={mainBoard.data}
								show={show}
								setShow={setShow}
								homeUnitId={mainBoard.homeUnitId}
								showAddSeniorModal={showAddSeniorModal}
								setShowAddSeniorModal={setShowAddSeniorModal}
								homeId={homeId}
								store={store}
								dispatch={dispatch}
								notesShow={notesShow}
								setNotesShow={setNotesShow}
								userState={userState}
								showDevice={showDevice}
								setShowDevice={setShowDevice}
								showNextVisio={showNextVisio}
								setShowNextVisio={setShowNextVisio}
							/>
						) : mainBoard.view === "TABLE" ? (
							<MainboardTableContainer
								mainBoard={mainBoard.data}
								show={show}
								setShow={setShow}
								homeUnitId={mainBoard.homeUnitId}
								showAddSeniorModal={showAddSeniorModal}
								setShowAddSeniorModal={setShowAddSeniorModal}
								homeId={homeId}
								store={store}
								dispatch={dispatch}
								notesShow={notesShow}
								setNotesShow={setNotesShow}
								userState={userState}
								showDevice={showDevice}
								setShowDevice={setShowDevice}
								showNextVisio={showNextVisio}
								setShowNextVisio={setShowNextVisio}
							/>
						) : null}
					</>
				)
			) : (
				<SelectHomeInput />
			)}
		</div>
	);
}
