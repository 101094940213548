import React from "react";
import { useTranslation } from "react-i18next";
import SeniorSensorPreferences from "./SeniorSensorPreferences";
import SensorPreferences from "./SensorPreferences";

export default function BluetoothActivation({ diaperStore, homeId }) {
	const { t } = useTranslation("common");
	const seniorsWithDiaper = diaperStore.seniors;
	const seniorsWithSensor = seniorsWithDiaper.filter(senior => senior.sensor_id);

	return seniorsWithSensor.length > 0 ? (
		<>
			<SensorPreferences homeId={homeId} />
			<SeniorSensorPreferences seniorsWithDiaper={seniorsWithDiaper} />
		</>
	) : (
		<p>{t("diaper.__bleNotPossible__")}</p>
	);
}
