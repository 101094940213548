import React from "react";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import SelectHomeInput from "../layout/SelectHomeInput";
import AccessControl from "../layout/AccessControl";
import * as p from "../constants/Permissions";
import Tabs from "../utils/Tabs/Tabs";
import { AmdDiaperSettingsRep, DiaperSettingsTabs } from "../utils/Tabs/TabsToDisplay";
import { UserInterface } from "../interfaces/UserInterface";
import AmdReport from "../components/DiaperConnected/ReportConfig/AmdReportConfigView";

interface Props {
	homeId: number | null;
	userState: UserInterface;
	isGroupManager: number | undefined;
}

const AmdReportConfig = ({ userState, homeId }: Props): any => {
	const displayAmdSync = process.env.REACT_APP_AMD_SYNC_CONFIG === "true" ? true : false;
	const isGroupManager = userState.userConnected.group_id ?? undefined;

	

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN]}
			isGroupManager={isGroupManager}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					<>
						<Tabs tabsToDisplay={displayAmdSync ? AmdDiaperSettingsRep : DiaperSettingsTabs} currentTab={"REPORT"} />

						<div className="big-card">
							{homeId ? <AmdReport homeId={homeId} userState={userState} /> : <SelectHomeInput />}
						</div>
					</>
				}
			/>
		</AccessControl>
	);
};
export default AmdReportConfig;
