import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { UserInterface } from "../interfaces/UserInterface";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
}

const HomesSecondaryNav = ({ userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { component } = useParams<{ component: string }>();
	const location = useLocation();
	const path = location.pathname;
	const hasGroupAccess = userState.userConnected.group_id ? true : false;
	const canManageTimeline = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_TIMELINE_FILTERS]);
	const canManageHDS = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.HW_BUDGET_DISTRIBUTION]);
	const canUpdateHome = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_HOME]);
	const canDisplayHDS = process.env.REACT_APP_SHOW_HDS === "true" ? true : false;

	const displayNavElement = (link: string, text: string, componentName?: string): JSX.Element => {
		return (
			<Link to={link}>
				<p className="secondary-nav-link">
					{componentName ? (
						componentName === component ? (
							<>
								<FaRegArrowAltCircleRight className="mr-2" />
								<strong>{text}</strong>
							</>
						) : (
							text
						)
					) : path === link ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<strong>{text}</strong>
						</>
					) : (
						text
					)}
				</p>
			</Link>
		);
	};

	return (
		<>
			{hasGroupAccess ? (
				<>
					<h3 className="mt-3">{t("home.__group__")}</h3>
					{displayNavElement("/manage/organisation/myhomes", t("home.__homes__"))}
				</>
			) : null}

			<h3 className="mt-3">{t("common.__home__")}</h3>
			{displayNavElement("/manage/mainboard", t("mainboard.__homeUnitView__"))}
			{displayNavElement("/manage/hu/homeunits", t("home.__homeUnits__"), "hu")}
			{displayNavElement("/manage/room/rooms", t("home.__theRooms__"), "room")}

			<h3 className="mt-3">{t("common.__communication__")}</h3>
			{displayNavElement("/manage/hd/documents", t("documents.__homeDocs__"), "hd")}
			{canUpdateHome
				? displayNavElement("/manage/recipients/list", t("common.__communicationConfig__"), "recipients")
				: null}

			<h3 className="mt-3">{t("common.__params__")}</h3>
			{displayNavElement("/manage/me/myHome", t("common.__home__"), "me")}
			{displayNavElement("/manage/nr/notificationrules", t("notifications.__notificationsRules__"), "nr")}
			{canUpdateHome ? displayNavElement("/manage/pref/devicepreferences", t("device.__preferences__"), "pref") : null}
			{canManageTimeline ? displayNavElement("/manage/ts/timelinesettings", t("home.__timelineParams__"), "ts") : null}
			{canDisplayHDS
				? canManageHDS
					? displayNavElement("/manage/hds/budgetrepartition", t("hds.__hdsRepartition__"), "hds")
					: null
				: null}
		</>
	);
};

export default React.memo(HomesSecondaryNav);
