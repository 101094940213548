import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CustomCalendar from "../../utils/CustomCalendar";
import { SeniorInterface } from "../../interfaces/UserInterface";

interface Props {
	homeId: number;
	seniorsFromHome: SeniorInterface[];
	locale: string;
}

export default function CalendarForPanel({ homeId, seniorsFromHome, locale }: Props): JSX.Element {
	const { t } = useTranslation("common");
	const history = useHistory();

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-6">
					<h2>{t("calendar.__calendar__")}</h2>
				</div>
				<div className="col-6">
					<button
						type="button"
						className="btn btn-sm btn-outline-secondary rounded-0 right"
						onClick={(): void => history.goBack()}>
						{t("common.__back__")}
					</button>
				</div>
			</div>
			<div className="calendar-container">
				<CustomCalendar homeId={homeId} locale={locale} seniorsFromHome={seniorsFromHome} />
			</div>
		</div>
	);
}
