import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../../utils/Form/MyInputText";
import Select from "react-select";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

const HandleRecipientsContainer = ({ homeId, actualRecipients, recipientsToSelect, type, setError }) => {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const history = useHistory();
	const [recipientId, setRecipientId] = useState(null);

	const onSubmit = data => {
		setError(false);
		const recipient = {
			id: parseInt(recipientId),
			designation: data.designation
		};
		const recipients = actualRecipients.concat(recipient);

		if (type === "msg") {
			http
				.put(`${url.HOMESURL}/${homeId}/message/recipients`, { user_recipients: recipients })
				.then(() => history.push("/manage/recipients/list"))
				.catch(() => setError(true));
		} else if (type === "visio") {
			http
				.put(`${url.HOMESURL}/${homeId}/visio/recipients`, { user_recipients: recipients })
				.then(() => history.push("/manage/recipients/list"))
				.catch(() => setError(true));
		}
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Select
					placeholder={t("hds.__workerSelection__")}
					options={recipientsToSelect}
					className="basic-multi-select mb-3"
					classNamePrefix="select"
					onChange={e => setRecipientId(e.id)}
				/>

				<MyInputText label={t("common.__addDesignation__")} name="designation" behavior={{ required: false }} />

				<button type="submit" className="btn btn-sm btn-outline-primary mt-2">
					{t("common.__save__")}
				</button>
			</form>
		</FormProvider>
	);
};

export default HandleRecipientsContainer;
