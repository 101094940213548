import React from "react";
import i18n from "../i18n/i18next";
import { FaAngleRight } from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";

const selectedDays = (value: number): string => {
	switch (value) {
		case 1:
			return i18n.t("common:common.__monday__");
		case 2:
			return i18n.t("common:common.__tuesday__");
		case 3:
			return i18n.t("common:common.__wednesday__");
		case 4:
			return i18n.t("common:common.__thursday__");
		case 5:
			return i18n.t("common:common.__friday__");
		case 6:
			return i18n.t("common:common.__saturday__");
		case 7:
			return i18n.t("common:common.__sunday__");
		default:
			return "";
	}
};

export const handleValidityDetails = (type: number, values: any, userLocale: string): JSX.Element | undefined => {
	switch (type) {
		case 1: {
			const start = values[0];
			const end = values[1];
			return (
				<>
					<FaAngleRight /> {i18n.t("common:badge.__activeDates__")} {handleDate(start, "P", userLocale)}{" "}
					{i18n.t("common:common.__toward__")} {handleDate(end, "P", userLocale)}.
				</>
			);
		}
		case 2: {
			const timingStart = values[0] + ":" + values[1];
			const timingEnd = values[2] + ":" + values[3];
			return (
				<>
					<FaAngleRight /> {i18n.t("common:badge.__activeTimingFromTo__")} {timingStart} {i18n.t("common:common.__to__")}{" "}
					{timingEnd}
				</>
			);
		}
		case 3:
			return (
				<>
					<FaAngleRight />{" "}
					{values.map((value: number) => (
						<span key={value}>{selectedDays(value)} - </span>
					))}
				</>
			);
		default:
			return;
	}
};
