import React from "react";
import { useTranslation } from "react-i18next";

export default function ChangeCounters({ statistics }) {
	const { t } = useTranslation("common");

	return (
		<div className="row ">
			<div className="col-md-4">
				<div className="diaper-statistics">
					<p className="title">{t("diaper.__dirtyBed__")}</p>
					<div className="right">
						<p className="saturation-statistic">
							<b>{statistics.dirty_bed.percentages.yes} %</b>
							<br />
							<span className="title right">
								{statistics.dirty_bed.counters.yes} {t("diaper.__bed__")}
							</span>
						</p>
					</div>
					<div className="space"></div>
				</div>
			</div>
			<div className="col-md-4">
				<div className="diaper-statistics-light">
					<p className="meta">
						{t("diaper.__diaperSaturated__")} : {statistics.diaper_saturated.counters.yes}
					</p>
					<p className="meta">
						{t("diaper.__diaperTornOff__")} : {statistics.torn_off.counters.yes}
					</p>
					<p className="meta">
						{t("diaper.__presenceOfRedness__")} : {statistics.presence_of_redness.counters.yes}
					</p>
				</div>
			</div>
			<div className="col-md-4">
				<div className="diaper-statistics-light">
					<p className="meta">
						{t("diaper.__presenceOfStools__")} : {statistics.presence_of_stools.counters.yes}
					</p>
					<p className="meta">
						{t("diaper.__drawSheetChanged__")} : {statistics.draw_sheet_changed.counters.yes}
					</p>
					<p className="meta">
						{t("diaper.__bringToWc__")} : {statistics.bring_to_wc.counters.yes}
					</p>
				</div>
			</div>
		</div>
	);
}
