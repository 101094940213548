import React, { useState, useEffect } from "react";
import { UserInterface } from "../interfaces/UserInterface";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import DevicesStatus from "../components/Admin/DevicesStatus";
import HandleLocale from "../utils/HandleLocale";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
	homeId: number | null;
}

const AdminDevicesActions = ({ userState, homeId }: Props): JSX.Element => {
	const [devicesStatuts, setDevicesStatus] = useState([]);
	const userLocale = HandleLocale(userState.userConnected.locale);
	const isSuperAdmin = userState.userConnected.is(p.SUPER_ADMIN);

	useEffect(() => {
		if (homeId && isSuperAdmin) {
			http
				.get(`${url.BASEURL}/support/homes/${homeId}/devices/status`)
				.then(res => setDevicesStatus(res.data))
				.catch(() => setDevicesStatus([]));
		}
		// eslint-disable-next-line
	}, [homeId, isSuperAdmin]);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={<DevicesStatus devicesStatus={devicesStatuts} userLocale={userLocale} homeId={homeId} />}
			/>
		</AccessControl>
	);
};
export default AdminDevicesActions;
