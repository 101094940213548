import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { fromToUrl } from "@e4ia/e4link_modules.datetime";
import MessagesBoxContainer from "./MessagesBoxContainer";
import HandleSuccess from "../../layout/HandleSuccess";
import NoData from "../../layout/NoData";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as p from "../../constants/Permissions";

const SelectedFamilyMessages = ({
	userState,
	senior,
	msgState,
	msgDispatch,
	notificationsDispatch,
	startDate,
	endDate
}) => {
	const { t } = useTranslation("common");
	const seniorId = senior.id;
	const history = useHistory();
	const [seniorMessagesError, setSeniorMessagesError] = useState(false);
	const [update, setUpdate] = useState(false);
	const canManageAllFamilyMessage = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.ALL_FAMILY_USER_MESSAGES]);
	const canManageMyFamilyMessage = userState.userConnected.is(p.MY_FAMILY_USER_MESSAGES);

	useEffect(() => {
		if (seniorId) {
			const fromTo = fromToUrl(startDate, endDate);
			const getUrl = canManageAllFamilyMessage
				? `${url.SENIORSURL}/${seniorId}/messages/${fromTo}`
				: canManageMyFamilyMessage
				? `${url.MESSAGES_URL}/titles/${fromTo}`
				: null;
			setSeniorMessagesError(false);
			if (getUrl) {
				http
					.get(getUrl)
					.then(res => {
						const messages = res.data.family_user_messages;
						const messagesToDisplay = messages.filter(message => message.type > 0 && message.type < 17);
						const ordredMessages = messagesToDisplay.sort((a, b) => b.updated_at.localeCompare(a.updated_at));
						msgDispatch({
							type: "GET_MESSAGES",
							payload: ordredMessages
						});
					})
					.catch(() => setSeniorMessagesError(true));
			}
		}
		// eslint-disable-next-line
	}, [seniorId, startDate, endDate, canManageAllFamilyMessage, update, canManageMyFamilyMessage]);

	return seniorMessagesError ? (
		<NoData />
	) : (
		<div className="row mt-2">
			<div className="col-md-6">
				<h5>
					{t("common.__senior__")} :{" "}
					<Link to={`/senior/profile/${senior.id}`}>
						<b className="category-outline-badge blue">
							{senior.label}
						</b>
					</Link>
				</h5>
			</div>
			<div className="col-md-6">
				<p className="meta right mt-2" role="button" onClick={() => history.goBack()}>
					<FaAngleDoubleLeft /> {t("common.__back__")}
				</p>
			</div>
			<div className="col-12">
				<HandleSuccess success={msgState.success} dispatch={msgDispatch} message={msgState.msg} />
				<MessagesBoxContainer
					userState={userState}
					msgDispatch={msgDispatch}
					notificationsDispatch={notificationsDispatch}
					msgToDisplay={msgState.messages}
					setUpdate={setUpdate}
				/>
			</div>
		</div>
	);
};

export default SelectedFamilyMessages;
