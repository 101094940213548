import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
	FaBookmark,
	FaAngleDoubleRight,
	FaAngleDoubleDown,
	FaCheck,
	FaRegTimesCircle,
	FaAngleRight
} from "react-icons/fa";
import HeaderContainer from "../../../layout/HeaderContainer";
import DisplayTenant from "./DisplayTenant";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import * as p from "../../../constants/Permissions";
import HandleError from "../../../layout/HandleError";

export default function Tenants({ userState, tenantId, setTenantId, setHomeId }) {
	const { t } = useTranslation("common");
	const [tenants, setTenants] = useState([]);
	const [displayHomesFromGroup, setDisplayHomesFromGroup] = useState({ display: false, group: null });
	const [displayTenant, setDisplayTenant] = useState({ show: false, tenantId: null });
	const [update, setUpdate] = useState(false);
	const [error, setError] = useState(false);
	const history = useHistory();

	const handleTenant = id => {
		if (id) {
			setDisplayTenant({ show: true, tenantId: id });
			setTenantId(id);
		}
	};

	const removeTenant = id => {
		if (id) {
			setError(false);
			http
				.delete(`${url.BASEURL}/tenants/${id}`)
				.then(() => setUpdate(true))
				.catch(() => setError(true));
		}
	};

	useEffect(() => {
		http
			.get(`${url.BASEURL}/tenants/hierarchy`)
			.then(res => setTenants(res.data.tenants))
			.catch(() => setTenants([]));
	}, [update]);

	const handleHome = id => {
		setHomeId(id);
		history.push(`/manage/me/myhome`);
	};

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("common.__tenants__")}
				addButton={t("common.__add__")}
				link={"/support/tenant/admintenants/add"}
				permissionNeeded={[p.SUPER_ADMIN]}
				userState={userState}
			/>

			<HandleError error={error} setError={setError} />

			{displayTenant.show ? (
				<DisplayTenant displayTenant={displayTenant} setDisplayTenant={setDisplayTenant} />
			) : (
				tenants.map(tenant => (
					<div key={tenant.id} className="big-list-items">
						<div className="d-flex justify-content-between">
							<div className={tenantId === tenant.id ? "blue" : "fake-link"} onClick={() => handleTenant(tenant.id)}>
								<h3>
									<FaBookmark className="mr-2 blue" /> {tenant.name}
								</h3>
							</div>
							<div className="mt-1">
								<FaCheck
									className={tenantId === tenant.id ? "table-icon blue" : "table-icon"}
									role="button"
									onClick={() => handleTenant(tenant.id)}
								/>
								<FaRegTimesCircle className="table-icon ml-3" role="button" onClick={() => removeTenant(tenant.id)} />
							</div>
						</div>
						<div className="ml-4">
							{tenant.groups.map(group => (
								<div
									key={group.id}
									className="small-list-items mt-2"
									role="button"
									onClick={() =>
										displayHomesFromGroup.group === group.id
											? setDisplayHomesFromGroup({ display: false, group: null })
											: setDisplayHomesFromGroup({ display: true, group: group.id })
									}>
									<h3>
										{displayHomesFromGroup.display ? (
											displayHomesFromGroup.group === group.id ? (
												<FaAngleDoubleDown className="mr-3" />
											) : (
												<FaAngleDoubleRight className="mr-3" />
											)
										) : (
											<FaAngleDoubleRight className="mr-3" />
										)}
										{group.name}
									</h3>

									{displayHomesFromGroup.group === group.id &&
										group.homes.map(home => (
											<div key={home.id} className="ml-4">
												<FaAngleRight className="mr-2" />
												<span className="category-outline-badge blue mt-1" role="button" onClick={() => handleHome(home.id)}>
													{home.name}
												</span>
											</div>
										))}
								</div>
							))}
						</div>
						{tenant.homes.length > 0 ? (
							<div className="ml-4 mt-3">
								<h3>Etablissements indépendants :</h3>
								{tenant.homes.map(home => (
									<div key={home.id} className="mt-1" role="button" onClick={() => handleHome(home.id)}>
										<FaAngleRight className="mr-2" />
										<span className="category-outline-badge blue">{home.name}</span>
									</div>
								))}
							</div>
						) : null}
					</div>
				))
			)}
		</div>
	);
}
