import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import { FaRegEdit, FaRegTimesCircle, FaExclamationTriangle } from "react-icons/fa";
import HandleError from "../../layout/HandleError";
import HandleSuccess from "../../layout/HandleSuccess";
import DisplayName from "../../utils/DisplayName";
import { SeniorInterface } from "../../interfaces/UserInterface";
import { HomeStoreInterface } from "../../interfaces/HomeInterface";
import { IExtendedFamily } from "./FamilyListForAdminContainer";
import { Tooltip } from "../Buttons/Tooltip";

interface Props {
	homeStore: HomeStoreInterface;
	homeDispatch: React.Dispatch<any>;
	familyList: IExtendedFamily[];
	affiliates: IExtendedFamily[];
	principalCounter: number;
	setEditFamily: React.Dispatch<React.SetStateAction<any>>;
	isSuperAdmin: boolean;
	filterFamilyList: (arg: string) => void;
	unlock: (arg: number) => void;
	removeFamilyAccount: (arg: number) => void;
	resetRgpd: (arg: number) => void;
}

const FamilyListForAdmin = ({
	filterFamilyList,
	unlock,
	removeFamilyAccount,
	resetRgpd,
	homeStore,
	homeDispatch,
	familyList,
	affiliates,
	principalCounter,
	setEditFamily,
	isSuperAdmin
}: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const allHomes = homeStore.home.allHomes;

	const columns = useMemo(
		() => [
			{
				Header: t("common.__name__"),
				accessor: "name",
				Cell: (row: any) => (
					<Link to={`/senior/family/${row.row.values.id}`}>
						<span className={row.row.original.principal ? "category-badge grey" : "category-badge yellow"}>
							{row.row.original.last_name.toUpperCase()} {row.row.original.first_name}
						</span>
					</Link>
				)
			},
			{
				Header: t("common.__senior__"),
				accessor: "searchSenior",
				Cell: (row: any) =>
					row.row.original.seniors.map((senior: SeniorInterface) => (
						<div key={senior.id} className="mb-3">
							<Link to={`/senior/profile/${senior.id}`}>
								<b>
									{senior.last_name.toUpperCase()} {senior.first_name}
								</b>
							</Link>
							<br />
							<span className="meta">{DisplayName(senior.home_id, allHomes)}</span>
						</div>
					))
			},
			{
				Header: t("common.__login__"),
				accessor: "login",
				Cell: (row: any) => (
					<>
						<p>{row.row.values.login}</p>
						{row.row.original.lastSignInDate ? (
							<p>
								<span className="meta">{t("healthworker.__lastSignIn__")} :</span>
								<br />
								{row.row.original.lastSignInDate}
							</p>
						) : null}
						<p>
							<span className="meta">{t("healthworker.__signInCount__")} :</span> {row.row.original.sign_in_count}
						</p>
						{row.row.original.has_accepted_rgpd ? (
							<p className="category-outline-badge grey" role="button" onClick={(): void => resetRgpd(row.row.values.id)}>
								{t("common.__resetRgpd__")}
							</p>
						) : (
							<p className="red">
								<FaExclamationTriangle className="mr-2" />
								{t("healthworker.__rgpdNotAccepted__")}
							</p>
						)}
					</>
				)
			},
			{
				Header: t("healthworker.__locked__"),
				accessor: "locked",
				Filter: SelectColumnFilter,
				Cell: (row: any) => (
					<>
						{row.row.original.locked_at ? (
							<>
								<span className="red">
									<b>{row.row.original.locked}</b>
								</span>
								<br />
								{row.row.original.lockedDate}
								<br />
								{t("healthworker.__failedAttemps__")} : {row.row.original.failed_attempts}
								<button
									type="button"
									className="category-outline-badge red fake-link mt-2"
									onClick={(): void => unlock(row.row.values.id)}>
									Débloquer
								</button>
							</>
						) : null}
					</>
				)
			},
			{
				Header: "",
				accessor: "id",
				disableFilters: true,
				width: 70,
				Cell: (row: any) => (
					<>
						<Tooltip text={t("common.__update__")}>
							<FaRegEdit
								className="table-icon"
								onClick={(): void =>
									setEditFamily({ show: true, info: row.row.original, title: "Compte Family", success: false })
								}
							/>
						</Tooltip>
						{isSuperAdmin ? (
							<FaRegTimesCircle className="table-icon" onClick={(): void => removeFamilyAccount(row.row.values.id)} />
						) : null}
					</>
				)
			}
		],
		[isSuperAdmin, allHomes, familyList]
	);

	return (
		<>
			<HandleError error={homeStore.es.error} dispatch={homeDispatch} message={homeStore.es.msg} />
			<HandleSuccess success={homeStore.es.success} dispatch={homeDispatch} message={homeStore.es.msg} />
			<div className="row">
				<div className="col-6">
					<p className="colored-tag grey" role="button" onClick={(): void => filterFamilyList("principal")}>
						<span className="mr-2">
							{t("family.__principal__")} : {principalCounter}
						</span>
					</p>
				</div>
				<div className="col-6">
					<p className="colored-tag yellow" role="button" onClick={(): void => filterFamilyList("affiliate")}>
						<span className="mr-2">
							{t("family.__affiliate__")} : {affiliates.length}
						</span>
					</p>
				</div>
			</div>
			<Table columns={columns} data={familyList} />
		</>
	);
};

export default FamilyListForAdmin;
