import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import {
	FaHeart,
	FaCamera,
	FaVideo,
	FaUserCog,
	FaRegFilePdf,
	FaRegHeart,
	FaRegCommentAlt,
	FaCommentAlt
} from "react-icons/fa";
import { DisplayRemovePostModal } from "./NewsMediaModal";
import { handleEditRemoveButtons } from "./BlogUtils";
import WhoLikeModal from "./WhoLikeModal";
import HandleSuccess from "../../layout/HandleSuccess";
import ModalContainer from "../../utils/ModalContainer";
import Loader from "../../layout/Loader";
import BlogStatusIcon from "../Buttons/BlogStatusIcon";
import * as p from "../../constants/Permissions";
import "./News.scss";

export default function NewsList({ blogStore, blogDispatch, userState, seniorsFromHome }) {
	const { t } = useTranslation("common");
	const userId = userState.userConnected.id;
	const blogPosts = blogStore.news;
	const isBlogger = userState.userConnected.is(p.HOME_BLOG_WRITER);
	const isModerator = userState.userConnected.is(p.CAN_MODERATE_HOME_BLOG);
	const canRemovePost = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_POST_MODIFY_HOME_BLOG]);
	const [show, setShow] = useState({ show: false, info: null, title: " " });
	const [showRemoveConfirmation, setShowRemoveConfirmation] = useState({
		show: false,
		info: { postId: null, postTitle: null },
		title: t("news.__removePostWarningTitle__")
	});

	const columns = [
		{
			Header: t("common.__status__"),
			accessor: "published",
			Filter: SelectColumnFilter,
			maxWidth: 60,
			width: 60,
			Cell: row => <BlogStatusIcon status={row.row.original.accepted} title={row.row.values.published} />
		},
		{
			Header: t("common.__date__"),
			accessor: "eventDate",
			maxWidth: 80,
			width: 70
		},
		{
			Header: t("news.__title__"),
			accessor: "title",
			minWidth: 120,
			Cell: row => (
				<>
					<Link to={`/blog/post/${row.row.original.id}`}>
						<b>{row.row.values.title}</b>
					</Link>
					<div className="meta">
						<FaCamera className="kpi-icon mr-2" />
						<span className="mr-2">{row.row.original.photoCounter}</span>
						<FaRegFilePdf className="kpi-icon mr-2" />
						<span className="mr-2">{row.row.original.pdfFiles ? row.row.original.pdfFiles.length : 0}</span>
						<FaVideo className="kpi-icon mr-2" />
						<span className="mr-2">{row.row.original.video ? row.row.original.video.length : 0}</span>
						<FaUserCog className="kpi-icon mr-2" />
						{row.row.original.focused_on ? row.row.original.seniors_focused_on.length : 0}
					</div>
				</>
			)
		},
		{
			Header: t("news.__author__"),
			accessor: "author",
			Filter: SelectColumnFilter,
			minWidth: 90,
			width: 110
		},
		{
			Header: t("news.__like__"),
			accessor: "like_counter",
			disableFilters: true,
			maxWidth: 85,
			width: 75,
			Cell: row =>
				row.row.original.accepted ? (
					<>
						<span
							className="center fake-link mr-1"
							onClick={() => setShow({ show: true, info: row.row.original.id, title: t("news.__like__") })}>
							{row.row.values.like_counter > 0 ? (
								<span className="like-button mr-2">
									<FaHeart className="kpi-icon" /> {row.row.values.like_counter}
								</span>
							) : (
								<span className="mr-2">
									<FaRegHeart className="kpi-icon red" /> <span className="meta">0</span>
								</span>
							)}
						</span>
						{row.row.original.comment_counter > 0 ? (
							<span className="meta mr-2">
								<FaCommentAlt className="kpi-icon " /> <b>{row.row.original.comment_counter}</b>
							</span>
						) : (
							<span className="mr-2 darkgrey">
								<FaRegCommentAlt className="kpi-icon" /> 0
							</span>
						)}
					</>
				) : null
		},
		{
			Header: "",
			accessor: "id",
			disableFilters: true,
			maxWidth: 65,
			width: 60,
			Cell: row => (
				<div className="d-inline right mr-5">
					{handleEditRemoveButtons(
						row.row.original.accepted,
						row.row.original.added_by,
						row.row.original.id,
						row.row.original.title,
						isBlogger,
						canRemovePost,
						userId,
						isModerator,
						setShowRemoveConfirmation
					)}
				</div>
			)
		}
	];

	return (
		<>
			{blogStore.loading ? (
				<Loader />
			) : blogPosts.length === 0 ? (
				<p className="meta mt-4">{t("news.__noNews__")}</p>
			) : (
				<>
					<HandleSuccess success={blogStore.success} dispatch={blogDispatch} message={t("common.__actionSuccess__")} />
					<Table columns={columns} data={blogPosts} />
				</>
			)}
			<ModalContainer show={show} setShow={setShow}>
				<WhoLikeModal show={show} seniorsFromHome={seniorsFromHome} />
			</ModalContainer>
			<ModalContainer show={showRemoveConfirmation} setShow={setShowRemoveConfirmation}>
				<DisplayRemovePostModal
					show={showRemoveConfirmation}
					setShow={setShowRemoveConfirmation}
					blogDispatch={blogDispatch}
					redirection={false}
				/>
			</ModalContainer>
		</>
	);
}
