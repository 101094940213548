import React, { useEffect } from "react";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import "./Layout.scss";

const SelectGroupAndHomeInput = ({
	isAdmin,
	hasGroupAccess,
	setGroupId,
	setHomeId,
	homeId,
	groupId,
	tenantId,
	setTenantId,
	isSuperAdmin,
	homeStore,
	homeDispatch
}) => {
	const { t } = useTranslation("common");

	useEffect(() => {
		if (isSuperAdmin) {
			if (homeStore.home.tenants.length === 0) {
				http
					.get(`${url.BASEURL}/tenants`)
					.then(res => {
						homeDispatch({
							type: "GET_TENANTS",
							payload: res.data.tenants
						});
					})
					.catch(() => {
						homeDispatch({
							type: "GET_TENANTS",
							payload: []
						});
					});
			}
		}
	}, [isSuperAdmin]);

	useEffect(() => {
		if (isSuperAdmin) {
			if (tenantId) {
				http
					.get(`${url.BASEURL}/tenants/${tenantId}/home_groups`)
					.then(res => {
						homeDispatch({
							type: "GET_GROUPS",
							payload: res.data.home_groups
						});
					})
					.catch(() => {
						homeDispatch({
							type: "GET_GROUPS",
							payload: []
						});
					});
				http
					.get(`${url.BASEURL}/tenants/${tenantId}/homes`)
					.then(res => {
						homeDispatch({
							type: "GET_ALL_HOMES",
							payload: res.data.homes
						});
					})
					.catch(() => {
						homeDispatch({
							type: "GET_ALL_HOMES",
							payload: []
						});
					});
			} else {
				http
					.get(`${url.BASEURL}/home_groups`)
					.then(res => {
						homeDispatch({
							type: "GET_GROUPS",
							payload: res.data.home_groups
						});
					})
					.catch(() => {
						homeDispatch({
							type: "GET_GROUPS",
							payload: []
						});
					});
				http
					.get(`${url.BASEURL}/homes`)
					.then(res => {
						homeDispatch({
							type: "GET_ALL_HOMES",
							payload: res.data.homes
						});
					})
					.catch(() => {
						homeDispatch({
							type: "GET_ALL_HOMES",
							payload: []
						});
					});
			}
		}
		if (groupId) {
			http
				.get(`${url.BASEURL}/home_groups/${groupId}?homes=true`)
				.then(res => {
					homeDispatch({
						type: "GET_HOMES_FROM_GROUP",
						payload: res.data.home_group.homes
					});
				})
				.catch(() => {
					homeDispatch({
						type: "GET_HOMES_FROM_GROUP",
						payload: []
					});
				});
		}
	}, [isSuperAdmin, tenantId, groupId]);

	const displayName = (id, array) => {
		const data = array.find(data => data.id === id);
		if (data) {
			return data;
		} else {
			return null;
		}
	};

	useEffect(() => {
		if (isAdmin && tenantId) {
			if (homeStore.home.groups.length === 0) {
				http
					.get(`${url.BASEURL}/home_groups`)
					.then(res => {
						homeDispatch({
							type: "GET_GROUPS",
							payload: res.data.home_groups
						});
					})
					.catch(() => {
						homeDispatch({
							type: "GET_GROUPS",
							payload: []
						});
					});
			}
		}
		// eslint-disable-next-line
	}, [tenantId, isAdmin]);

	useEffect(() => {
		if (isAdmin && tenantId) {
			if (groupId) {
				http
					.get(`${url.BASEURL}/home_groups/${groupId}?homes=true`)
					.then(res => {
						homeDispatch({
							type: "GET_HOMES_FROM_GROUP",
							payload: res.data.home_group.homes
						});
					})
					.catch(() => {
						homeDispatch({
							type: "GET_HOMES_FROM_GROUP",
							payload: []
						});
					});
			} else {
				if (homeStore.home.allHomes.length === 0) {
					http
						.get(`${url.BASEURL}/homes`)
						.then(res => {
							homeDispatch({
								type: "GET_ALL_HOMES",
								payload: res.data.homes
							});
						})
						.catch(() => {
							homeDispatch({
								type: "GET_ALL_HOMES",
								payload: []
							});
						});
				}
			}
		}
		// eslint-disable-next-line
	}, [tenantId, groupId, isAdmin]);

	const allHomes = homeStore.home.allHomes;
	const homesFromGroup =
		allHomes.length > 0 ? (groupId ? allHomes.filter(home => home.group_id === groupId) : allHomes) : [];
	const tenants = homeStore.home.tenants;
	const groups = homeStore.home.groups;

	const handleGroupId = id => {
		if (id) {
			setGroupId(parseInt(id));
			setHomeId(null);
		} else {
			setGroupId(null);
		}
	};

	const handleTenantId = id => {
		if (id) {
			setTenantId(parseInt(id));
			setGroupId(null);
			setHomeId(null);
		} else {
			setTenantId(null);
		}
	};

	return (
		<>
			{isSuperAdmin ? (
				<div className="row">
					<div className="col-4">
						<Select
							value={tenantId ? displayName(tenantId, tenants) : null}
							onChange={e => (e ? handleTenantId(e.value) : setTenantId(null))}
							options={tenants}
							placeholder={t("common.__tenant__")}
							isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
							classNamePrefix="react-select"
							isClearable
						/>
					</div>
					<div className="col-4">
						<Select
							value={groupId ? displayName(groupId, groups) : null}
							onChange={e => (e ? handleGroupId(e.value) : setGroupId(null))}
							options={groups}
							placeholder={t("home.__group__")}
							isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
							classNamePrefix="react-select"
							isClearable
						/>
					</div>
					<div className="col-4">
						{groupId ? (
							<Select
								value={homeId ? displayName(homeId, homesFromGroup) : null}
								onChange={e => (e ? setHomeId(e.value) : setHomeId(null))}
								options={homesFromGroup}
								placeholder={t("common.__home__")}
								isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
								classNamePrefix="react-select"
								isClearable
							/>
						) : (
							<Select
								value={homeId ? displayName(homeId, allHomes) : null}
								onChange={e => (e ? setHomeId(e.value) : setHomeId(null))}
								options={allHomes}
								placeholder={t("common.__home__")}
								isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
								classNamePrefix="react-select"
								isClearable
							/>
						)}
					</div>
				</div>
			) : null}
			{isAdmin ? (
				<div className="row">
					<div className="col-6">
						<Select
							value={groupId ? displayName(groupId, groups) : null}
							onChange={e => (e ? handleGroupId(e.value) : setGroupId(null))}
							options={groups}
							placeholder={t("home.__group__")}
							isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
							classNamePrefix="react-select"
							isClearable
						/>
					</div>
					<div className="col-6">
						{groupId ? (
							<Select
								value={homeId ? displayName(homeId, homesFromGroup) : null}
								onChange={e => (e ? setHomeId(e.value) : setHomeId(null))}
								options={homesFromGroup}
								placeholder={t("common.__home__")}
								isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
								classNamePrefix="react-select"
								isClearable
							/>
						) : (
							<Select
								value={homeId ? displayName(homeId, allHomes) : null}
								onChange={e => (e ? setHomeId(e.value) : setHomeId(null))}
								options={allHomes}
								placeholder={t("common.__home__")}
								isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
								classNamePrefix="react-select"
								isClearable
							/>
						)}
					</div>
				</div>
			) : null}

			{hasGroupAccess ? (
				<Select
					value={homeId ? displayName(homeId, allHomes) : null}
					onChange={e => (e ? setHomeId(e.value) : setHomeId(null))}
					options={allHomes}
					placeholder={t("home.__homes__")}
					isClearable
					isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
					classNamePrefix="react-select"
				/>
			) : null}
		</>
	);
};

export default SelectGroupAndHomeInput;
