import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Event.scss";
import { FaFileUpload, FaTimes } from "react-icons/fa";
import ErrorContainer from "../../layout/ErrorContainer";
import http from "../../services/Interceptor";
import * as constant from "../../constants/Url";

export default function Excel({ homeId }) {
	const { t } = useTranslation("common");
	const [file, setFile] = useState(null);
	const [filename, setFilename] = useState(null);
	const [errorTemplate, setErrorTemplate] = useState(false);
	const [httpResponse, setHttpResponse] = useState(null);

	const onSelectFile = e => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.readAsDataURL(e.target.files[0]);
			setFile(e.target.files[0]);
			setFilename(e.target.files[0].name);
		}
	};

	const onSubmit = e => {
		e.preventDefault();

		const formData = new FormData();
		formData.append("xlsx", file);

		http
			.post(`${constant.HOMESURL}/${homeId}/home_calendar_entries/excel/upload`, formData)
			.then(() => setHttpResponse({ error: false }))
			.catch(err => {
				if (err.response.status === 400) {
					setErrorTemplate(true);
				} else {
					setHttpResponse({ error: true });
				}
			});
	};

	return (
		<div className="row mt-3">
			<div className="col-md-1">
				<p className="big-number">1</p>
			</div>
			<div className="col-md-9 mt-2">
				<h3 className="ml-3">{t("calendar.__downloadTemplate__")}</h3>
			</div>
			<div className="col-md-2 mt-2">
				<a href={`${constant.EVENTURL}/excel/template`} className="template-button right">
					Template.xlsx
				</a>
			</div>

			<div className="col-md-1">
				<p className="big-number">2</p>
			</div>
			<div className="col-md-11 mt-2">
				<h3 className="ml-3">{t("calendar.__excelSpec__")}</h3>
				<ul>
					<li>
						<p>{t("calendar.__excelSpecType__")}</p>
					</li>
					<li>
						<p>{t("calendar.__excelSpecStart__")}</p>
					</li>
					<li>
						<p>{t("calendar.__excelSpecEnd__")}</p>
					</li>
					<li>
						<p>{t("calendar.__excelSpecName__")}</p>
					</li>
					<li>
						<p>{t("calendar.__excelSpecDetails__")}</p>
					</li>
				</ul>
				<img src="/img/template-xlsx-event.png" className="img-fluid" alt="Modèle de template Excel" />
				<div className="mb-3"></div>
			</div>
			<div className="col-md-1">
				<p className="big-number">3</p>
			</div>
			<div className="col-md-11 mt-2">
				<h3 className="ml-3">{t("calendar.__excelUpload__")}</h3>
				<form onSubmit={e => onSubmit(e)}>
					{errorTemplate ? (
						<p className="error-msg">
							{t("calendar.__excelUploadError__")}
							<span className="right" role="button" tabIndex="0" onClick={() => setErrorTemplate(false)}>
								<FaTimes />
							</span>
						</p>
					) : null}

					<ErrorContainer
						alert={t("calendar.__excelUploadAlert__")}
						success={t("calendar.__excelUploadSuccess__")}
						httpResponse={httpResponse}
						setHttpResponse={setHttpResponse}
					/>

					<div className="center mt-2">
						<label htmlFor="xlsx" type="file" className="excel-big-badge">
							<FaFileUpload className="mr-2" />
							{t("calendar.__excelSelection__")}
							<input
								id="xlsx"
								type="file"
								onChange={onSelectFile}
								name="xlsx"
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								style={{ display: "none" }}
							/>
						</label>

						{filename ? (
							<>
								<p className="success">
									{t("calendar.__excelFileSelected__")} : <strong>{filename}</strong>
								</p>
								<button className="btn btn-sm btn-outline-primary mt-2" type="submit">
									{t("common.__validate__")}
								</button>
							</>
						) : null}
					</div>
				</form>
			</div>
		</div>
	);
}
