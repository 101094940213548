class User {
	constructor(first_name, last_name, type_id, permissions, tenant_id, group_id, home_id, id, uiprefs, locale, timezone) {
		this.first_name = first_name;
		this.last_name = last_name;
		this.type_id = type_id;
		this.permissions = new Set(permissions);
		this.tenant_id = tenant_id;
		this.group_id = group_id;
		this.home_id = home_id;
		this.id = id;
		this.uiprefs = uiprefs ? uiprefs : null;
		this.locale = locale;
		this.timezone = timezone;
	}

	is(permission) {
		if (this.permissions.has(permission)) {
			return true;
		} else {
			return false;
		}
	}

	is_in(permissionsNeeded) {
		if (permissionsNeeded.length > 0) {
			for (const p of permissionsNeeded) {
				if (this.permissions.has(p)) {
					return true;
				}
			}
		}
		return false;
	}
}

export default User;
