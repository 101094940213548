import React from "react";
import { FaTimes } from "react-icons/fa";

export interface ShowState {
	show: boolean;
	info: any;
	title?: string;
	success?: boolean;
}

interface Props {
	show: ShowState;
	setShow: React.Dispatch<React.SetStateAction<ShowState>>;
	children: React.ReactElement;
}

const ModalContainer = ({ show, setShow, children }: Props): JSX.Element | null => {
	return show.show ? (
		<div className="overlay">
			<div className="dialog">
				<div
					className="close-modal"
					role="button"
					onClick={(): void => setShow({ show: false, info: null, title: "", success: false })}>
					<FaTimes />
				</div>
				{show.title ? <h2>{show.title}</h2> : ""}
				{children}
			</div>
		</div>
	) : null;
};
export default ModalContainer;
