import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { StoreInterface } from "../../../interfaces/UserInterface";
import useDateBeforeOrAfter from "../../../hooks/useDateBeforeOrAfter";
import MyInputSelect from "../../../utils/Form/MyInputSelect";
import MyInputNumber from "../../../utils/Form/MyInputNumber";
import RangePicker from "../../../utils/DatePicker/RangePicker";
import SelectHomeInput from "../../../layout/SelectHomeInput";
import HandleError from "../../../layout/HandleError";
import http from "../../../services/Interceptor";
import * as constant from "./ValidityTypes";
import * as url from "../../../constants/Url";

interface Props {
	userLocale: string;
	store: StoreInterface;
	dispatch: React.Dispatch<any>;
	homeId: number | null;
	isModal?: boolean;
	badgesAvailableDispatch?: React.Dispatch<any>;
	setShow?: (args: { show: boolean; info: any }) => void;
}

const CreateHealthWorkerBadge = ({
	userLocale,
	store,
	dispatch,
	homeId,
	isModal,
	badgesAvailableDispatch,
	setShow
}: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const [validityType, setValidityType] = useState(0);
	const [selectedDays, setSelectedDays] = useState<any>([]);
	const [qrCode, setQrCode] = useState(null);
	const [badgeTypeSelected, setBadgeTypeSelected] = useState(1);
	const history = useHistory();
	const today = new Date();
	const tomorow = useDateBeforeOrAfter({ isBefore: false, numberOfDays: 1 });
	const [startDate, setStartDate] = useState(today);
	const [endDate, setEndDate] = useState(tomorow);

	const healthWorkers = [
		{
			id: null,
			name: t("badge.__noAssignment__")
		},
		...store.users.healthWorkers
	];

	const handleTypeBadge = (type: number): void => {
		if (type === 1) {
			setBadgeTypeSelected(1);
		} else if (type === 2) {
			setBadgeTypeSelected(2);
			http
				.get(`${url.BASEURL}/user_badges/generate/random/qrcode`)
				.then(res => setQrCode(res.data.value))
				.catch(() => setQrCode(null));
		}
	};

	const handleDays = (e: any, id: any): void => {
		if (e.target.checked) {
			setSelectedDays([...selectedDays, id]);
		} else {
			setSelectedDays(selectedDays.filter((day: number) => day !== id));
		}
	};

	const onSubmit = (data: any): void => {
		dispatch({
			type: "RESET_ES"
		});
		let value: string | null = "";
		if (badgeTypeSelected === 1) {
			value = data.value;
		} else if (badgeTypeSelected === 2) {
			value = qrCode;
		}

		let badgeData: any = "";
		if (validityType === 1) {
			const start = handleDate(startDate, "yyyy-MM-dd' '00:00:00");
			const end = handleDate(endDate, "yyyy-MM-dd' '23:59:59");

			badgeData = {
				user_badge: {
					home_id: homeId,
					type_id: badgeTypeSelected,
					validity: {
						type: validityType,
						values: [start, end]
					},
					value: value ? value.trim() : value,
					user_id: parseInt(data.user)
				}
			};
		} else if (validityType === 2) {
			badgeData = {
				user_badge: {
					home_id: homeId,
					type_id: badgeTypeSelected,
					validity: {
						type: validityType,
						values: [parseInt(data.beginHour), parseInt(data.beginMinute), parseInt(data.endHour), parseInt(data.endMinute)]
					},
					value: value ? value.trim() : value,
					user_id: parseInt(data.user)
				}
			};
		} else {
			badgeData = {
				user_badge: {
					home_id: homeId,
					type_id: badgeTypeSelected,
					validity: {
						type: validityType,
						values: selectedDays
					},
					value: value ? value.trim() : value,
					user_id: parseInt(data.user)
				}
			};
		}
		http
			.post(`${url.BASEURL}/user_badges`, badgeData)
			.then(res => {
				if (isModal && badgesAvailableDispatch && setShow) {
					badgesAvailableDispatch({ type: "NEW_BADGE", payload: res.data.user_badge });
					setShow({ show: false, info: null });
				} else {
					dispatch({
						type: "ADD_BADGE",
						payload: res.data.user_badge
					});
					dispatch({
						type: "DISPLAY_SUCCESS",
						payload: t("badge.__addSuccess__", { name: res.data.user_badge.value })
					});
					history.push("/user/healthworkerbadges");
				}
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR",
					payload: t("badge.__addBadgeError__")
				});
			});
	};

	return (
		<FormProvider {...methods}>
			<h5 className="blue">{t("badge.__addBadge__")}</h5>
			<HandleError error={store.es.error} dispatch={dispatch} message={store.es.msg} />
			{!homeId ? (
				<SelectHomeInput />
			) : (
				<form onSubmit={handleSubmit(onSubmit)}>
					<div>
						<label className="mr-5">{t("common.__type__")} : </label>
						<input
							type="radio"
							id="pin-code"
							name="typeId"
							value={1}
							className="mr-2"
							onClick={(): void => handleTypeBadge(1)}
							defaultChecked
						/>
						<label htmlFor="strict" className="mr-5">
							{t("badge.__pinCode__")}
						</label>

						<input
							type="radio"
							id="qr-code"
							name="typeId"
							value={2}
							className="mr-2"
							onClick={(): void => handleTypeBadge(2)}
						/>
						<label htmlFor="strict">{t("badge.__qrCode__")}</label>
					</div>
					<div className="mt-3 mb-3">
						{badgeTypeSelected === 1 ? (
							<MyInputNumber
								label={t("badge.__addPinCode__")}
								name="value"
								behavior={{
									required: true,
									maxLength: 4,
									minLength: 4,
									pattern: /[0-9]{4}/
								}}
							/>
						) : null}
						{badgeTypeSelected === 2 ? (
							<div className="mb-5 row">
								<div className="col-md-6 form-group">
									<label className="form-label mr-3">{t("badge.__qrCodeValue__")} : </label>
									<br />
									<input type="text" defaultValue={qrCode ? qrCode : ""} disabled className="form-control form-control-sm" />
								</div>
								<div className="col-md-6">
									{qrCode ? (
										<>
											<p>{t("badge.__qrCodePreview__")} :</p>
											<img src={`${url.BASEURL}/user_badges/generate/png/${qrCode}`} alt="qrCode" className="img-fluid qr-code" />
										</>
									) : null}
								</div>
							</div>
						) : null}
					</div>
					{isModal ? null : (
						<MyInputSelect
							label={t("hds.__workerSelection__")}
							name="user"
							behavior={{ required: false }}
							list={healthWorkers}
						/>
					)}
					<MyInputSelect
						label={t("badge.__validity__")}
						name="validity"
						behavior={{ required: true }}
						list={constant.validityTypes}
						// value={constant.validityTypes}
						onChange={(e: any): void => setValidityType(parseInt(e.target.value))}
					/>

					{validityType === 3 && (
						<>
							<p className="blue">
								<b>{t("badge.__daysValidity__")} :</b>
							</p>
							{constant.wantedDays.map(days => (
								<div key={days.id}>
									<input type="checkbox" key={days.id} onChange={(e: any): void => handleDays(e, days.id)} />{" "}
									<span className="label-checkbox">{days.name}</span>
								</div>
							))}
						</>
					)}

					{validityType === 1 && (
						<>
							<p className="blue">
								<b>{t("badge.__rangeValidity__")}</b>
							</p>
							<RangePicker
								userLocale={userLocale}
								startDate={startDate}
								setStartDate={setStartDate}
								endDate={endDate}
								setEndDate={setEndDate}
							/>
						</>
					)}

					{validityType === 2 && (
						<div className="row">
							<div className="col-12">
								<p className="blue">
									<b>{t("badge.__timingValidity__")}</b>
								</p>
							</div>
							<div className="col-md-6">
								<p className="blue">{t("badge.__timingStart__")} :</p>
							</div>
							<div className="col-md-6">
								<p className="blue">{t("badge.__timingEnd__")} :</p>
							</div>
							<div className="col-md-2 mr-2">
								<MyInputNumber
									label={t("common.__hour__")}
									name="beginHour"
									behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
									placeholder="01"
								/>
							</div>
							<div className="col-md-2">
								<MyInputNumber
									label={t("common.__minute__")}
									name="beginMinute"
									behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
									placeholder="01"
								/>
							</div>
							<div className="col-md-2"></div>
							<div className="col-md-2 mr-2">
								<MyInputNumber
									label={t("common.__hour__")}
									name="endHour"
									behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
									placeholder="01"
								/>
							</div>
							<div className="col-md-2">
								<MyInputNumber
									label={t("common.__minute__")}
									name="endMinute"
									behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
									placeholder="01"
								/>
							</div>
						</div>
					)}
					<br />
					<button type="submit" className="btn btn-primary btn-sm">
						{t("common.__save__")}
					</button>
				</form>
			)}
		</FormProvider>
	);
};
export default CreateHealthWorkerBadge;
