import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../../utils/Form/MyInputText";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import HandleError from "../../../layout/HandleError";

const EditTenant = ({ editTenant, setEditTenant }) => {
	const { t } = useTranslation("common");
	const [error, setError] = useState(false);
	const methods = useForm();
	const { handleSubmit } = methods;
	const tenant = editTenant.tenant;
	const tenantId = editTenant.tenant.id;

	const onSubmit = values => {
		setError(false);
		const data = {
			tenant: {
				name: values.name,
				description: values.description
			}
		};
		http
			.put(`${url.BASEURL}/tenants/${tenantId}`, data)
			.then(res => {
				setError(false);
				setEditTenant({ edit: false, tenant: res.data.tenant, success: true });
			})
			.catch(() => setError(true));
	};

	return (
		<FormProvider {...methods}>
			<HandleError error={error} setError={setError} />
			<h5 className="blue">{t("common.__update__")} :</h5>
			<form onSubmit={handleSubmit(onSubmit)}>
				<MyInputText label={t("common.__name__")} value={tenant.name} name="name" behavior={{ required: true }} />
				<MyInputText
					label={t("common.__description__")}
					value={tenant.description}
					name="description"
					behavior={{ required: true }}
				/>
				<button
					className="btn btn-outline-secondary btn-sm mr-2"
					type="button"
					onClick={() => setEditTenant({ edit: false, tenant: null, success: false })}>
					{t("common.__cancel__")}
				</button>
				<button className="btn btn-primary btn-sm" type="submit">
					{t("common.__save__")}
				</button>
			</form>
		</FormProvider>
	);
};

export default EditTenant;
