import React from "react";
import HandleError from "../../../layout/HandleError";
import { useTranslation } from "react-i18next";
import * as HomeConfigChoices from "../../../constants/HomeConfig";
import ToggleModeration from "./ToggleModeration";

export default function HomeModerationSettings({ myHome, homeDispatch, homeStore }) {
	const { t } = useTranslation("common");

	return myHome.config_choices ? (
		<>
			<h5>{t("moderation.__moderation__")}</h5>
			<HandleError error={homeStore.es.error} dispatch={homeDispatch} message={homeStore.es.msg} noSpace />

			<ToggleModeration
				index={HomeConfigChoices.SENIOR_LIFE_STORIES_MODERATED}
				msgParamsErrorKey={"home.__bioModerationParamsError__"}
				msgKeyActiveKey={"home.__bioModerationActive__"}
				msgKeyInactiveKey={"home.__bioModerationInactive__"}
				homeDispatch={homeDispatch}
				myHome={myHome}
			/>

			<ToggleModeration
				index={HomeConfigChoices.SENIOR_AVATAR_MODERATED}
				msgParamsErrorKey={"home.__avatarModerationParamsError__"}
				msgKeyActiveKey={"home.__avatarModerationActive__"}
				msgKeyInactiveKey={"home.__avatarModerationInactive__"}
				homeDispatch={homeDispatch}
				myHome={myHome}
			/>

			<ToggleModeration
				index={HomeConfigChoices.SENIOR_PHOTO_FRAME_ITEMS_MODERATED}
				msgParamsErrorKey={"home.__pictsModerationParamsError__"}
				msgKeyActiveKey={"home.__pictsModerationActive__"}
				msgKeyInactiveKey={"home.__pictsModerationInactive__"}
				homeDispatch={homeDispatch}
				myHome={myHome}
			/>

			<ToggleModeration
				index={HomeConfigChoices.HOME_BLOG_POSTS_MODERATED}
				msgParamsErrorKey={"home.__blogModerationParamsError__"}
				msgKeyActiveKey={"home.__blogModerationActive__"}
				msgKeyInactiveKey={"home.__blogModerationInactive__"}
				homeDispatch={homeDispatch}
				myHome={myHome}
			/>

			<h5 className="mt-3">{t("permissions.__timeline__")}</h5>
			<ToggleModeration
				index={HomeConfigChoices.DISPLAY_HW_IN_FAMILY_TIMELINE}
				msgParamsErrorKey={"common.__errorOccured__"}
				msgKeyActiveKey={"home.__displayHwActive__"}
				msgKeyInactiveKey={"home.__displayHwInactive__"}
				homeDispatch={homeDispatch}
				myHome={myHome}
			/>
			<ToggleModeration
				index={HomeConfigChoices.DISPLAY_MOOD_IN_FAMILY}
				msgParamsErrorKey={"common.__errorOccured__"}
				msgKeyActiveKey={"home.__displayMoodInFamily__"}
				msgKeyInactiveKey={"home.__noMoodInFamily__"}
				homeDispatch={homeDispatch}
				myHome={myHome}
			/>
		</>
	) : null;
}
