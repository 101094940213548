import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleDoubleRight, FaCheckCircle, FaExclamationCircle, FaPlusCircle, FaQuestionCircle } from "react-icons/fa";
import { DeltaProps } from "./DotationReducer";
import PieChartContainer from "../../../utils/PieChartContainer";

interface Props {
	deltaStatistics: DeltaProps;
	showPercentage: boolean;
}

const DotationAndChangesChart = ({ deltaStatistics }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [chartKey, setChartKey] = useState<number>(0);

	useEffect(() => {
		setChartKey(prevKey => prevKey + 1);
	}, [deltaStatistics.chart]);

	return (
		<div className="row">
			<div className="col-12">
				<h5 className="mt-4">
					{t("diaper.__changeDistribution__")}/{t("diaper.__dotations__")}
				</h5>
			</div>
			<div className="col-md-5">
				{deltaStatistics && deltaStatistics.result ? (
					<div className="big-card mt-1">
						<p>{t("diaper.__rawData__")} :</p>
						<div className="light-separator"></div>
						<div className="d-flex justify-content-between align-items-center mt-2">
							<p>
								<FaCheckCircle className="green mr-3" style={{ width: "auto", height: "1.1rem" }} />
								{t("diaper.__compliantChange__")}
							</p>
							<p>
								<strong>{deltaStatistics.result.counts.nb_ok}</strong>
							</p>
						</div>
						<div className="d-flex justify-content-between align-items-center mt-2">
							<p>
								<FaExclamationCircle className="red mr-3" style={{ width: "auto", height: "1.1rem" }} />
								{t("diaper.__nonCompliantChange__")}
							</p>
							<p>
								<strong>{deltaStatistics.result.counts.nb_found_but_bad}</strong>
							</p>
						</div>
						<div className="d-flex justify-content-between align-items-center mt-2">
							<p>
								<FaPlusCircle className="blue mr-3" style={{ width: "auto", height: "1.1rem" }} />
								{t("diaper.__extraProvision__")}
							</p>
							<p>
								<strong>{deltaStatistics.result.counts.nb_extra}</strong>
							</p>
						</div>
						<div className="d-flex justify-content-between align-items-center mt-2">
							<p>
								<FaQuestionCircle className="yellow mr-3" style={{ width: "auto", height: "1.1rem" }} />
								{t("diaper.__provisionMissing__")}
							</p>
							<p>
								<strong>{deltaStatistics.result.counts.nb_not_found}</strong>
							</p>
						</div>
					</div>
				) : null}
			</div>
			<div className="col-md-7">
				<div className="big-card mt-1">
					{deltaStatistics && deltaStatistics.chart ? (
						deltaStatistics.chart.every((item: { value: number }) => item.value === 0) ? (
							<p>
								<FaAngleDoubleRight className="mr-2" />
								<b>{t("diaper.__provisionsAreOk__")}</b>
							</p>
						) : (
							<PieChartContainer
								data={deltaStatistics.chart}
								dataKey={"value"}
								height={320}
								showPercentage={true}
								key={chartKey}
							/>
						)
					) : null}
				</div>
			</div>
		</div>
	);
};
export default DotationAndChangesChart;
