const CommentsReducer = (state, action) => {
	switch (action.type) {
		case "GET_COMMENTS":
			return {
				...state,
				comments: action.payload
			};

		case "ADD_COMMENT":
			return {
				...state,
				comments: action.payload
			};

		case "DISPLAY_ERROR":
			return {
				...state,
				error: true
			};
		case "RESET_ES":
			return {
				...state,
				error: false,
				success: false
			};
		default:
			return state;
	}
};

export default CommentsReducer;
