import React from "react";
import { FaPrint } from "react-icons/fa";

const PrintIcon = (): JSX.Element => {
	const print = (): void => {
		window.print();
	};

	return <FaPrint role="button" className="table-icon white" onClick={print} />;
};
export default PrintIcon;
