import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaRegEnvelope, FaTimes, FaVideo } from "react-icons/fa";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

const RecipientsList = ({ homeId, canUpdateRecipients, users }) => {
	const { t } = useTranslation("common");
	const [msgRecipients, setMsgRecipients] = useState([]);
	const [visioRecipients, setVisioRecipients] = useState([]);
	const [updateVisioList, setUpdateVisioList] = useState(false);
	const [updateMsgList, setUpdateMsgList] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/visio/recipients`)
				.then(res => setVisioRecipients(res.data.user_recipients))
				.catch(() => setVisioRecipients([]));
		}
	}, [homeId, updateVisioList]);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/message/recipients`)
				.then(res => setMsgRecipients(res.data.user_recipients))
				.catch(() => setMsgRecipients([]));
		}
	}, [homeId, updateMsgList]);

	const removeRecipient = (recipient, type) => {
		setError(false);
		if (type === "msg") {
			const recipients = msgRecipients.filter(msg => msg.id !== recipient.id);
			const data = {
				user_recipients: recipients
			};
			http
				.put(`${url.HOMESURL}/${homeId}/message/recipients`, data)
				.then(() => setUpdateMsgList(prevState => !prevState))
				.catch(() => setError(true));
		} else if (type === "visio") {
			const recipients = visioRecipients.filter(visio => visio.id !== recipient.id);
			const data = {
				user_recipients: recipients
			};
			http
				.put(`${url.HOMESURL}/${homeId}/visio/recipients`, data)
				.then(() => setUpdateVisioList(prevState => !prevState))
				.catch(() => setError(true));
		}
	};

	return (
		<>
			{error ? (
				<p className="error-msg mt-2">
					<span className="right" role="button" tabIndex="0" onClick={() => setError(false)}>
						<FaTimes />
					</span>
					<b>{t("common.__errorOccured__")}</b>
				</p>
			) : (
				<br />
			)}

			{users.length > 0 ? (
				<>
					<div className="header-details">
						<b>{t("common.__toKnow__")}</b> {t("common.__recipientWarning__")}
					</div>
					<div className="row mt-4">
						<div className="col-md-8">
							<h5>
								<FaRegEnvelope className="mr-2" />
								{t("common.__messageRecipients__")} :
							</h5>
						</div>
						<div className="col-md-4">
							{canUpdateRecipients ? (
								<Link to="/manage/recipients/updatelist/msg">
									<button type="button" className="btn btn-sm btn-outline-primary right mt-2">
										{t("common.__add__")}
									</button>
								</Link>
							) : null}
						</div>
						<div className="col-12">
							<ul className="small-list my-3">
								{msgRecipients.length > 0 ? (
									msgRecipients.map(recipient => (
										<li key={recipient.id} className="d-block">
											{canUpdateRecipients ? (
												<span className="recipient-badge">
													<b>
														{recipient.first_name === "badge_general" ? null : recipient.first_name}{" "}
														{recipient.last_name.toUpperCase()}
													</b>
													{recipient.designation ? <>, {recipient.designation}</> : null}
													<span className="table-icon right" onClick={() => removeRecipient(recipient, "msg")}>
														<FaTimes />
													</span>
												</span>
											) : (
												<Link to={`/user/healthworker/${recipient.id}`}>
													<span className="category-outline-badge blue mb-2">
														<b>
															{recipient.first_name === "badge_general" ? null : recipient.first_name}{" "}
															{recipient.last_name.toUpperCase()}
														</b>
														{recipient.designation ? <>, {recipient.designation}</> : null}
													</span>
												</Link>
											)}
										</li>
									))
								) : (
									<p className="meta">{t("common.__noElement__")}</p>
								)}
							</ul>
						</div>
					</div>

					<div className="row mt-5">
						<div className="col-md-8">
							<h5>
								<FaVideo className="mr-2" />
								{t("common.__visioRecipients__")} :
							</h5>
						</div>
						<div className="col-md-4">
							{canUpdateRecipients ? (
								<Link to="/manage/recipients/updatelist/visio">
									<button type="button" className="btn btn-sm btn-outline-primary right mt-2">
										{t("common.__add__")}
									</button>
								</Link>
							) : null}
						</div>
						<div className="col-12">
							<ul className="small-list my-3">
								{visioRecipients.length > 0 ? (
									visioRecipients.map(recipient => (
										<li key={recipient.id} className="d-block">
											{canUpdateRecipients ? (
												<span className="recipient-badge">
													<b>
														{recipient.first_name === "badge_general" ? null : recipient.first_name}{" "}
														{recipient.last_name.toUpperCase()}
													</b>
													{recipient.designation ? <>, {recipient.designation}</> : null}
													<span className="table-icon right" onClick={() => removeRecipient(recipient, "visio")}>
														<FaTimes />
													</span>
												</span>
											) : (
												<Link to={`/user/healthworker/${recipient.id}`}>
													<span className="category-outline-badge blue mb-2">
														<b>
															{recipient.first_name === "badge_general" ? null : recipient.first_name}{" "}
															{recipient.last_name.toUpperCase()}
														</b>
														{recipient.designation ? <>, {recipient.designation}</> : null}{" "}
													</span>
												</Link>
											)}
										</li>
									))
								) : (
									<p className="meta">{t("common.__noElement__")}</p>
								)}
							</ul>
						</div>
					</div>
				</>
			) : (
				t("common.__noElement__")
			)}
		</>
	);
};

export default RecipientsList;
