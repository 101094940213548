import React from "react";
import { FaCheckCircle, FaExclamationTriangle, FaTimesCircle } from "react-icons/fa";
import { Tooltip } from "./Tooltip";

interface Props {
	status: boolean;
	title: string;
}

const BlogStatusIcon = ({ status, title }: Props): JSX.Element | undefined => {
	const handleIconWithTooltip = (title: string, color: string, icon: JSX.Element): JSX.Element => {
		return (
			<Tooltip text={title}>
				<div className={`${color} center`}>{icon}</div>
			</Tooltip>
		);
	};

	const handleStatus = (status: boolean, title: string): JSX.Element | undefined => {
		switch (status) {
			case true:
				return handleIconWithTooltip(title, "green", <FaCheckCircle className="regular-icon" />);
			case false:
				return handleIconWithTooltip(title, "red", <FaTimesCircle className="regular-icon" />);
			case null:
				return handleIconWithTooltip(title, "blue", <FaExclamationTriangle className="regular-icon" />);
			default:
				return;
		}
	};

	return handleStatus(status, title);
};
export default BlogStatusIcon;
