import React from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserInterface } from "../interfaces/UserInterface";

interface Props {
	title: string;
	addButton: string;
	link: string | null;
	permissionNeeded?: number[];
	userState?: UserInterface;
}

const HeaderContainer = ({ title, addButton, link, permissionNeeded, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { mode } = useParams<{ mode: string }>();
	const history = useHistory();
	const hasPermission = permissionNeeded && userState ? userState.userConnected.is_in(permissionNeeded) : null;

	return (
		<div className="row form-inline">
			<div className="col-6">
				<h2>{title}</h2>
			</div>
			<div className="col-6">
				{permissionNeeded ? (
					hasPermission ? (
						mode !== "add" ? (
							link ? (
								<Link to={link}>
									<button data-testid="add-btn" type="button" className="btn btn-outline-primary float-right btn-sm">
										{addButton}
									</button>
								</Link>
							) : (
								<button
									type="button"
									className="btn btn-outline-secondary float-right btn-sm"
									onClick={(): void => history.goBack()}>
									{t("common.__back__")}
								</button>
							)
						) : (
							<button
								type="button"
								className="btn btn-outline-secondary float-right btn-sm"
								onClick={(): void => history.goBack()}>
								{t("common.__back__")}
							</button>
						)
					) : (
						<button
							type="button"
							className="btn btn-outline-secondary float-right btn-sm"
							onClick={(): void => history.goBack()}>
							{t("common.__back__")}
						</button>
					)
				) : mode !== "add" ? (
					link ? (
						<Link to={link}>
							<button type="button" className="btn btn-outline-primary float-right btn-sm">
								{addButton}
							</button>
						</Link>
					) : (
						<button
							type="button"
							className="btn btn-outline-secondary float-right btn-sm"
							onClick={(): void => history.goBack()}>
							{t("common.__back__")}
						</button>
					)
				) : null}
			</div>
		</div>
	);
};
export default HeaderContainer;
