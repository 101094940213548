import { useContext, useEffect } from "react";
import { DiaperContext } from "../context/DiaperContext";
import { UserInterface } from "../interfaces/UserInterface";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
	homeId: number | null;
	refetch?: boolean;
}

const useDiaperStore = ({ userState, homeId, refetch }: Props): any => {
	const { diaperStore, diaperDispatch } = useContext(DiaperContext);
	const isDiaperManager = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]);
	const hasGroupAccess = userState.userConnected.group_id ? true : false;
	const hasMultipleHomeAccess = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN]) || hasGroupAccess;

	const getSeniorsWithDiapers = (homeId: number): void => {
		http.get(`${url.HOMESURL}/${homeId}/rht/all/for/seniors`).then(res => {
			diaperDispatch({
				type: "GET_DIAPERS",
				payload: res.data.diapers
			});
			diaperDispatch({
				type: "GET_SENIORS_INCO",
				payload: res.data.seniors
			});
		});
	};

	useEffect(() => {
		if (isDiaperManager && homeId) {
			if (hasMultipleHomeAccess) {
				getSeniorsWithDiapers(homeId);
			} else if (diaperStore.diapers.length === 0 || diaperStore.seniors.length === 0) {
				getSeniorsWithDiapers(homeId);
			}
		}
	}, [homeId, isDiaperManager, refetch]);

	return { diaperStore, diaperDispatch };
};
export default useDiaperStore;
