import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaGripVertical } from "react-icons/fa";
import { Table } from "../../../utils/Table/TableWithFilter";
import { Link } from "react-router-dom";
import { SeniorInterface, UserInterface } from "../../../interfaces/UserInterface";
import ModalContainer, { ShowState } from "../../../utils/ModalContainer";
import AmdSeniorDotation from "./AmdSeniorDotation";
import AmdSeniorSyncInput from "./AmdSeniorSyncInput";
import NoData from "../../../layout/NoData";
import AmdSeniorPartnerIdDissociation from "./AmdSeniorPartnerIdDissociation";
import * as p from "../../../constants/Permissions";

interface Props {
	seniors: SeniorInterface[];
	editSeniorPartnerId: {
		show: boolean;
		info: {
			id: number;
			partnerId: number;
		} | null;
		title: string;
		success: boolean;
	};

	deleteSeniorPartnerId: {
		show: boolean;
		info: {
			id: number;
			partnerId: number;
		} | null;
		title: string;
		success: boolean;
	};
	setEditSeniorPartnerId: (args: any) => void;
	setDeleteSeniorPartnerId: (args: any) => void;
	userState: UserInterface;
}

const AmdSeniorSyncContainer = ({
	seniors,
	editSeniorPartnerId,
	setEditSeniorPartnerId,
	setDeleteSeniorPartnerId,
	deleteSeniorPartnerId,
	userState
}: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [show, setShow] = useState<ShowState>({ show: false, info: null, title: "" });
	const isAdmin = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.CAN_DELETE_SENIOR]);

	const columns = [
		{
			Header: t("common.__senior__"),
			accessor: "name",
			Cell: (row: any) => (
				<Link to={`/senior/profile/${row.row.original.id}`}>
					<p className="category-outline-badge blue">{row.row.values.name}</p>
				</Link>
			)
		},
		{
			Header: t("common.__homeUnit__"),
			accessor: "homeUnit",
			Cell: (row: any) => (row.row.values.homeUnit ? <p>{row.row.values.homeUnit}</p> : null)
		},
		{
			Header: "AMD ID Résident",
			accessor: "senior_partner_id",
			Cell: (row: any) => (row.row.values.senior_partner_id ? <p>{row.row.values.senior_partner_id}</p> : null)
		},
		{
			Header: "",
			accessor: "id",
			disableFilters: true,
			minWidth: 30,
			width: 40,
			maxWidth: 50,

			Cell: (row: any) => (
				<div className="center">
					{editSeniorPartnerId.show &&
					editSeniorPartnerId.info &&
					row.row.values.id === editSeniorPartnerId.info.id ? null : row.row.values.senior_partner_id ? (
						<>
							<FaCheck
								className={row.row.original.provisions.length > 0 ? "big-check-icon mr-2" : "big-sync-icon"}
								role="button"
								onClick={(): void =>
									setEditSeniorPartnerId({
										show: true,
										info: { id: row.row.values.id, partnerId: row.row.values.senior_partner_id },
										title: row.row.values.name,
										success: false
									})
								}
							/>

							{isAdmin && (
								<button
									role="button"
									className="ConfirmationModal-btn btn btn-sm pl-2 pr-2 ml-2 mr-2 mt-2 mb-2 pt-1 pb-1"
									data-toggle="modal"
									onClick={(): void => {
										setDeleteSeniorPartnerId({
											show: true,
											info: { id: row.row.values.id, partnerId: row.row.values.senior_partner_id },
											title: row.row.values.name + " - " + row.row.values.senior_partner_id,
											partnerId: row.row.values.senior_partner_id,
											success: false
										});
									}}>
									{t("common.__dissociate__")}
								</button>
							)}

							{row.row.original.provisions.length > 0 ? (
								<FaGripVertical
									className="big-sync-icon"
									onClick={(): void => setShow({ show: true, info: row.row.original, title: row.row.values.name })}
									role="button"
								/>
							) : null}
						</>
					) : (
						<button
							type="button"
							className="btn-info btn btn-sm pl-3 pr-3 ml-4 mr-2 mt-2 mb-2 pt-1 pb-1"
							onClick={(): void =>
								setEditSeniorPartnerId({
									show: true,
									info: { id: row.row.values.id, partnerId: row.row.values.senior_partner_id },
									title: row.row.values.name,
									success: false
								})
							}>
							{t("common.__associate__")}
						</button>
					)}
				</div>
			)
		}
	];

	return seniors.length > 0 ? (
		<>
			<Table columns={columns} data={seniors} />
			<ModalContainer show={editSeniorPartnerId} setShow={setEditSeniorPartnerId}>
				<AmdSeniorSyncInput show={editSeniorPartnerId} setShow={setEditSeniorPartnerId} />
			</ModalContainer>
			<ModalContainer show={show} setShow={setShow}>
				<AmdSeniorDotation show={show} />
			</ModalContainer>
			<ModalContainer show={deleteSeniorPartnerId} setShow={setDeleteSeniorPartnerId}>
				<AmdSeniorPartnerIdDissociation show={deleteSeniorPartnerId} setShow={setDeleteSeniorPartnerId} />
			</ModalContainer>
		</>
	) : (
		<NoData />
	);
};
export default AmdSeniorSyncContainer;
