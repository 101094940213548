import React from "react";
import { FaRegEdit } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tooltip } from "./Tooltip";
import { HealthWorkerInterface, UserInterface } from "../../interfaces/UserInterface";
import * as p from "../../constants/Permissions";

interface Props {
	userState: UserInterface;
	userToEdit: HealthWorkerInterface;
}

const EditHealthWorkerButton = ({ userState, userToEdit }: Props): JSX.Element | null => {
	const { t } = useTranslation("common");

	const editButton = (id: number): JSX.Element => (
		<Tooltip text={t("common.__update__")}>
			<Link to={`/user/hw/edithw/${id}`}>
				<FaRegEdit className="table-icon" role="button" aria-label="edit-healthworker" />
			</Link>
		</Tooltip>
	);

	const displayEditButton = (userToEdit: HealthWorkerInterface, userState: UserInterface): JSX.Element | null => {
		const canManageHealthWorkers = userState.userConnected.is(p.CAN_MANAGE_USER_AND_BADGE);
		if (userState.userConnected.home_id && canManageHealthWorkers) {
			return editButton(userToEdit.id);
		} else if (userState.userConnected.group_id && canManageHealthWorkers) {
			return userToEdit.group_id ? null : editButton(userToEdit.id);
		} else if (userState.userConnected.tenant_id) {
			return userToEdit.tenant_id ? null : editButton(userToEdit.id);
		} else {
			return userState.userConnected.is(p.SUPER_ADMIN) ? editButton(userToEdit.id) : null;
		}
	};

	return displayEditButton(userToEdit, userState);
};
export default EditHealthWorkerButton;
