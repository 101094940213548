import React, { useState, useEffect } from "react";
import "./AmdReport.css";
import { useTranslation } from "react-i18next";
import DisplayPieChart from "../DiaperConnected/ChangeStatistics/DisplayPieChart";
import PieChartContainer from "../../utils/PieChartContainer";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { FaCheckCircle, FaExclamationCircle, FaPlusCircle, FaQuestionCircle } from "react-icons/fa";
import http from "../../services/Interceptor";
import { useParams } from "react-router-dom";

const AmdReport = () => {
	const { t } = useTranslation("common");
	const [data, setData] = useState(null);
	const [error, setError] = useState(false);
	const { id } = useParams();

	const formatDate = dateString => {
		const date = new Date(dateString);
		const day = date.getDate().toString().padStart(2, "0");
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	};

	const formatDateSync = dateString => {
		const date = new Date(dateString);
		const options = { day: "2-digit", month: "short", year: "numeric" };
		const formattedDate = date.toLocaleDateString("fr-FR", options);
		const formattedTime = date.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" });
		return `${formattedDate}, ${formattedTime}`;
	};

	const formatPercentage = (num, decimals = 2) => {
		return (num * 100).toFixed(decimals);
	};

	useEffect(() => {
		http
			.get(`/storages/amd-report/${id}`)
			.then(response => {
				setData(response.data);
			})
			.catch(() => setError(true));
	}, []);
	if (!data) {
		return <div>{t("common.__loading__")}</div>;
	}
	if (error) {
		return <div>{t("report.__errorLoadingData__")}</div>;
	}

	const nameMappingChangeGraph = {
		bring_to_wc: t("diaper.__bringToWc__"),
		diaper_partially_saturated: `${t("diaper.__diaperSaturated__")} (${t("diaper.__half__")})`,
		dirty_bed: t("diaper.__dirtyBed__"),
		draw_sheet_changed: t("diaper.__drawSheetChanged__"),
		presence_of_redness: t("diaper.__presenceOfRedness__"),
		presence_of_stools: t("diaper.__presenceOfStools__"),
		torn_off: t("diaper.__diaperTornOff__")
	};

	const changeGraphData = data.data_percentage_evals_with_colors.map(item => ({
		name: nameMappingChangeGraph[item.name],
		value: item.value,
		color: item.color
	}));

	const totalValue = changeGraphData.reduce((sum, item) => sum + item.value, 0);
	const nothingToReportPercentage = 100 - totalValue;
	changeGraphData.push({
		name: t("diaper.__nothingToReport__"),
		value: nothingToReportPercentage,
		color: "#28B463"
	});

	const dirtyBedGraphData = data.number_of_dirty_beds.map(item => ({
		count: item.count,
		date: formatDate(item.date)
	}));

	const renderCustomLabel = ({ name, value }) => `${name}: ${value}%`;

	const mapAndFilterData = (dataArray, dataKey) => {
		return dataArray
			.map(item => {
				const senior = data.seniors.find(senior => senior.id === item.senior_id);
				if (senior) {
					return {
						first_name: senior.first_name,
						last_name: senior.last_name,
						[dataKey]: item[dataKey]
					};
				}
			})
			.filter(Boolean);
	};

	const top5MostChanged = mapAndFilterData(data.top5_seniors_changed, "count");
	const top5MostChangedCIJ = mapAndFilterData(data.top5_seniors_changed_CIJ, "percentage");
	const top5HighConfirmity = mapAndFilterData(data.top_5_seniors_conform, "nb_ok_percentage");
	const top5LowConfirmity = mapAndFilterData(data.top_5_seniors_not_conform, "nb_ok_percentage");
	const top10TornOff = mapAndFilterData(data.top10_torn_off, "draw_sheet");
	const top10PresenceOfRedness = mapAndFilterData(data.top10_presence_of_redness, "redness");

	const imageSrc = `data:image/png;base64,${data.home_logo}`;

	return (
		<div className="container">
			<div className="header">
				{data.home_logo ? (
					<img src={imageSrc} alt="HomeLogo" height={70} width={120} />
				) : (
					<div style={{ height: 70, width: 120 }}></div>
				)}
				<div className="amd-logo">
					<img src="/img/amd/amdlink.png" width={140} height={70} alt="AmdLogo" />
				</div>
				<div className="address">
					<p>{data.home_name}</p>
					<p>{data.home_address}</p>
				</div>
				<h1 className="rectangle-header">{t("report.__monthlyMonitoringReport__")}</h1>
				<p className="centered-text">
					Période du {formatDate(data.from)} au {formatDate(data.to)}
				</p>
			</div>

			<h5>{t("senior.__seniors__")}</h5>
			<hr className="full-width-line marginTop" />
			<table className="styled-table">
				<thead>
					<tr>
						<td className="incontinence bold">{t("report.__subjectsToIncontinence__")}</td>
						<td className="incontinence-count">{data.nb_seniors}</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="equipped">{t("report.__equippedWithSensors__")}</td>
						<td className="equipped-count">{data.nb_seniors_with_diaper_sensor}</td>
					</tr>
				</tbody>
			</table>

			<h5>{t("report.__profilType__")}</h5>
			<hr className="full-width-line" />
			<div className="change-stats">
				<div className="section-change-stats">
					<DisplayPieChart
						display={true}
						data={changeGraphData}
						height={600}
						wdith={300}
						innerRadius={180}
						outerRadius={230}
						paddingAngle={5}
						label={renderCustomLabel}
						showPercentage={true}
						isAnimationActive={false}
					/>
				</div>
			</div>
			<h5>{t("hds.__statistics__")}</h5>
			<hr className="full-width-line" />

			<div className="stats">
				<div>
					<table className="most-changed">
						<thead>
							<tr className="most-changed">
								<th className="most-changed centered-text">{t("report.__top5MostChangedSeniors__")}</th>
							</tr>
						</thead>
						<tbody>
							{top5MostChanged.map((item, index) => (
								<tr key={index}>
									<td className={index % 2 === 0 ? "colored-tr-change bold" : "wback bold"}>
										{item.last_name} {item.first_name}
									</td>

									<td className={index % 2 === 0 ? "colored-tr-change text-right" : "wback text-right"}>{item.count}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<div>
					<table className="most-changed">
						<thead>
							<tr>
								<th className="most-changed centered-text">{t("report.__top5HighestCIJ__")}</th>
							</tr>
						</thead>
						<tbody>
							{top5MostChangedCIJ.map((item, index) => (
								<tr key={index}>
									<td className={index % 2 === 0 ? "colored-tr-change bold" : "wback bold"}>
										{item.last_name} {item.first_name}
									</td>

									<td className={index % 2 === 0 ? "colored-tr-change text-right" : "wback text-right"}>
										{item.percentage.toFixed(2)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<div className="stats">
				<div>
					<table className="high-confirmity">
						<thead>
							<tr className="high-confirmity-th">
								<th className="high-confirmity-th centered-text" colSpan="2">
									{t("report.__strongComplianceIndex__")}
								</th>
							</tr>
						</thead>
						<tbody>
							{top5HighConfirmity.map((item, index) => (
								<tr key={index}>
									<td className={index % 2 === 0 ? "high-confirmity-tr text-left" : "wback text-left"}> {index + 1}</td>
									<td className={index % 2 === 0 ? "high-confirmity-tr bold text-right" : "wback bold text-right"}>
										{item.last_name} {item.first_name}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<div>
					<table className="low-confirmity">
						<thead>
							<tr>
								<th className="low-confirmity centered-text" colSpan="2">
									{t("report.__lowComplianceIndex__")}
								</th>
							</tr>
						</thead>
						<tbody>
							{top5LowConfirmity.map((item, index) => (
								<tr key={index}>
									<td className={index % 2 === 0 ? "low-confirmity-tr text-left" : "wback text-left"}>
										{" "}
										{index === 0 ? data.nb_seniors : data.nb_seniors - index}
									</td>
									<td className={index % 2 === 0 ? "low-confirmity-tr bold text-right" : "wback bold text-right"}>
										{item.last_name} {item.first_name}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<div className="footer1">
				<p>
					Page <span className="bold">1</span> sur <span className="bold">3</span>
				</p>
			</div>

			<div className="landscape">
				<h1 className="padding">{t("report.__ReportSpoiledBedsPerDayOverPeriod__")}</h1>
				<hr className="full-width-line" />
				<div>
					<ResponsiveContainer height={290} width={900}>
						<BarChart data={dirtyBedGraphData} margin={{ top: 10, right: 5, left: 5, bottom: 10 }}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="date" />
							<YAxis />
							<Tooltip />
							<Bar
								dataKey="count"
								stroke="#00a0e3"
								fill="#00a0e3"
								fillOpacity={0.8}
								radius={[5, 5, 0, 0]}
								name={t("diaper.__dirtyBed__")}
							/>
						</BarChart>
					</ResponsiveContainer>
				</div>

				<table className="tableAvg">
					<thead>
						<tr>
							<td className="incontinence padding bold">{t("report.__dailyAverage__")}</td>
							<td className="daily-avrg padding bold">{data.average_dirty_bed.toFixed(1)}</td>
						</tr>
					</thead>
				</table>

				<h5>{t("report.__otherReports__")}</h5>
				<hr className="full-width-line margin-Other-report" />
				<div className="stats">
					<div>
						<table className="low-confirmity">
							<thead>
								<tr>
									<th className="low-confirmity centered-text">{t("diaper.__diaperTornOff__")}</th>
								</tr>
							</thead>
							<tbody>
								{Array.from({ length: 7 }).map((_, index) => {
									const item = top10TornOff[index];
									return (
										<tr key={index}>
											<td className={index % 2 === 0 ? "low-confirmity-tr bold" : "wback bold"}>
												{item ? `${item.last_name} ${item.first_name}` : "—"}
											</td>
											<td className={index % 2 === 0 ? "low-confirmity-tr text-right" : "wback text-right"}>
												{item ? item.draw_sheet : "—"}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>

					<div>
						<table className="low-confirmity">
							<thead>
								<tr>
									<th className="low-confirmity centered-text">{t("diaper.__presenceOfRedness__")}</th>
								</tr>
							</thead>
							<tbody>
								{Array.from({ length: 7 }).map((_, index) => {
									const item = top10PresenceOfRedness[index];
									return (
										<tr key={index}>
											<td className={index % 2 === 0 ? "low-confirmity-tr bold" : "wback bold"}>
												{item ? `${item.last_name} ${item.first_name}` : "—"}
											</td>
											<td className={index % 2 === 0 ? "low-confirmity-tr text-right" : "wback text-right"}>
												{item ? item.redness : "—"}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
				<div className="footer2">
					<p className="font-size">
						Page <span className="bold">2</span> sur <span className="bold">3</span>
					</p>
				</div>
			</div>
			{data.integral ? (
				<div>
					<h1 className="padding marginTop">{t("report.__budgetIndicators__")}</h1>
					<hr className="full-width-line" />

					<div className="dotation-stats">
						<div className="section">
							<h5>{t("report.__objectifsAMDProfil__")}</h5>
							<div className="sync-date">
								{t("diaper.__dataSyncedOn__")} : {formatDateSync(data.CIJ_home.updated_at)}
							</div>
							<div className="section-content">
								<div className="data-box">
									<div className="label-1">
										{t("diaper.__cid__")} : {t("diaper.__cidInfo__")}
									</div>
									<div className="value">
										{data.CIJ_home.IndicateurCIJ.toFixed(3)}
										<span className="unit">€ HT</span>
									</div>
									<div className="objective">{data.CIJ_home.ObjectifCIJ}</div>
								</div>

								<div className="data-box">
									<div className="label-1">C.R. : {t("diaper.__complianceReco__")}</div>
									<div className="value">
										{formatPercentage(data.CIJ_home.IndicateurCR)}
										<span className="unit">%</span>
									</div>
									<div className="objective">{data.CIJ_home.ObjectifCR}</div>
								</div>

								<div className="data-box">
									<div className="label-1">
										{t("diaper.__ar__")} : {t("diaper.__anatomicalRate__")}
									</div>
									<div className="value">
										{formatPercentage(data.CIJ_home.IndicateurTA)}
										<span className="unit">%</span>
									</div>
									<div className="objective">{data.CIJ_home.ObjectifTA}</div>
									<div className="unit-1">Form / Produits portés</div>
								</div>
							</div>
						</div>

						<div className="section">
							<h5>{t("report.__actualDataFromChangFromAMD-linkApplication__")}</h5>
							<div className="section-content">
								<div className="data-box">
									<div className="label-1">
										{t("diaper.__realCidBySenior__")} ({t("common.__average__")})
									</div>
									<div className="value">
										{data.cost_average_cij.CIJ.toFixed(3)} <span className="unit">€ HT</span>
									</div>

									<div className="details-item">
										<div className="label2">
											{t("diaper.__recordedChangeNumber__")} : <span className="nums">{data.cost_average_cij.nb}</span>
										</div>
										<div className="label2">
											{t("diaper.__totalBudget__")} :<span className="nums">{data.cost_average_cij.sum.toFixed(3)} € HT</span>
										</div>
										<div className="label2">
											{t("diaper.__averageCostPerChange__")} :{" "}
											<span className="nums">{data.cost_average_cij.average.toFixed(3)} € HT</span>
										</div>
									</div>
								</div>

								<div className="data-box">
									<div className="label-1">{t("diaper.__realAnatomicalRate__")}</div>
									<div className="value">
										{data.ratio_form.percentage.toFixed(2)}
										<span className="unit">%</span>
									</div>

									<div className="details-item">
										<div className="label2">
											{t("diaper.__nber__")} :
											<span className="nums">
												{data.ratio_form.nb_form} / {data.ratio_form.nb_total}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="section-dot">
							<h5>{t("report.__distributionOfExchangeRatesDotation__")}</h5>
							<div className="section-content">
								<div className="data-box2">
									<div className="small-title">{t("report.__rawData__")}</div>
									<hr className="half-width-line" />

									<div className="dotation">
										<FaCheckCircle className="green icon" style={{ width: "auto", height: "1.1rem" }} />
										<span className="text">{t("diaper.__compliantChange__")}</span>
										<span className="nums">{data.provision_by_home.result.counts.nb_ok}</span>
									</div>

									<div className="dotation">
										<FaExclamationCircle className="red icon" style={{ width: "auto", height: "1.1rem" }} />
										<span className="text">{t("diaper.__nonCompliantChange__")}</span>{" "}
										<span className="nums">{data.provision_by_home.result.counts.nb_found_but_bad}</span>
									</div>

									<div className="dotation">
										<FaPlusCircle className="blue icon" style={{ width: "auto", height: "1.1rem" }} />
										<span className="text">{t("diaper.__extraProvision__")}</span>{" "}
										<span className="nums">{data.provision_by_home.result.counts.nb_extra}</span>
									</div>

									<div className="dotation">
										<FaQuestionCircle className="yellow icon" style={{ width: "auto", height: "1.1rem" }} />
										<span className="text">{t("diaper.__provisionMissing__")}</span>{" "}
										<span className="nums">{data.provision_by_home.result.counts.nb_not_found}</span>
									</div>
								</div>

								<div className="pie-box">
									<div className="chart-container">
										<PieChartContainer
											data={[
												{ name: t("diaper.__compliantChange__"), value: data.provision_by_home.result.percentages.nb_ok },
												{
													name: t("diaper.__nonCompliantChange__"),
													value: data.provision_by_home.result.percentages.nb_found_but_bad
												},
												{ name: t("diaper.__extraProvision__"), value: data.provision_by_home.result.percentages.nb_extra },
												{ name: t("diaper.__provisionMissing__"), value: data.provision_by_home.result.percentages.nb_not_found }
											]}
											dataKey="value"
											nameKey="name"
											height={320}
											showPercentage={true}
											isAnimationActive={false}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer3">
						<p>
							Page <span className="bold">3</span> sur <span className="bold">3</span>
						</p>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default AmdReport;
