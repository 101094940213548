import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function WhoLikeModal({ show, seniorsFromHome }) {
	const { t } = useTranslation("common");
	const [familyLikes, setFamilyLikes] = useState([]);

	useEffect(() => {
		if (show.info) {
			http
				.get(`${url.BASEURL}/homes/blog_posts/${show.info}/who/like`)
				.then(res => setFamilyLikes(res.data.family_users))
				.catch(() => setFamilyLikes([]));
		}
	}, [show.info]);

	const displaySeniorName = id => {
		const senior = seniorsFromHome.find(senior => senior.id === id);
		return senior ? senior.label : null;
	};

	return familyLikes.length > 0 ? (
		familyLikes.map(family => (
			<div key={family.id}>
				<div className={family.principal ? "family-outline-badge blue" : "family-outline-badge yellow"}>
					<div className="row">
						<div className="col-md-6">
							<b className="mr-2">
								{family.last_name} {family.first_name}
							</b>
						</div>
						<div className="col-md-6">
							{family.senior_ids.map((senior, index) => (
								<div className="right" key={index}>
									<p className="meta right">{displaySeniorName(senior)}</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		))
	) : (
		<p className="meta">{t("common.__noData__")}</p>
	);
}
