import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, SelectColumnFilter } from "../../../utils/Table/TableWithFilter";
import { DisplayDiaperModal } from "../Cards/SensorModal";
import { handleModelIcon, handleIllustration } from "../Illustrations";
import { FaThumbtack } from "react-icons/fa";
import HandleError from "../../../layout/HandleError";
import ModalContainer from "../../../utils/ModalContainer";

export default function DiapersList({ diaperStore, diaperDispatch }) {
	const { t } = useTranslation("common");
	const [show, setShow] = useState({ show: false, info: { diaper: null }, title: t("diaper.__diaper__") });

	const columns = [
		{
			Header: t("diaper.__range__"),
			accessor: "name",
			Filter: SelectColumnFilter,
			Cell: row => (
				<div className="d-block">
					<div className="d-flex flex-row">
						<div>{handleIllustration(row.row.original.brand, row.row.original.line, row.row.original.ref)}</div>
						<div
							className="ml-2"
							onClick={() => setShow({ show: true, info: { diaper: row.row.original }, title: t("diaper.__diaper__") })}>
							<span className="fake-link category-outline-badge blue">{row.row.values.name}</span>
							<br />
							<span className="meta">Réf. : {row.row.original.ref}</span>
						</div>
					</div>
				</div>
			)
		},
		{
			Header: t("news.__pictSize__"),
			accessor: "size",
			Filter: SelectColumnFilter,
			maxWidth: 100,
			minWidth: 90,
			width: 95
		},
		{
			Header: t("diaper.__saturation__"),
			accessor: "model",
			Filter: SelectColumnFilter,
			maxWidth: 100,
			minWidth: 90,
			width: 95,
			Cell: row => (
				<>
					<p>{row.row.original.model}</p>
					{handleModelIcon(row.row.original)}
				</>
			)
		}
	];

	return (
		<div className="big-card">
			<h2>{t("diaper.__diapers__")}</h2>
			<HandleError error={diaperStore.error} dispatch={diaperDispatch} message={diaperStore.msg} />
			<div className="diapers-list mb-3">
				<p className="pt-1">
					<FaThumbtack className="mr-2" />
					<b>{t("diaper.__selectHomeToDisplayHisDiapers__")}</b>
				</p>
			</div>
			{diaperStore.diapers.length > 0 ? (
				<Table columns={columns} data={diaperStore.diapers} />
			) : (
				<p>{t("common.__noElement__")}</p>
			)}
			<ModalContainer show={show} setShow={setShow}>
				<DisplayDiaperModal show={show} />
			</ModalContainer>
		</div>
	);
}
