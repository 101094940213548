import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AddNews from "../../News/AddNews";

export default function CreatePost({ blogStore, blogDispatch, homeId, seniorsFromHome, homeUnits }) {
	const { t } = useTranslation("common");
	const history = useHistory();

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-6">
					<h2>{t("news.__addNews__")}</h2>
				</div>
				<div className="col-6">
					<div className="inline right">
						<button type="button" className="btn btn-sm btn-outline-secondary rounded-0" onClick={() => history.goBack()}>
							{t("common.__back__")}
						</button>
					</div>
				</div>
			</div>
			<AddNews
				isControlPanel
				blogStore={blogStore}
				blogDispatch={blogDispatch}
				homeId={homeId}
				seniorsFromHome={seniorsFromHome}
				homeUnits={homeUnits}
			/>
		</div>
	);
}
