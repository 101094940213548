import React from "react";
import { useTranslation } from "react-i18next";
import { UserInterface } from "../interfaces/UserInterface";
import { BlogTabs } from "../utils/Tabs/TabsToDisplay";
import useStoreData from "../hooks/useStoreData";
import SelectHomeInput from "../layout/SelectHomeInput";
import BlogHeader from "../layout/BlogHeader";
import NewsLikeFamilyFlow from "../components/News/NewsLikeFamilyFlow";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const SocialNews_DisplayLikeFamily = ({ homeId, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { store } = useStoreData({ userState, homeId });
	const seniorsFromHome = store.seniors.seniorsFromHome;
	const canManagePosts = userState.userConnected.is_in([
		p.HOME_BLOG_WRITER,
		p.CAN_MODERATE_HOME_BLOG,
		p.CAN_POST_MODIFY_HOME_BLOG,
		p.ADMIN,
		p.SUPER_ADMIN
	]);
	const displayAddPostButton = userState.userConnected.is_in([
		p.HOME_BLOG_WRITER,
		p.CAN_POST_MODIFY_HOME_BLOG,
		p.SUPER_ADMIN,
		p.ADMIN
	]);

	return canManagePosts ? (
		<>
			<Tabs tabsToDisplay={BlogTabs} currentTab={"BLOG"} />
			<div className="big-card">
				<BlogHeader displayAddPostButton={displayAddPostButton} title={t("news.__news__")} />
				{homeId ? (
					<NewsLikeFamilyFlow homeId={homeId} userState={userState} seniorsFromHome={seniorsFromHome} isPanel={false} />
				) : (
					<SelectHomeInput />
				)}
			</div>
		</>
	) : homeId ? (
		<NewsLikeFamilyFlow homeId={homeId} userState={userState} seniorsFromHome={seniorsFromHome} isPanel={false} />
	) : (
		<SelectHomeInput />
	);
};
export default SocialNews_DisplayLikeFamily;
