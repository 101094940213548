import React from "react";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import AddHomeRule from "../components/Home/NotificationsRules/AddHomeRule";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_AddNotificationRules = ({ homeId, userState }: Props): JSX.Element => {
	const { store, dispatch } = useStoreData({ userState, homeId });

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_CHECKER_RULE]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<HomesSecondaryNav userState={userState} />}
				component={<AddHomeRule userState={userState} store={store} dispatch={dispatch} homeId={homeId} />}
			/>
		</AccessControl>
	);
};
export default MyHome_AddNotificationRules;
