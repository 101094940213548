import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../utils/Form/MyInputText";
import MyInputSelect from "../../utils/Form/MyInputSelect";
import MyInputNumber from "../../utils/Form/MyInputNumber";
import genderList from "../../constants/GenderList";
import girLevel from "../../constants/GirLevel";
import * as constant from "../../constants/Url";
import http from "../../services/Interceptor";
import HandleError from "../../layout/HandleError";

export default function EditSenior({ editMode, setEditMode, store, dispatch }) {
	const { t } = useTranslation("common");
	const seniorSelected = editMode.senior;
	const methods = useForm();
	const { handleSubmit } = methods;
	const [profileId, setProfileId] = useState(0);
	const canDisplayHDS = process.env.REACT_APP_SHOW_HDS === "true" ? true : false;

	const handleProfile = e => {
		if (e.target.checked) {
			setProfileId(1);
		}
	};

	const onSubmit = values => {
		const seniorData = {
			senior: {
				arrival_date: values.arrivalYear + "-" + values.arrivalMonth + "-" + values.arrivalDay,
				birth_date: values.year + "-" + values.month + "-" + values.day,
				first_name: values.firstName,
				last_name: values.lastName,
				gender: values.gender,
				gir_level: values.gir,
				profile_id: parseInt(profileId)
			}
		};
		http
			.put(`${constant.BASEURL}/seniors/${seniorSelected.id}`, seniorData)
			.then(res => {
				dispatch({
					type: "EDIT_SENIOR",
					payload: res.data.senior
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: <>{t("senior.__editSuccess__")}</>
				});
			})
			.then(() => setEditMode({ edit: false, id: null }))
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<FormProvider {...methods}>
			<h5>{t("senior.__editSenior__")} :</h5>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__editError__")} />

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col-md-2">
						<MyInputSelect
							label={t("senior.__addSeniorGender__")}
							name="gender"
							behavior={{ required: true }}
							list={genderList}
							value={seniorSelected.gender}
						/>
					</div>
					<div className="col-md-5">
						<MyInputText
							label={t("common.__firstname__")}
							name="firstName"
							behavior={{ required: true }}
							value={seniorSelected.first_name}
						/>
					</div>
					<div className="col-md-5">
						<MyInputText
							label={t("common.__name__")}
							name="lastName"
							behavior={{ required: true }}
							value={seniorSelected.last_name}
						/>
					</div>

					<div className="col-md-3 mt-4">
						<p>{t("senior.__birthDate__")} :</p>
					</div>
					<div className="col-md-3">
						<MyInputNumber
							label={t("common.__day__")}
							name="day"
							behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
							value={seniorSelected.birth_date.split("-")[2]}
						/>
					</div>
					<div className="col-md-3">
						<MyInputNumber
							label={t("common.__month__")}
							name="month"
							behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
							value={seniorSelected.birth_date.split("-")[1]}
						/>
					</div>
					<div className="col-md-3">
						<MyInputNumber
							label={t("common.__year__")}
							name="year"
							behavior={{ required: true, maxLength: 4, pattern: /[0-9]{4}/ }}
							value={seniorSelected.birth_date.split("-")[0]}
						/>
					</div>

					<div className="col-md-3 mt-2">
						<p>{t("senior.__arrivalDate__")} :</p>
					</div>
					<div className="col-md-3">
						<MyInputNumber
							label={t("common.__day__")}
							name="arrivalDay"
							behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
							value={seniorSelected.arrival_date.split("-")[2]}
						/>
					</div>
					<div className="col-md-3">
						<MyInputNumber
							label={t("common.__month__")}
							name="arrivalMonth"
							behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
							value={seniorSelected.arrival_date.split("-")[1]}
						/>
					</div>
					<div className="col-md-3">
						<MyInputNumber
							label={t("common.__year__")}
							name="arrivalYear"
							behavior={{ required: true, maxLength: 4, pattern: /[0-9]{4}/ }}
							value={seniorSelected.arrival_date.split("-")[0]}
						/>
					</div>
					{canDisplayHDS ? (
						<>
							<div className="col-md-6">
								<MyInputSelect
									label={t("senior.__agir__")}
									name="gir"
									behavior={{ required: false }}
									value={seniorSelected.gir_level}
									list={girLevel}
								/>
							</div>
							<div className="col-md-6 mt-4">
								<input type="checkbox" name="profile" className="mr-2" onChange={e => handleProfile(e)} />{" "}
								<label>{t("senior.__independant__")}</label>
							</div>
						</>
					) : null}
					<div className="col-md-12">
						<button
							type="button"
							className="btn btn-outline-secondary btn-sm mt-4 mr-2"
							onClick={() => setEditMode({ edit: false, id: null })}
						>
							{t("common.__cancel__")}
						</button>
						<button type="submit" className="btn btn-primary btn-sm mt-4">
							{t("common.__save__")}
						</button>
					</div>
				</div>
			</form>
		</FormProvider>
	);
}
