import React from "react";
import { useTranslation } from "react-i18next";
import { HomeInterface } from "../../interfaces/HomeInterface";
import MyInputSelect from "../../utils/Form/MyInputSelect";

interface Props {
	homeId: number | null;
	newUserRole: string;
	setNewUserRole: (arg: string) => void;
	homesFromGroup: HomeInterface[];
}

const AssignHomeToGroupManager = ({ homeId, newUserRole, setNewUserRole, homesFromGroup }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<div className="header-details" data-testid="add-user-homeFromGroup-block">
			<h5 className="blue">{t("healthworker.__addAffectation__")}</h5>
			<div className="row mb-3">
				<div className="col-md-6">
					<input
						type="checkbox"
						checked={newUserRole === "GROUP_MANAGER"}
						onChange={(): void => setNewUserRole("GROUP_MANAGER")}
						data-testid="add-user-checkbox-home-perimeter"
					/>
					<span className="label-checkbox">
						<b>{t("users.__accessToAllHomes__")}</b>
					</span>
				</div>
				<div className="col-md-6">
					<input
						type="checkbox"
						checked={newUserRole === "HOME_MANAGER"}
						onChange={(): void => setNewUserRole("HOME_MANAGER")}
						data-testid="add-user-checkbox-group-perimeter"
					/>
					<span className="label-checkbox">
						<b>{t("users.__accessToOneHome__")}</b>
					</span>
				</div>
			</div>
			{newUserRole === "GROUP_MANAGER" ? null : (
				<MyInputSelect
					label={t("common.__homeSelection__")}
					name="home"
					behavior={{ required: true }}
					list={homesFromGroup}
					value={homeId}
					data-testid="add-user-homeFromGroup-selection"
				/>
			)}
		</div>
	);
};

export default AssignHomeToGroupManager;
