import i18n from "../i18n/i18next";

const girLevel = [
	{
		id: 0,
		name: i18n.t("common:senior.__girNotDefined__")
	},
	{
		id: 1,
		name: "Gir 1",
		description: i18n.t("common:senior.__gir1Info__")
	},
	{
		id: 2,
		name: "Gir 2",
		description: i18n.t("common:senior.__gir2Info__")
	},
	{
		id: 3,
		name: "Gir 3",
		description: i18n.t("common:senior.__gir3Info__")
	},
	{
		id: 4,
		name: "Gir 4",
		description: i18n.t("common:senior.__gir4Info__")
	},
	{
		id: 5,
		name: "Gir 5",
		description: i18n.t("common:senior.__gir5Info__")
	},
	{
		id: 6,
		name: "Gir 6",
		description: i18n.t("common:senior.__gir6Info__")
	}
];

export default girLevel;
