import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DailyChangeVisualisation } from "./DailyChangeVisualisation";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { FiltersProps } from "./DotationFilters";
import { AverageProps, RatioProps } from "./DotationReducer";
import { DiaperInterface } from "../../../interfaces/DiaperInterface";
import { HealthWorkerInterface } from "../../../interfaces/UserInterface";
import DotationTable from "./DotationTable";
import DotationBudgetStatistics from "./DotationBudgetStatistics";
import DotationTableFiltered from "./DotationTableFiltered";
import DotationAndChangesChart from "./DotationAndChangesChart";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

interface StatisticStoreInterFace {
	loading: boolean;
	ratio: RatioProps | null;
	average: AverageProps | null;
	delta: any | null;
	filteredDelta: any | null;
	filter: string;
}

interface Props {
	homeId: number;
	userLocale: string;
	diapers: DiaperInterface[];
	filtersStore: FiltersProps;
	healthWorkers: HealthWorkerInterface[];
	statisticStore: StatisticStoreInterFace;
	statisticDispatch: React.Dispatch<any>;
	showPercentage: boolean;
}

const DotationStatistics = ({
	homeId,
	userLocale,
	diapers,
	filtersStore,
	healthWorkers,
	statisticStore,
	statisticDispatch
}: Props): JSX.Element => {
	const { t } = useTranslation("common");

	const handleData = (startDate: string, endDate: string, startTime: string, endTime: string): any => {
		switch (filtersStore.filterSelected) {
			case "SENIOR":
				if (filtersStore.senior && filtersStore.senior.value) {
					const seniorValue = filtersStore.senior.value;
					return { 
						from: `${startDate} ${startTime}`,
						to: `${endDate} ${endTime}`,
						senior_id: seniorValue 
					};
				} else {
					return { 
						from: `${startDate} ${startTime}`,
						to: `${endDate} ${endTime}`,
						home_id: homeId  
					};
				}
				break;

			case "HOME_UNIT":
				return { 	from: `${startDate} ${startTime}`,
				to: `${endDate} ${endTime}`, home_unit_id: filtersStore.homeUnitId };
			case "FLOOR":
				return { 	from: `${startDate} ${startTime}`,
				to: `${endDate} ${endTime}`, floor_id: filtersStore.floorId, home_id: homeId };
			default:
				return { 	from: `${startDate} ${startTime}`,
				to: `${endDate} ${endTime}`, home_id: homeId };
		}
	};

	useEffect(() => {
		let mounted = true;
		if (homeId && mounted) {
			statisticDispatch({ type: "LOADING" });

			const startDate = handleDate(filtersStore.startDate, "yyyy-MM-dd");
			const endDate = handleDate(filtersStore.endDate, "yyyy-MM-dd");
			const startTimeFormatted = handleDate(filtersStore.startTime, "HH:mm:ss");
			const endTimeFormatted = handleDate(filtersStore.endTime, "HH:mm:ss");
			const data = handleData(startDate, endDate, startTimeFormatted, endTimeFormatted);

			http
				.post(`${url.BASEURL}/rht/report/cost_average`, data)
				.then(res => statisticDispatch({ type: "SET_AVERAGE", payload: res.data }))
				.catch(() => statisticDispatch({ type: "SET_AVERAGE", payload: {} }));
			http
				.post(`${url.BASEURL}/rht/report/ratio_form_report`, data)
				.then(res => statisticDispatch({ type: "SET_RATIO", payload: res.data }))
				.catch(() => statisticDispatch({ type: "SET_RATIO", payload: {} }));
			http
				.post(`${url.BASEURL}/rht/report/provisions_delta`, data)
				.then(res => {
					const data = {
						seniors_results: res.data.seniors_results,
						result: res.data.result,
						chart: [
							{ name: t("diaper.__compliantChange__"), value: res.data.result.percentages.nb_ok },
							{ name: t("diaper.__nonCompliantChange__"), value: res.data.result.percentages.nb_found_but_bad },
							{ name: t("diaper.__extraProvision__"), value: res.data.result.percentages.nb_extra },
							{ name: t("diaper.__provisionMissing__"), value: res.data.result.percentages.nb_not_found }
						]
					};
					statisticDispatch({ type: "SET_DELTA", payload: data });
				})
				.catch(() => statisticDispatch({ type: "SET_DELTA", payload: {} }));
		}
		return function cleanup() {
			mounted = false;
		};
	}, [homeId, filtersStore]);

	useEffect(() => {
		let mounted = true;
		if (mounted && statisticStore.delta && statisticStore.ratio && statisticStore.average) {
			statisticDispatch({ type: "LOADED" });
		}
		return function cleanup() {
			mounted = false;
		};
	}, [statisticStore.delta, statisticStore.ratio, statisticStore.average]);

	return (
		<>
			<h5 className="mt-2">
				{t("diaper.__realDataFromOurApp__")} {process.env.REACT_APP_NAME}
			</h5>
			<DotationBudgetStatistics average={statisticStore.average} anatomicRate={statisticStore.ratio} />

			{filtersStore.filterSelected === "SENIOR" && Array.isArray(filtersStore.senior)
				? filtersStore.senior.map(
						senior =>
							senior?.value && (
								<DailyChangeVisualisation
									diapers={diapers}
									startDate={filtersStore.startDate}
									endDate={filtersStore.endDate}
									userLocale={userLocale}
									selectedSenior={senior}
									key={senior.value}
								/>
							)
				  )
				: null}

			{statisticStore.delta ? (
				<DotationAndChangesChart deltaStatistics={statisticStore.delta} showPercentage={true} />
			) : null}

			<div className="big-card mt-3 mb-3 dont-print-this">
				<p>{t("common.__detailedView__")}</p>
				{filtersStore.filterSelected === "SENIOR" && filtersStore.senior ? null : (
					<div className="d-flex mb-3">
						<p className="meta mr-2 mt-1">{t("common.__filterBy__")} :</p>
						<p
							className={statisticStore.filter === "OK" ? "category-badge green" : "category-outline-badge green"}
							role="button"
							onClick={(): void => statisticDispatch({ type: "FILTER_DELTA", payload: "OK" })}>
							{t("diaper.__compliantChange__")}
						</p>
						<p
							className={statisticStore.filter === "FOUND_BUT_BAD" ? "category-badge red" : "category-outline-badge red"}
							role="button"
							onClick={(): void => statisticDispatch({ type: "FILTER_DELTA", payload: "FOUND_BUT_BAD" })}>
							{t("diaper.__nonCompliantChange__")}
						</p>
						<p
							className={statisticStore.filter === "EXTRA" ? "category-badge blue" : "category-outline-badge blue"}
							role="button"
							onClick={(): void => statisticDispatch({ type: "FILTER_DELTA", payload: "EXTRA" })}>
							{t("diaper.__extraProvision__")}
						</p>
						<p
							className={statisticStore.filter === "NOT_FOUND" ? "category-badge yellow" : "category-outline-badge yellow"}
							role="button"
							onClick={(): void => statisticDispatch({ type: "FILTER_DELTA", payload: "NOT_FOUND" })}>
							{t("diaper.__provisionMissing__")}
						</p>
						<p
							className="category-outline-badge grey"
							role="button"
							onClick={(): void => statisticDispatch({ type: "DISPLAY_ALL", payload: "NO_FILTER" })}>
							X
						</p>
					</div>
				)}
				{statisticStore.filter === "NO_FILTER" || (filtersStore.filterSelected === "SENIOR" && filtersStore.senior) ? (
					<DotationTable statistics={statisticStore.delta} diapers={diapers} userLocale={userLocale} />
				) : (
					<DotationTableFiltered
						statistics={statisticStore.filteredDelta}
						diapers={diapers}
						healthWorkers={healthWorkers}
						userLocale={userLocale}
					/>
				)}
			</div>
		</>
	);
};
export default DotationStatistics;
