import React from "react";
import { UserInterface } from "../interfaces/UserInterface";
import { SeniorTabs } from "../utils/Tabs/TabsToDisplay";
import useStoreData from "../hooks/useStoreData";
import FamilyList from "../components/Seniors/Family/FamilyList";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";
import SelectHomeInput from "../layout/SelectHomeInput";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const Seniors_Family = ({ homeId, userState }: Props): JSX.Element => {
	const { store } = useStoreData({ userState, homeId });
	const isAdmin = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN]);

	return homeId ? (
		<>
			<Tabs tabsToDisplay={SeniorTabs} currentTab={"FAMILIES"} />
			<FamilyList
				seniorsFromHome={store.seniors.seniorsFromHome}
				homeId={homeId}
				isAdmin={isAdmin}
				userLocale={userState.userConnected.locale}
			/>
		</>
	) : (
		<SelectHomeInput />
	);
};
export default Seniors_Family;
