import React, { useEffect } from "react";
import { UserInterface } from "../interfaces/UserInterface";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AccessControl from "../layout/AccessControl";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import AddHome from "../components/Home/Homes/AddHome";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";
import { HomeStoreInterface } from "../interfaces/HomeInterface";

interface Props {
	userState: UserInterface;
	tenantId: number | null;
	setHomeId: (arg: number) => void;
	setTenantId: (arg: number) => void;
	setGroupId: (arg: number) => void;
	homeStore: HomeStoreInterface;
	homeDispatch: React.Dispatch<any>;
	groupId: number | null;
}

const AllHomes_AddHome = ({
	userState,
	setHomeId,
	tenantId,
	setTenantId,
	homeStore,
	homeDispatch,
	groupId,
	setGroupId
}: Props): JSX.Element => {
	const isAdmin = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN]);
	const isSuperAdmin = userState.userConnected.is(p.SUPER_ADMIN);

	useEffect(() => {
		if (isAdmin) {
			http
				.get(`${url.BASEURL}/home_types`)
				.then(res => {
					homeDispatch({
						type: "GET_HOME_TYPES",
						payload: res.data.home_types
					});
				})
				.catch(() => {
					homeDispatch({
						type: "GET_HOME_TYPES",
						payload: []
					});
				});
		}
		// eslint-disable-next-line
	}, [isAdmin]);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.ADMIN, p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={
					<AddHome
						homeStore={homeStore}
						homeDispatch={homeDispatch}
						isSuperAdmin={isSuperAdmin}
						setHomeId={setHomeId}
						tenantId={tenantId}
						setTenantId={setTenantId}
						groupId={groupId}
						setGroupId={setGroupId}
					/>
				}
			/>
		</AccessControl>
	);
};
export default AllHomes_AddHome;
