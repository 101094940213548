import React from "react";
import { UserInterface } from "../interfaces/UserInterface";
import { VisioTabs } from "../utils/Tabs/TabsToDisplay";
import Tabs from "../utils/Tabs/Tabs";
import AccessControl from "../layout/AccessControl";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import VisioTimingConstraints from "../components/Home/Recipients/VisioTimingConstraints";
import SelectHomeInput from "../layout/SelectHomeInput";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_VisioConstraints = ({ homeId, userState }: Props): JSX.Element => {
	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_HOME]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<HomesSecondaryNav userState={userState} />}
				component={
					<>
						<Tabs tabsToDisplay={VisioTabs} currentTab={"CONSTRAINTS"} />
						<div className="big-card">{homeId ? <VisioTimingConstraints homeId={homeId} /> : <SelectHomeInput />}</div>
					</>
				}
			/>
		</AccessControl>
	);
};
export default MyHome_VisioConstraints;
