import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaRegTimesCircle } from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import useDetectOutsideClick from "../../hooks/useDetectOutsideClick";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function RemoveButtons({ recurringEvent, eventId, eventsDispatch, eventDate, setUpdate }) {
	const { t } = useTranslation("common");
	const dropdownRef = useRef(null);
	const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

	const removeEvent = (eventId, type) => {
		if (eventId && type) {
			let deleteUrl;
			if (type.type === "ALL_EVENTS") {
				deleteUrl = `${url.EVENTURL}/${eventId}/recurring/all`;
			} else if (type.type === "EVENTS_AFTER_THIS_DATE") {
				const today = handleDate(eventDate, "yyyy-MM-dd'T'hh:mm:00");
				deleteUrl = `${url.EVENTURL}/${eventId}/recurring/greater_than/${today}`;
			} else {
				deleteUrl = `${url.EVENTURL}/${eventId}`;
			}
			http
				.delete(deleteUrl)
				.then(() => {
					if (type.type === "ALL_EVENTS" || type.type === "EVENTS_AFTER_THIS_DATE") {
						setUpdate(true);
					} else {
						eventsDispatch({
							type: "DELETE_EVENT",
							payload: eventId
						});
					}
					eventsDispatch({
						type: "DISPLAY_SUCCESS",
						payload: t("calendar.__removeAnimation__")
					});
				})
				.catch(() => {
					eventsDispatch({
						type: "ERROR_EVENTS",
						payload: t("common.__delError__")
					});
				});
		}
	};

	return recurringEvent ? (
		<div className="menu-container">
			<FaRegTimesCircle className="table-icon" role="button" onClick={() => setIsActive(prevState => !prevState)} />
			<div ref={dropdownRef} className={`menu ${isActive ? "active" : "inactive"}`}>
				<li role="button" onClick={() => removeEvent(eventId, { type: "EVENT" })}>
					{t("calendar.__removeEvent__")}
				</li>
				<li role="button" onClick={() => removeEvent(eventId, { type: "ALL_EVENTS" })}>
					{t("calendar.__removeAllEvents__")}
				</li>
				<li role="button" onClick={() => removeEvent(eventId, { type: "EVENTS_AFTER_THIS_DATE" })}>
					{t("calendar.__removeNextEvents__")}
				</li>
			</div>
		</div>
	) : (
		<FaRegTimesCircle className="table-icon" role="button" onClick={() => removeEvent(eventId, { type: "EVENT" })} />
	);
}
