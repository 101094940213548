import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import useDateBeforeOrAfter from "../../hooks/useDateBeforeOrAfter";
import DiaperChangeBarChart from "./DiaperChangeBarChart";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function OneWeekDiaperChangeStatistics({ homeId, userState }) {
	const { t } = useTranslation("common");
	const userLocale = userState.userConnected.locale;
	const oneWeek = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 6 });
	const [oneWeekDirtyBed, setOneWeekDirtyBed] = useState([]);

	useEffect(() => {
		if (homeId && oneWeek) {
			const data = {
				from: format(oneWeek, "yyyy-MM-dd'T'00:00:00"),
				to: format(new Date(), "yyyy-MM-dd'T'23:59:59"),
				home_id: homeId
			};
			http
				.post(`${url.BASEURL}/rht/report/dirty_bed`, data)
				.then(res => {
					const result = res.data;
					const data = result.map(elt => {
						return {
							...elt,
							formatedDate: handleDate(elt.date, "P", userLocale)
						};
					});
					setOneWeekDirtyBed(data);
				})
				.catch(() => setOneWeekDirtyBed([]));
		}
	}, [homeId, userLocale]);

	return (
		<div className="big-card mt-3 mb-3">
			<div className="row">
				<div className="col-12">
					<h5>{t("diaper.__dirtyBeds__")}</h5>
				</div>
				<div className="col-md-8">
					<DiaperChangeBarChart data={oneWeekDirtyBed} />
				</div>
				<div className="col-md-4">
					<div className="header-details">
						<p className="mb-3 mt-2">
							<b>{t("diaper.__dirtBedCounter__")} :</b>
						</p>
						{oneWeekDirtyBed.length > 0 ? (
							<ul className="mb-2">
								{oneWeekDirtyBed.map((dirtyBed, index) => (
									<li key={index} className="mt-2">
										<span className="meta mr-4">{dirtyBed.formatedDate} :</span>
										{dirtyBed.count === 0 ? <span className="meta">{dirtyBed.count}</span> : <b>{dirtyBed.count}</b>}
									</li>
								))}
							</ul>
						) : (
							<p className="meta">{t("common.__noData__")}</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
