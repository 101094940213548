import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
	isToggle: boolean;
	value: any;
	action: any;
	textActif?: string;
	textInactif?: string;
	meta?: boolean;
}

const Toggle = ({ isToggle, value, action, textActif, textInactif, meta }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	return isToggle ? (
		<div className="form-inline">
			<div className="toggle-button-checked" onClick={(): void => action(value)}></div>
			<label className={meta ? "toggle-meta ml-1" : "ml-1"}>{textActif ? textActif : t("common.__active__")}</label>
		</div>
	) : (
		<div className="form-inline">
			<div className="toggle-button" onClick={(): void => action(value)}></div>
			<label className={meta ? "toggle-meta ml-1" : "ml-1"}>{textInactif ? textInactif : t("common.__inactive__")}</label>
		</div>
	);
};
export default Toggle;
