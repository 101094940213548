import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import * as p from "../../../constants/Permissions";
import RangePicker from "../../../utils/DatePicker/RangePicker";
import AllActivities from "../../Timeline/AllActivities";
import useDateBeforeOrAfter from "../../../hooks/useDateBeforeOrAfter";
import NoData from "../../../layout/NoData";

export default function HealthWorkerActivities({ hwId, userState }) {
	const { t } = useTranslation("common");
	const canReadActivities = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.CAN_READ_ACTIVITIES]);
	const [healthWorkerDetails, setHealthWorkerDetails] = useState({});
	const [hwError, setHwError] = useState(false);
	const today = new Date();
	const yesterday = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 1 });
	const [startDate, setStartDate] = useState(yesterday);
	const [endDate, setEndDate] = useState(today);

	useEffect(() => {
		if (hwId) {
			http
				.get(`${url.BASEURL}/users/${hwId}`)
				.then(res => setHealthWorkerDetails(res.data.user))
				.catch(() => setHwError(true));
		}
		// eslint-disable-next-line
	}, [hwId]);

	return (
		<>
			{hwError ? (
				<NoData />
			) : (
				<>
					<h5>
						<FaAngleRight /> {t("activities.__from__")} :{" "}
						<Link to={`/user/healthworker/${hwId}`}>
							{healthWorkerDetails.first_name === "badge_general"
								? healthWorkerDetails.last_name
								: `${healthWorkerDetails.first_name} ${healthWorkerDetails.last_name}`}
						</Link>
					</h5>
					<div className="form-inline">
						<p className="mt-1 mr-2">{t("common.__dates__")} : </p>
						<RangePicker
							userLocale={userState.userConnected.locale}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
						/>
					</div>

					<div className="space"></div>
					{canReadActivities ? (
						<AllActivities userState={userState} id={hwId} startDate={startDate} endDate={endDate} display="worker" />
					) : null}
				</>
			)}
		</>
	);
}
