import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserInterface } from "../interfaces/UserInterface";
import { TabletTabs } from "../utils/Tabs/TabsToDisplay";
import Tabs from "../utils/Tabs/Tabs";
import useDateBeforeOrAfter from "../hooks/useDateBeforeOrAfter";
import SelectHomeInput from "../layout/SelectHomeInput";
import HeaderWithRangeContainer from "../layout/HeaderWithRangeContainer";
import AllActivities from "../components/Timeline/AllActivities";
import Memo from "../components/MemoT/Memo";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_Activities = ({ homeId, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const userLocale = userState.userConnected.locale;
	const today = new Date();
	const last30Days = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 30 });
	const [startDate, setStartDate] = useState(last30Days);
	const [endDate, setEndDate] = useState(today);
	const canReadActivities = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.CAN_READ_ACTIVITIES]);

	return homeId ? (
		canReadActivities ? (
			<>
				<Tabs tabsToDisplay={TabletTabs} currentTab={"TIMELINE"} />
				<div className="big-card">
					<HeaderWithRangeContainer
						title={null}
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
						userLocale={userLocale}
					/>
					<AllActivities userState={userState} id={homeId} startDate={startDate} endDate={endDate} display="all" />
				</div>
			</>
		) : (
			<div className="big-card">
				<HeaderWithRangeContainer
					title={t("memo.__memo__")}
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
					userLocale={userLocale}
				/>
				<Memo userState={userState} homeId={homeId} startDate={startDate} endDate={endDate} />
			</div>
		)
	) : (
		<SelectHomeInput />
	);
};
export default MyHome_Activities;
