import { displayBadgeType, displayValidityTypes } from "../../components/HealthWorkers/Badges/ValidityTypes";
import i18n from "../../i18n/i18next";
import DisplayName from "../../utils/DisplayName";

const HealthWorkersReducer = (state, action) => {
	switch (action.type) {
		case "GET_HEALTHWORKER_TYPES":
			return {
				...state,
				healthWorkerTypes: action.payload
			};
		case "ADD_HEALTHWORKER_TYPE":
			return {
				...state,
				healthWorkerTypes: [...state.healthWorkerTypes, action.payload]
			};
		case "DELETE_HEALTHWORKER_TYPE":
			return {
				...state,
				healthWorkerTypes: state.healthWorkerTypes.filter(hwt => hwt.id !== action.payload)
			};
		case "EDIT_HEALTHWORKER_TYPE": {
			const updatedType = action.payload;
			const healthWorkerTypesUpdated = state.healthWorkerTypes.map(hwt => {
				if (hwt.id === updatedType.id) {
					return updatedType;
				}
				return hwt;
			});
			return {
				...state,
				healthWorkerTypes: healthWorkerTypesUpdated
			};
		}
		case "GET_HEALTHWORKERS": {
			const users = action.payload;
			const usersData = users.map(function (user) {
				return {
					...user,
					name:
						user.first_name === "badge_general"
							? user.last_name.toUpperCase()
							: `${user.last_name.toUpperCase()} ${user.first_name}`,
					activeAccount: user.active ? i18n.t("common:common.__active__") : i18n.t("common:common.__inactive__"),
					job: DisplayName(user.type_id, state.healthWorkerTypes)
				};
			});
			return {
				...state,
				healthWorkers: usersData
			};
		}

		case "ADD_HEALTHWORKER": {
			const newUser = {
				...action.payload,
				name:
					action.payload.first_name === "badge_general"
						? action.payload.last_name.toUpperCase()
						: `${action.payload.last_name.toUpperCase()} ${action.payload.first_name}`,
				activeAccount: action.payload.active ? i18n.t("common:common.__active__") : i18n.t("common:common.__inactive__"),
				job: DisplayName(action.payload.type_id, state.healthWorkerTypes)
			};
			return {
				...state,
				healthWorkers: [...state.healthWorkers, newUser]
			};
		}

		case "EDIT_HEALTHWORKER": {
			const updatedHealthWorker = action.payload;
			const healthWorkerUpdated = state.healthWorkers.map(healthWorker => {
				if (healthWorker.id === updatedHealthWorker.id) {
					return {
						...updatedHealthWorker,
						name:
							updatedHealthWorker.first_name === "badge_general"
								? updatedHealthWorker.last_name.toUpperCase()
								: `${updatedHealthWorker.last_name.toUpperCase()} ${updatedHealthWorker.first_name}`,
						activeAccount: updatedHealthWorker.active
							? i18n.t("common:common.__active__")
							: i18n.t("common:common.__inactive__"),
						job: DisplayName(updatedHealthWorker.type_id, state.healthWorkerTypes)
					};
				}
				return healthWorker;
			});
			return {
				...state,
				healthWorkers: healthWorkerUpdated
			};
		}

		case "DELETE_HEALTHWORKER":
			return {
				...state,
				healthWorkers: state.healthWorkers.filter(healthWorker => healthWorker.id !== action.payload)
			};

		case "GET_BADGES": {
			const badges = action.payload;
			const badgeData = badges.map(function (badge) {
				return {
					...badge,
					type: displayBadgeType(badge.type_id),
					restriction: displayValidityTypes(badge.validity.type),
					state: badge.active ? i18n.t("common:common.__active__") : i18n.t("common:common.__inactive__")
				};
			});
			return {
				...state,
				badges: badgeData
			};
		}

		case "ADD_BADGE": {
			const newBadge = {
				...action.payload,
				type: displayBadgeType(action.payload.type_id),
				restriction: displayValidityTypes(action.payload.validity.type),
				state: i18n.t("common:common.__active__")
			};
			return {
				...state,
				badges: [...state.badges, newBadge]
			};
		}

		case "TOGGLE_BADGE": {
			const updatedBadge = action.payload;
			const badgeUpdated = state.badges.map(badge => {
				if (badge.id === updatedBadge.id) {
					return {
						...badge,
						active: updatedBadge.active,
						state: updatedBadge.active ? i18n.t("common:common.__active__") : i18n.t("common:common.__inactive__")
					};
				}
				return badge;
			});
			return {
				...state,
				badges: badgeUpdated
			};
		}

		case "EDIT_BADGE": {
			const updatedBadge = action.payload;
			const badgeUpdated = state.badges.map(badge => {
				if (badge.id === updatedBadge.id) {
					return {
						...updatedBadge,
						type: displayBadgeType(updatedBadge.type_id),
						restriction: displayValidityTypes(updatedBadge.validity.type),
						state: updatedBadge.active ? i18n.t("common:common.__active__") : i18n.t("common:common.__inactive__")
					};
				}
				return badge;
			});
			return {
				...state,
				badges: badgeUpdated
			};
		}

		case "DELETE_BADGE":
			return {
				...state,
				badges: state.badges.filter(badge => badge.id !== action.payload)
			};
		case "LOGOUT_HW": {
			return {
				...state,
				badges: [],
				healthWorkers: []
				// allHealthWorkers: []
			};
		}
		default:
			return state;
	}
};

export default HealthWorkersReducer;
