import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fromToUrl } from "@e4ia/e4link_modules.datetime";
import PieChartContainer from "../../utils/PieChartContainer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

interface Props {
	startDate: Date;
	endDate: Date;
	homeId: number | null;
}

const BlogStatistics = ({ homeId, startDate, endDate }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [blogStats, setBlogStats] = useState([]);

	useEffect(() => {
		if (homeId) {
			const fromTo = fromToUrl(startDate, endDate);

			http.get(`${url.HOMESURL}/${homeId}/stats/blog_posts/${fromTo}`).then(res => {
				const data = res.data.blog_posts;
				const blogData: any = [
					{
						name: t("news.__moderationOk__"),
						value: data.accepted
					},
					{
						name: t("news.__moderationRefused__"),
						value: data.rejected
					},
					{
						name: t("news.__moderationPending__"),
						value: data.pending
					}
				];
				if (blogData.every((item: any) => item.value === 0)) {
					setBlogStats([]);
				} else {
					setBlogStats(blogData);
				}
			});
		}
		// eslint-disable-next-line
	}, [startDate, endDate, homeId]);

	return (
		<div className="col">
			<p>
				<b>{t("homeBoard.__blogStats__")}</b>
			</p>
			{blogStats.length > 0 ? (
				<PieChartContainer data={blogStats} dataKey="value" height={320} showPercentage={false} />
			) : (
				<>
					<div className="light-separator"></div>
					<p className="meta">
						{t("common.__noElement__")} {t("common.__selectNewDate__")}
					</p>
				</>
			)}
		</div>
	);
};
export default BlogStatistics;
