import React from "react";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export const removeDevice = (deviceId: number, devicesDispatch: React.Dispatch<any>, t: any): void => {
	http
		.delete(`${url.BASEURL}/devices/${deviceId}`)
		.then(() => {
			devicesDispatch({
				type: "DELETE_DEVICE",
				payload: deviceId
			});
			devicesDispatch({
				type: "DISPLAY_SUCCESS",
				payload: t("device.__removeSuccess__")
			});
		})
		.catch(() => {
			devicesDispatch({
				type: "DISPLAY_ERROR",
				payload: t("common.__delError__")
			});
		});
};
