import React, { useEffect, useState, useContext } from "react";
import { HomeContext } from "../context/HomeContext";
import { UserInterface } from "../interfaces/UserInterface";
import { DeviceInterface, HomeStoreInterface, RoomInterface } from "../interfaces/HomeInterface";
import DevicesList from "../components/Devices/DevicesList";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import AccessControl from "../layout/AccessControl";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";
import DevicesListByHome from "../components/Devices/DevicesListByHome";
import DisplayName from "../utils/DisplayName";

interface Props {
	homeId: number | null;
	userState: UserInterface;
	homeStore: HomeStoreInterface;
	setHomeId: (id: number) => void;
}

const Devices = ({ userState, homeId, setHomeId, homeStore }: Props): JSX.Element => {
	const { devicesStore, devicesDispatch } = useContext(HomeContext);
	const [deviceSNType, setDeviceSNType] = useState([]);
	const [rooms, setRooms] = useState<RoomInterface[] | null>(null);
	const isAdminOrSuperAdmin = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN]);
	const allHomes = homeStore.home.allHomes;

	useEffect(() => {
		if (isAdminOrSuperAdmin) {
			http
				.get(`${url.BASEURL}/device_serial_number_types`)
				.then(res => setDeviceSNType(res.data.device_serial_number_types))
				.catch(() => setDeviceSNType([]));
		}
	}, [isAdminOrSuperAdmin]);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/rooms`)
				.then(res => setRooms(res.data.rooms))
				.catch(() => setRooms([]));
		}
		// eslint-disable-next-line
	}, [homeId]);

	useEffect(() => {
		if (homeId && rooms) {
			http.get(`${url.HOMESURL}/${homeId}/devices`).then(res => {
				const result = res.data.devices;
				const data = result.map((device: DeviceInterface) => {
					const room = rooms.find(room => room.device_id === device.id);
					return {
						...device,
						home: DisplayName(device.home_id, allHomes),
						roomName: room?.name,
						roomId: room?.id
					};
				});
				devicesDispatch({
					type: "GET_DEVICES",
					payload: data
				});
			});
		} else {
			http.get(`${url.BASEURL}/devices`).then(res => {
				const result = res.data.devices;
				const devices = result.map((device: DeviceInterface) => {
					return {
						...device,
						home: DisplayName(device.home_id, allHomes)
					};
				});
				devicesDispatch({
					type: "GET_DEVICES",
					payload: devices
				});
			});
		}
		// eslint-disable-next-line
	}, [homeId, rooms]);

	useEffect(() => {
		return function cleanup(): void {
			devicesDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.ADMIN, p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={
					homeId ? (
						<DevicesListByHome deviceSNType={deviceSNType} devicesStore={devicesStore} devicesDispatch={devicesDispatch} />
					) : (
						<DevicesList
							setHomeId={setHomeId}
							deviceSNType={deviceSNType}
							devicesStore={devicesStore}
							devicesDispatch={devicesDispatch}
						/>
					)
				}
			/>
		</AccessControl>
	);
};
export default Devices;
