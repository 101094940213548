import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
	FaRegHeart,
	FaHeart,
	FaCamera,
	FaVideo,
	FaRegClock,
	FaRegFilePdf,
	FaDownload,
	FaRegCommentAlt,
	FaUsersCog,
	FaBullhorn
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import VideoPlayer from "../../utils/VideoPlayer";
import Comments from "./Comments/Comments";
import Parse from "html-react-parser";
import LazyloadImg from "../../layout/LazyloadImg";

export default function PostContainer({ userState, post, isPanel, setShow, blogDispatch }) {
	const { t } = useTranslation("common");
	const [showComments, setShowComments] = useState({ show: false, postId: null, counter: 0 });
	const [displaySeniors, setDisplaySeniors] = useState({ display: false, postId: null });

	const displayMeta = medias => {
		if (medias.length > 0) {
			const video = medias.filter(media => media.content_type.split("/")[0] === "video");
			const pdf = medias.filter(media => media.content_type === "application/pdf");
			const videoCounter = video.length;
			const pdfCounter = pdf.length;
			const pictCounter = medias.length - (videoCounter + pdfCounter);
			return (
				<>
					<FaVideo color="#4A4E64" className="nav-icon mr-2 ml-3" /> {videoCounter}
					<FaCamera color="#4A4E64" className="nav-icon  ml-3 mr-2" /> {pictCounter}
					<FaRegFilePdf color="#4A4E64" className="nav-icon  ml-3 mr-2" /> {pdfCounter}
				</>
			);
		}
	};

	const displayMedias = medias => {
		const videoFiles = medias.filter(media => media.content_type.split("/")[0] === "video");
		const pdfFiles = medias.filter(media => media.content_type.split("/")[0] === "application");
		const picturesFiles = medias.filter(media => media.content_type.split("/")[0] === "image");

		return medias.length > 0 ? (
			<>
				{videoFiles.length > 0 && picturesFiles.length < 1 ? (
					<VideoPlayer uriVideo={videoFiles[0].path} contentType={videoFiles[0].content_type} />
				) : picturesFiles.length === 1 ? (
					<LazyloadImg path={`/storages/blog_media/${picturesFiles[0].path}`} display="pict" />
				) : picturesFiles.length >= 2 ? (
					display2picts(picturesFiles)
				) : null}
				{pdfFiles.length > 0 &&
					pdfFiles.map((elt, index) => (
						<div className="header-details" key={index}>
							<a href={`/storages/blog_media${elt.path}`} download={`${elt.filename}`}>
								<FaDownload className="mr-2" />
								<b>{elt.filename}</b> ({t("common.__pdfFile__")})
							</a>
						</div>
					))}
			</>
		) : null;
	};

	const display2picts = picturesFiles => {
		const picturesToDisplay = picturesFiles.splice(0, 2);
		return picturesToDisplay.map((pict, index) => (
			<div key={index} className="column">
				<LazyloadImg path={`/storages/blog_media/${pict.path}`} display="pict" />
				{/* <img src={`/storages/blog_media/${pict.path}`} alt="illustration" className="pict" /> */}
			</div>
		));
	};

	const handleShowComments = post => {
		if (showComments.show) {
			if (showComments.postId === post.id) {
				setShowComments({ show: false, postId: null, counter: 0 });
			} else {
				setShowComments({ show: true, postId: post.id, counter: post.comment_counter });
			}
		} else {
			setShowComments({ show: true, postId: post.id, counter: post.comment_counter });
		}
	};

	return (
		<>
			<div className="d-flex justify-content-between flow">
				{isPanel ? (
					<Link to={{ pathname: `/controlpanel/post`, state: { post: post } }}>
						<p className="blog-title">
							<FaBullhorn className="mr-3" />
							{post.title}
						</p>
					</Link>
				) : (
					<Link to={`/blog/post/${post.id}`}>
						<p className="blog-title">
							<FaBullhorn className="mr-3" />
							{post.title}
						</p>
					</Link>
				)}
				{post.focused_on ? (
					<div
						className="occupation-toggle"
						role="button"
						onClick={() => setDisplaySeniors({ display: !displaySeniors.display, id: post.id })}>
						<FaUsersCog className="flow-button" />
						<span className="meta">{post.seniors_focused_on.length}</span>
					</div>
				) : null}
			</div>
			<div className="flow-block">
				<p className="meta">
					<FaRegClock color="#4A4E64" className="nav-icon mr-2" />
					{handleDate(post.date, "dd/MM/yyyy", userState.userConnected.locale)}
					{displayMeta(post.medias)}
				</p>
				{displaySeniors.display && displaySeniors.id === post.id ? (
					<div className="mt-3">
						{post.seniors_focused_on.map(senior =>
							isPanel ? (
								<b key={senior.id} className="small-tag blue mr-2">
									{senior.last_name.toUpperCase()} {senior.first_name}
								</b>
							) : (
								<Link key={senior.id} to={`/senior/profile/${senior.id}`} className="category-outline-badge blue mr-2">
									{senior.last_name.toUpperCase()} {senior.first_name}
								</Link>
							)
						)}
					</div>
				) : null}
			</div>

			{post.medias.length === 0 ? null : displayMedias(post.medias)}

			<div className="social-text">{Parse(post.body)}</div>
			<div className="social-separator"></div>
			<div className="center social-like row">
				<div className="col-6">
					{post.like_counter > 0 ? (
						<span
							className="like-button"
							role="button"
							onClick={() => setShow({ show: true, info: post.id, title: t("news.__like__") })}>
							<FaHeart className="social-icon mr-2" />
							<span className="like-button">
								<b>{post.like_counter}</b>
							</span>
						</span>
					) : (
						<span className="meta">
							<FaRegHeart className="social-icon darkgrey mr-2" /> 0
						</span>
					)}
				</div>
				<div className="col-6">
					{post.comment_counter > 0 ? (
						<span
							id={`show-comments-${post.id}`}
							className="fake-link"
							role="button"
							onClick={() => handleShowComments(post)}>
							<b>
								<FaRegCommentAlt className="social-icon blue mr-2" />
								{post.comment_counter}
							</b>
						</span>
					) : (
						<span className="metafake-link" role="button" onClick={() => handleShowComments(post)}>
							<FaRegCommentAlt className="social-icon darkgrey mr-2" /> 0
						</span>
					)}
				</div>
			</div>
			{showComments.show && post.id === showComments.postId ? (
				<>
					<div className="social-separator"></div>
					<div style={{ margin: "1rem" }}>
						<Comments
							blogDispatch={blogDispatch}
							postId={post.id}
							commentsCounter={post.comment_counter}
							userState={userState}
						/>
					</div>
				</>
			) : null}
		</>
	);
}
