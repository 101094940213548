import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import MyInputTextarea from "../../utils/Form/MyInputTextarea";
import ErrorContainer from "../../layout/ErrorContainer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import { FaAngleRight } from "react-icons/fa";

export default function VisioMsgModal({ show, setShow, msgDispatch, notificationsDispatch, userState, type }) {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const [httpResponse, setHttpResponse] = useState(null);

	const onSubmit = values => {
		const data = {
			message_id: show.info.id,
			message: values.message
		};
		const path = type === "cancel" ? `${url.HOMESURL}/visio/cancel` : `${url.BASEURL}/homes/visio/reject`;
		http
			.post(path, data)
			.then(res => {
				if (userState.userConnected.id === res.data.family_user_message.user_id) {
					notificationsDispatch({
						type: "UPDATE_MESSAGES_COUNTER",
						payload: 1
					});
				}
				msgDispatch({
					type: "EDIT_MESSAGE",
					payload: res.data.family_user_message
				});
				msgDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("mailbox.__msgSent__")
				});
				setShow({ show: false, info: { id: null }, success: true });
			})
			.catch(() => setHttpResponse({ error: true }));
	};

	return (
		<FormProvider {...methods}>
			<h2>
				{type === "cancel" ? (
					<>
						{t("calendar.__visio__")} <FaAngleRight /> {t("common.__cancel__")}
					</>
				) : (
					t("mailbox.__vRefused__")
				)}
			</h2>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ErrorContainer
					alert={t("mailbox.__msgNotSent__")}
					success={t("mailbox.__msgSent__")}
					httpResponse={httpResponse}
					setHttpResponse={setHttpResponse}
				/>

				<MyInputTextarea label={t("mailbox.__addComment__")} name="message" behavior={{ required: true }} rows="5" />
				<button type="submit" className="btn btn-primary">
					{t("common.__send__")}
				</button>
			</form>
		</FormProvider>
	);
}
