import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleRight } from "react-icons/fa";
import { PhotoFramePreferenceInterface } from "./PreferencesReducer";
import PhotoFramePreferences from "./PhotoFramePreferences";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

interface Props {
	seniorId: number;
	preferences: PhotoFramePreferenceInterface;
	dispatch: React.Dispatch<any>;
}

const SeniorPreferences = ({ seniorId, preferences, dispatch }: Props): JSX.Element | null => {
	const { t } = useTranslation("common");

	useEffect(() => {
		if (seniorId) {
			http.get(`${url.SENIORSURL}/${seniorId}/device_config`).then(res => {
				dispatch({
					type: "GET_SENIOR_PHOTOFRAME_PREFERENCES",
					payload: res.data.PHOTO_FRAME_ACTIVATION_RANGES
				});
			});
		}
	}, [seniorId]);

	useEffect(() => {
		return function cleanup() {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<div className="mt-4 mb-3">
			{seniorId && preferences.seniorSelected.photoframe.length > 0 ? (
				<PhotoFramePreferences seniorId={seniorId} preferences={preferences.seniorSelected} dispatch={dispatch} />
			) : (
				<p>
					<FaAngleRight className="mr-2" />
					{t("device.__noSeniorPhotoPref__")}
				</p>
			)}
		</div>
	);
};
export default SeniorPreferences;
