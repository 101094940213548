import React, { useEffect, useReducer, useState } from "react";
import { HealthWorkerTypesInterface, UserInterface, StoreInterface } from "../../interfaces/UserInterface";
import HandleError from "../../layout/HandleError";
import HandleSuccess from "../../layout/HandleSuccess";
import NoData from "../../layout/NoData";
import Loader from "../../layout/Loader";
import HealthWorkerProfileContainer from "./HealthWorkerProfileContainer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export const HealthWorkerReducer = (state: any, action: { type: string; payload: any }): any => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true,
				error: false
			};
		case "GET_HEALTHWORKER":
			return {
				...state,
				loading: false,
				healthWorker: action.payload,
				badges: action.payload.badges,
				error: false
			};
		case "TOGGLE_HEALTHWORKER":
			return {
				...state,
				healthWorker: action.payload
			};
		case "RESET_RGPD":
			return {
				...state,
				healthWorker: {
					...state.healthWorker,
					has_accepted_rgpd: false
				}
			};
		case "DISPLAY_ERROR":
			return {
				...state,
				loading: false,
				error: true
			};
		default:
			return {
				...state
			};
	}
};

interface Props {
	id: number;
	healthWorkerTypes: HealthWorkerTypesInterface[];
	store: StoreInterface;
	dispatch: React.Dispatch<any>;
	userState: UserInterface;
	homeId: number | null;
}

const HealthWorkerProfile = ({ id, healthWorkerTypes, store, dispatch, userState, homeId }: Props): JSX.Element => {
	const [update, setUpdate] = useState(false);
	const [healthWorkerStore, healthWorkerDispatch] = useReducer(HealthWorkerReducer, {
		healthWorker: null,
		badges: [],
		error: false,
		loading: false,
		msg: null
	});

	useEffect(() => {
		if (id) {
			http
				.get(`${url.BASEURL}/users/${id}?badges=true`)
				.then(res => {
					healthWorkerDispatch({
						type: "GET_HEALTHWORKER",
						payload: res.data.user
					});
				})
				.catch(() => {
					healthWorkerDispatch({
						type: "DISPLAY_ERROR",
						payload: true
					});
				});
		}
		// eslint-disable-next-line
	}, [id, update]);

	return healthWorkerStore.loading ? (
		<Loader />
	) : healthWorkerStore.healthWorker ? (
		<>
			<HandleError error={store.es.error} dispatch={dispatch} message={store.es.msg} />
			<HandleSuccess success={store.es.success} dispatch={dispatch} message={store.es.msg} noSpace />
			<HealthWorkerProfileContainer
				userState={userState}
				dispatch={dispatch}
				healthWorkerTypes={healthWorkerTypes}
				healthWorkerDetails={healthWorkerStore.healthWorker}
				healthWorkerBadges={healthWorkerStore.badges}
				healthWorkerDispatch={healthWorkerDispatch}
				homeId={homeId}
				store={store}
				setUpdate={setUpdate}
			/>
		</>
	) : (
		healthWorkerStore.error && <NoData />
	);
};

export default HealthWorkerProfile;
