import React, { useEffect, useContext, useReducer, useCallback } from "react";
import { DiaperContext } from "../../context/DiaperContext";
import { fromToUrl, handleDate } from "@e4ia/e4link_modules.datetime";
import { getActivityAction, getActivityDetail, getActivityTitle, mood } from "../../utils/Activities/Activities";
import DisplayName from "../../utils/DisplayName";
import TimelineContainer from "./TimelineContainer";
import TimelineReducer from "./TimelineReducer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as p from "../../constants/Permissions";

export default function AllActivities({ userState, id, startDate, endDate, display }) {
	const initialState = {
		loading: false,
		data: []
	};
	const [activities, activitiesDispatch] = useReducer(TimelineReducer, initialState);
	const { diaperStore, diaperDispatch } = useContext(DiaperContext);
	const diapers = diaperStore.diapers;
	const isDiaperManager = userState.userConnected.is(p.DIAPER_APPLICATION);

	const displayHealthWorkerName = useCallback(hw => {
		if (hw.first_name === "badge_general") {
			return `${hw.last_name}`;
		} else {
			return `${hw.first_name} ${hw.last_name}`;
		}
	}, []);

	const handleDiaperName = useCallback(
		(dval, dmsg) => {
			if (dval === -1) {
				return DisplayName(dmsg.diaper_id, diapers);
			}
			return;
		},
		[diapers]
	);

	const handleUrl = useCallback((display, fromTo, id) => {
		switch (display) {
			case "senior":
				return `${url.SENIORSURL}/${id}/raw_activities/${fromTo}`;
			case "worker":
				return `${url.BASEURL}/health_workers/${id}/raw_activities/${fromTo}`;
			default:
				return `${url.HOMESURL}/${id}/raw_activities/${fromTo}`;
		}
	}, []);

	useEffect(() => {
		if (startDate && endDate && id) {
			activitiesDispatch({
				type: "LOADING"
			});

			const fromTo = fromToUrl(startDate, endDate);
			const fetchUrl = handleUrl(display, fromTo, id);

			// did = detail ID
			// After a selected action, a popup ask for more details
			// 1: meal 2: in the refectory 3: hydratation 4: diaper 5: praticien 6:connected diaper

			http.get(fetchUrl).then(res => {
				const rawData = res.data;
				const filteredData = rawData.filter(data => data.aid !== 11 && data.aid !== 12);
				const extendedData = filteredData.map(activity => {
					return {
						...activity,
						aid_title: getActivityTitle(activity.aid),
						aid_action: getActivityAction(activity.aid),
						did_string: activity.did
							? activity.did === 6
								? handleDiaperName(activity.dval, activity.dmsg)
								: getActivityDetail(activity.dval, activity.aid)
							: null,
						hw_name: activity.hw ? displayHealthWorkerName(activity.hw) : null,
						date: handleDate(activity.dt, "P", userState.userConnected.locale),
						mood: activity.aid < 6 ? mood(activity.aid) : null,
						s_name: activity.s ? `${activity.s.last_name} ${activity.s.first_name}` : null
					};
				});
				activitiesDispatch({
					type: "GET_ACTIVITIES",
					payload: extendedData
				});
			});
		}
	}, [id, startDate, endDate, display, userState.userConnected.locale]);

	useEffect(() => {
		if (isDiaperManager && diaperStore.diapers.length === 0) {
			http.get(`${url.BASEURL}/rht/diapers`).then(res => {
				diaperDispatch({
					type: "GET_DIAPERS",
					payload: res.data.diapers
				});
			});
		}
	}, [diaperStore.diapers.length, isDiaperManager]);

	return (
		<TimelineContainer userState={userState} loading={activities.loading} data={activities.data} display={display} />
	);
}
