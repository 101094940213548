import React from "react";
import * as API from "../../../services/API";
import { useTranslation } from "react-i18next";
import * as HomeConfigChoices from "../../../constants/HomeConfig";

export default function ToggleModeration({
	index,
	msgParamsErrorKey,
	msgKeyActiveKey,
	msgKeyInactiveKey,
	homeDispatch,
	myHome
}) {
	const { t } = useTranslation("common");

	const toggleManager = (index, bool, errMsgKey) => {
		homeDispatch({
			type: "RESET_ES"
		});

		const data = { home: {} };
		data.home[HomeConfigChoices.fields[index]] = bool;
		API.settingsModeration(
			myHome.id,
			data,
			res => {
				homeDispatch({
					type: "GET_MY_HOME",
					payload: res
				});
				homeDispatch({
					type: "UPDATE_HOME",
					payload: res
				});
			},
			err => {
				if (err.response.status === 400) {
					homeDispatch({
						type: "DISPLAY_ERROR",
						payload: t(errMsgKey)
					});
				} else {
					homeDispatch({
						type: "DISPLAY_ERROR",
						payload: t("common.__errorOccured__")
					});
				}
			}
		);
	};

	return myHome.config_choices[index] ? (
		<div className="row mt-2">
			<div className="col-md-1">
				<div className="toggle-button-checked" onClick={() => toggleManager(index, false, msgParamsErrorKey)}></div>
			</div>
			<div className="col-md-11">
				<span className="ml-2">{t(msgKeyActiveKey)}</span>
			</div>
		</div>
	) : (
		<div className="row mt-2">
			<div className="col-md-1">
				<div className="toggle-button" onClick={() => toggleManager(index, true, msgParamsErrorKey)}></div>
			</div>
			<div className="col-md-11">
				<span className="ml-2">{t(msgKeyInactiveKey)}</span>
			</div>
		</div>
	);
}
