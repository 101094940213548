const AdminReducer = (state, action) => {
	switch (action.type) {
		case "GET_ALL_FAMILIES": {
			const users = action.payload;
			const usersData = users.map(function (user) {
				return {
					...user,
					name: `${user.first_name} ${user.last_name}`
				};
			});
			return {
				...state,
				allFamilies: usersData
			};
		}

		case "DELETE_FAMILY":
			return {
				...state,
				allFamilies: state.allFamilies.filter(family => family.id !== action.payload)
			};

		case "GET_TENANTS": {
			return {
				...state,
				tenants: action.payload
			};
		}
		default:
			return state;
	}
};

export default AdminReducer;
