import React, { useRef, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { BlogContext } from "../../context/BlogContext";
import useLoadMore from "../../hooks/useLoadMore";
import WhoLikeModal from "./WhoLikeModal";
import ModalContainer from "../../utils/ModalContainer";
import Loader from "../../layout/Loader";
import NoData from "../../layout/NoData";
import PostContainer from "./PostContainer";
import OccupationContainer from "./OccupationContainer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function NewsLikeFamilyFlow({ userState, homeId, seniorsFromHome, isPanel }) {
	const { t } = useTranslation("common");
	const [show, setShow] = useState({ show: false, info: null, title: " " });
	const { blogDispatch } = useContext(BlogContext);

	const { status, data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
		["blog_posts", homeId],
		async ({ pageParam = 0 }) => {
			const res = await http.get(`${url.HOMESURL}/${homeId}/accepted/blog_posts?idxo=` + pageParam);
			return res.data;
		},
		{
			getPreviousPageParam: firstPage => firstPage.previousId ?? false,
			getNextPageParam: lastPage => lastPage.nextId ?? false
		}
	);

	const loadMoreButtonRef = useRef();

	useLoadMore({
		target: loadMoreButtonRef,
		onIntersect: fetchNextPage,
		enabled: hasNextPage
	});

	const handleData = post => {
		switch (post.type) {
			case 7:
				return <OccupationContainer occupation={post.occupation} seniors={seniorsFromHome} isPanel={isPanel} />;
			case 1:
				return (
					<PostContainer
						post={post.blog_post}
						displayStatus={false}
						userState={userState}
						blogDispatch={blogDispatch}
						setShow={setShow}
						isPanel={isPanel}
					/>
				);
			default:
				return;
		}
	};

	return status === "loading" ? (
		<Loader />
	) : status === "error" ? (
		<NoData />
	) : (
		<>
			{data && data.pages[0].data.length > 0 && (
				<>
					{data.pages.map((group, i) => (
						<div key={i} className="news-container">
							{group.data.map((post, index) => (
								<div key={post.id || `${i}-${index}`}>{handleData(post)}</div>
							))}
						</div>
					))}
					<div className="center">
						<button
							ref={loadMoreButtonRef}
							onClick={() => fetchNextPage()}
							disabled={!hasNextPage || isFetchingNextPage}
							className="btn btn-sm btn-outline-primary">
							{isFetchingNextPage ? t("common.__loading__") : hasNextPage ? t("news.__more__") : null}
						</button>
						{isFetching && !isFetchingNextPage ? t("common.__loading__") : null}
					</div>
				</>
			)}
			<ModalContainer show={show} setShow={setShow}>
				<WhoLikeModal show={show} seniorsFromHome={seniorsFromHome} />
			</ModalContainer>
		</>
	);
}
