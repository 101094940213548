import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import { useHistory } from "react-router-dom";
import { FaRegEdit, FaRegTimesCircle, FaSimCard } from "react-icons/fa";
import HeaderContainer from "../../layout/HeaderContainer";
import EditDevice from "./EditDevice";
import DeviceDetails from "./DeviceDetails";
import HandleSuccess from "../../layout/HandleSuccess";
import HandleError from "../../layout/HandleError";
import ModalContainer, { ShowState } from "../../utils/ModalContainer";
import { removeDevice } from "./deviceUtils";
import { DeviceInterface, DeviceStoreInterface } from "../../interfaces/HomeInterface";
import { Tooltip } from "../Buttons/Tooltip";

interface Props {
	deviceSNType: { id: string; name: string }[];
	devicesStore: DeviceStoreInterface;
	devicesDispatch: React.Dispatch<any>;
	setHomeId: (arg: number) => void;
}

const DevicesList = ({ deviceSNType, devicesStore, devicesDispatch, setHomeId }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [show, setShow] = useState<ShowState>({ show: false, info: null });
	const [editMode, setEditMode] = useState<{ edit: boolean; device: DeviceInterface | null }>({
		edit: false,
		device: null
	});

	const goToHome = (id: number): void => {
		setHomeId(id);
		history.push("/manage/me/myhome");
	};

	const allColumns = [
		{
			Header: "SIM card",
			accessor: "withSimCard",
			Filter: SelectColumnFilter,
			width: 65,
			minWidth: 50,
			maxWidth: 80,
			Cell: (row: any) => (row.row.original.sim_card ? <FaSimCard /> : null)
		},
		{
			Header: t("common.__home__"),
			accessor: "home",
			Filter: SelectColumnFilter,
			Cell: (row: any) => (
				<span
					className="category-outline-badge grey"
					role="button"
					onClick={(): void => goToHome(row.row.original.home_id)}>
					{row.row.original.home}
				</span>
			)
		},
		{
			Header: t("device.__serialNumber__"),
			accessor: "serial_number",
			Cell: (row: any) => (
				<span
					className="category-outline-badge blue"
					role="button"
					onClick={(): void => setShow({ show: true, info: row.row.original, title: t("common.__device__") })}>
					{row.row.values.serial_number}
				</span>
			)
		},
		{
			Header: "",
			accessor: "id",
			disableFilters: true,
			disableSortBy: true,
			width: 50,
			maxWidth: 50,
			Cell: (row: any) => (
				<div className="center">
					<Tooltip text={t("common.__update__")}>
						<FaRegEdit
							className="table-icon"
							role="button"
							onClick={(): void => setEditMode({ edit: true, device: row.row.original })}
						/>
					</Tooltip>
					<Tooltip text={t("calendar.__remove__")}>
						<FaRegTimesCircle
							className="table-icon"
							role="button"
							onClick={(): void => removeDevice(row.row.values.id, devicesDispatch, t)}
						/>
					</Tooltip>
				</div>
			)
		}
	];

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("device.__devices__")}
				addButton={t("device.__addDevice__")}
				link={"/support/device/add"}
			/>

			{editMode.edit ? (
				<EditDevice
					editMode={editMode}
					setEditMode={setEditMode}
					devicesDispatch={devicesDispatch}
					deviceSNType={deviceSNType}
				/>
			) : (
				<>
					<HandleError error={devicesStore.es.error} dispatch={devicesDispatch} message={devicesStore.es.msg} />
					<HandleSuccess success={devicesStore.es.success} dispatch={devicesDispatch} message={devicesStore.es.msg} />
					{devicesStore.devices.devices.length > 0 ? (
						<Table columns={allColumns} data={devicesStore.devices.devices} />
					) : (
						<p>{t("common.__noElement__")}</p>
					)}
				</>
			)}
			<ModalContainer show={show} setShow={setShow}>
				<DeviceDetails show={show} />
			</ModalContainer>
		</div>
	);
};

export default DevicesList;
