import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

interface Props {
	displayAddPostButton: boolean;
	title: string | null;
}

const BlogHeader = ({ displayAddPostButton, title }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const history = useHistory();

	return (
		<div className="row">
			<div className="col-8">
				<h2>{title}</h2>
			</div>
			<div className="col-4">
				{displayAddPostButton ? (
					<Link to="/blog/news/addpost">
						<button type="button" className="btn btn-sm btn-outline-primary right">
							{t("news.__addNews__")}
						</button>
					</Link>
				) : title === t("news.__news__") ? null : (
					<button type="button" className="btn btn-sm btn-outline-secondary right" onClick={(): void => history.goBack()}>
						{t("common.__back__")}
					</button>
				)}
			</div>
		</div>
	);
};
export default BlogHeader;
