import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaAngleDoubleUp, FaAngleDoubleDown, FaRegTimesCircle, FaCheckCircle, FaRegImages } from "react-icons/fa";
import "./Moderation.scss";
import * as API from "../../services/API";
import DisplayName from "../../utils/DisplayName";
import LazyloadImg from "../../layout/LazyloadImg";
import NoData from "../../layout/NoData";

export default function PhotoBoxModeration({ seniorsFromHome, homeId, notificationsDispatch }) {
	const { t } = useTranslation("common");
	const [displayPictures, setDisplayPictures] = useState(false);
	const [picturesToModerate, setPicturesToModerate] = useState([]);
	const [update, setUpdate] = useState(false);
	const [error, setError] = useState(false);
	const pictures = [...picturesToModerate].sort((a, b) => a.senior_id - b.senior_id);

	useEffect(() => {
		if (homeId) {
			API.getPhotoModeration(
				homeId,
				res => {
					setPicturesToModerate(res);
				},
				() => {
					setError(true);
				}
			);
		}
	}, [homeId, update]);

	const pictureValidation = (pictPath, boolean) => {
		const data = {
			accepted: boolean
		};
		API.pictureModeration(
			pictPath,
			data,
			() => {
				setUpdate(prevUpdate => !prevUpdate);
				notificationsDispatch({
					type: "MODERATE_PICTURES"
				});
			},
			() => {
				notificationsDispatch({
					type: "MODERATION_ERROR"
				});
			}
		);
	};

	const orderPictures = () => {
		let seniorId = 0;
		let result = [];

		for (var i = 0; i < pictures.length; i++) {
			const picture = pictures[i];
			const idToCompare = picture.senior_id;
			if (seniorId === idToCompare) {
				result.push(blockToDisplay(picture, false, picture.uuid));
			} else {
				result.push(blockToDisplay(picture, true, picture.uuid + 1));
				result.push(blockToDisplay(picture, false, picture.uuid));
				seniorId = idToCompare;
			}
		}
		return result;
	};

	const blockToDisplay = (picture, displaySenior, id) => {
		return (
			<div key={id}>
				{displaySenior ? (
					<div className="header-photobox">
						{t("common.__senior__")} :
						<br />
						<Link to={`/senior/profile/${picture.senior_id}`}>{DisplayName(picture.senior_id, seniorsFromHome)}</Link>
					</div>
				) : (
					<div className="p-2">
						<LazyloadImg path={`/storages/photo_frame_items/${picture.path}`} display="senior-photobox" />

						{/* <img src={} className="senior-photobox" alt={picture.uuid} /> */}

						<div className="moderation-card-footer center">
							<FaRegTimesCircle className="moderation-card-icon-no" onClick={() => pictureValidation(picture.path, false)} />
							<FaCheckCircle className="moderation-card-icon-ok" onClick={() => pictureValidation(picture.path, true)} />
						</div>
					</div>
				)}
			</div>
		);
	};

	return (
		<div className="row">
			<div className="col-2">
				<FaRegImages className={`${displayPictures ? "blue" : " "} + moderation-icon ml-2`} />
			</div>
			<div className="col-8">
				<h5>{t("moderation.__photoFrame__")}</h5>
				{picturesToModerate.length > 0 ? (
					<p className="meta">
						{t("moderation.__moderationCounter__")} : <b className="blue"> {picturesToModerate.length}</b>
					</p>
				) : (
					<p className="meta">{t("moderation.__noModerationPending__")}</p>
				)}
			</div>

			<div className="col-2">
				{picturesToModerate.length > 0 ? (
					displayPictures ? (
						<FaAngleDoubleUp className="family-icon right" onClick={() => setDisplayPictures(false)} />
					) : (
						<FaAngleDoubleDown className="family-icon right" onClick={() => setDisplayPictures(true)} />
					)
				) : null}
			</div>
			<div className="col-12">
				{displayPictures ? error ? <NoData /> : <div className="d-flex flex-row flex-wrap">{orderPictures()}</div> : null}
			</div>
		</div>
	);
}
