import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import OnlyThread from "./OnlyThread";
import ResponseForm from "./ReponseForm";
import { useTranslation } from "react-i18next";
import HandleSuccess from "../../layout/HandleSuccess";
import { FaReply } from "react-icons/fa";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as p from "../../constants/Permissions";
import * as constant from "../../constants/TypesConstants";
import HandleError from "../../layout/HandleError";

export default function SendResponse({ params, msgState, msgDispatch, userState, notificationsDispatch }) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const msgId = params.id;
	const mode = params.mode;
	const [responseMode, setResponseMode] = useState(false);
	const canManageAllFamilyMessage = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.ALL_FAMILY_USER_MESSAGES]);
	const canManageMyFamilyMessage = userState.userConnected.is(p.MY_FAMILY_USER_MESSAGES);
	const [threadMessages, setThreadMessages] = useState([]);
	const [errorMessage, setErrorMessage] = useState(null);
	const [subject, setSubject] = useState(null);

	useEffect(() => {
		if (mode === "answer") {
			setResponseMode(true);
		}
	}, [mode]);

	useEffect(() => {
		return function cleanup() {
			msgDispatch({
				type: "RESET_ES"
			});
		};
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (msgId) {
			if (canManageAllFamilyMessage) {
				setErrorMessage(false);
				http
					.get(`${url.HOMESURL}/messages/${msgId}/thread_from_any_id`)
					.then(res => setThreadMessages(res.data.family_user_messages))
					.catch(() => setErrorMessage(true));
			}
			if (canManageMyFamilyMessage) {
				setErrorMessage(false);
				http
					.get(`${url.MESSAGES_URL}/${msgId}/thread`)
					.then(res => {
						const data = res.data.family_user_messages;
						setThreadMessages(data);
						const unreadMsgCounter = data.filter(msg => msg.unread);
						notificationsDispatch({
							type: "UPDATE_MESSAGES_COUNTER",
							payload: parseInt(unreadMsgCounter.length)
						});
						const subjectPosition = data.length - 1;
						setSubject(data[subjectPosition].subject);
					})
					.catch(() => setErrorMessage(true));
			}
		}
		// eslint-disable-next-line
	}, [msgId, msgState.success]);

	return (
		<>
			<div className="row">
				<div className="col-md-6">
					<h2>{t("mailbox.__thread__")}</h2>
				</div>
				<div className="col-md-6">
					{threadMessages.length > 0 ? (
						threadMessages[0].type === constant.MULTI_MSG_FROM_USER_TO_FAMILY ? null : mode === "thread" && responseMode ? (
							<button className="btn btn-outline-primary btn-sm right" type="button" onClick={() => setResponseMode(false)}>
								{t("common.__cancel__")}
							</button>
						) : (
							<button className="btn btn-primary btn-sm right" type="button" onClick={() => setResponseMode(true)}>
								<FaReply className="mr-2" /> {t("mailbox.__answer__")}
							</button>
						)
					) : null}
					<button className="btn btn-outline-secondary btn-sm right mr-2" type="button" onClick={() => history.goBack()}>
						{t("common.__back__")}
					</button>
				</div>
			</div>

			{responseMode && (
				<ResponseForm msgId={msgId} msgState={msgState} msgDispatch={msgDispatch} setResponseMode={setResponseMode} />
			)}

			<HandleSuccess success={msgState.success} dispatch={msgDispatch} message={msgState.msg} noSpace />
			<HandleError error={errorMessage} setError={setErrorMessage} message={t("common.__noData__")} />
			<OnlyThread threadMessages={threadMessages} subject={subject} userLocale={userState.userConnected.locale} />
		</>
	);
}
