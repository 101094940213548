import React, { useContext, useEffect } from "react";
import { DiaperContext } from "../../../context/DiaperContext";
import { handleIllustration, handleModelIcon } from "../Illustrations";
import { DiaperInterface } from "../../../interfaces/DiaperInterface";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

interface DotationInterface {
	begin_time: string;
	diaper_id: number;
	start_time: number;
	grooming: number;
}

interface Props {
	show: {
		show: boolean;
		info: { provisions: DotationInterface[]; name: string; drawsheet: boolean; senior_partner_id: number } | null;
		title?: string;
		success?: boolean;
	};
}

const AmdSeniorDotation = ({ show }: Props): JSX.Element | null => {
	const { diaperStore, diaperDispatch } = useContext(DiaperContext);
	const seniorDotation = show.info ? show.info.provisions : null;

	useEffect(() => {
		if (diaperStore.diapers.length === 0) {
			http.get(`${url.BASEURL}/rht/diapers`).then(res => {
				diaperDispatch({
					type: "GET_DIAPERS",
					payload: res.data.diapers
				});
			});
		}
		// eslint-disable-next-line
	}, []);

	const seniorDotationList = seniorDotation
		? seniorDotation.map((dotation: DotationInterface) => {
				return {
					...dotation,
					diaper: diaperStore.diapers.find((diaper: DiaperInterface) => diaper.id === dotation.diaper_id)
				};
		  })
		: null;

	const handleGrooming = (grooming: number): JSX.Element | null => {
		switch (grooming) {
			case 1:
				return <img src="/img/diaper/wc.png" alt="wc" />;
			case 2:
				return <img src="/img/diaper/bassin.png" alt="bassin" />;
			case 3:
				return <img src="/img/diaper/urinal.png" alt="urinal" />;
			case 4:
				return <img src="/img/diaper/sondeurinaire.png" alt="sondeurinaire" />;
			case 5:
				return <img src="/img/diaper/stomie.png" alt="stomie" />;
			default:
				return null;
		}
	};

	return (
		<>
			{seniorDotationList && diaperStore.diapers.length > 0
				? seniorDotationList.map((dotation: any, index: number) => (
						<div className="header-details" key={index}>
							<div className="d-flex justify-content-between">
								<div>
									<p>
										<b>{dotation.begin_time}</b>
									</p>

									{dotation.grooming > 0 ? handleGrooming(dotation.grooming) : null}
								</div>
								{dotation.diaper ? (
									<div className="diaper-info">
										{handleIllustration(dotation.diaper.brand, dotation.diaper.line, dotation.diaper.ref)}
										<span className="right meta mt-2 ml-3">
											{dotation.diaper.name}
											<br />
											<span className="right mt-1">{handleModelIcon(dotation.diaper)}</span>
										</span>
									</div>
								) : null}
							</div>
						</div>
				  ))
				: null}
		</>
	);
};
export default AmdSeniorDotation;
