import React from "react";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import HealthWorkerTypeBudgetBoard from "../components/Budget/HealthWorkerTypeBudgetBoard";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const HealthWorkerTypeBudgetCharts = ({ homeId, userState }: Props): JSX.Element => {
	const { store } = useStoreData({ userState, homeId });

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.HW_BUDGET_DISTRIBUTION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<HomesSecondaryNav userState={userState} />}
				component={
					<HealthWorkerTypeBudgetBoard
						userState={userState}
						healthWorkerType={store.users.healthWorkerTypes}
						healthWorkers={store.users.healthWorkers}
						homeId={homeId}
					/>
				}
			/>
		</AccessControl>
	);
};
export default HealthWorkerTypeBudgetCharts;
