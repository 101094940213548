import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import SeniorWithDiaperSaturationChartContainer from "./SeniorWithDiaperSaturationChartContainer";
import Loader from "../../../layout/Loader";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

const SeniorWithDiaperSaturationChart = ({ seniorId, startDate, startTime, endDate, endTime, userLocale }) => {
	const { t } = useTranslation("common");
	const [values, setValues] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	function generateMissingEntries(data, intervalMinutes = 5) {
		const result = [];
		const intervalInMs = intervalMinutes * 60 * 1000;

		for (let i = 0; i < data.length - 1; i++) {
			const currentEntry = data[i];
			const nextEntry = data[i + 1];

			result.push(currentEntry);

			const currentDateTime = new Date(currentEntry.datetime);
			const nextDateTime = new Date(nextEntry.datetime);
			const timeDiff = nextDateTime - currentDateTime;

			if (timeDiff > intervalInMs) {
				const numMissingEntries = Math.floor(timeDiff / intervalInMs) - 1;

				for (let j = 1; j <= numMissingEntries; j++) {
					const newDateTime = new Date(currentDateTime.getTime() + j * intervalInMs);
					const formattedDateTime =
						newDateTime.getFullYear() +
						"-" +
						("0" + (newDateTime.getMonth() + 1)).slice(-2) +
						"-" +
						("0" + newDateTime.getDate()).slice(-2) +
						" " +
						("0" + newDateTime.getHours()).slice(-2) +
						":" +
						("0" + newDateTime.getMinutes()).slice(-2) +
						":" +
						("0" + newDateTime.getSeconds()).slice(-2);

					const ratio = j / (numMissingEntries + 1);

					const interpolatedH = currentEntry.h + ratio * (nextEntry.h - currentEntry.h);
					const interpolatedT = currentEntry.t + ratio * (nextEntry.t - currentEntry.t);
					const c = 0;
					const ctrl = 0;
					const wc = 0;
					const s = 0;

					const newEntry = {
						...currentEntry,
						datetime: formattedDateTime,
						h: Math.round(interpolatedH * 100) / 100,
						t: Math.round(interpolatedT * 100) / 100,
						c: c,
						ctrl: ctrl,
						wc: wc,
						s: s
					};
					result.push(newEntry);
				}
			}
		}

		result.push(data[data.length - 1]);

		return result;
	}

	useEffect(() => {
		if (seniorId && startDate && endDate && startTime && endTime) {
			setIsLoading(true);
			const startDateFormatted = handleDate(startDate, "yyyy-MM-dd");
			const endDateFormatted = handleDate(endDate, "yyyy-MM-dd");
			const startTimeFormatted = handleDate(startTime, "HH:mm:ss");
			const endTimeFormatted = handleDate(endTime, "HH:mm:ss");
			const start = `${startDateFormatted} ${startTimeFormatted}`;
			const end = `${endDateFormatted} ${endTimeFormatted}`;

			http
				.get(
					`${url.SENIORSURL}/${seniorId}/rht/values/plus/plus/from/${encodeURIComponent(start)}/to/${encodeURIComponent(
						end
					)}`
				)
				.then(res => {
					setIsLoading(false);
					const data = generateMissingEntries(res.data.transformed);

					const completedValues = data.map(val => {
						return {
							...val,
							d: handleDate(val.datetime, "Pp", userLocale)
						};
					});
					setValues(completedValues);
				})
				.catch(() => {
					setIsLoading(false);
					setValues([]);
				});
		}
	}, [seniorId, startDate, endDate, startTime, endTime, userLocale]);

	return (
		<div className="big-card">
			<h5>{t("diaper.__saturationChart__")}</h5>
			{isLoading ? (
				<Loader />
			) : values.length > 0 ? (
				<SeniorWithDiaperSaturationChartContainer values={values} />
			) : (
				<p className="meta">{t("common.__noDataAvailable__")}</p>
			)}
		</div>
	);
};

export default SeniorWithDiaperSaturationChart;
