import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { FaRegTimesCircle } from "react-icons/fa";

const MyInputNumber = props => {
	const { t } = useTranslation("common");
	const {
		register,
		formState: { errors }
	} = useFormContext();

	const [inputValue, setInputValue] = useState(props.value || props.type === "number" ? 0 : "");

	const resetValue = () => {
		setInputValue(0);
	};

	return (
		<div className="form-group mb-3">
			{props.label === undefined ? null : (
				<label htmlFor={props.name} className="form-label">
					{props.label}
				</label>
			)}
			<div className="row">
				<div className="col">
					<input
						placeholder={props.placeholder}
						id={props.id ? props.id : props.name}
						name={props.name}
						step={props.step ? props.step : 1}
						type={props.type || "text"}
						{...register(props.name, {
							required: props.behavior.required,
							pattern: props.behavior.pattern,
							maxLength: props.behavior.maxLength,
							min: props.behavior.min || 0,
							max: props.behavior.max
						})}
						className="form-control form-control-sm"
						value={inputValue}
						onChange={e => setInputValue(e.target.value)}
						onFocus={e => (e.target.placeholder = "")}
					/>
				</div>
				{props.allowReset && (
					<div className="col-1 p-0">
						<FaRegTimesCircle
							role="button"
							onClick={() => {
								resetValue();
							}}
						/>
					</div>
				)}
			</div>

			{props.behavior.required ? (
				errors[props.name] ? null : (
					<small className="form-text text-muted right" id={`${props.name}Error`}>
						<span className="required">{t("common.__required__")}</span>
					</small>
				)
			) : null}

			{errors[props.name] ? (
				props.behavior.min && errors[props.name].type === "min" ? (
					<small className="red right mt-1">
						Valeur minimum autorisée : <b>{props.behavior.min}</b>.
					</small>
				) : null
			) : null}

			{errors[props.name] ? (
				props.behavior.max && errors[props.name].type === "max" ? (
					<small className="red right mt-1">
						Valeur maximum autorisée : <b>{props.behavior.max}</b>.
					</small>
				) : null
			) : null}

			{errors[props.name] ? (
				props.behavior.maxLength ? (
					props.behavior.maxLength === 2 ? (
						<small className="red mt-5">{t("common.__twoNumbersRequired__")}</small>
					) : props.behavior.maxLength === 3 ? (
						<small className="red mt-5">{t("common.__threeNumbersMax__")}</small>
					) : props.behavior.maxLength === 4 ? (
						<small className="red mt-5">{t("common.__fourNumbersRequired__")}</small>
					) : (
						<small className="red mt-5">{t("common.__numberRequired__")}</small>
					)
				) : props.behavior.pattern ? (
					<small className="red mt-5">{t("common.__numberRequired__")}</small>
				) : null
			) : null}
		</div>
	);
};

export default MyInputNumber;
