import i18n from "../../../i18n/i18next";

export const validityTypes = [
	{
		id: 0,
		name: i18n.t("common:badge.__noRestriction__")
	},
	{
		id: 1,
		name: i18n.t("common:badge.__occasionalBadge__")
	},
	{
		id: 2,
		name: i18n.t("common:badge.__restrictedTime__")
	},
	{
		id: 3,
		name: i18n.t("common:badge.__restrictedDays__")
	}
];

export const wantedDays = [
	{
		id: 1,
		name: i18n.t("common:common.__monday__")
	},
	{
		id: 2,
		name: i18n.t("common:common.__tuesday__")
	},
	{
		id: 3,
		name: i18n.t("common:common.__wednesday__")
	},
	{
		id: 4,
		name: i18n.t("common:common.__thursday__")
	},
	{
		id: 5,
		name: i18n.t("common:common.__friday__")
	},
	{
		id: 6,
		name: i18n.t("common:common.__saturday__")
	},
	{
		id: 7,
		name: i18n.t("common:common.__sunday__")
	}
];

export const displayValidityTypes = type => {
	switch (type) {
		case 0:
			return i18n.t("common:badge.__noRestriction__");
		case 1:
			return i18n.t("common:badge.__occasionalBadge__");
		case 2:
			return i18n.t("common:badge.__restrictedTime__");
		case 3:
			return i18n.t("common:badge.__restrictedDays__");
		default:
			return;
	}
};

export const displayValidityTypeColor = type => {
	switch (type) {
		case 0:
			return "category-outline-badge green";
		case 1:
			return "category-outline-badge purple";
		case 2:
			return "category-outline-badge marine";
		case 3:
			return "category-outline-badge yellow";
		default:
			return;
	}
};

export const displayBadgeType = type => {
	switch (type) {
		case 1:
			return "PIN code";
		case 2:
			return "QR code";
		default:
			return;
	}
};

export const badgeTypes = [
	{
		id: 1,
		name: "PIN code"
	},
	{
		id: 2,
		name: "QR code"
	}
];
