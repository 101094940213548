import { useContext, useEffect } from "react";
import { HomeContext } from "../context/HomeContext";
import { UserInterface } from "../interfaces/UserInterface";
import http from "../services/Interceptor";
import * as url from "../constants/Url";

interface Props {
	userState: UserInterface;
	homeId: number | null;
}

const useStoreData = ({ userState, homeId }: Props): any => {
	const { store, dispatch } = useContext(HomeContext);
	const isHomeManager = userState.userConnected.home_id ? true : false;

	const getRooms = (homeId: number): void => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/rooms`)
				.then(res => {
					const rooms = res.data.rooms;
					const sortedRooms = rooms.sort((a: any, b: any) => a.name.localeCompare(b.name, undefined, { numeric: true }));
					dispatch({
						type: "GET_ROOMS",
						payload: sortedRooms
					});
				})
				.catch(() => {
					dispatch({
						type: "GET_ROOMS",
						payload: []
					});
				});
		}
	};

	const getHomeUnits = (homeId: number): void => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/home_units?supervisors=true`)
				.then(res => {
					dispatch({
						type: "GET_HOMEUNITS",
						payload: res.data.home_units
					});
				})
				.catch(() => {
					dispatch({
						type: "GET_HOMEUNITS",
						payload: []
					});
				});
		}
	};

	const getSeniors = (homeId: number): void => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/seniors?room=true`)
				.then(res => {
					dispatch({
						type: "GET_SENIORS",
						payload: res.data.seniors
					});
				})
				.catch(() => {
					dispatch({
						type: "GET_SENIORS",
						payload: []
					});
				});
		}
	};

	const getHealthWorkers = (homeId: number): void => {
		http
			.get(`${url.HOMESURL}/${homeId}/users`)
			.then(res => {
				dispatch({
					type: "GET_HEALTHWORKERS",
					payload: res.data.users
				});
			})
			.catch(() => {
				dispatch({
					type: "GET_HEALTHWORKERS",
					payload: []
				});
			});
	};

	const getAllWorkers = (): void => {
		http
			.get(`${url.BASEURL}/users`)
			.then(res => {
				dispatch({
					type: "GET_HEALTHWORKERS",
					payload: res.data.users
				});
			})
			.catch(() => {
				dispatch({
					type: "GET_HEALTHWORKERS",
					payload: []
				});
			});
	};

	useEffect((): any => {
		let mounted = true;
		if (mounted && store.users.healthWorkerTypes && store.users.healthWorkerTypes.length === 0) {
			http.get(`${url.BASEURL}/health_worker_types`).then(res => {
				const types = res.data.health_worker_types.sort((a: any, b: any) => a.name.localeCompare(b.name));
				dispatch({
					type: "GET_HEALTHWORKER_TYPES",
					payload: types
				});
			});
		}
		return () => (mounted = false);
	}, []);

	useEffect((): any => {
		let mounted = true;
		if (mounted && !isHomeManager && store.users.healthWorkers.length < 1) {
			getAllWorkers();
		}
		return () => (mounted = false);
		// eslint-disable-next-line
	}, [isHomeManager, homeId]);

	useEffect((): any => {
		let mounted = true;
		if (mounted) {
			if (homeId) {
				if (isHomeManager) {
					if (store.home.homeUnits.length < 1) {
						getHomeUnits(homeId);
					}
					if (store.home.rooms.length < 1) {
						getRooms(homeId);
					}
					if (store.seniors.seniorsFromHome.length < 1) {
						getSeniors(homeId);
					}
					if (store.users.healthWorkers.length < 1) {
						getHealthWorkers(homeId);
					}
				} else {
					getHomeUnits(homeId);
					getRooms(homeId);
					getSeniors(homeId);
				}
			}
		}
		return () => (mounted = false);
		// eslint-disable-next-line
	}, [isHomeManager, homeId]);

	return { store, dispatch };
};
export default useStoreData;
