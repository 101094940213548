import React from "react";
import HomesListFromGroup from "./HomesListFromGroup";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import NoData from "../../../layout/NoData";

const HomesList = ({ groupId, setHomeId, homeStore, isGroupManager }) => {
	const { t } = useTranslation("common");
	const history = useHistory();

	const allGroups = homeStore.home.groups;
	const myGroup = isGroupManager
		? homeStore.home.myGroup
		: allGroups.length > 0
		? allGroups.find(group => group.id === groupId)
		: null;

	const homesFromGroup =
		homeStore.home.allHomes.length > 0
			? groupId
				? homeStore.home.allHomes.filter(home => home.group_id === groupId)
				: []
			: [];

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-6">
					<h2>{t("home.__homes__")}</h2>
				</div>
				<div className="col-6">
					<button type="button" className="btn btn-sm btn-outline-secondary right mt-2" onClick={() => history.goBack()}>
						{t("common.__back__")}
					</button>
				</div>
			</div>
			{myGroup ? (
				<HomesListFromGroup myGroup={myGroup} homesFromGroup={homesFromGroup} setHomeId={setHomeId} />
			) : (
				<NoData />
			)}
		</div>
	);
};

export default HomesList;
