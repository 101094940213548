import React from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../../utils/Form/MyInputText";
import HandleError from "../../../layout/HandleError";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

const CreateType = ({ setAddMode, homeStore, homeDispatch }) => {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;

	const onSubmit = data => {
		const typeData = {
			home_type: {
				description: data.description,
				name: data.name
			}
		};
		http
			.post(`${url.BASEURL}/home_types`, typeData)
			.then(res => {
				homeDispatch({
					type: "ADD_HOME_TYPE",
					payload: res.data.home_type
				});
				homeDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__addNewTypeSuccess__")
				});
			})
			.then(() => setAddMode(false))
			.catch(() => {
				homeDispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__addError__")
				});
			});
	};

	return (
		<FormProvider {...methods}>
			<h5 className="blue">{t("home.__addNewType__")}</h5>
			<HandleError error={homeStore.es.error} dispatch={homeDispatch} message={homeStore.es.msg} />

			<form onSubmit={handleSubmit(onSubmit)}>
				<MyInputText label="Type" name="name" behavior={{ required: true }} />
				<MyInputText label="Description" name="description" behavior={{ required: false }} />
				<button className="btn btn-primary btn-sm" type="submit">
					{t("common.__save__")}
				</button>
			</form>
		</FormProvider>
	);
};

export default CreateType;
