import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/UserContext";
import Select from "react-select";
import DisplayName from "../utils/DisplayName";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import { HomeInterface } from "../interfaces/HomeInterface";

const SelectHomeInput = (): JSX.Element => {
	const { t } = useTranslation("common");
	const { homeStore, homeDispatch, homeId, setHomeId, groupId } = useContext(UserContext);
	const allHomes = homeStore.home.allHomes;
	const homesToSelect =
		allHomes.length > 0 ? (groupId ? allHomes.filter((home: HomeInterface) => home.group_id === groupId) : allHomes) : [];

	useEffect(() => {
		if (homeStore.home.allHomes.length === 0) {
			http.get(`${url.BASEURL}/homes`).then(res => {
				homeDispatch({
					type: "GET_ALL_HOMES",
					payload: res.data.homes
				});
			});
		}
		// eslint-disable-next-line
	}, [homeStore.home.allHomes.length]);

	return (
		<div className="mt-4 mb-4">
			<div className="error-msg mt-2 mb-2 px-3">
				<h5>{t("common.__noHome__")}</h5>
			</div>
			<Select
				value={homeId ? DisplayName(homeId, homesToSelect) : homeId}
				onChange={(e): void => (e ? setHomeId(e.value) : setHomeId(null))}
				options={homesToSelect}
				placeholder={t("home.__homes__")}
				isOptionSelected={(option, selectValue): boolean => selectValue.some(i => i === option)}
				classNamePrefix="react-select"
				isClearable
			/>
		</div>
	);
};

export default SelectHomeInput;
