import React from "react";
import { useTranslation } from "react-i18next";
import { Table, SelectColumnFilter } from "../../../utils/Table/TableWithFilter";
import { FaArrowCircleRight, FaBell } from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";

export default function SeniorWithDiapersDetails({ saturationValues, userState }) {
	const { t } = useTranslation("common");

	const data = saturationValues.map(value => {
		return {
			...value,
			lastChange: value.change ? handleDate(value.change, "P", userState.userConnected.locale) : null,
			lastSaturation: value.saturation ? handleDate(value.saturation, "P", userState.userConnected.locale) : null,
			lastChangeDateTime: value.change ? handleDate(value.change, "Pp", userState.userConnected.locale) : null,
			lastSaturationDateTime: value.saturation ? handleDate(value.saturation, "Pp", userState.userConnected.locale) : null,
			timing: value.delta ? (
				<>
					{value.delta.h ? `${value.delta.h}h` : null} {value.delta.m ? `${value.delta.m}mn` : null}{" "}
					{value.delta.s ? `${value.delta.s}s` : null}
				</>
			) : null
		};
	});

	const columns = [
		{
			Header: t("diaper.__lastSaturation__"),
			accessor: "lastSaturation",
			Filter: SelectColumnFilter,
			Cell: row =>
				row.row.values.lastSaturation ? (
					<>
						<FaBell style={{ height: "0.85rem", width: "auto", verticalAlign: "middle", marginRight: "0.5rem" }} />
						{row.row.original.lastSaturationDateTime}
					</>
				) : null
		},
		{
			Header: t("diaper.__lastChange__"),
			accessor: "lastChange",
			Filter: SelectColumnFilter,
			Cell: row =>
				row.row.values.lastChange ? (
					<>
						<FaArrowCircleRight
							style={{ marginRight: "0.5rem", color: "#00a0e3", height: "1rem", width: "auto", verticalAlign: "middle" }}
						/>
						{row.row.original.lastChangeDateTime}
					</>
				) : (
					<p className="red">
						<FaBell style={{ marginRight: "0.5rem", height: "1rem", width: "auto", verticalAlign: "middle" }} />
						<b>{t("diaper.__saturationAlert__")}</b>
					</p>
				)
		},
		{
			Header: t("common.__timeLimit__"),
			accessor: "timing",
			Filter: SelectColumnFilter
		}
	];

	return data.length > 0 ? <Table columns={columns} data={data} /> : null;
}
