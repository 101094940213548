import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../utils/Form/MyInputText";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import MyInputEmail from "../../utils/Form/MyInputEmail";
import DateTimePicker from "../../utils/DatePicker/DateTimePicker";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import HandleError from "../../layout/HandleError";

const CheckVisio = ({ userState }) => {
	const { t } = useTranslation("common");
	const userLocale = userState.userConnected.locale;
	const [appointment, setAppointment] = useState(null);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [error, setError] = useState(false);
	const methods = useForm();
	const { handleSubmit } = methods;

	const onSubmit = values => {
		setError(false);
		const when = handleDate(selectedDate, "yyyy-MM-dd' 'HH:mm:ss");
		const data = {
			first_name: values.firstName,
			last_name: values.lastName,
			email: values.mail,
			when
		};
		http
			.post(`${url.BASEURL}/jitsi/new`, data)
			.then(res => {
				setError(false);
				setAppointment(res.data);
			})
			.catch(() => setError(true));
	};

	return (
		<div className="big-card">
			<h2>{t("mailbox.__createVisio__")}</h2>

			<HandleError error={error} setError={setError} />

			{appointment ? (
				<>
					<div className="header-details">
						<h3>{t("mailbox.__yourVisioAppointment__")} :</h3>
						<div className="space"></div>
						<p className="cesure">
							<b>Client :</b> {appointment.client}
						</p>
						<p className="cesure">
							<b>
								Master : <a href={appointment.master}>{appointment.master}</a>
							</b>
						</p>
						<p className="cesure">
							<b>Room :</b> {appointment.room}
						</p>
						<p className="cesure">
							<b>Token :</b> {appointment.token}
						</p>
					</div>
					<button type="button" className="btn btn-sm btn-primary mt-2" onClick={() => setAppointment(null)}>
						{t("mailbox.__createVisio__")}
					</button>
				</>
			) : (
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row mt-3">
							<div className="col-6">
								<MyInputText label={t("common.__firstname__")} name="firstName" behavior={{ required: true }} />
							</div>
							<div className="col-6">
								<MyInputText label={t("common.__name__")} name="lastName" behavior={{ required: true }} />
							</div>
							<div className="col-12 mb-2">
								<MyInputEmail
									label={t("common.__mail__")}
									name="mail"
									behavior={{ required: true, pattern: /^\S+@\S+\.\S+$/ }}
								/>
							</div>
							<div className="col-12 mb-2">
								<label>{t("calendar.__eventDates__")} </label>
								<DateTimePicker userLocale={userLocale} startDate={selectedDate} setStartDate={setSelectedDate} />
							</div>
						</div>
						<button className="btn btn-primary btn-sm mb-2 mt-3" type="submit">
							{t("common.__save__")}
						</button>
					</form>
				</FormProvider>
			)}
		</div>
	);
};

export default CheckVisio;
