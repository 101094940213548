import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as API from "../../services/API";
import HandleError from "../../layout/HandleError";
import PermissionsManager from "./PermissionsManager";

export default function HandleUserPermissions({ store, dispatch, hwId, isAdminOrSuperAdmin, healthworker }) {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const history = useHistory();
	const [permissions, setPermissions] = useState(healthworker.permissions);

	const onSubmit = () => {
		dispatch({
			type: "HIDE_ERROR"
		});
		const data = {
			user: {
				permissions: permissions
			}
		};
		API.updateHealthWorker(
			hwId,
			data,
			res => {
				dispatch({
					type: "EDIT_HEALTHWORKER",
					payload: res
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("healthworker.__updateSuccess__")
				});
				history.push(`/user/healthworker/${hwId}`);
			},
			() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			}
		);
	};

	return (
		<>
			<p className="header-details">
				{t("healthworker.__teamMember__")}
				<b>
					{healthworker ? (
						healthworker.first_name === "badge_general" ? null : (
							<>
								: {healthworker.first_name} {healthworker.last_name}
							</>
						)
					) : null}
				</b>
			</p>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__editError__")} />

			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<PermissionsManager
						isAdminOrSuperAdmin={isAdminOrSuperAdmin}
						permissions={permissions}
						setPermissions={setPermissions}
						editMode
					/>
					<button type="button" className="btn btn-outline-secondary mr-2 btn-sm" onClick={() => history.goBack()}>
						{t("common.__back__")}
					</button>
					<button type="submit" className="btn btn-primary btn-sm">
						{t("common.__save__")}
					</button>
				</form>
			</FormProvider>
		</>
	);
}
