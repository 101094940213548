import React, { useEffect, useState } from "react";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { FaHeart } from "react-icons/fa";

export default function WhoLike({ postId }) {
	const [familyLikes, setFamilyLikes] = useState([]);

	useEffect(() => {
		if (postId) {
			http
				.get(`${url.BASEURL}/homes/blog_posts/${postId}/who/like`)
				.then(res => setFamilyLikes(res.data.family_users))
				.catch(() => setFamilyLikes([]));
		}
	}, [postId]);

	return familyLikes.length > 0 ? (
		<div className="ml-1 mb-3 mt-4">
			{familyLikes.map(family => (
				<div key={family.id}>
					<span className="meta mr-4">
						<FaHeart className="mr-2 red" style={{ height: "18px", width: "auto" }} /> {family.first_name} {family.last_name}
					</span>
				</div>
			))}
		</div>
	) : null;
}
