import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import HeaderContainer from "../../../layout/HeaderContainer";
import HandleError from "../../../layout/HandleError";
import MyInputText from "../../../utils/Form/MyInputText";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import * as p from "../../../constants/Permissions";

const AddHomeUnit = ({ homeId, store, dispatch, userState }) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const methods = useForm();
	const { handleSubmit } = methods;

	const onSubmit = data => {
		const homeUnitData = {
			home_unit: {
				name: data.homeUnit,
				home_id: homeId
			}
		};
		http
			.post(`${url.BASEURL}/home_units?supervisors=true`, homeUnitData)
			.then(res => {
				dispatch({
					type: "ADD_HOMEUNIT",
					payload: res.data.home_unit
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__addHomeUnitSuccess__", {
						name: res.data.home_unit.name
					})
				});
				history.push("/manage/hu/homeunits");
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("home.__homeUnits__")}
				addButton={t("home.__addHomeUnit__")}
				link={"/manage/hu/addhomeunit/add"}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]}
				userState={userState}
			/>
			<HandleError
				error={store.es.error}
				data-testid="homeunit-error"
				dispatch={dispatch}
				message={t("common.__editError__")}
			/>

			<h5 className="blue">{t("home.__addHomeUnit__")}</h5>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MyInputText label={t("common.__name__")} name="homeUnit" behavior={{ required: true }} />
					<button className="btn btn-primary btn-sm" type="submit">
						{t("common.__save__")}
					</button>
				</form>
			</FormProvider>
		</div>
	);
};

export default AddHomeUnit;
