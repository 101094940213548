import React from "react";
import { useTranslation } from "react-i18next";
import { FaUserClock } from "react-icons/fa";

export default function SaturationBoards({ statistics }) {
	const { t } = useTranslation("common");

	return (
		<div className="row mt-2 mb-2">
			<div className="col-md-4">
				<div className="diaper-statistics ">
					<p className="title mt-2">{t("diaper.__averageTime__")} :</p>
					<p className="saturation-statistic">
						{"avg" in statistics ? (
							<>
								{statistics.avg.h} h {statistics.avg.m} mn {statistics.avg.s}
							</>
						) : null}
					</p>
					<FaUserClock className="saturation-icon" />
				</div>
			</div>
			<div className="col-md-4">
				<div className="diaper-statistics-light ">
					<p className="mt-3">
						<span className="meta mr-2">{t("diaper.__minimumTime__")} :</span>
						<span className="title">
							{"min" in statistics ? (
								<>
									{statistics.min.h} h {statistics.min.m} mn {statistics.min.s}
								</>
							) : null}
						</span>
					</p>
					<p className="mt-3">
						<span className="meta mr-2">{t("diaper.__maximumTime__")} :</span>
						<span className="title">
							{"max" in statistics ? (
								<>
									{statistics.max.h} h {statistics.max.m} mn {statistics.max.s}
								</>
							) : null}
						</span>
					</p>
				</div>
			</div>
			<div className="col-md-4">
				<div className="diaper-statistics-light ">
					<p className="mt-3">
						<span className="meta mr-2">{t("diaper.__numberOfChanges__")} :</span>
						<span className="title">{"nb_changes" in statistics ? <>{statistics.nb_changes}</> : null}</span>
					</p>
					<p className="mt-3">
						<span className="meta mr-2">{t("diaper.__numberOfSaturations__")} :</span>
						<span className="title">{"nb_saturations" in statistics ? <>{statistics.nb_saturations}</> : null}</span>
					</p>
				</div>
			</div>
		</div>
	);
}
