import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, Bar, ResponsiveContainer, Area } from "recharts";
import { BsDropletFill, BsThermometerHalf } from "react-icons/bs";

const SeniorWithDiaperStatisticsContainer = ({ values }) => {
	const { t } = useTranslation("common");

	const [displayControls, setDisplayControls] = useState(true);
	const [displayChanges, setDisplayChanges] = useState(true);
	const [displaySaturations, setDisplaySaturations] = useState(true);

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			const data = payload[0].payload;
			return (
				<div
					style={{
						backgroundColor: "white",
						border: "solid 1px #949494",
						padding: "0.5rem",
						borderRadius: "7px",
						boxShadow: "1px 1px 5px 0px #949494"
					}}>
					<p>
						<b>{label}</b>
					</p>
					<div className="social-separator mt-2 mb-2"></div>
					{data.h && data.h > 0 ? (
						<p>
							<BsDropletFill style={{ marginRight: "10px", height: "1rem", width: "auto" }} /> {data.h}
						</p>
					) : null}
					{data.t && data.t > 0 ? (
						<p>
							<BsThermometerHalf style={{ marginRight: "10px", height: "1rem", width: "auto" }} /> {data.t}
						</p>
					) : null}

					{payload[0].payload.wc === 1 ? (
						<p>
							<img src="/img/diaper/wc.png" alt="wc" style={{ marginRight: "10px", height: "1.1rem", width: "auto" }} />
							{t("diaper.__bringToWc__")}
						</p>
					) : null}
				</div>
			);
		}
		return null;
	};
	return (
		<>
			<div className="row mt-2 mb-2">
				<div className="col-12">
					<p className="meta">{t("common.__filters__")} :</p>
				</div>
				<div className="col-sm-4 text-center">
					<input type="checkbox" checked={displayControls} onChange={() => setDisplayControls(prevState => !prevState)} />
					<span className="ml-2">{t("diaper.__control__")}</span>
				</div>
				<div className="col-sm-4 text-center">
					<input type="checkbox" checked={displayChanges} onChange={() => setDisplayChanges(prevState => !prevState)} />
					<span className="ml-2">{t("diaper.__change__")}</span>
				</div>
				<div className="col-sm-4 text-center">
					<input
						type="checkbox"
						checked={displaySaturations}
						onChange={() => setDisplaySaturations(prevState => !prevState)}
					/>
					<span className="ml-2">{t("diaper.__saturation__")}</span>
				</div>
			</div>

			<div style={{ width: "100%", height: 420 }}>
				<ResponsiveContainer>
					<ComposedChart
						data={values}
						margin={{
							top: 20,
							right: 0,
							bottom: 20,
							left: 0
						}}>
						<CartesianGrid stroke="#f5f5f5" />
						<XAxis dataKey="d" />
						<YAxis />
						<Tooltip offset={100} content={<CustomTooltip />} />
						<Legend verticalAlign="bottom" height={20} iconSize={16} />
						<defs>
							<linearGradient id="colorH" x1="0" y1="0" x2="0" y2="1">
								<stop offset="30%" stopColor="#87c7ef" stopOpacity={1} />
								<stop offset="70%" stopColor="#cee3ef" stopOpacity={1} />
							</linearGradient>
							<linearGradient id="colorT" x1="0" y1="0" x2="0" y2="1">
								<stop offset="5%" stopColor="#ffae43" stopOpacity={1} />
								<stop offset="95%" stopColor="#ffd8a5" stopOpacity={1} />
							</linearGradient>
						</defs>
						<Area
							type="monotone"
							dataKey="h"
							fillOpacity={1}
							fill="url(#colorH)"
							stroke="#00a0e3"
							activeDot={{ r: 6 }}
							name={t("diaper.__humidity__")}
						/>
						<Area
							type="monotone"
							dataKey="t"
							fillOpacity={1}
							fill="url(#colorT)"
							stroke="#b86900"
							activeDot={{ r: 6 }}
							name={t("diaper.__temperature__")}
						/>
						{displaySaturations ? <Bar dataKey="s" barSize={2} fill="#fa5456" name={t("diaper.__saturation__")} /> : null}
						{displayChanges ? <Bar dataKey="c" barSize={2} fill="#bd00ff" name={t("diaper.__change__")} /> : null}
						{displayControls ? <Bar dataKey="ctrl" barSize={2} fill="#50c878" name={t("diaper.__control__")} /> : null}
					</ComposedChart>
				</ResponsiveContainer>
			</div>
		</>
	);
};

export default SeniorWithDiaperStatisticsContainer;
