import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { FaCheckCircle, FaBell, FaRegBell } from "react-icons/fa";
import useNotifications from "../../hooks/useNotifications";
import useStoreData from "../../hooks/useStoreData";
import * as p from "../../constants/Permissions";
import ModalContainer from "../../utils/ModalContainer";
import NotificationDetails from "../AlertBoard/NotificationDetails";
import { displayRuleCategory } from "../Home/NotificationsRules/RuleUtils";
import { checkNotification } from "../AlertBoard/NotificationsUtils";

export default function LastNotifications({ homeId, userState }) {
	const { t } = useTranslation("common");
	const { store } = useStoreData({ userState, homeId });
	const last = true;
	const userLocale = useMemo(() => userState.userConnected.locale, [userState]);
	const [notificationsStore, notificationsDispatch] = useNotifications({ homeId, store, last, userLocale });
	const canCheckNotifications = useMemo(
		() => userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_CHECK_NOTIFICATIONS]),
		[userState]
	);
	const notifications = notificationsStore.data;
	const [showNotification, setShowNotification] = useState({
		show: false,
		info: null,
		title: t("common.__notification__")
	});

	return notifications.error ? null : notifications.length > 0 ? (
		<div className="last-notifications-widget mt-3">
			{notifications.map(notification =>
				notification.senior !== "null" ? (
					<div
						className={notification.checked ? "manager-toast-card mb-3" : "manager-toast-card-alert mb-3"}
						key={notification.id}>
						<div className="manager-toast-type">
							{displayRuleCategory(notification.category)}
							<p
								className="title-toast"
								role="button"
								onClick={() => setShowNotification({ show: true, info: notification, title: t("common.__notification__") })}>
								{notification.checker_rule_name}
							</p>
							{notification.rule.type === 6 ? null : (
								<p className="toast-meta">
									<FaRegBell className="mr-1" />
									{notification.rule.type === 2
										? handleDate(notification.info.when[1], "Pp", userLocale)
										: handleDate(notification.info.when, "Pp", userLocale)}
								</p>
							)}
							<div className="toast-separator"></div>

							<div className="d-flex justify-content-between align-items-center">
								<div>
									<Link to={`/senior/profile/${notification.senior_id}`}>
										<strong>{notification.senior}</strong>
									</Link>
									<Link to={`/manage/room/details/${notification.room_id}`}>
										<p className="toast-meta">
											{notification.room} - {notification.homeUnit}
										</p>
									</Link>
								</div>
								<div>
									{notification.checked ? (
										<FaCheckCircle
											className="big-icon light"
											role="button"
											onClick={() => setShowNotification({ show: true, info: notification, title: t("common.__notification__") })}
										/>
									) : canCheckNotifications ? (
										<FaBell
											className="big-icon red"
											role="button"
											onClick={() => checkNotification(notification.id, notificationsDispatch)}
										/>
									) : (
										<FaBell
											className="big-icon red"
											role="button"
											onClick={() => setShowNotification({ show: true, info: notification, title: t("common.__notification__") })}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				) : null
			)}
			<ModalContainer show={showNotification} setShow={setShowNotification}>
				<NotificationDetails show={showNotification} userLocale={userLocale} />
			</ModalContainer>
		</div>
	) : (
		canCheckNotifications && (
			<div className="last-notifications-widget mt-3">
				<div className="manager-toast-card">
					<div className="manager-toast-type">
						<h5>{t("notifications.__notifications__")}</h5>
						<div className="light-separator"></div>
						<p>
							<b>{t("notifications.__wantToAddRule__")}</b>
						</p>
						<p className="meta mt-3 mb-4">{t("notifications.__whyToAddRule__")}</p>
						<Link to="/manage/nr/rule/add">
							<button type="button" className="btn btn-sm btn-outline-primary mb-3">
								{t("rules.__addNotificationRules__")}
							</button>
						</Link>
					</div>
				</div>
			</div>
		)
	);
}
