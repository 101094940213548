import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaAngleDoubleUp, FaAngleDoubleDown, FaRegTimesCircle, FaCheckCircle, FaRegUserCircle } from "react-icons/fa";
import "./Moderation.scss";
import * as API from "../../services/API";
import DisplayName from "../../utils/DisplayName";
import NoData from "../../layout/NoData";

export default function AvatarModeration({ seniorsFromHome, homeId, notificationsDispatch }) {
	const { t } = useTranslation("common");
	const [displayAvatar, setDisplayAvatar] = useState(false);
	const [avatarsToModerate, setAvatarsToModerate] = useState([]);
	const [update, setUpdate] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (homeId) {
			API.getAvatarsModeration(
				homeId,
				res => {
					setAvatarsToModerate(res);
				},
				() => setError(true)
			);
		}
	}, [homeId, update]);

	const avatarValidation = (avatarId, boolean) => {
		const data = {
			accepted: boolean
		};
		API.sendAvatarModeration(
			avatarId,
			data,
			() => {
				setUpdate(prevUpdate => !prevUpdate);
				notificationsDispatch({
					type: "MODERATE_AVATAR"
				});
			},
			() => {
				notificationsDispatch({
					type: "MODERATION_ERROR"
				});
			}
		);
	};

	return (
		<>
			<div className="row">
				<div className="col-2">
					<FaRegUserCircle className={`${displayAvatar ? "blue" : " "} + moderation-icon ml-2`} />
				</div>
				<div className="col-8">
					<h5>{t("moderation.__avatars__")}</h5>
					{avatarsToModerate.length > 0 ? (
						<p className="meta">
							{t("moderation.__moderationCounter__")} : <b className="blue"> {avatarsToModerate.length}</b>
						</p>
					) : (
						<p className="meta">{t("moderation.__noModerationPending__")}</p>
					)}
				</div>

				<div className="col-2">
					{avatarsToModerate.length > 0 ? (
						displayAvatar ? (
							<FaAngleDoubleUp className="family-icon right" onClick={() => setDisplayAvatar(false)} />
						) : (
							<FaAngleDoubleDown className="family-icon right" onClick={() => setDisplayAvatar(true)} />
						)
					) : null}
				</div>
			</div>

			{displayAvatar ? (
				<>
					<div className="d-flex flex-row mt-3">
						{error ? (
							<NoData />
						) : (
							avatarsToModerate.map(avatar => (
								<div className="p-2 avatar-moderation-card m-2" key={avatar.id}>
									<div className="avatar-moderation-card-body center">
										<Link to={`/senior/profile/${avatar.senior_id}`}>
											<b>{DisplayName(avatar.senior_id, seniorsFromHome)}</b>
										</Link>
										<img
											src={`/storages/seniors/avatars/${avatar.id}/avatar`}
											className="senior-avatar-activities"
											alt="avatar"
										/>
									</div>
									<div className="moderation-card-footer center">
										<FaRegTimesCircle className="moderation-card-icon-no" onClick={() => avatarValidation(avatar.id, false)} />
										<FaCheckCircle className="moderation-card-icon-ok" onClick={() => avatarValidation(avatar.id, true)} />
									</div>
								</div>
							))
						)}
					</div>
				</>
			) : null}
		</>
	);
}
