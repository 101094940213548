import React from "react";
import i18n from "../i18n/i18next";
import { FaEnvelope, FaVideo, FaVideoSlash, FaRegCalendarTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { handleDate } from "@e4ia/e4link_modules.datetime";

// NotificationBOX : Type of messages
export const MSG_FROM_FAMILY_TO_USER = 1;
export const MSG_FROM_USER_TO_FAMILY = 2;
export const FAMILY_ASK_FOR_VISIO = 3;
export const USER_ACCEPT_VISIO = 4;
export const USER_REJECT_VISIO = 5;
export const FAMILY_CANCEL_VISIO = 6;
export const MULTI_MSG_FROM_USER_TO_FAMILY = 7;
export const DUPLICATED_MSG_FROM_USER_TO_FAMILY = 8;
export const USER_ASK_FOR_VISIO = 13;
export const FAMILY_ACCEPT_VISIO = 14;
export const FAMILY_REJECT_VISIO = 15;
export const USER_CANCEL_VISIO = 16;
export const WELCOME_MESSAGE = 100;

export function displayMessageCategory(type) {
	switch (type) {
		case MSG_FROM_FAMILY_TO_USER:
		case MSG_FROM_USER_TO_FAMILY:
		case MULTI_MSG_FROM_USER_TO_FAMILY:
		case DUPLICATED_MSG_FROM_USER_TO_FAMILY:
		case WELCOME_MESSAGE:
			return i18n.t("common:common.__message__");
		case FAMILY_ASK_FOR_VISIO:
		case USER_ACCEPT_VISIO:
		case USER_REJECT_VISIO:
		case FAMILY_CANCEL_VISIO:
		case USER_ASK_FOR_VISIO:
		case FAMILY_ACCEPT_VISIO:
		case FAMILY_REJECT_VISIO:
		case USER_CANCEL_VISIO:
			return i18n.t("common:calendar.__visio__");
		default:
			return;
	}
}

export function displayMessageType(type) {
	switch (type) {
		case MSG_FROM_FAMILY_TO_USER:
			return i18n.t("common:mailbox.__familyMsg__");
		case MSG_FROM_USER_TO_FAMILY:
			return i18n.t("common:mailbox.__supervisorMsg__");
		case FAMILY_ASK_FOR_VISIO:
		case USER_ASK_FOR_VISIO:
			return i18n.t("common:mailbox.__visioRequest__");
		case USER_ACCEPT_VISIO:
		case FAMILY_ACCEPT_VISIO:
			return i18n.t("common:mailbox.__vAccepted__");
		case USER_REJECT_VISIO:
		case FAMILY_REJECT_VISIO:
			return i18n.t("common:mailbox.__vRefused__");
		case FAMILY_CANCEL_VISIO:
		case USER_CANCEL_VISIO:
			return i18n.t("common:mailbox.__vCanceled__");
		case MULTI_MSG_FROM_USER_TO_FAMILY:
			return i18n.t("common:mailbox.__multiMsgSent__");
		case DUPLICATED_MSG_FROM_USER_TO_FAMILY:
			return i18n.t("common:mailbox.__multiMsg__");
		default:
			return;
	}
}

export const handleContent = (type, subject, message, visioDate, id, unread, userState, isControlPanel) => {
	switch (type) {
		case FAMILY_ASK_FOR_VISIO:
			return (
				<p>
					<b>
						<FaVideo className="regular-icon blue mr-2" />
						{i18n.t("common:mailbox.__visioAsked__")}{" "}
						{visioDate ? handleDate(visioDate, "PPPPp", userState.userConnected.locale) : null}
					</b>
				</p>
			);
		case USER_ASK_FOR_VISIO:
			return (
				<p>
					<b>
						<FaVideo className="regular-icon mr-2" />
						{i18n.t("common:mailbox.__visioAsked__")}{" "}
						{visioDate ? handleDate(visioDate, "PPPPp", userState.userConnected.locale) : null}
					</b>
				</p>
			);
		case FAMILY_CANCEL_VISIO:
			return (
				<>
					<b>
						<FaRegCalendarTimes className={unread ? "regular-icon red mr-2" : "regular-icon mr-2"} />
						{i18n.t("common:mailbox.__vCanceled__")}:{" "}
						{visioDate ? handleDate(visioDate, "PPPPp", userState.userConnected.locale) : null}
					</b>
					<br />
					{i18n.t("common:common.__message__")} : {message}
				</>
			);
		case USER_CANCEL_VISIO:
			return (
				<>
					<b>
						<FaRegCalendarTimes className="regular-icon mr-2" />
						{i18n.t("common:mailbox.__vCanceled__")}:{" "}
						{visioDate ? handleDate(visioDate, "PPPPp", userState.userConnected.locale) : null}
					</b>
					<br />
					{i18n.t("common:common.__message__")} : {message}
				</>
			);
		case USER_ACCEPT_VISIO:
		case FAMILY_ACCEPT_VISIO:
			return (
				<b>
					<FaVideo className="regular-icon green mr-2" />
					{i18n.t("common:mailbox.__visioPlanned__")}{" "}
					{visioDate ? handleDate(visioDate, "PPPPp", userState.userConnected.locale) : null}
				</b>
			);
		case USER_REJECT_VISIO:
			return (
				<>
					<b>
						<FaVideoSlash className="regular-icon mr-2" />
						{i18n.t("common:mailbox.__vRefused__")} :{" "}
						{visioDate ? handleDate(visioDate, "PPPPp", userState.userConnected.locale) : null}
					</b>
					<br />
					{i18n.t("common:common.__message__")} : {message}
				</>
			);
		case FAMILY_REJECT_VISIO:
			return (
				<>
					<b>
						<FaVideoSlash className="regular-icon red mr-2" />
						{i18n.t("common:mailbox.__vRefused__")}:{" "}
						{visioDate ? handleDate(visioDate, "PPPPp", userState.userConnected.locale) : null}
					</b>
					<p className={unread ? "red mt-1" : "mt-1"}>
						{i18n.t("common:common.__message__")} : {message}
					</p>
				</>
			);
		case MSG_FROM_FAMILY_TO_USER:
			return unread ? (
				<>
					<b>
						{i18n.t("common:mailbox.__object__")} :{" "}
						{subject ? subject : message.length > 80 ? `${message.substring(0, 80)} [...]` : message}
					</b>
					{isControlPanel ? null : (
						<>
							<br />
							<Link to={`/msg/messagesbox/${id}/thread`}>
								<FaEnvelope className="mr-2 regular-icon" />
								<b>{i18n.t("common:mailbox.__moreThread__")}</b>
							</Link>
						</>
					)}
				</>
			) : (
				<>
					<b>
						{i18n.t("common:mailbox.__object__")} :{" "}
						{subject ? subject : message.length > 80 ? `${message.substring(0, 80)} [...]` : message}
					</b>
					<br />
					<Link to={`/msg/messagesbox/${id}/thread`}>
						<FaEnvelope className="regular-icon mr-2 blue" />
						<b>{i18n.t("common:mailbox.__moreThread__")}</b>
					</Link>
				</>
			);
		case MSG_FROM_USER_TO_FAMILY:
		case MULTI_MSG_FROM_USER_TO_FAMILY:
		case DUPLICATED_MSG_FROM_USER_TO_FAMILY:
			return (
				<>
					<b>
						{i18n.t("common:mailbox.__object__")} :{" "}
						{subject ? subject : message.length > 50 ? `${message.substring(0, 50)} [...]` : message}
					</b>
					<br />
					<Link to={`/msg/messagesbox/${id}/thread`} className="mt-1">
						<FaEnvelope className="regular-icon mr-2" />
						<b>{i18n.t("common:mailbox.__readMessage__")}</b>
					</Link>
				</>
			);
		default:
			return null;
	}
};

export const handleButtons = (msgState, msgDispatch, t) => {
	return (
		<>
			<button
				type="button"
				className={msgState.filter === "msg" ? "btn btn-primary btn-sm" : "btn btn-outline-primary btn-sm"}
				onClick={() =>
					msgDispatch({
						type: "MSGBOX_FILTER",
						payload: "msg"
					})
				}>
				{t("common.__messages__")}
			</button>
			<button
				type="button"
				className={msgState.filter === "visio" ? "btn btn-primary btn-sm" : "btn btn-outline-primary btn-sm"}
				onClick={() =>
					msgDispatch({
						type: "MSGBOX_FILTER",
						payload: "visio"
					})
				}>
				{t("common.__visio__")}
			</button>
			<button
				type="button"
				className={msgState.filter === "all" ? "btn btn-primary btn-sm" : "btn btn-outline-primary btn-sm"}
				onClick={() =>
					msgDispatch({
						type: "MSGBOX_FILTER",
						payload: "all"
					})
				}>
				{t("common.__all__")}
			</button>
		</>
	);
};
