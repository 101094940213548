import React, { ElementType, CSSProperties } from "react";

interface ButtonProps {
	label: string;
	onClick?: () => void;
	className?: string;
	[key: string]: any;
	icon: ElementType;
	iconStyle?: CSSProperties;
	disabled: boolean;
}

const Button: React.FC<ButtonProps> = ({ label, onClick, className, icon: Icon, disabled,iconStyle, ...props }) => {
	return (
		<button className={className} onClick={onClick} disabled={disabled} {...props}>
			{label}
			{Icon && <Icon style={iconStyle} />}
		</button>
	);
};

export default Button;
