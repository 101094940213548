import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useDateBeforeOrAfter from "../../hooks/useDateBeforeOrAfter";

interface Props {
	setStartDate: (date: Date) => void;
	setEndDate: (date: Date) => void;
	today: Date;
	last3Month: Date;
}

const RangeTypeSelection = ({ setStartDate, setEndDate, today, last3Month }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [selectRange, setSelectRange] = useState("trimester");
	const last6Month = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 180 });
	const last12Month = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 365 });

	const handleRange = (rangeType: string): void => {
		switch (rangeType) {
			case "trimester":
				setSelectRange("trimester");
				setStartDate(last3Month);
				setEndDate(today);
				break;
			case "semester":
				setSelectRange("semester");
				setStartDate(last6Month);
				setEndDate(today);
				break;
			case "year":
				setSelectRange("year");
				setStartDate(last12Month);
				setEndDate(today);
				break;
			default:
				setStartDate(last3Month);
				setEndDate(today);
		}
	};

	return (
		<div className="btn-group btn-group-sm mb-3">
			<button
				type="button"
				className={selectRange === "trimester" ? "btn btn-primary" : "btn btn-outline-secondary"}
				onClick={(): void => handleRange("trimester")}>
				{t("common.__last3Month__")}
			</button>
			<button
				type="button"
				className={selectRange === "semester" ? "btn btn-primary" : "btn btn-outline-secondary"}
				onClick={(): void => handleRange("semester")}>
				{t("common.__last6Month__")}
			</button>
			<button
				type="button"
				className={selectRange === "year" ? "btn btn-primary" : "btn btn-outline-secondary"}
				onClick={(): void => handleRange("year")}>
				{t("common.__lastYear__")}
			</button>
		</div>
	);
};
export default RangeTypeSelection;
