import React from "react";
import { SeniorInterface, UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import HomePreferences from "../components/Home/DevicePreferences/HomePreferences";
import SelectHomeInput from "../layout/SelectHomeInput";
import * as p from "../constants/Permissions";
import Tabs from "../utils/Tabs/Tabs";
import { DevicePreferencesTabs } from "../utils/Tabs/TabsToDisplay";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const DevicePreferences = ({ userState, homeId }: Props): JSX.Element => {
	const userLocale = userState.userConnected.locale;
	const { store } = useStoreData({ userState, homeId });
	const seniorsFromHome = store.seniors.seniorsFromHome;
	const seniorsWithDevice = seniorsFromHome.filter((senior: SeniorInterface) => senior.deviceId);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.SUPER_ADMIN, p.ADMIN, p.CAN_UPDATE_HOME]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<HomesSecondaryNav userState={userState} />}
				component={
					<>
						<Tabs tabsToDisplay={DevicePreferencesTabs} currentTab={"PHOTOFRAME"} />
						<div className="big-card">
							{homeId ? (
								<HomePreferences homeId={homeId} userLocale={userLocale} seniorsWithDevice={seniorsWithDevice} />
							) : (
								<SelectHomeInput />
							)}
						</div>
					</>
				}
			/>
		</AccessControl>
	);
};
export default DevicePreferences;
