import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye, FaExchangeAlt } from "react-icons/fa";
import AddProtectionToSenior from "./AddProtectionToSenior";
import ControlProtectionToSenior from "./ControlProtectionToSenior";

export default function AddOrControlProtectionToSenior({ userLocale, show, setShow, diapers, setUpdate }) {
	const { t } = useTranslation("common");
	const [selectAction, setSelectAction] = useState(null);
	const senior = show.info.senior;

	return (
		<div className="mt-4">
			{selectAction === "ADD_PROTECTION" ? (
				<AddProtectionToSenior
					diapers={diapers}
					senior={senior}
					setShow={setShow}
					userLocale={userLocale}
					setUpdate={setUpdate}
				/>
			) : selectAction === "CONTROL_PROTECTION" ? (
				<ControlProtectionToSenior setShow={setShow} userLocale={userLocale} senior={senior} />
			) : (
				<>
					<div className="small-list-items" role="button" onClick={() => setSelectAction("ADD_PROTECTION")}>
						<FaExchangeAlt className="big-kpi-icon" /> {t("diaper.__addDiaper__")}
					</div>
					<div className="small-list-items" role="button" onClick={() => setSelectAction("CONTROL_PROTECTION")}>
						<FaEye className="big-kpi-icon" /> {t("diaper.__control__")}
					</div>
				</>
			)}
		</div>
	);
}
