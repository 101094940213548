import React from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";

export default function ErrorContainer({ alert, success, httpResponse, setHttpResponse }) {
	const { t } = useTranslation("common");
	return (
		<>
			{httpResponse !== null ? (
				httpResponse.error ? (
					<p className="error-msg">
						<span className="right ml-2" role="button" tabIndex="0" onClick={() => setHttpResponse(null)}>
							<FaTimes />
						</span>
						{t("common.__errorOccured__")} <br /> {alert}
					</p>
				) : (
					<p className="success-msg">
						<span className="right ml-2" role="button" tabIndex="0" onClick={() => setHttpResponse(null)}>
							<FaTimes />
						</span>
						{success}
					</p>
				)
			) : (
				<br />
			)}
		</>
	);
}
