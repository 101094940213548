import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import MyInputText from "../../../utils/Form/MyInputText";
import MyInputSelect from "../../../utils/Form/MyInputSelect";
import MyInputNumber from "../../../utils/Form/MyInputNumber";
import girLevel from "../../../constants/GirLevel";
import genderList from "../../../constants/GenderList";
import { NewSeniorInterface } from "../../../interfaces/UserInterface";

interface Props {
	newSeniorStore: NewSeniorInterface;
	newSeniorDispatch: React.Dispatch<any>;
}

const AddSeniorContainer = ({ newSeniorStore, newSeniorDispatch }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const canDisplayHDS = process.env.REACT_APP_SHOW_HDS === "true" ? true : false;
	const [isAutonomous, setIsAutonomous] = useState(false);

	const saveNewSenior = (values: any): void => {
		const senior = {
			arrival_date: values.arrivalYear + "-" + values.arrivalMonth + "-" + values.arrivalDay,
			birth_date: values.year + "-" + values.month + "-" + values.day,
			first_name: values.firstName,
			last_name: values.lastName,
			gender: values.gender,
			gir_level: parseInt(values.girLevel),
			profile_id: isAutonomous ? 1 : 0
		};
		const room_id = values.room === "0" ? null : parseInt(values.room);

		newSeniorDispatch({
			type: "SAVE_NEW_SENIOR",
			payload: { senior, room_id }
		});
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(saveNewSenior)}>
				<div className="row mt-4 header-details">
					<div className="col-md-2">
						<MyInputSelect
							label={t("senior.__addSeniorGender__")}
							name="gender"
							behavior={{ required: true }}
							list={genderList}
						/>
					</div>
					<div className="col-md-5">
						<MyInputText label={t("common.__firstname__")} name="firstName" behavior={{ required: true }} />
					</div>
					<div className="col-md-5">
						<MyInputText label={t("common.__name__")} name="lastName" behavior={{ required: true }} />
					</div>
					<div className="col-12">
						<br />
					</div>
					<div className="col-md-3 mt-2">
						<p className="mt-4">{t("senior.__birthDate__")} : </p>
					</div>
					<div className="col-md-3">
						<MyInputNumber
							label={t("common.__day__")}
							name="day"
							behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
							placeholder="01"
						/>
					</div>
					<div className="col-md-3">
						<MyInputNumber
							label={t("common.__month__")}
							name="month"
							behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
							placeholder="01"
						/>
					</div>
					<div className="col-md-3">
						<MyInputNumber
							label={t("common.__year__")}
							name="year"
							behavior={{ required: true, maxLength: 4, pattern: /[0-9]{4}/ }}
							placeholder="1900"
						/>
					</div>
					<div className="col-12">
						<br />
					</div>
					<div className="col-md-3 mt-2">
						<p className="mt-4">{t("senior.__arrivalDate__")} : </p>
					</div>
					<div className="col-md-3">
						<MyInputNumber
							label={t("common.__day__")}
							name="arrivalDay"
							behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
							placeholder="01"
						/>
					</div>
					<div className="col-md-3">
						<MyInputNumber
							label={t("common.__month__")}
							name="arrivalMonth"
							behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
							placeholder="01"
						/>
					</div>
					<div className="col-md-3">
						<MyInputNumber
							label={t("common.__year__")}
							name="arrivalYear"
							behavior={{ required: true, maxLength: 4, pattern: /[0-9]{4}/ }}
							placeholder="1900"
						/>
					</div>
					<div className="col-12">
						<br />
					</div>
					<div className="col-md-6 mt-3">
						<MyInputSelect
							name="room"
							behavior={{ required: false }}
							list={newSeniorStore.rooms}
							label={t("senior.__roomAssignment__")}
						/>
					</div>
					<div className="col-md-6 mt-3">
						{canDisplayHDS ? (
							<MyInputSelect label={t("senior.__agir__")} name="girLevel" behavior={{ required: false }} list={girLevel} />
						) : null}
					</div>
					<div className="col-12">
						<input type="checkbox" name="profile" className="mr-2" onChange={(): void => setIsAutonomous(!isAutonomous)} />{" "}
						<label>{t("senior.__independant__")}</label>
					</div>
				</div>

				<div className="d-flex justify-content-end mb-2 mt-2">
					<button type="submit" className="btn btn-primary btn-sm">
						{t("common.__nextStep__")}
					</button>
				</div>
			</form>
		</FormProvider>
	);
};

export default AddSeniorContainer;
