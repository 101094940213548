import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaRegTimesCircle, FaRegEdit, FaAngleRight } from "react-icons/fa";
import HandleSuccess from "../../../layout/HandleSuccess";
import http from "../../../services/Interceptor";
import * as constant from "../../../constants/Url";
import { Tooltip } from "../../Buttons/Tooltip";

const HomeUnitsFromHome = ({ store, dispatch, canManageHomeUnit }) => {
	const { t } = useTranslation("common");
	const homeUnits = store.home.homeUnits;

	const removeHomeUnit = homeUnitId => {
		dispatch({
			type: "RESET_ES"
		});
		http
			.delete(`${constant.BASEURL}/home_units/${homeUnitId}`)
			.then(() => {
				dispatch({
					type: "DELETE_HOMEUNIT",
					payload: homeUnitId
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__removeHomeUnitSuccess__")
				});
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<>
			{homeUnits.length === 0 ? (
				<div className="mt-3">
					<h5>
						<FaAngleRight className="mr-2" />
						{t("common.__noElement__")}
					</h5>
				</div>
			) : (
				<>
					<HandleSuccess success={store.es.success} dispatch={dispatch} message={store.es.msg} />

					{homeUnits.map(homeUnit => (
						<div key={homeUnit.id} className="small-list-items mb-4">
							<div className="row">
								<div className="col-md-8">
									<h5>{homeUnit.name}</h5>
								</div>
								<div className="col-md-4">
									{canManageHomeUnit ? (
										<span className="float-right mt-2">
											<Tooltip text={t("common.__update__")}>
												<Link to={`/manage/hu/edithomeunit/${homeUnit.id}`}>
													<FaRegEdit className="table-icon" role="button" />
												</Link>
											</Tooltip>
											<Tooltip text={t("calendar.__remove__")}>
												<FaRegTimesCircle className="table-icon" role="button" onClick={() => removeHomeUnit(homeUnit.id)} />
											</Tooltip>
										</span>
									) : null}
								</div>
								{homeUnit.supervisors ? (
									homeUnit.supervisors.length < 1 ? (
										<p className="meta ml-3">{t("home.__noHomeUnitManager__")}</p>
									) : (
										homeUnit.supervisors.map(supervisor => (
											<span key={supervisor.id} className="ml-2">
												<Link to={`/user/healthworker/${supervisor.id}`}>
													<span className="category-outline-badge blue">
														{supervisor.first_name === "badge_general"
															? supervisor.last_name
															: `${supervisor.first_name} ${supervisor.last_name.toUpperCase()}`}
													</span>
												</Link>
											</span>
										))
									)
								) : null}
							</div>
						</div>
					))}
				</>
			)}
		</>
	);
};

export default HomeUnitsFromHome;
