import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as url from "../../../constants/Url";
import http from "../../../services/Interceptor";
import DisplayThread from "./DisplayThread";
import ResponseForm from "./ResponseForm";

export default function AnswerMessage({ msgData, userState, homeId }) {
	const { t } = useTranslation("common");
	const [thread, setThread] = useState([]);
	const [errorThread, setErrorThread] = useState(false);
	const [responseMode, setResponseMode] = useState(true);
	const initialMsg = thread.find(msg => msg.subject);

	useEffect(() => {
		http
			.get(`${url.HOMESURL}/messages/${msgData.id}/thread_from_any_id`)
			.then(res => setThread(res.data.family_user_messages))
			.catch(err => {
				if (err.response.status === 403) {
					setErrorThread(true);
				} else {
					setThread([]);
				}
			});
	}, [msgData.id, responseMode]);

	return (
		<>
			<p className="meta">
				{t("mailbox.__seniorFamily__")} : {msgData.senior}
				{initialMsg ? (
					<span className="modal-title mt-1">
						<br />
						{t("mailbox.__object__")} : {initialMsg.subject}
					</span>
				) : null}
			</p>

			<ResponseForm msgId={msgData.id} responseMode={responseMode} setResponseMode={setResponseMode} />
			<div className="space"></div>
			<hr />
			<h3>{t("mailbox.__thread__")}</h3>
			{errorThread ? (
				<p className="error-msg">{t("common.__permissionError__")}</p>
			) : (
				<DisplayThread
					thread={thread}
					family={msgData.family}
					senior={msgData.senior}
					userState={userState}
					homeId={homeId}
				/>
			)}
		</>
	);
}
