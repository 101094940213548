import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	FaIdCardAlt,
	FaCheckSquare,
	FaExclamationCircle,
	FaToggleOff,
	FaToggleOn,
	FaUserCircle,
	FaPhoneAlt,
	FaEnvelope
} from "react-icons/fa";
import {
	HealthWorkerTypesInterface,
	UserInterface,
	BadgeInterface,
	HealthWorkerInterface,
	StoreInterface
} from "../../interfaces/UserInterface";
import DisplayFullBadge from "./DisplayFullBadge";
import Toggle from "../../utils/Toggle";
import ModalContainer, { ShowState } from "../../utils/ModalContainer";
import AddBadge from "./Badges/AddBadge";
import DisplayName from "../../utils/DisplayName";
import EditHealthWorkerButton from "../Buttons/EditHealthWorkerIcon";
import GoToPermissionIcon from "../Buttons/GoToPermissionIcon";
import TimelineIcon from "../Buttons/TimelineIcon";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as p from "../../constants/Permissions";

interface Props {
	healthWorkerDetails: HealthWorkerInterface;
	healthWorkerBadges: BadgeInterface[];
	healthWorkerTypes: HealthWorkerTypesInterface[];
	dispatch: React.Dispatch<any>;
	healthWorkerDispatch: React.Dispatch<any>;
	userState: UserInterface;
	homeId: number | null;
	store: StoreInterface;
	setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const HealthWorkerProfileContainer = ({
	healthWorkerDetails,
	healthWorkerBadges,
	healthWorkerDispatch,
	userState,
	store,
	dispatch,
	healthWorkerTypes,
	homeId,
	setUpdate
}: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [badgeSelected, setBadgeSelected] = useState([]);
	const [show, setShow] = useState<ShowState>({
		show: false,
		info: null,
		title: " "
	});
	const isAdmin = userState.userConnected.is(p.ADMIN);
	const isAdminOrSuperAdmin = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN]);
	const canReadTimeline = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_READ_ACTIVITIES]);
	const canManageUserAndBadge = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]);
	const canManagePermissions = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.CAN_MANAGE_PERMISSIONS]);

	const thisHealthWorkerIsAdmin = healthWorkerDetails && healthWorkerDetails.tenant_id ? true : false;
	const thisHealthWorkerIsGroupManager = healthWorkerDetails && healthWorkerDetails.group_id ? true : false;
	const thisHealthWorkerIsHomeManager = healthWorkerDetails && healthWorkerDetails.home_id ? true : false;

	const toggleHealthWorker = (id: number): void => {
		http
			.put(`${url.BASEURL}/users/${id}/toggle`)
			.then(res => {
				healthWorkerDispatch({
					type: "TOGGLE_HEALTHWORKER",
					payload: res.data.user
				});
				dispatch({
					type: "EDIT_HEALTHWORKER",
					payload: res.data.user
				});
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR",
					msg: t("common.__errorOccured__")
				});
			});
	};

	const resetRgpd = (userId: number): void => {
		http
			.put(`${url.BASEURL}/users/${userId}/reset/rgpd`)
			.then(() => {
				healthWorkerDispatch({
					type: "RESET_RGPD",
					payload: false
				});
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR",
					msg: t("common.__errorOccured__")
				});
			});
	};

	return (
		<>
			<div className="d-flex justify-content-between">
				<h2>
					{healthWorkerDetails.last_name.toUpperCase()}{" "}
					{healthWorkerDetails.first_name === "badge_general" ? null : healthWorkerDetails.first_name}
				</h2>
				<div className="mt-1">
					{canReadTimeline && thisHealthWorkerIsHomeManager ? (
						<TimelineIcon type="HEALTHWORKER" id={healthWorkerDetails.id} />
					) : null}
					<EditHealthWorkerButton userState={userState} userToEdit={healthWorkerDetails} />
					<GoToPermissionIcon canManagePermissions={canManagePermissions} user={healthWorkerDetails} />
				</div>
			</div>

			<div className="header-details">
				<div className="d-flex justify-content-between">
					{thisHealthWorkerIsAdmin ? (
						<h3 className="mb-2">Admin</h3>
					) : thisHealthWorkerIsGroupManager ? (
						<h3 className="mb-2">
							{t("users.__groupManager__")}
							<span className="meta"> - {DisplayName(healthWorkerDetails.type_id, healthWorkerTypes)}</span>
						</h3>
					) : (
						<h3>{DisplayName(healthWorkerDetails.type_id, healthWorkerTypes)}</h3>
					)}

					<div>
						{(isAdmin && thisHealthWorkerIsAdmin) || !canManageUserAndBadge ? (
							healthWorkerDetails.active ? (
								<span className="meta">
									<FaToggleOn className="blue ml-2 mr-2" />
									{t("common.__active__")}
								</span>
							) : (
								<span className="meta">
									<FaToggleOff className="ml-2 mr-2" />
									{t("common.__inactive__")}
								</span>
							)
						) : (
							<Toggle isToggle={healthWorkerDetails.active} value={healthWorkerDetails.id} action={toggleHealthWorker} meta />
						)}
					</div>
				</div>
				<p>
					<FaUserCircle className="regular-icon mr-2" /> {healthWorkerDetails.login}
				</p>
				<p>
					<FaEnvelope className="regular-icon mr-2" />
					<a href={`mailto:${healthWorkerDetails.email}`}>{healthWorkerDetails.email}</a>
				</p>
				<p>
					<FaPhoneAlt className="regular-icon mr-2" /> {healthWorkerDetails.phone}
					{healthWorkerDetails.mobile_phone && `- ${t("common.__smartphone__")} : ${healthWorkerDetails.mobile_phone}`}
				</p>
				{healthWorkerDetails.has_accepted_rgpd ? (
					<div className="d-flex justify-content-between">
						<p>
							<FaCheckSquare className="regular-icon blue mr-2" /> {t("healthworker.__rgpdAccepted__")}
						</p>
						{isAdminOrSuperAdmin && (
							<button className="btn btn-sm btn-outline-secondary" onClick={(): void => resetRgpd(healthWorkerDetails.id)}>
								{t("common.__resetRgpd__")}
							</button>
						)}
					</div>
				) : (
					<p>
						<FaExclamationCircle className="regular-icon red mr-2" /> {t("healthworker.__rgpdNotAccepted__")}
					</p>
				)}
			</div>
			<div className="space"></div>
			{canManageUserAndBadge && thisHealthWorkerIsHomeManager ? (
				<>
					<div className="row">
						<div className="col-md-6">
							<h5>
								<FaIdCardAlt className="regular-icon mr-2" />
								{t("badge.__badgeAssigned__")} :
							</h5>
						</div>
						{homeId && homeId === healthWorkerDetails.home_id ? (
							<div className="col-md-6 mt-2">
								<button
									type="button"
									className="btn btn-sm btn-outline-primary right"
									onClick={(): void =>
										setShow({
											show: true,
											info: healthWorkerDetails.id,
											title: t("badge.__AssignBadge__")
										})
									}>
									{t("badge.__AssignBadge__")}
								</button>
							</div>
						) : null}
					</div>
					{healthWorkerBadges.length > 0 ? (
						healthWorkerBadges.map((badge: BadgeInterface) => (
							<DisplayFullBadge
								badge={badge}
								setUpdate={setUpdate}
								store={store}
								badgeSelected={badgeSelected}
								setBadgeSelected={setBadgeSelected}
								healthWorkerBadges={healthWorkerBadges}
								hwId={healthWorkerDetails.id}
								dispatch={dispatch}
								canManageUserAndBadge={canManageUserAndBadge}
								userState={userState}
								key={badge.id}
							/>
						))
					) : (
						<p className="meta">{t("badge.__noBadge__")}</p>
					)}
				</>
			) : null}
			<ModalContainer show={show} setShow={setShow}>
				<AddBadge
					show={show}
					setShow={setShow}
					healthWorkerBadges={healthWorkerBadges}
					store={store}
					dispatch={dispatch}
					setUpdate={setUpdate}
					homeId={homeId}
					userState={userState}
				/>
			</ModalContainer>
		</>
	);
};

export default HealthWorkerProfileContainer;
