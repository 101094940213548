import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BlogContext } from "../context/BlogContext";
import { UserInterface } from "../interfaces/UserInterface";
import { BlogTabs } from "../utils/Tabs/TabsToDisplay";
import { handlePosts } from "../components/News/BlogUtils";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import BlogHeader from "../layout/BlogHeader";
import SelectHomeInput from "../layout/SelectHomeInput";
import NoData from "../layout/NoData";
import NewsList from "../components/News/NewsList";
import Tabs from "../utils/Tabs/Tabs";
import http from "../services/Interceptor";
import * as constant from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const SocialNews = ({ homeId, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { blogStore, blogDispatch } = useContext(BlogContext);
	const { store } = useStoreData({ userState, homeId });
	const isOnlyBlogger = userState.userConnected.is(p.HOME_BLOG_WRITER);
	const displayAddPostButton = userState.userConnected.is_in([
		p.HOME_BLOG_WRITER,
		p.CAN_POST_MODIFY_HOME_BLOG,
		p.ADMIN,
		p.SUPER_ADMIN
	]);

	useEffect(() => {
		return function cleanup(): void {
			blogDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (homeId) {
			if (isOnlyBlogger && blogStore.news.length === 0) {
				blogDispatch({
					type: "LOADING_NEWS"
				});
				http
					.get(`${constant.BASEURL}/me/blog_posts`)
					.then(res => {
						const posts = handlePosts(res.data.blog_posts, userState.userConnected.locale, store.users.healthWorkers);
						blogDispatch({
							type: "GET_NEWS",
							payload: posts
						});
					})
					.catch(() => {
						blogDispatch({
							type: "ERROR_NEWS"
						});
					});
			} else {
				http
					.get(`${constant.HOMESURL}/${homeId}/blog_posts`)
					.then(res => {
						const posts = handlePosts(res.data.blog_posts, userState.userConnected.locale, store.users.healthWorkers);
						blogDispatch({
							type: "GET_NEWS",
							payload: posts
						});
					})
					.catch(() => {
						blogDispatch({
							type: "ERROR_NEWS"
						});
					});
			}
		}
		// eslint-disable-next-line
	}, [homeId]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[
				p.ADMIN,
				p.SUPER_ADMIN,
				p.HOME_BLOG_WRITER,
				p.CAN_POST_MODIFY_HOME_BLOG,
				p.CAN_MODERATE_HOME_BLOG
			]}>
			<Tabs tabsToDisplay={BlogTabs} currentTab={"MANAGE_POST"} />
			<div className="big-card">
				{homeId ? (
					<>
						<BlogHeader displayAddPostButton={displayAddPostButton} title={t("news.__handleNews__")} />
						<br />
						{blogStore.error ? (
							<NoData />
						) : (
							<NewsList
								blogStore={blogStore}
								blogDispatch={blogDispatch}
								userState={userState}
								seniorsFromHome={store.seniors.seniorsFromHome}
							/>
						)}
					</>
				) : (
					<SelectHomeInput />
				)}
			</div>
		</AccessControl>
	);
};
export default SocialNews;
