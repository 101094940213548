import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DisplayName from "../../../utils/DisplayName";
import { displayDiaper } from "../../DiaperConnected/Illustrations";
import { Table, SelectColumnFilter } from "../../../utils/Table/TableWithFilter";
import { Link } from "react-router-dom";
import { fromToUrl, handleDate } from "@e4ia/e4link_modules.datetime";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import useStoreData from "../../../hooks/useStoreData";

export default function ChangeDebug({ startDate, endDate, homeId, seniorsFromHome, diapers, userState }) {
	const { t } = useTranslation("common");
	const { store } = useStoreData({ userState, homeId });
	const users = useMemo(() => store.users.healthWorkers, [store.users.healthWorkers]);
	const [changeList, setChangeList] = useState([]);

	useEffect(() => {
		if (homeId && startDate && endDate) {
			const fromTo = fromToUrl(startDate, endDate);
			http
				.get(`${url.HOMESURL}/${homeId}/rht/changes/${fromTo}`)
				.then(res => setChangeList(res.data.senior_diaper_changes))
				.catch(() => setChangeList([]));
		}
	}, [homeId, startDate, endDate]);

	const data = useMemo(
		() =>
			changeList &&
			changeList.map(saturation => {
				const healthworkerName = DisplayName(saturation.user_id, users);
				const healthworker = healthworkerName ? healthworkerName : t("healthworker.__noHealthWorker__");
				const senior = seniorsFromHome.find(senior => senior && saturation.senior_id === senior.id);
				const seniorLabel = senior && senior.label ? senior.label : t("common.__inactiveAccount__");
				const diaper = diapers.find(diaper => diaper && diaper.id === saturation.diaper_id);

				return {
					...saturation,
					healthworker,
					senior: seniorLabel,
					diaper: diaper
				};
			}),
		[changeList, users, seniorsFromHome, diapers]
	);
	const columns = [
		{
			Header: t("healthworker.__teamMember__"),
			accessor: "healthworker",
			Filter: SelectColumnFilter,
			Cell: row => {
				return row.row.original.user_id &&
					row.row.values.healthworker &&
					row.row.original.user_id !== t("healthworker.__noHealthWorker__") ? (
					<Link to={`/user/healthworker/${row.row.original.user_id}`}>
						<span className="category-outline-badge blue">{row.row.values.healthworker}</span>
					</Link>
				) : (
					<span className="msg-tag yellow mr-3">{t("healthworker.__noHealthWorker__")}</span>
				);
			}
		},
		{
			Header: t("common.__senior__"),
			accessor: "senior",
			Filter: SelectColumnFilter,
			Cell: row => (
				<Link to={`/senior/profile/${row.row.original.senior_id}`}>
					{row.row.values.senior !== t("common.__inactiveAccount__") ? (
						<span className="category-outline-badge blue">{row.row.values.senior}</span>
					) : (
						<span className="msg-tag red mr-3">{row.row.values.senior}</span>
					)}
				</Link>
			)
		},

		{
			Header: t("common.__doneAt__"),
			accessor: "done_at",
			Cell: row => handleDate(row.row.original.done_at, "PPp")
		},
		{
			Header: t("diaper.__diaper__"),
			accessor: "diaper.description",
			Cell: row =>
				row.row.original.diaper ? (
					displayDiaper(row.row.original.diaper)
				) : (
					<span className="msg-tag purple mr-3">{t("diaper.__noDiaper__")}</span>
				)
		}
	];

	return data.length < 1 ? (
		<p className="red mt-3">
			{t("common.__noDataAvailable__")} {t("common.__changeChoice__")}
		</p>
	) : (
		<Table columns={columns} data={data} />
	);
}
