const DevicesReducer = (state, action) => {
	switch (action.type) {
		case "GET_DEVICES":
			return {
				...state,
				devices: action.payload
			};

		case "GET_DEVICE_TYPES":
			return {
				...state,
				types: action.payload
			};

		case "ADD_DEVICE":
			return {
				...state,
				devices: [action.payload, ...state.devices]
			};

		case "EDIT_DEVICE": {
			const updatedDevice = action.payload;
			const deviceUpdated = state.devices.map(device => {
				if (device.id === updatedDevice.id) {
					return updatedDevice;
				}
				return device;
			});
			return {
				...state,
				devices: deviceUpdated
			};
		}

		case "DELETE_DEVICE":
			return {
				...state,
				devices: state.devices.filter(device => device.id !== action.payload)
			};

		default:
			return state;
	}
};

export default DevicesReducer;
