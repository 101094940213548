import React, { useState, useEffect, useMemo } from "react";
import useStoreData from "../../hooks/useStoreData";
import * as p from "../../constants/Permissions";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import DisplayName from "../../utils/DisplayName";
import MemoContainer from "./MemoContainer";
import { fromToUrl, handleDate } from "@e4ia/e4link_modules.datetime";

export default function Memo({ homeId, userState, startDate, endDate }) {
	const { store } = useStoreData({ userState, homeId });
	const homeUnits = store.home.homeUnits;
	const seniorsFromHome = store.seniors.seniorsFromHome;
	const healthWorkers = store.users.healthWorkers;
	const canReadAllMemo = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_READ_ALL_MEMOT]);
	const [memoData, setMemoData] = useState([]);
	const [copied, setCopied] = useState(0);
	const userLocale = userState.userConnected.locale;

	const allMemos = useMemo(
		() =>
			memoData.map(function (memo) {
				return {
					...memo,
					healthworker: DisplayName(memo.health_worker_id, healthWorkers),
					homeUnit: DisplayName(memo.home_unit_id, homeUnits),
					senior: DisplayName(memo.senior_id, seniorsFromHome),
					day: handleDate(memo.date, "PP", userLocale),
					copy: copied === memo.id ? true : false
				};
			}),
		[memoData, healthWorkers, homeUnits, seniorsFromHome, copied, userLocale]
	);

	useEffect(() => {
		if (homeId && startDate && endDate) {
			const fromTo = fromToUrl(startDate, endDate);
			const getUrl = canReadAllMemo
				? `${url.HOMESURL}/${homeId}/memo_t_notes/${fromTo}`
				: `${url.BASEURL}/me/homes/${homeId}/memo_t_notes/${fromTo}`;
			http
				.get(getUrl)
				.then(res => setMemoData(res.data.memo_t_notes))
				.catch(() => setMemoData([]));
		}
	}, [homeId, copied, startDate, endDate, canReadAllMemo, healthWorkers, seniorsFromHome, homeUnits]);

	return <MemoContainer homeId={homeId} userState={userState} allMemo={allMemos} setCopied={setCopied} />;
}
