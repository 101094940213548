import React, { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import Toggle from "../../../utils/Toggle";
import * as url from "../../../constants/Url";
import http from "../../../services/Interceptor";
import { FaHandPointRight } from "react-icons/fa";
import HandleError from "../../../layout/HandleError";
import SensorPreferencesReducer from "./SensorPreferencesReducer";

export default function SensorPreferences({ homeId }) {
	const { t } = useTranslation("common");

	const initialState = {
		sensorPreference: false,
		error: false
	};

	const [preferences, dispatch] = useReducer(SensorPreferencesReducer, initialState);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/device_config`)
				.then(res => {
					dispatch({
						type: "GET_PREFERENCE",
						payload: res.data.DIAPER_BLE_SENSOR
					});
				})
				.catch(() => {
					dispatch({
						type: "GET_PREFERENCE",
						payload: null
					});
				});
		}
	}, [homeId]);

	const handleHomeSensor = preference => {
		const data = {
			config: {
				DIAPER_BLE_SENSOR: preference
			}
		};
		http
			.put(`${url.HOMESURL}/${homeId}/device_config`, data)
			.then(res => {
				dispatch({
					type: "GET_PREFERENCE",
					payload: res.data.DIAPER_BLE_SENSOR
				});
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<>
			<h2>{t("diaper.__connectedDiapers__")}</h2>
			<div className="header-details mb-4">
				<p>
					<FaHandPointRight className="mr-2 social-icon" />
					{t("diaper.__diaperSensorBlePref__")}
				</p>
			</div>
			<label>
				<b>{t("common.__aHome__")} :</b>
			</label>
			<HandleError error={preferences.error} dispatch={dispatch} message={t("common.__editError__")} noSpace />
			{preferences.sensorPreference !== null ? (
				<Toggle
					isToggle={preferences.sensorPreference}
					action={() => handleHomeSensor(!preferences.sensorPreference)}
					textActif={t("diaper.__homeBleActivate__")}
					textInactif={t("diaper.__homeBleDeactivate__")}
					meta={false}
				/>
			) : (
				<p className="meta">{t("common.__noDataAvailable__")}</p>
			)}
		</>
	);
}
