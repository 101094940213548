import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HeaderWithRangeContainer from "../../../layout/HeaderWithRangeContainer";

const TabDiapers = ({ saturationMode, userState, startDate, setStartDate, endDate, setEndDate, component }) => {
	const { t } = useTranslation("common");
	const userLocale = userState.userConnected.locale;

	return (
		<>
			<div className="nav nav-tabs">
				<li className="nav-item">
					<Link to="/support/admindiapers/saturation">
						<p className={saturationMode ? "nav-link active fake-link" : "nav-link fake-link"}>
							{saturationMode ? <b>{t("diaper.__saturation__")}</b> : <>{t("diaper.__saturation__")}</>}
						</p>
					</Link>
				</li>
				<li className="nav-item">
					<Link to="/support/admindiapers/change">
						<p className={!saturationMode ? "nav-link fake-link active" : "nav-link fake-link"}>
							{!saturationMode ? <b>{t("diaper.__change__")}</b> : <>{t("diaper.__change__")}</>}
						</p>
					</Link>
				</li>
			</div>
			<div className="big-card">
				<HeaderWithRangeContainer
					title={saturationMode ? t("diaper.__saturation__") : t("diaper.__change__")}
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
					userLocale={userLocale}
				/>
				{component}
			</div>
		</>
	);
};

export default TabDiapers;
