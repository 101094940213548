import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsXLg } from "react-icons/bs";

interface ConfirmationModalProps {
	show: boolean;
	onClose: () => void;
	onConfirm: () => void;
	message: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ show, onClose, onConfirm, message }) => {
	const [error, setError] = useState(false);
	const { t } = useTranslation("common");

	if (!show) {
		return null;
	}

	return (
		<div>
			<h2> {t("common.__confirmationRequired__")}</h2>
			<div className="modal-body">
				<p className="justified-text">{message}</p>
			</div>
			<div className="modal-footer">
				<button className="cancel-button" onClick={onClose}>
					{t("common.__cancel__")}
				</button>
				<button className="confirm-button" onClick={onConfirm}>
					{t("common.__confirm__")}
				</button>
			</div>
			{error ? (
				<p className="alert">
					<span className="right close-btn">
						<BsXLg style={{ verticalAlign: "middle" }} role="button" onClick={(): void => setError(false)} />
					</span>
					{t("common.__errorOccured__")}
				</p>
			) : null}
		</div>
	);
};

export default ConfirmationModal;
