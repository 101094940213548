import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaExclamationTriangle } from "react-icons/fa";
import SeniorSensorPreferenceContainer from "./SeniorSensorPreferenceContainer";

export default function SeniorSensorPreferences({ seniorsWithDiaper }) {
	const { t } = useTranslation("common");
	const seniorsWithSensor = seniorsWithDiaper.filter(senior => senior.sensor_id);
	const [filterSeniors, setFilterSeniors] = useState(true);
	const [seniorsToDisplay, setSeniorsToDisplay] = useState(seniorsWithSensor);
	const seniorsWithoutSensorInRoom = seniorsWithDiaper.filter(senior => !senior.sensor_id && senior.room);

	const handleSeniorFilter = filter => {
		if (filter === "WITH_SENSOR") {
			setFilterSeniors(true);
			setSeniorsToDisplay(seniorsWithSensor);
		} else {
			setFilterSeniors(false);
			setSeniorsToDisplay(seniorsWithoutSensorInRoom);
		}
	};

	return (
		<>
			<div className="light-separator"></div>
			<label className="mr-3">
				<b>{t("senior.__seniors__")} :</b>
			</label>
			<input type="checkbox" onChange={() => handleSeniorFilter("WITH_SENSOR")} checked={filterSeniors} />
			<span className="label-checkbox mr-4">{t("diaper.__withSensor__")}</span>
			<input type="checkbox" onChange={() => handleSeniorFilter("ALL")} checked={!filterSeniors} />
			<span className="label-checkbox">{t("diaper.__noSensor__")}</span>
			{seniorsToDisplay.length > 0 ? (
				<div className="mt-4">
					{seniorsToDisplay.map(senior =>
						senior.room ? (
							<SeniorSensorPreferenceContainer key={senior.id} senior={senior} />
						) : (
							<p className="mt-3 meta" key={senior.id}>
								<b>
									<FaExclamationTriangle className="mr-2" />
									{senior.name} : {t("home.__notInRoom__")}
								</b>
							</p>
						)
					)}
				</div>
			) : null}
		</>
	);
}
