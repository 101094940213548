import React, { useContext } from "react";
import { UserInterface } from "../interfaces/UserInterface";
import { CalendarTabs } from "../utils/Tabs/TabsToDisplay";
import { NotificationsContext } from "../context/NotificationsContext";
import Tabs from "../utils/Tabs/Tabs";
import AccessControl from "../layout/AccessControl";
import SelectHomeInput from "../layout/SelectHomeInput";
import EventsList from "../components/CalendarEntries/EventsList";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const CalendarEntries = ({ homeId, userState }: Props): JSX.Element => {
	const { eventsState, eventsDispatch } = useContext(NotificationsContext);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_CALENDAR]}>
			<>
				<Tabs tabsToDisplay={CalendarTabs} currentTab="MANAGE_EVENTS" />
				<div className="big-card">
					{homeId ? (
						<EventsList homeId={homeId} userState={userState} eventsState={eventsState} eventsDispatch={eventsDispatch} />
					) : (
						<SelectHomeInput />
					)}
				</div>
			</>
		</AccessControl>
	);
};
export default CalendarEntries;
