import React, { useEffect, useState } from "react";
import "./MainBoard.scss";
import MainBoardContainer from "./MainBoardContainer";
import MainBoardTableContainer from "./MainboardTableContainer";

export default function BoardFromHomeUnit({
	mainBoard,
	homeUnitId,
	view,
	show,
	setShow,
	showAddSeniorModal,
	setShowAddSeniorModal,
	homeId,
	dispatch,
	healthWorkers,
	notesShow,
	setNotesShow,
	userState,
	showDevice,
	setShowDevice,
	showNextVisio,
	setShowNextVisio,
	store
}) {
	const [boardFromHU, setBoardFromHU] = useState([]);

	useEffect(() => {
		const board = mainBoard.find(board => board.id === parseInt(homeUnitId));
		setBoardFromHU([board]);
		// eslint-disable-next-line
	}, [homeUnitId]);

	return view === "CARDS" ? (
		<MainBoardContainer
			mainBoard={boardFromHU}
			show={show}
			setShow={setShow}
			showAddSeniorModal={showAddSeniorModal}
			setShowAddSeniorModal={setShowAddSeniorModal}
			homeId={homeId}
			dispatch={dispatch}
			healthWorkers={healthWorkers}
			notesShow={notesShow}
			setNotesShow={setNotesShow}
			userState={userState}
			showDevice={showDevice}
			setShowDevice={setShowDevice}
			showNextVisio={showNextVisio}
			setShowNextVisio={setShowNextVisio}
			store={store}
		/>
	) : (
		<MainBoardTableContainer
			mainBoard={boardFromHU}
			show={show}
			setShow={setShow}
			homeUnitId={homeUnitId}
			showAddSeniorModal={showAddSeniorModal}
			setShowAddSeniorModal={setShowAddSeniorModal}
			homeId={homeId}
			store={store}
			dispatch={dispatch}
			notesShow={notesShow}
			setNotesShow={setNotesShow}
			userState={userState}
			showDevice={showDevice}
			setShowDevice={setShowDevice}
			showNextVisio={showNextVisio}
			setShowNextVisio={setShowNextVisio}
		/>
	);
}
