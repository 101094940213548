import React from "react";
import { useTranslation } from "react-i18next";
import { DeviceInterface } from "../../interfaces/HomeInterface";

interface Props {
	show: { show: boolean; info: DeviceInterface };
}

const DeviceDetails = ({ show }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const device = show.info;

	return (
		<div className="mt-3">
			<p>
				{t("common.__type__")} : <b>{device.serial_number_type}</b>
			</p>
			<p>
				{t("device.__serialNumber__")} : <b>{device.serial_number}</b>
			</p>
			<hr />
			<p>
				{t("common.__home__")} : {device.home}
			</p>
			{device.roomName ? (
				<p>
					{t("common.__room__")} : {device.roomName}
				</p>
			) : null}
		</div>
	);
};

export default DeviceDetails;
