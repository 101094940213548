import React, { useContext, useEffect } from "react";
import { NotificationsContext } from "../context/NotificationsContext";
import { UserInterface } from "../interfaces/UserInterface";
import { CalendarTabs } from "../utils/Tabs/TabsToDisplay";
import AccessControl from "../layout/AccessControl";
import AddEvent from "../components/CalendarEntries/AddEvent";
import Tabs from "../utils/Tabs/Tabs";
import SelectHomeInput from "../layout/SelectHomeInput";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const CalendarEntries_Add = ({ homeId, userState }: Props): JSX.Element => {
	const { eventsState, eventsDispatch } = useContext(NotificationsContext);

	useEffect(() => {
		return function cleanup(): void {
			eventsDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_CALENDAR]}>
			<Tabs tabsToDisplay={CalendarTabs} currentTab="MANAGE_EVENTS" />
			<div className="big-card">
				{homeId ? (
					<AddEvent userState={userState} homeId={homeId} eventsState={eventsState} eventsDispatch={eventsDispatch} />
				) : (
					<SelectHomeInput />
				)}
			</div>
		</AccessControl>
	);
};
export default CalendarEntries_Add;
