import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { HealthWorkerFormContext } from "../context/HealthWorkerFormContext";
import { HealthWorkersTabs } from "../utils/Tabs/TabsToDisplay";
import { UserInterface } from "../interfaces/UserInterface";
import { HomeStoreInterface } from "../interfaces/HomeInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import HeaderContainer from "../layout/HeaderContainer";
import AddHealthWorkerStepOne from "../components/HealthWorkers/AddHealthWorkerStepOne";
import AddHealthWorkerStepTwo from "../components/HealthWorkers/AddHealthWorkerStepTwo";
import AddHealthWorkerStepThree from "../components/HealthWorkers/AddHealthWorkerStepThree";
import AddHealthWorkerResume from "../components/HealthWorkers/AddHealthWorkerResume";
import StepContainer from "../utils/Stepper/StepContainer";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
	homeId: number;
	groupId: number | null;
	tenantId: number | null;
	homeStore: HomeStoreInterface;
}

const HealthWorkers_AddHealthWorker = ({ userState, homeId, groupId, tenantId, homeStore }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { step } = useParams<{ step: string }>();
	const { hwFormStore, hwFormDispatch } = useContext(HealthWorkerFormContext);
	const { store, dispatch } = useStoreData({ userState, homeId });
	const hwTypes = store.users.healthWorkerTypes;

	const steps = [
		{
			id: 0,
			step: "newuser",
			name: t("healthworker.__identity__"),
			link: "/user/adduser/newuser"
		},
		{
			id: 1,
			step: "role",
			name: t("permissions.__permissions__"),
			link: "/user/adduser/role"
		},
		{
			id: 2,
			step: "badge",
			name: t("badge.__badge__"),
			link: "/user/adduser/badge"
		},
		{
			id: 3,
			step: "resume",
			name: t("common.__validate__"),
			link: "/user/adduser/resume"
		}
	];

	const componentToDisplay = (step: string): JSX.Element => {
		switch (step) {
			case "newuser":
				return <AddHealthWorkerStepOne hwTypes={hwTypes} hwFormStore={hwFormStore} hwFormDispatch={hwFormDispatch} />;
			case "role":
				return (
					<AddHealthWorkerStepTwo
						userState={userState}
						homeId={homeId}
						groupId={groupId}
						tenantId={tenantId}
						hwFormStore={hwFormStore}
						hwFormDispatch={hwFormDispatch}
						homeStore={homeStore}
					/>
				);
			case "badge":
				return (
					<AddHealthWorkerStepThree
						hwFormStore={hwFormStore}
						hwFormDispatch={hwFormDispatch}
						userLocale={userState.userConnected.locale}
						store={store}
						dispatch={dispatch}
					/>
				);
			case "resume":
				return (
					<AddHealthWorkerResume
						hwFormStore={hwFormStore}
						hwFormDispatch={hwFormDispatch}
						hwTypes={hwTypes}
						dispatch={dispatch}
					/>
				);
			default:
				return <AddHealthWorkerStepOne hwTypes={hwTypes} hwFormStore={hwFormStore} hwFormDispatch={hwFormDispatch} />;
		}
	};

	useEffect(() => {
		return function cleanup(): void {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		return function cleanup(): void {
			hwFormDispatch({
				type: "EMPTY_NEW_USER_STATE"
			});
		};
	}, []);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]}>
			<Tabs tabsToDisplay={HealthWorkersTabs} currentTab={"HEALTHWORKERS"} />
			<div className="big-card">
				<HeaderContainer
					title={t("healthworker.__team__")}
					addButton={t("healthworker.__addAccount__")}
					link={"/user/adduser/newuser"}
					permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]}
					userState={userState}
				/>
				<StepContainer steps={steps} stepToDisplay={step} />

				{componentToDisplay(step)}
			</div>
		</AccessControl>
	);
};
export default HealthWorkers_AddHealthWorker;
