import React, { useEffect, useState } from "react";
import { UserInterface } from "../interfaces/UserInterface";
import { VisioTabs } from "../utils/Tabs/TabsToDisplay";
import Tabs from "../utils/Tabs/Tabs";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import AccessControl from "../layout/AccessControl";
import VisioTimingConstraintsUpdate from "../components/Home/Recipients/VisioTimingConstraintsUpdate";
import http from "../services/Interceptor";
import * as p from "../constants/Permissions";
import * as url from "../constants/Url";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_UpdateVisioConstraints = ({ homeId, userState }: Props): JSX.Element => {
	const [visioDescription, setVisioDescription] = useState(null);
	const [daysValues, setDaysValues] = useState([]);
	const [daysToDisplay, setDaysToDisplay] = useState([]);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/visio/validity`)
				.then(res => {
					setError(false);
					setVisioDescription(res.data.home_visio_validity.description ? res.data.home_visio_validity.description : " ");
					if (res.data.home_visio_validity.validity) {
						const data = res.data.home_visio_validity.validity.values;
						setDaysValues(data);
						setDaysToDisplay(data.map((elt: { day: number }) => elt.day));
					}
				})
				.catch(() => setError(true));
		}
	}, [homeId]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_HOME]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<HomesSecondaryNav userState={userState} />}
				component={
					<>
						<Tabs tabsToDisplay={VisioTabs} currentTab={"CONSTRAINTS"} />
						<VisioTimingConstraintsUpdate
							homeId={homeId}
							visioDescription={visioDescription}
							setVisioDescription={setVisioDescription}
							daysValues={daysValues}
							setDaysValues={setDaysValues}
							daysToDisplay={daysToDisplay}
							setDaysToDisplay={setDaysToDisplay}
							error={error}
							setError={setError}
						/>
					</>
				}
			/>
		</AccessControl>
	);
};
export default MyHome_UpdateVisioConstraints;
