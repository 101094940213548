import React, { useEffect } from "react";
import { SeniorTabs } from "../utils/Tabs/TabsToDisplay";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import SelectHomeInput from "../layout/SelectHomeInput";
import HandleSeniors from "../components/Seniors/HandleSeniors";
import Tabs from "../utils/Tabs/Tabs";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const Seniors = ({ homeId, userState }: Props): JSX.Element => {
	const { store, dispatch } = useStoreData({ userState, homeId });

	useEffect(() => {
		return function cleanup(): void {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Tabs tabsToDisplay={SeniorTabs} currentTab={"SENIORS"} />
			<div className="big-card">
				{homeId ? (
					<HandleSeniors store={store} dispatch={dispatch} homeId={homeId} userState={userState} />
				) : (
					<SelectHomeInput />
				)}
			</div>
		</>
	);
};
export default Seniors;
