import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

export default function AmdSeniorPartnerIdDissociation({ show, setShow }: { show: any; setShow: any }): JSX.Element {
	const { t } = useTranslation("common");
	const seniorId = show.info.id;
	const partnerId = show.info.partnerId;
	const [seniorPartnerId, setSeniorPartnerId] = useState(partnerId);
	const [error, setError] = useState(false);

	const onSubmit = (e: { preventDefault: () => void }): void => {
		e.preventDefault();
		setError(false);

		if (seniorId && seniorPartnerId) {
			const data = {
				senior_id: seniorId,
				partner_id: seniorPartnerId
			};
			http
				.delete(`${url.SENIORSURL}/amd_profile/partner_id/delete`, { data })
				.then(() => {
					setShow({ show: false, info: null, title: "", success: true });
				})
				.catch(() => {
					setError(true);
				});
		}
	};

	const handleCancel = (): void => {
		setShow({ show: false, info: null, title: "", success: false });
	};
	return (
		<form onSubmit={onSubmit}>
			{error ? (
				<p className="error-msg">
					<span className="right ml-2" role="button" onClick={(): void => setError(false)}>
						<FaTimes />
					</span>
					{t("common.__errorOccured__")}
				</p>
			) : null}
			<div className="mt-3">
				<div className="d-flex flex-column align-items-center">
					<div className="header-details">
						<p className="red">
							<b>{t("common.__caution__")} :</b>
						</p>
						<p>{t("common.__dissociateIdMessage__")}</p>
					</div>
					<div className="d-flex justify-content-end w-100">
						<button type="button" onClick={handleCancel} className="btn btn-sm btn-secondary pl-2 pr-2 ml-2 mr-2 mt-2">
							{t("common.__cancel__")}
						</button>
						<button type="submit" className="ConfirmationModal-btn btn btn-sm pl-2 pr-2 ml-2 mr-2 mt-2">
							{t("common.__confirm__")}
						</button>
					</div>
				</div>
			</div>
		</form>
	);
}
