import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DailyChangeVisualisationContainer } from "./DailyChangeVisualisationContainer";
import { FaAngleRight } from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

export function DailyChangeVisualisation({ diapers, selectedSenior, startDate, endDate, userLocale }) {
	const { t } = useTranslation("common");
	const [seniorStatistics, setSeniorStatistics] = useState([]);
	const [selectedSeniorProvision, setSelectedSeniorProvision] = useState([]);

	useEffect(() => {
		if (selectedSenior) {
			http
				.get(`${url.SENIORSURL}/${selectedSenior.value}/amd_profile/provisions`)
				.then(res => setSelectedSeniorProvision(res.data.provisions))
				.catch(() => setSelectedSeniorProvision({}));
		}
	}, [selectedSenior]);

	useEffect(() => {
		if (selectedSenior && startDate && endDate) {
			const startDateFormatted = handleDate(startDate, "yyyy-MM-dd 00:00:00");
			const endDateFormatted = handleDate(endDate, "yyyy-MM-dd 23:59:59");
			http
				.get(
					`${url.SENIORSURL}/${selectedSenior.value}/rht/all/from/${encodeURIComponent(
						startDateFormatted
					)}/to/${encodeURIComponent(endDateFormatted)}`
				)
				.then(res => setSeniorStatistics(res.data))
				.catch(() => setSeniorStatistics([]));
		}
	}, [selectedSenior, startDate, endDate]);

	const seniorProvision =
		selectedSeniorProvision.length > 0
			? selectedSeniorProvision.map(dotation => {
					return {
						...dotation,
						diaper: diapers.find(diaper => diaper.id === dotation.diaper_id),
						hour: parseInt(dotation.begin_time.split(":")[0])
					};
			  })
			: [];

	return selectedSeniorProvision.length > 0 ? (
		seniorStatistics.length > 0 ? (
			<div className="big-card mt-3 mb-3">
				<p>{selectedSenior.label} :</p>
				{seniorStatistics.map((stat, index) => (
					<div key={index} className="mt-3 mb-3">
						<span className="meta">
							<FaAngleRight className="mr-2" />
							<b>{handleDate(new Date(stat.date), "PPPP", userLocale)} :</b>
						</span>
						{stat.values.length > 0 ? (
							<DailyChangeVisualisationContainer seniorProvision={seniorProvision} eventsList={stat.values} />
						) : (
							<span className="ml-2 meta">{t("common.__noActionRegistred__")}</span>
						)}
					</div>
				))}
			</div>
		) : null
	) : null;
}
