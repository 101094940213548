import { subDays } from "date-fns";
import React from "react";
import DatePicker from "react-datepicker";
import HandleLocale from "../HandleLocale";

interface Props {
	userLocale: string;
	startDate: Date;
	setStartDate?: (date: Date) => void;
	dispatch?: React.Dispatch<any>;
	type?: string;
	onDateChange?: (date: Date) => void;
}

const OnlyDatePicker = ({ startDate, setStartDate, userLocale, dispatch, type, onDateChange }: Props): JSX.Element => {
	const locale = HandleLocale(userLocale);

	const handleChange = (date: Date): any => {
		if (onDateChange) {
			onDateChange(date);
		} else if (setStartDate) {
			setStartDate(date);
		} else if (dispatch) {
			if (type === "END") {
				dispatch({ type: "END_DATE", payload: date });
			} else {
				dispatch({ type: "START_DATE", payload: date });
			}
		}
	};
	const yesterday = subDays(new Date(), 1);
	const today = new Date();

	let maxDate = today;
	const minDate = null;

	if (type === "START") {
		maxDate = yesterday;
	} else if (type === "END") {
		maxDate = today;
	}

	return (
		<div className="mb-3">
			<DatePicker
				selected={startDate}
				onChange={handleChange}
				locale={locale}
				dateFormat="PPPP"
				className="form-control form-control-sm"
				maxDate={maxDate}
				minDate={minDate}
			/>
		</div>
	);
};
export default OnlyDatePicker;
