export const fileSize = size => {
	if (size === 0) return "0 Bytes";
	const k = 1024;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	const i = Math.floor(Math.log(size) / Math.log(k));
	return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const onUploadFiles = (e, medias, setMedias, uploadSize, setUploadSize) => {
	if (e.target.files && e.target.files.length > 0) {
		let newFiles = [...e.target.files];
		const allFiles = [...medias, ...newFiles];
		allFiles.map(file => {
			return (file["preview"] = URL.createObjectURL(file));
		});
		setMedias(allFiles);
		const newFilesSizes = newFiles.map(file => file.size);
		const newUploadSize = uploadSize + newFilesSizes.reduce((a, b) => a + b, 0);
		setUploadSize(newUploadSize);
	}
};

export const onUploadVideo = (e, medias, setMedias, uploadSize, setUploadSize) => {
	if (e.target.files && e.target.files.length > 0) {
		setMedias([...medias, e.target.files[0]]);
		const videoUploadedSize = e.target.files[0].size;
		setUploadSize(videoUploadedSize + uploadSize);
	}
};

export const removeVideo = (name, files, videoFiles, pdfFiles, setUploadSize, setVideoFiles) => {
	let newVideoList = videoFiles.filter(f => f.name !== name);
	setVideoFiles(newVideoList);
	let allFiles = [...files, ...newVideoList, ...pdfFiles];
	let sizes = allFiles.map(file => file.size);
	setUploadSize(sizes.length > 0 ? sizes.reduce((sum, current) => sum + current) : 0);
};

export const removeFile = (name, files, videoFiles, pdfFiles, setUploadSize, setFiles) => {
	let newFileList = files.filter(f => f.name !== name);
	setFiles(newFileList);
	let allFiles = [...newFileList, ...videoFiles, ...pdfFiles];
	let sizes = allFiles.map(file => file.size);
	setUploadSize(sizes.length > 0 ? sizes.reduce((sum, current) => sum + current) : 0);
};

export const removePdfFile = (name, files, videoFiles, pdfFiles, setUploadSize, setPdfFiles) => {
	let newPdfList = pdfFiles.filter(f => f.name !== name);
	setPdfFiles(newPdfList);
	let allFiles = [...files, ...videoFiles, ...newPdfList];
	let sizes = allFiles.map(file => file.size);
	setUploadSize(sizes.length > 0 ? sizes.reduce((sum, current) => sum + current) : 0);
};
