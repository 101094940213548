import React, { createContext, useReducer } from "react";
import NotificationsReducer from "./reducers/NotificationsReducer";
import EventReducer from "./reducers/EventReducer";
import MessagesReducer from "./reducers/MessagesReducer";

const today = new Date();

const initialNotificationsState = {
	avatarModeration: 0,
	lifeStoryModeration: 0,
	picturesModeration: 0,
	blogModeration: 0,
	moderationCounter: 0,
	messageCounter: 0,
	moderationError: false,
	moderationSuccess: false
};

const initialEventsState = {
	events: [],
	error: false,
	success: false,
	loading: false,
	msg: null,
	startDate: new Date(),
	endDate: new Date(today.setMonth(today.getMonth() + 6))
};

const initialMsgState = {
	messages: [],
	error: false,
	success: false,
	loading: false,
	msg: null,
	startDate: null,
	endDate: null,
	filter: "all"
};

export const NotificationsContext = createContext();

export const NotificationsContextProvider = ({ children }) => {
	const [notificationsState, notificationsDispatch] = useReducer(NotificationsReducer, initialNotificationsState);
	const [eventsState, eventsDispatch] = useReducer(EventReducer, initialEventsState);
	const [msgState, msgDispatch] = useReducer(MessagesReducer, initialMsgState);

	return (
		<NotificationsContext.Provider
			value={{
				notificationsState,
				notificationsDispatch,
				eventsState,
				eventsDispatch,
				msgState,
				msgDispatch
			}}>
			{children}
		</NotificationsContext.Provider>
	);
};

export const NotificationsContextConsumer = NotificationsContext.Consumer;
