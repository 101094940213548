import React from "react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";

export default function PieChartContainer({
	data,
	dataKey,
	height,
	showPercentage = false,
	innerRadius = 45,
	outerRadius = 85,
	paddingAngle = 1,
	isAnimationActive = true
}) {
	const COLORS = ["#50c878", "#fa5456", "#00a0e3", "#ffae42"];

	const renderCustomLabel = ({ value }) => {
		let returnValue = null;
		if (value > 0) {
			returnValue = showPercentage ? `${value}%` : value;
		}
		return returnValue;
	};

	const CustomLabelLine = props => {
		const { cx, cy, midAngle, outerRadius, value } = props;

		const isSpecial = value === 0;

		const RADIAN = Math.PI / 180;
		const radius = outerRadius + 20;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		const startRadius = outerRadius;
		const startX = cx + startRadius * Math.cos(-midAngle * RADIAN);
		const startY = cy + startRadius * Math.sin(-midAngle * RADIAN);

		return (
			<g>
				<path
					d={`M${startX},${startY}L${x},${y}`}
					stroke={isSpecial ? null : props.payload.color || COLORS[props.index % COLORS.length]}
					fill="none"
				/>
			</g>
		);
	};

	return (
		<ResponsiveContainer width={"100%"} height={height} wrapperStyle={{ width: "100%" }}>
			<PieChart>
				<Legend verticalAlign="top" />
				<Pie
					data={data}
					dataKey={dataKey}
					innerRadius={innerRadius}
					outerRadius={outerRadius}
					paddingAngle={paddingAngle}
					minAngle={6}
					label={renderCustomLabel}
					isAnimationActive={isAnimationActive}
					labelLine={<CustomLabelLine />}>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={entry.color || COLORS[index % COLORS.length]} />
					))}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
}
