import React from "react";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import AddRoom from "../components/Home/Rooms/AddRoom";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_AddRoom = ({ homeId, userState }: Props): JSX.Element => {
	const { store, dispatch } = useStoreData({ userState, homeId });

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<HomesSecondaryNav userState={userState} />}
				component={<AddRoom store={store} homeId={homeId} dispatch={dispatch} userState={userState} />}
			/>
		</AccessControl>
	);
};
export default MyHome_AddRoom;
