import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import MyInputNumber from "../../utils/Form/MyInputNumber";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function AddHealtWorkerTypeBudget({ setUpdate, homeId, hwType, setHttpResponse }) {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const [calculError, setCalculError] = useState(false);
	const [hwBudget, setHwBudget] = useState(false);

	const onSubmit = data => {
		setHttpResponse(null);
		if (parseInt(data.care) + parseInt(data.dependency) + parseInt(data.accomodation) === 100) {
			setCalculError(false);
			const budgetData = {
				health_worker_budget_distribution: {
					home_id: homeId,
					health_worker_type_id: hwType.id,
					care: data.care,
					dependency: data.dependency,
					accomodation: data.accomodation
				}
			};
			http
				.post(`${url.BASEURL}/health_worker_budget_distributions`, budgetData)
				.then(() => {
					setUpdate(prevUpdate => !prevUpdate);
					setHttpResponse({ error: false });
				})
				.catch(() => setHttpResponse({ error: true }));
		} else {
			setCalculError(true);
		}
	};

	return (
		<div className="row">
			<div className="col-3">
				<p>
					<b>{hwType.name}</b>
				</p>
			</div>
			<div className="col-9">
				{hwBudget ? (
					<FormProvider {...methods}>
						<form onSubmit={handleSubmit(onSubmit)}>
							{calculError ? (
								<p className="error-msg">
									{t("hds.__caution100__")}
									<span className="right" role="button" tabIndex="0" onClick={() => setCalculError(false)}>
										<FaTimes />
									</span>
								</p>
							) : null}
							<div className="row">
								<div className="col-md-3">
									<MyInputNumber
										id={`accomodation${hwType.id}`}
										name="accomodation"
										behavior={{ required: false, maxLength: 3, pattern: /[0-9]/ }}
									/>
								</div>
								<div className="col-md-3">
									<MyInputNumber
										id={`dependency${hwType.id}`}
										name="dependency"
										behavior={{ required: false, maxLength: 3, pattern: /[0-9]/ }}
									/>
								</div>
								<div className="col-md-3">
									<MyInputNumber
										id={`care${hwType.id}`}
										name="care"
										behavior={{ required: false, maxLength: 3, pattern: /[0-9]/ }}
									/>
								</div>
								<div className="col-md-3">
									<button className="btn btn-sm btn-outline-secondary" type="submit">
										{t("common.__save__")}
									</button>
								</div>
							</div>
						</form>
					</FormProvider>
				) : (
					<button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => setHwBudget(true)}>
						{t("hds.__defineHds__")}
					</button>
				)}
			</div>
		</div>
	);
}
