import React from "react";
import { useTranslation } from "react-i18next";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

export default function DiaperChangeBarChart({ data }) {
	const { t } = useTranslation("common");

	return data.length > 0 ? (
		<div style={{ width: "100%", height: 325 }}>
			<ResponsiveContainer>
				<BarChart data={data} margin={{ top: 10, right: 5, left: 5, bottom: 10 }}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="formatedDate" />
					<YAxis />
					<Tooltip />
					<Bar
						dataKey="count"
						barSize={25}
						stroke="#00a0e3"
						fill="#00a0e3"
						fillOpacity={0.8}
						radius={[5, 5, 0, 0]}
						name={t("diaper.__dirtyBed__")}
					/>
				</BarChart>
			</ResponsiveContainer>
		</div>
	) : null;
}
