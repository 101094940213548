import React from "react";
import { useTranslation } from "react-i18next";
import HandleError from "../../layout/HandleError";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function RemoveSeniorConfirmation({ show, setShow, dispatch, store }) {
	const { t } = useTranslation("common");
	const room = show.info.room;
	const removeSeniorFromRoom = roomId => {
		const roomData = {
			room: {
				senior_id: null,
				id: roomId
			}
		};
		http
			.put(`${url.BASEURL}/rooms/${roomId}`, roomData)
			.then(() => {
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("mainboard.__roomIsFree__", { room: room.name })
				});
			})
			.then(() => setShow({ show: false, info: { room: room }, title: "", success: true }))
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<>
			<p className="meta">{t("mainboard.__removeSeniorFromRoomInfo__")}</p>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__errorOccured__")} />
			<h5 className="mt-3 mb-4 blue">
				{t("common.__senior__")} : {room.senior.last_name} {room.senior.first_name} ({t("common.__room__")} {room.name})
			</h5>

			<button
				className="btn btn-sm btn-outline-secondary mr-2"
				type="button"
				onClick={() => setShow({ show: false, info: null, title: "", success: false })}>
				{t("common.__cancel__")}
			</button>
			<button className="btn btn-sm btn-primary" type="button" onClick={() => removeSeniorFromRoom(room.id)}>
				{t("common.__validate__")}
			</button>
		</>
	);
}
