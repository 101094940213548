const SeniorsReducer = (state, action) => {
	switch (action.type) {
		case "GET_SENIORS": {
			const data = action.payload;
			const sortedSeniors = data.length > 0 ? data.sort((a, b) => a.last_name.localeCompare(b.last_name)) : [];
			const seniors =
				sortedSeniors.length > 0
					? sortedSeniors.map(function (senior) {
							return {
								...senior,
								room: senior.room ? senior.room.name : null,
								homeUnitId: senior.room ? (senior.room.home_unit_id ? senior.room.home_unit_id : null) : null,
								value: senior.id,
								label: `${senior.last_name.toUpperCase()} ${senior.first_name}`,
								name: `${senior.last_name.toUpperCase()} ${senior.first_name}`,
								deviceId: senior.room ? senior.room.device_id : null
							};
					  })
					: [];
			return {
				...state,
				seniorsFromHome: seniors
			};
		}

		case "ADD_SENIOR": {
			const senior = action.payload;
			const newSenior = {
				...senior,
				name: `${senior.last_name.toUpperCase()} ${senior.first_name}`,
				room: senior.room ? senior.room.name : null,
				homeUnitId: senior.room ? (senior.room.home_unit_id ? senior.room.home_unit_id : null) : null,
				value: senior.id,
				label: `${senior.last_name.toUpperCase()} ${senior.first_name}`
			};
			const seniors = [...state.seniorsFromHome, newSenior];
			const sortedSeniors = seniors.sort((a, b) => a.last_name.localeCompare(b.last_name));
			return {
				...state,
				seniorsFromHome: sortedSeniors
			};
		}

		case "EDIT_SENIOR": {
			const updatedSenior = action.payload;
			const seniors = state.seniorsFromHome;
			const seniorsUpdated = seniors.map(senior => {
				if (senior.id === updatedSenior.id) {
					const mySenior = {
						...updatedSenior,
						name: `${updatedSenior.last_name.toUpperCase()} ${updatedSenior.first_name}`,
						room: senior.room ? senior.room.name : null,
						homeUnitId: senior.room ? (senior.room.home_unit_id ? senior.room.home_unit_id : null) : null,
						value: senior.id,
						label: `${senior.last_name.toUpperCase()} ${senior.first_name}`
					};
					return mySenior;
				}
				return senior;
			});
			const sortedSeniors = seniorsUpdated.sort((a, b) => a.last_name.localeCompare(b.last_name));
			return {
				...state,
				seniorsFromHome: sortedSeniors
			};
		}

		case "GET_FAMILIES": {
			const response = action.payload;
			const families = response.map(function (family) {
				return {
					...family,
					name: `${family.first_name.toUpperCase()} ${family.last_name}`
				};
			});
			return {
				...state,
				families: families
			};
		}
		case "LOGOUT_SENIORS":
			return {
				seniorsFromHome: []
			};
		default:
			return state;
	}
};

export default SeniorsReducer;
