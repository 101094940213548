import React, { useEffect } from "react";
import { UserInterface } from "../interfaces/UserInterface";
import { HomeInterface, HomeStoreInterface } from "../interfaces/HomeInterface";
import AccessControl from "../layout/AccessControl";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AllHomesList from "../components/Home/Homes/AllHomesList";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";
import { useQuery } from "react-query";
import Loader from "../layout/Loader";
import NoData from "../layout/NoData";

interface Props {
	userState: UserInterface;
	setHomeId: (homeId: number) => void;
	setGroupId: (groupId: number) => void;
	homeStore: HomeStoreInterface;
	homeDispatch: React.Dispatch<any>;
	groupId: number | null;
}

const AllHomes = ({ userState, groupId, setHomeId, setGroupId, homeStore, homeDispatch }: Props): JSX.Element => {
	const { isLoading, data } = useQuery("all_homes", () =>
		http.get(`${url.BASEURL}/homes?devices=true&users=true&seniors=true`).then(res => res.data)
	);
	const allHomes = data ? data.homes : [];
	const homes =
		allHomes.length > 0 ? (groupId ? allHomes.filter((home: HomeInterface) => home.group_id === groupId) : allHomes) : [];

	useEffect(() => {
		return function cleanup(): void {
			homeDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.ADMIN, p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={
					isLoading ? (
						<Loader />
					) : homes.length > 0 ? (
						<AllHomesList
							setGroupId={setGroupId}
							homes={homes}
							homeStore={homeStore}
							homeDispatch={homeDispatch}
							setHomeId={setHomeId}
						/>
					) : (
						<NoData />
					)
				}
			/>
		</AccessControl>
	);
};
export default AllHomes;
