import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import HandleSuccess from "../../../layout/HandleSuccess";
import HeaderContainer from "../../../layout/HeaderContainer";
import HomeModerationSettings from "./HomeModerationSettings";
import HomeCounters from "../../HomeManagerBoard/HomeCounters";
import http from "../../../services/Interceptor";
import * as constant from "../../../constants/Url";
import * as p from "../../../constants/Permissions";

export default function HomeDetails({ homeId, groupId, tenantId, homeStore, homeDispatch, userState }) {
	const { t } = useTranslation("common");
	const myHome = homeStore.home.myHome;
	const [uploadLogo, setUploadLogo] = useState(null);
	const [fileName, setFileName] = useState(null);
	const [httpResponse, setHttpResponse] = useState(null);
	const [logo, setLogo] = useState(false);
	const canUpdateHomeSettings = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_HOME]);
	const isSuperAdmin = userState.userConnected.is(p.SUPER_ADMIN);

	/** Test if a logo is in the database **/
	useEffect(() => {
		let mounted = true;
		if (homeId && mounted) {
			http
				.get(`/storages/homes/${homeId}/logo`)
				.then(() => setLogo(true))
				.catch(() => setLogo(false));
		}
		return () => (mounted = false);
		// eslint-disable-next-line
	}, [homeId]);

	const displayLogo = (logo, homeId) => {
		if (logo) {
			if (fileName === null) {
				return (
					<>
						<img src={`/storages/homes/${homeId}/logo`} alt="logo" className="home-logo img-fluid" />
						{canUpdateHomeSettings ? (
							<p className="small-meta center">
								<label className="add-new-logo" htmlFor="logo" type="file">
									+ {t("home.__newLogo__")}
								</label>
							</p>
						) : null}
					</>
				);
			} else {
				return (
					<div className="bg-home-logo">
						<div className="center">
							<div className="add-home-logo">
								{fileName}
								<button className="btn btn-sm btn-primary" type="submit">
									{t("common.__send__")}
								</button>
							</div>
						</div>
					</div>
				);
			}
		} else if (!logo) {
			if (fileName) {
				return (
					<div className="bg-home-logo">
						<div className="center">
							<div className="add-home-logo">
								{fileName}
								<button className="btn btn-sm btn-primary" type="submit">
									{t("common.__send__")}
								</button>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<label htmlFor="logo" type="file">
						<div className="bg-home-logo">
							<div className="add-home-logo">{t("home.__addLogo__")}</div>
						</div>
					</label>
				);
			}
		}
	};

	/** Handle Logo uploaded **/
	const onSelectFile = e => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.readAsDataURL(e.target.files[0]);
			setUploadLogo(e.target.files[0]);
			setFileName(e.target.files[0].name);
		}
	};

	/** Send Logo to the Database **/
	const onSubmit = e => {
		e.preventDefault();
		const logo = new FormData();
		logo.append("logo", uploadLogo);

		http
			.post(`${constant.UPLOAD_URL}/${homeId}/logo`, logo)
			.then(() => {
				setFileName(null);
				setLogo(true);
				setHttpResponse({ error: false });
			})
			.catch(() => {
				setHttpResponse({ error: true });
			});

		http.post(`${constant.UPLOAD_URL}/${homeId}/device/logo`, logo);
	};

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("common.__home__")}
				addButton={t("home.__updateHome__")}
				link={"/manage/me/updatehome"}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_HOME]}
				userState={userState}
			/>

			<div className="mt-4">
				<HandleSuccess success={homeStore.es.success} dispatch={homeDispatch} message={homeStore.es.msg} />

				{httpResponse ? (
					httpResponse.error ? (
						<p className="error-msg">{t("home.__errorLogo__")}</p>
					) : (
						<p className="success">{t("home.__successLogo__")}</p>
					)
				) : null}
				{myHome ? (
					<>
						<div className="row">
							<div className="col-md-4 center">
								<form onSubmit={onSubmit} encType="multipart/form-data">
									<input
										id="logo"
										type="file"
										name="file"
										accept="image/*"
										onChange={onSelectFile}
										style={{ display: "none" }}
									/>
									{displayLogo(logo, myHome.id)}
								</form>
							</div>
							<div className="col-md-8">
								<h5>{myHome.name}</h5>
								<p>{myHome.address}</p>
								<p className="meta">
									{t("common.__phone__")} : {myHome.phone}
								</p>
								<p className="meta">
									{t("home.__website__")} : {myHome.url}
								</p>
							</div>
						</div>
						<HomeCounters
							homeId={homeId}
							userState={userState}
							groupId={groupId}
							tenantId={tenantId}
							isSuperAdmin={isSuperAdmin}
						/>
						{canUpdateHomeSettings ? (
							<HomeModerationSettings homeStore={homeStore} homeDispatch={homeDispatch} myHome={myHome} />
						) : null}
					</>
				) : null}
			</div>
		</div>
	);
}
