import React from "react";
import HealthWorkerTypeBudget from "../components/Budget/HealtWorkerTypeBudget";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const HealthWorkerTypeBudgetRepartition = ({ homeId, userState }: Props): JSX.Element => {
	const { store } = useStoreData({ userState, homeId });
	const healthWorkerType = store.users.healthWorkerTypes;

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.HW_BUDGET_DISTRIBUTION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<HomesSecondaryNav userState={userState} />}
				component={<HealthWorkerTypeBudget healthWorkerType={healthWorkerType} homeId={homeId} />}
			/>
		</AccessControl>
	);
};
export default HealthWorkerTypeBudgetRepartition;
