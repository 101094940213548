import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export const checkNotification = (id: number, notificationsDispatch: React.Dispatch<any>): void => {
	http
		.put(`${url.BASEURL}/notifications/${id}/check`)
		.then(res => {
			notificationsDispatch({
				type: "EDIT_NOTIFICATION",
				payload: res.data.notification
			});
		})
		.catch(() => {
			notificationsDispatch({
				type: "ERROR"
			});
		});
};
