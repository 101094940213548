import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import MyInputTextarea from "../../../utils/Form/MyInputTextarea";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import HandleError from "../../../layout/HandleError";

export default function ResponseForm({ msgId, responseMode, setResponseMode }) {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const [error, setError] = useState(false);

	const onSubmit = values => {
		setError(false);
		const data = {
			message_id: msgId,
			message: values.message
		};
		http
			.post(`${url.HOMESURL}/controlpanel/messages/reply_to`, data)
			.then(() => setResponseMode(false))
			.catch(() => setError(true));
	};

	return responseMode ? (
		<FormProvider {...methods}>
			<HandleError error={error} setError={setError} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<MyInputTextarea label={t("mailbox.__answer__")} name="message" behavior={{ required: true }} rows="5" />
				<button type="submit" className="btn btn-primary btn-sm mt-3">
					{t("common.__send__")}
				</button>
				<div className="space"></div>
			</form>
		</FormProvider>
	) : null;
}
