import React from "react";
import { useTranslation } from "react-i18next";
import { FaNotesMedical } from "react-icons/fa";
import { SeniorInterface } from "../../interfaces/UserInterface";
import { Tooltip } from "./Tooltip";

interface Props {
	senior: SeniorInterface;
	setNotesShow: (data: { show: boolean; info: { senior: SeniorInterface }; title: string }) => void;
}

const SeniorMemoIcon = ({ senior, setNotesShow }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<Tooltip text={t("memo.__memo__")}>
			<FaNotesMedical
				className="board-card-icon"
				role="button"
				onClick={(): void => setNotesShow({ show: true, info: { senior: senior }, title: senior.label })}
			/>
		</Tooltip>
	);
};
export default SeniorMemoIcon;
