const MainBoardReducer = (state, action) => {
	switch (action.type) {
		case "GET_DATA":
			return {
				...state,
				loading: false,
				error: false,
				data: action.payload
			};
		case "LOADING":
			return {
				...state,
				loading: true,
				error: false
			};
		case "DISPLAY_ERROR":
			return {
				...state,
				error: true,
				loading: false
			};
		case "SELECT_VIEW":
			return {
				...state,
				view: action.payload
			};
		case "SELECT_HOME_UNIT":
			return {
				...state,
				homeUnitId: action.payload
			};
		default:
			return state;
	}
};

export default MainBoardReducer;
