import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaTimes, FaRegEnvelope } from "react-icons/fa";
import http from "../../../services/Interceptor";
import IncoRecipientsAddUser from "./IncoRecipientsAddUser";
import * as url from "../../../constants/Url";
import HandleError from "../../../layout/HandleError";

export default function IncoRecipients({
	homeId,
	healthworkers,
	isAdmin,
	recipientsIds,
	setRecipientsListUpdated,
	recipients,
	addUser,
	setAddUser
}) {
	const [error, setError] = useState(false);
	const removeRecipient = id => {
		const newRecipientsList = recipientsIds.filter(recipientId => recipientId !== id);
		const data = {
			user_ids: newRecipientsList
		};
		setError(false);
		http
			.put(`${url.HOMESURL}/${homeId}/rht/saturation/notification/recipients`, data)
			.then(() => setRecipientsListUpdated(prevState => !prevState))
			.catch(() => setError(true));
	};

	return (
		<div className="mt-3">
			<HandleError error={error} setError={setError} noSpace />

			{addUser ? (
				<IncoRecipientsAddUser
					setRecipientsListUpdated={setRecipientsListUpdated}
					setAddUser={setAddUser}
					homeId={homeId}
					healthworkers={healthworkers}
					recipients={recipients}
					recipientsIds={recipientsIds}
				/>
			) : (
				<ul className="small-list my-3">
					{recipients.map(recipient => (
						<li key={recipient.id} className="d-block">
							{isAdmin ? (
								<span className="recipient-badge">
									<b className="mr-3">
										<FaRegEnvelope className="mr-3" />
										{recipient.last_name.toUpperCase()} {recipient.first_name === "badge_general" ? null : recipient.first_name}
									</b>
									( {recipient.email} )
									<span className="table-icon right" onClick={() => removeRecipient(recipient.id)}>
										<FaTimes />
									</span>
								</span>
							) : (
								<Link to={`/user/healthworker/${recipient.id}`}>
									<span className="recipient-badge">
										<b className="mr-3">
											<FaRegEnvelope className="mr-2" />
											{recipient.last_name.toUpperCase()} {recipient.first_name === "badge_general" ? null : recipient.first_name}
										</b>
										( {recipient.email} )
									</span>
								</Link>
							)}
						</li>
					))}
				</ul>
			)}
		</div>
	);
}
