import React, { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { NewSeniorReducer, newSeniorState } from "../../../context/reducers/NewSeniorReducer";
import CreateFamilyUsersContainer from "./CreateFamilyUsersContainer";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { FamilyInterface } from "../../../interfaces/UserInterface";

interface Props {
	addFamily: { show: boolean; info: null | number };
	setAddFamily: React.SetStateAction<any>;
	isModal: boolean;
	newSeniorDispatch: React.Dispatch<any>;
	display:boolean;
}

const AddFamilyUser = ({ addFamily, setAddFamily, isModal, newSeniorDispatch, display }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const seniorId = addFamily.info;
	const [familyUsersStore, familyUsersDispatch] = useReducer(NewSeniorReducer, newSeniorState);

	useEffect(() => {
		return function cleanup() {
			familyUsersDispatch({
				type: "RESET_ES"
			});
		};
	}, []);

	const onSubmit = (): void => {
		familyUsersDispatch({
			type: "LOADING"
		});
		const existingFamiliesSelected = familyUsersStore.existingFamilyUserIds.map((familyId: number) => ({
			family_user_id: familyId
		}));
		const newFamiliesAdded = familyUsersStore.newFamilyUsers.map((family: FamilyInterface) => ({ family_user: family }));
		const familyData = {
			senior_id: seniorId,
			family_users: existingFamiliesSelected.concat(newFamiliesAdded)
		};
		http
			.put(`${url.SENIORSURL}/${seniorId}`, familyData)
			.then(() => setAddFamily({ show: false, info: null }))
			.catch(err => {
				familyUsersDispatch({
					type: "ERROR",
					payload:
						err.response.request.responseText === JSON.stringify({ errors: { login: ["has already been taken"] } })
							? t("senior.__addSeniorLoginError__")
							: err.response.request.responseText === JSON.stringify({ errors: { email: ["has already been taken"] } })
							? t("family.__userAlreadyExist__")
							: err.response.request.responseText === JSON.stringify({ errors: { detail: "__TOO_MUCH_PRINCIPAL_HELPER__" } })
							? t("family.__too_much_principal_helper__")
							: t("senior.__addSeniorError__")
				});
			});
	};

	return (
		<>
			<CreateFamilyUsersContainer
				newFamilyStore={familyUsersStore}
				newFamilyDispatch={familyUsersDispatch}
				isModal={isModal}
				onSubmit={onSubmit}
				newSeniorDispatch={newSeniorDispatch}
				display={display}

			/>
		</>
	);
};

export default AddFamilyUser;
