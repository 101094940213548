import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { FaSignOutAlt, FaBars, FaRegUserCircle } from "react-icons/fa";
import useStoreData from "../hooks/useStoreData";
import SocketComponent from "../components/AlertBoard/SocketComponent";
import MainNav from "./MainNav";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import "./Layout.scss";

export default function Header({ userState, homeId, userDispatch, setHomeId, setGroupId, homeDispatch, socket }) {
	// const location = window.location.href;
	// const prod = location.includes("localhost");
	const history = useHistory();
	const [mainResponsiveMenu, setMainResponsiveMenu] = useState(false);
	const { dispatch } = useStoreData({ userState, homeId });

	const handleLogout = () => {
		sessionStorage.clear();
		localStorage.clear();
		setHomeId(null);
		setGroupId(null);
		userDispatch({
			type: "LOGOUT"
		});
		http.post(`${url.BASEURL}/logout`).then(() => {
			homeDispatch({
				type: "LOGOUT"
			});
			dispatch({
				type: "LOGOUT_SENIORS"
			});
			dispatch({
				type: "LOGOUT_HW"
			});
			dispatch({
				type: "LOGOUT_ROOMS_UNITS"
			});
			history.push("/");
		});
	};

	const handleLogoLink = () => {
		if (userState.userConnected) {
			history.push("/myaccount");
		} else history.push("/");
	};

	return (
		<>
			{/* {prod ? <div className="prod center"></div> : null} */}
			<header>
				<div className="d-flex justify-content-between">
					<div className="navbar-brand">
						{process.env.REACT_APP_EFORLINK_LOGO === "true" ? (
							<img src="/img/Logo-eforlink.png" alt="e4link" className="logo" onClick={handleLogoLink} />
						) : (
							<img src="/img/amd/amdlink.png" alt="e4link" className="amd-logo" onClick={handleLogoLink} />
						)}
					</div>
					<div className="d-flex flex-row-reverse mr-2 mt-3">
						<div
							className="responsive-menu-button"
							onClick={() => setMainResponsiveMenu(prevMainResponsiveMenu => !prevMainResponsiveMenu)}>
							<div className="nav-header">
								<FaBars className="nav-icon" />
							</div>
						</div>
						<div className="nav-header">
							<FaSignOutAlt className="nav-icon" id="logout" onClick={handleLogout} />
						</div>
						<SocketComponent userState={userState} homeId={homeId} socket={socket} />
						<div className="nav-header">
							<Link to="/myaccount">
								<FaRegUserCircle className="nav-icon" />
							</Link>
						</div>
					</div>
				</div>
			</header>
			{mainResponsiveMenu ? (
				<div className="responsive-nav">
					<MainNav
						userState={userState}
						mainResponsiveMenu={mainResponsiveMenu}
						setMainResponsiveMenu={setMainResponsiveMenu}
					/>
				</div>
			) : null}
		</>
	);
}
