import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table, SelectColumnFilter } from "../../../utils/Table/TableWithFilter";
import { FaRegEdit, FaAngleRight, FaRegTimesCircle, FaUserPlus } from "react-icons/fa";
import SelectHomeInput from "../../../layout/SelectHomeInput";
import DisplayName from "../../../utils/DisplayName";
import DeviceIcon from "../../Buttons/DeviceIcon";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import HandleError from "../../../layout/HandleError";
import ModalContainer from "../../../utils/ModalContainer";
import AddSeniorInARoom from "../../UserBoards/AddSeniorInARoom";
import HandleDeviceInRoom from "../../Devices/HandleDeviceInRoom";
import { Tooltip } from "../../Buttons/Tooltip";
import { sortAlphabetically } from "../../../utils/sort/AlphabeticOrderSort.tsx";

const RoomsFromHome = ({ homeId, store, dispatch, canManageHomeUnit }) => {
	const { t } = useTranslation("common");
	const roomsFromHome = store.home.rooms;
	const homeUnits = store.home.homeUnits;
	const seniorsFromHome = store.seniors.seniorsFromHome;
	const [showDevice, setShowDevice] = useState({
		show: false,
		info: null,
		title: t("common.__room__"),
		success: false
	});

	const [showAddSeniorModal, setShowAddSeniorModal] = useState({
		show: false,
		info: null,
		title: t("common.__room__"),
		success: false
	});

	const data = roomsFromHome.map(function (room) {
		return {
			...room,
			senior: room.senior_id ? DisplayName(room.senior_id, seniorsFromHome) : null,
			homeUnit: room.home_unit_id === null ? null : DisplayName(room.home_unit_id, homeUnits)
		};
	});

	const sortedData = sortAlphabetically(data, "senior");

	const deleteRoom = id => {
		http
			.delete(`${url.BASEURL}/rooms/${id}`)
			.then(() => {
				dispatch({
					type: "DELETE_ROOM",
					payload: parseInt(id)
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__removeRoomSuccess__")
				});
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	const columns = useMemo(
		() => [
			{
				Header: t("common.__name__"),
				accessor: "name",
				Cell: row => (
					<Link to={`/manage/room/details/${row.row.original.id}`}>
						<b>{row.row.values.name}</b>
					</Link>
				)
			},
			{
				Header: t("home.__floor__"),
				accessor: "floor",
				Filter: SelectColumnFilter
			},
			{
				Header: t("common.__homeUnit__"),
				accessor: "homeUnit",
				Filter: SelectColumnFilter,
				Cell: ({ cell: { value } }) => (value === null ? "" : value)
			},
			{
				Header: t("common.__senior__"),
				accessor: "senior",
				Filter: SelectColumnFilter,
				Cell: row => {
					return row.row.original.senior_id ? (
						<Link to={`/senior/profile/${row.row.original.senior_id}`}>
							<p className="category-outline-badge blue">
								<b>{row.row.values.senior}</b>
							</p>
						</Link>
					) : (
						<span
							className="add-senior-badge"
							role="button"
							tabIndex="0"
							onClick={() =>
								setShowAddSeniorModal({
									show: true,
									info: { room: row.row.original, homeId: homeId },
									title: `${t("common.__room__")} ${row.row.values.name}`,
									success: false
								})
							}>
							<FaUserPlus className="mr-1" /> {t("senior.__assignSenior__")}
						</span>
					);
				}
			},
			{
				Header: "",
				accessor: "id",
				disableFilters: true,
				Cell: row => {
					return (
						<div className="d-inline-block float-right mr-5">
							<DeviceIcon room={row.row.original} canManageHomeUnit={canManageHomeUnit} setShowDevice={setShowDevice} />
							{canManageHomeUnit ? (
								<>
									<Tooltip text={t("common.__update__")}>
										<Link to={`/manage/room/editroom/${row.row.values.id}`}>
											<FaRegEdit className="table-icon" role="button" />
										</Link>
									</Tooltip>
									<Tooltip text={t("calendar.__remove__")}>
										<FaRegTimesCircle className="table-icon" role="button" onClick={() => deleteRoom(row.row.values.id)} />
									</Tooltip>
								</>
							) : null}
						</div>
					);
				}
			}
		],
		[data]
	);

	return homeId ? (
		data.length > 0 ? (
			<>
				<HandleError error={store.es.error} dispatch={dispatch} message={t("home.__removeRoomError__")} noSpace />
				<Table columns={columns} data={sortedData} />
				<ModalContainer show={showAddSeniorModal} setShow={setShowAddSeniorModal}>
					<AddSeniorInARoom store={store} dispatch={dispatch} show={showAddSeniorModal} setShow={setShowAddSeniorModal} />
				</ModalContainer>
				<ModalContainer show={showDevice} setShow={setShowDevice}>
					<HandleDeviceInRoom store={store} dispatch={dispatch} homeId={homeId} show={showDevice} setShow={setShowDevice} />
				</ModalContainer>
			</>
		) : (
			<h5 className="mt-2">
				<FaAngleRight className="mr-2" />
				{t("common.__noElement__")}
			</h5>
		)
	) : (
		<SelectHomeInput />
	);
};

export default RoomsFromHome;
