import React, { useState } from "react";
import { useHistory } from "react-router-dom";

interface Props {
	tabsToDisplay: {
		id: string;
		title: string;
		path: string;
	}[];
	currentTab: string;
}

const Tabs = ({ tabsToDisplay, currentTab }: Props): JSX.Element => {
	const [active, setActive] = useState(currentTab ? currentTab : 0);
	const history = useHistory();

	const goTo = (id: string, path: string): void => {
		setActive(id);
		history.push(path);
	};

	return (
		<div className="nav nav-tabs">
			{tabsToDisplay.map(elt => (
				<li className="nav-item" key={elt.id}>
					<p className={`nav-link active ${active !== elt.id && "meta-tabs"}`} onClick={(): void => goTo(elt.id, elt.path)}>
						{active === elt.id ? <b>{elt.title}</b> : elt.title}
					</p>
				</li>
			))}
		</div>
	);
};
export default Tabs;
