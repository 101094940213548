import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import { FaTimes } from "react-icons/fa";
import RgpdContainer from "./RgpdContainer";

interface Props {
	setShow: (a: boolean) => void;
}

const Rgpd = ({ setShow }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [error, setError] = useState(false);
	const history = useHistory();

	const handleRgpd = (): void => {
		http
			.post(`${url.BASEURL}/me/rgpd/accept`, {
				has_accepted_rgpd: true
			})
			.then(() => {
				setShow(false);
				setError(false);
				history.push("/blog");
			})
			.catch(() => setError(true));
	};

	return (
		<div className="rgpd">
			<h2 className="center mb-4">{t("users.__personalInfo__")}</h2>
			<RgpdContainer />
			{error ? (
				<p className="error-msg">
					{t("news.__addNewsError__")}
					<span className="right" role="button" tabIndex={0} onClick={(): void => setError(false)}>
						<FaTimes />
					</span>
				</p>
			) : (
				<br />
			)}
			<div className="mb-2 center">
				<button type="button" className="btn btn-sm btn-outline-secondary mr-2" onClick={(): void => setShow(false)}>
					{t("users.__rgpdNotAccepted__")}
				</button>
				<button type="button" className="btn btn-sm btn-primary" onClick={(): void => handleRgpd()}>
					{t("users.__rgpdAccepted__")}
				</button>
			</div>
		</div>
	);
};
export default Rgpd;
