import i18n from "../../../i18n/i18next";

export const steps = [
	{
		id: 0,
		step: "SENIOR",
		name: i18n.t("common:common.__senior__")
	},
	{
		id: 1,
		step: "FAMILY",
		name: i18n.t("common:family.__principals__")
	},
	{
		id: 2,
		step: "RESUME",
		name: i18n.t("common:common.__validate__")
	}
];
