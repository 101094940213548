import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../Seniors/SeniorDocument/Documents.scss";
import { FaAngleRight, FaRegTimesCircle } from "react-icons/fa";
import DocumentForm from "./DocumentForm";
import SelectHomeInput from "../../layout/SelectHomeInput";
import http from "../../services/Interceptor";
import * as constant from "../../constants/Url";
import HandleSuccess from "../../layout/HandleSuccess";
import * as p from "../../constants/Permissions";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import HandleError from "../../layout/HandleError";
import NoData from "../../layout/NoData";

const Document = ({ esState, esDispatch, homeId, userState }) => {
	const { t } = useTranslation("common");
	const [addMode, setAddMode] = useState(false);
	const [update, setUpdate] = useState(false);
	const [homeDocuments, setHomeDocuments] = useState([]);
	const [error, setError] = useState(false);
	const canManageHomeDocuments = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_DOCUMENTS]);

	useEffect(() => {
		const fetchDocuments = id => {
			if (id) {
				http
					.get(`${constant.BASEURL}/homes/${id}/documents`)
					.then(res => {
						setError(false);
						const documents = res.data.home_documents.reverse();
						setHomeDocuments(documents);
					})
					.catch(() => setError(true));
			}
		};
		fetchDocuments(homeId);
		// eslint-disable-next-line
	}, [homeId, update, addMode]);

	const removeDocument = id => {
		if (id && canManageHomeDocuments) {
			http
				.delete(`/storages/homes/documents/${id}`)
				.then(() => {
					esDispatch({
						type: "DISPLAY_SUCCESS",
						payload: t("documents.__removeSuccess__")
					});
					setUpdate(prevUpdate => !prevUpdate);
				})
				.catch(() => {
					esDispatch({
						type: "DISPLAY_ERROR",
						payload: t("common.__delError__")
					});
				});
		}
	};

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-md-6">
					<h2>{t("documents.__homeDocs__")}</h2>
				</div>
				<div className="col-md-6">
					{canManageHomeDocuments ? (
						addMode ? (
							<button type="button" className="btn btn-sm btn-outline-secondary right" onClick={() => setAddMode(false)}>
								{t("common.__back__")}
							</button>
						) : (
							<button type="button" className="btn btn-sm btn-outline-primary right" onClick={() => setAddMode(true)}>
								{t("documents.__addHomeDoc__")}
							</button>
						)
					) : null}
				</div>
			</div>

			<HandleSuccess success={esState.success} dispatch={esDispatch} message={esState.msg} />
			<HandleError error={esState.error} dispatch={esDispatch} message={esState.msg} noSpace />

			{homeId ? (
				addMode ? (
					<DocumentForm
						update={update}
						setUpdate={setUpdate}
						setAddMode={setAddMode}
						esDispatch={esDispatch}
						esState={esState}
						homeId={homeId}
					/>
				) : homeDocuments.length > 0 ? (
					<>
						<div className="header-details">
							<p>
								<b>
									{t("documents.__homeDocCounter__")} : {homeDocuments.length}
								</b>
							</p>
							<p>{t("documents.__homeDocInfo__")}</p>
						</div>

						<div className="d-flex flex-wrap">
							{homeDocuments.map(document => (
								<div className="document-border center center" key={document.id}>
									<div className="document-container">
										<img src={`/storages/homes/documents/${document.id}/thumb`} alt={document.title} className="document-thumb" />
										<a href={`/storages/homes/documents/${document.id}`} target="_blanck">
											<p className="mt-2">
												<strong>{document.title}</strong> -{" "}
												<span className="small-meta">{document.content_type.split("/")[1]}</span>
												<br />
												<span className="small-meta">{handleDate(document.datetime, "P", userState.userConnected.locale)}</span>
											</p>
										</a>
										{canManageHomeDocuments ? (
											<FaRegTimesCircle className="suppr-btn" role="button" onClick={() => removeDocument(document.id)} />
										) : null}
									</div>
								</div>
							))}
						</div>
					</>
				) : error ? (
					<NoData />
				) : (
					<p className="meta">
						<FaAngleRight className="mr-2" />
						{t("common.__noElement__")}
					</p>
				)
			) : (
				<SelectHomeInput />
			)}
		</div>
	);
};

export default Document;
