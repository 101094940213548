import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HomeUnitInterface, UserInterface } from "../../../interfaces/UserInterface";
import OnlyDatePicker from "../../../utils/DatePicker/OnlyDatePicker";
import Select from "react-select";
import PrintIcon from "../../Buttons/PrintIcon";
import useDiaperStore from "../../../hooks/useDiaperStore";
import { MdFilterAlt } from "react-icons/md";
import { addYears } from "date-fns";
import OnlyTimePicker from "../../../utils/DatePicker/OnlyTimePicker";

export interface FiltersProps {
	startTime: Date;
	endTime: Date;
	startDate: Date;
	endDate: Date;
	baseDate?: Date;
	homeUnitId: number | null;
	floorId: number | null;
	senior: { label: string; value: number };
	filterSelected: string;
	homeId: number | null;
	userState: UserInterface;
}

export const DotationFiltersInitialState: FiltersProps = {
	startDate: new Date(
		localStorage.getItem("startDate") || new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()
	),
	endDate: new Date(localStorage.getItem("endDate") || new Date()),
	startTime: new Date(localStorage.getItem("startTime") || new Date() || ""),
	endTime: new Date(localStorage.getItem("endTime") || new Date() || ""),
	homeUnitId: null,
	floorId: null,
	senior: JSON.parse(localStorage.getItem("selectedSenior") || "null") || [],
	filterSelected: "HOME",
	homeId: null,
	userState: {} as UserInterface
};

interface Props {
	store: any;
	userLocale: string;
	seniors: { label: string; value: number }[];
	filtersStore: FiltersProps;
	filtersDispatch: React.Dispatch<any>;
	homeId: number;
	userState: UserInterface;
}

export const DotationFiltersReducer = (state: FiltersProps, action: { type: string; payload?: any }): any => {
	switch (action.type) {
		case "START_DATE":
			return {
				...state,
				startDate: action.payload
			};
		case "END_DATE":
			return {
				...state,
				endDate: action.payload
			};
			case "START_TIME":
			return {
				...state,
				startTime: action.payload
			};
		case "END_TIME":
			return {
				...state,
				endTime: action.payload
			};
		case "SET_SENIOR":
			return {
				...state,
				senior: action.payload,
				filterSelected: "SENIOR"
			};
		case "SET_HOME_UNIT":
			return {
				...state,
				homeUnitId: action.payload,
				filterSelected: "HOME_UNIT"
			};
		case "SET_FLOOR":
			return {
				...state,
				floorId: action.payload,
				filterSelected: "FLOOR"
			};
		case "RESET":
			return {
				...state,
				homeUnitId: null,
				floorId: null,
				senior: null,
				filterSelected: "HOME"
			};
		default:
			return state;
	}
};

export const DotationFilters = ({
	store,
	userLocale,
	seniors,
	filtersStore,
	filtersDispatch,
	userState,
	homeId
}: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { diaperStore } = useDiaperStore({ userState, homeId });
	const homeUnits = store.home.homeUnits;
	const allFloors = store.home.rooms.map((room: any) => room.floor);
	const allFloorsValues = allFloors.filter((floor: any) => floor !== null);
	const floorsList = [...new Set(allFloorsValues)].sort((a: any, b: any) => a - b);
	const floors = floorsList.map(floor => {
		return { id: floor, name: floor === 0 ? t("common.__rdc__") : floor };
	});

	const handleSeniorSelectionChange = (selectedOption: { label: string; value: number } | null): void => {
		if (selectedOption) {
			filtersDispatch({ type: "SET_SENIOR", payload: selectedOption });
			localStorage.setItem("selectedSenior", JSON.stringify(selectedOption));
		} else {
			filtersDispatch({ type: "RESET" });
			localStorage.setItem("selectedSenior", JSON.stringify(null));
		}
	};

	const handleStartDateChange = (date: Date): void => {
		filtersDispatch({ type: "START_DATE", payload: date });
		localStorage.setItem("startDate", date.toISOString());

		const storedEndDate = localStorage.getItem("endDate");
		if (storedEndDate) {
			const existingEndDate = new Date(storedEndDate);

			if (existingEndDate <= date) {
				const newEndDate = new Date(date);
				newEndDate.setDate(newEndDate.getDate() + 1);
				filtersDispatch({ type: "END_DATE", payload: newEndDate });
				localStorage.setItem("endDate", newEndDate.toISOString());
			} else {
				const oneYearAfterStartDate = addYears(date, 1);
				if (existingEndDate > oneYearAfterStartDate) {
					filtersDispatch({ type: "END_DATE", payload: oneYearAfterStartDate });
					localStorage.setItem("endDate", oneYearAfterStartDate.toISOString());
				}
			}
		} else {
			const newEndDate = addYears(date, 1);
			filtersDispatch({ type: "END_DATE", payload: newEndDate });
			localStorage.setItem("endDate", newEndDate.toISOString());
		}
	};

	const handleEndDateChange = (date: Date): void => {
		filtersDispatch({ type: "END_DATE", payload: date });
		localStorage.setItem("endDate", date.toISOString());

		const storedStartDate = localStorage.getItem("startDate");
		if (storedStartDate) {
			const existingStartDate = new Date(storedStartDate);
			if (date < existingStartDate) {
				const adjustedStartDate = new Date(date);
				adjustedStartDate.setDate(adjustedStartDate.getDate() - 1);
				filtersDispatch({ type: "START_DATE", payload: adjustedStartDate });
				localStorage.setItem("startDate", adjustedStartDate.toISOString());
			} else if (date > existingStartDate) {
				const oneYearAfterStartDate = addYears(existingStartDate, 1);
				if (date < oneYearAfterStartDate) {
					// if true , leave it unchanged!
				} else {
					const newStartDate = addYears(date, -1);
					filtersDispatch({ type: "START_DATE", payload: newStartDate });
					localStorage.setItem("startDate", newStartDate.toISOString());
				}
			}
		}
	};

	const isValidDate = (dateString: string): boolean => {
		const date = new Date(dateString);
		return !isNaN(date.getTime());
	};

	useEffect(() => {
		const storedStartDate = localStorage.getItem("startDate");

		const startDate =
			storedStartDate && isValidDate(storedStartDate)
				? new Date(storedStartDate)
				: new Date(new Date().setDate(new Date().getDate() - 1));

		const storedEndDate = localStorage.getItem("endDate");
		const endDate = storedEndDate && isValidDate(storedEndDate) ? new Date(storedEndDate) : new Date();
		const storedStartTime = localStorage.getItem("startTime");
		const startTime = storedStartTime && isValidDate(storedStartTime) ? new Date(storedStartTime) : new Date();
		const storedEndTime = localStorage.getItem("endTime");
		const endTime = storedEndTime && isValidDate(storedEndTime) ? new Date(storedEndTime) : new Date();


		const seniorsWithDotation = diaperStore.seniors.filter((senior: any) => senior.provisions.length > 0);
		const seniorsDotation = seniorsWithDotation.map((senior: any) => {
			return {
				label: senior.name,
				value: senior.id
			};
		});

		let selectedSenior: any;
		try {
			const item = localStorage.getItem("selectedSenior");
			selectedSenior = item ? JSON.parse(item) : null;
		} catch (error) {
			selectedSenior = null;
		}

		const matchingSaturationSensor = seniorsDotation.filter((senior: { value: any }) => {
			const nameParts = senior.value ? senior.value : null;
			const selectedName = selectedSenior && selectedSenior.value ? selectedSenior.value : null;

			return nameParts === selectedName;
		});

		const handleStateUpdates = (): void => {
			filtersDispatch({ type: "START_DATE", payload: startDate });
			filtersDispatch({ type: "END_DATE", payload: endDate });
			filtersDispatch({ type: "START_TIME", payload: startTime });
			filtersDispatch({ type: "END_TIME", payload: endTime });
			filtersDispatch({
				type: "SET_SENIOR",
				payload: matchingSaturationSensor.length > 0 ? matchingSaturationSensor[0] : null
			});
		};
		handleStateUpdates();
	}, [filtersDispatch, diaperStore.seniors]);

	useEffect(() => {
		if (filtersStore.senior || filtersStore.startDate || filtersStore.endDate) {
			localStorage.setItem("startDate", filtersStore.startDate.toISOString());

			localStorage.setItem("endDate", filtersStore.endDate.toISOString());

			localStorage.setItem("endTime", filtersStore.endTime.toISOString());

			localStorage.setItem("startTime", filtersStore.startTime.toISOString());
		}
	}, [filtersStore.senior, filtersStore.startDate, filtersStore.endDate, filtersStore.startTime, filtersStore.endTime]);

	function clearDotationFilters(): any {
		const today = new Date();
		const keysToRemove = ["startDate", "endDate", "startTime", "endTime", "selectedSenior"];
		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);
		keysToRemove.forEach(key => localStorage.removeItem(key));
		const currentHourWithMinutes = new Date();
		currentHourWithMinutes.setSeconds(0, 0);
		filtersDispatch({ type: "START_DATE", payload: yesterday });
		filtersDispatch({ type: "END_DATE", payload: today });
		filtersDispatch({ type: "SET_SENIOR", payload: null });
		filtersDispatch({ type: "START_TIME", payload: currentHourWithMinutes });
		filtersDispatch({ type: "END_TIME", payload: currentHourWithMinutes });
	}

	const handleStartTimeChange = (date: Date): void => {
	filtersDispatch({ type: "START_TIME", payload: date });
		localStorage.setItem("startTime", date.toISOString());
	};

	const handleEndTimeChange = (date : Date): void  => {
		filtersDispatch({ type: "END_TIME", payload: date });
		localStorage.setItem("endTime", date.toISOString());
	};
	return (
		<div className="big-card dont-print-this">
			<h2>{t("diaper.__provisionMonitoring__")}</h2>
			<div className="d-flex justify-content-end align-items-center">
				<div>
					<button className="btn btn-sm btn-primary mt-2 dont-print-this" onClick={(): any => window.print()}>
						{t("common.__print__")} <PrintIcon />
					</button>
					<button type="button" className="btn btn-sm btn-primary mt-2 ml-2 dont-print-this" onClick={clearDotationFilters}>
						{t("common.__resetFilters__")} <MdFilterAlt size={22} />
					</button>
				</div>
			</div>
			<div className="row mt-4">
				<div className="col-sm-2">
					<p>
						<b>{t("common.__dates__")} :</b>
					</p>
				</div>
				<div className="col-sm-5">
					<OnlyDatePicker
						startDate={filtersStore.startDate}
						dispatch={filtersDispatch}
						type={"START"}
						userLocale={userLocale}
						onDateChange={handleStartDateChange}
					/>
				</div>
				<div className="col-sm-5">
						<OnlyTimePicker
							startTime={filtersStore.startTime}
							dispatch={filtersDispatch}
							type="START"
							userLocale={userLocale}
							onTimeChange={handleStartTimeChange}
						/>
					</div>
					<div className="col-sm-2">
						<p>{t("common.__toward__")}</p>
					</div>
				<div className="col-sm-5">
					<OnlyDatePicker
						startDate={filtersStore.endDate}
						dispatch={filtersDispatch}
						type={"END"}
						userLocale={userLocale}
						onDateChange={handleEndDateChange}
					/>
				</div>
				<div className="col-sm-5">
						<OnlyTimePicker
							startTime={filtersStore.endTime}
							dispatch={filtersDispatch}
							type="END"
							userLocale={userLocale}
							onTimeChange={handleEndTimeChange}
						/>
					</div>

				{homeUnits.length > 1 ? (
					<>
						<div className="col-sm-2 mt-2">
							<p className="meta mt-1">{t("common.__homeUnit__")} :</p>
						</div>
						<div className="col-sm-10 mt-2">
							<span
								className={filtersStore.filterSelected === "HOME_UNIT" ? "category-outline-badge grey" : "category-badge blue"}
								role="button"
								onClick={(): void => filtersDispatch({ type: "RESET" })}>
								{t("common.__all__")}
							</span>
							{homeUnits.map((homeUnit: HomeUnitInterface) => (
								<span
									key={homeUnit.id}
									role="button"
									className={
										filtersStore.filterSelected === "HOME_UNIT" && filtersStore.homeUnitId === homeUnit.id
											? "category-badge blue"
											: "category-outline-badge grey"
									}
									onClick={(): void => filtersDispatch({ type: "SET_HOME_UNIT", payload: homeUnit.id })}>
									{homeUnit.name}
								</span>
							))}
						</div>
					</>
				) : null}
				{floors.length > 1 ? (
					<>
						<div className="col-sm-2 mt-3">
							<p className="meta mt-1">{t("home.__floor__")} :</p>
						</div>
						<div className="col-sm-10 mt-3">
							<span
								className={filtersStore.filterSelected === "FLOOR" ? "category-outline-badge grey" : "category-badge blue"}
								role="button"
								onClick={(): void => filtersDispatch({ type: "RESET" })}>
								{t("common.__all__")}
							</span>
							{floors.map((floor, index) => (
								<span
									key={index}
									role="button"
									className={
										filtersStore.filterSelected === "FLOOR" && filtersStore.floorId === floor.id
											? "category-badge blue"
											: "category-outline-badge grey"
									}
									onClick={(): void => filtersDispatch({ type: "SET_FLOOR", payload: floor.id })}>
									<>
										{floor.name === t("common.__rdc__") ? null : t("home.__floor__")} {floor.name}
									</>
								</span>
							))}
						</div>
					</>
				) : null}
				<div className="col-sm-2 mt-3">
					<p className="meta mt-3">{t("common.__senior__")} :</p>
				</div>
				<div className="col mb-5"></div>
				<div className="col-sm-10 mt-1">
					<Select
						placeholder={t("senior.__selectSenior__")}
						name="seniors"
						options={seniors}
						className="basic-multi-select mb-2 mt-3"
						classNamePrefix="select"
						onChange={(e: any): void => handleSeniorSelectionChange(e)}
						isClearable
						value={filtersStore.filterSelected === "SENIOR" ? filtersStore.senior : null}
					/>
				</div>
			</div>
		</div>
	);
};
