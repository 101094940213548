import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaPrint, FaRegEnvelope, FaUnlink, FaToggleOn, FaTag, FaTimes } from "react-icons/fa";
import { handleValidityDetails } from "../../utils/BadgeRestrictions";
import { displayBadgeType, displayValidityTypeColor, displayValidityTypes } from "./Badges/ValidityTypes";
import HandleError from "../../layout/HandleError";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as API from "../../services/API";

export default function DisplayFullBadge({
	badge,
	badgeSelected,
	setBadgeSelected,
	healthWorkerBadges,
	hwId,
	store,
	dispatch,
	setUpdate,
	canManageUserAndBadge,
	userState
}) {
	const { t } = useTranslation("common");
	const userLocale = userState.userConnected.locale;
	const hwBadge = {
		...badge,
		type: displayBadgeType(badge.type_id),
		restriction: displayValidityTypes(badge.validity.type),
		state: badge.active ? t("common.__active__") : t("common.__inactive__")
	};

	const handleSelectedBadges = badge => {
		if (badgeSelected.includes(badge)) {
			setBadgeSelected(badgeSelected.filter(id => id !== badge));
		} else {
			setBadgeSelected([...badgeSelected, badge]);
		}
	};
	const ids = healthWorkerBadges.map(badge => badge.id);
	const [qrCodeSent, setQrCodeSent] = useState(false);

	const sendQrCode = badgeId => {
		dispatch({
			type: "RESET_ES"
		});
		http
			.get(`${url.BASEURL}/user_badges/${badgeId}/send/email`)
			.then(() => setQrCodeSent(true))
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__errorOccured__")
				});
			});
	};

	const removeBadge = id => {
		dispatch({
			type: "RESET_ES"
		});
		if (ids.includes(id)) {
			const data = {
				user: {
					badges: ids.filter(elt => elt !== id)
				}
			};
			API.updateHealthWorker(
				hwId,
				data,
				res => {
					dispatch({
						type: "EDIT_HEALTHWORKER",
						payload: res
					});
					setUpdate(prevState => !prevState);
				},
				() => {
					dispatch({
						type: "DISPLAY_ERROR",
						payload: t("common.__errorOccured__")
					});
				}
			);
		}
	};

	return canManageUserAndBadge ? (
		<div className="form-card" onClick={() => handleSelectedBadges(badge.id)}>
			<div className="row">
				<div className="col-12">
					{qrCodeSent ? (
						<p className="success-msg">
							{t("badge.__qrCodeSent__")}
							<span className="right fake-link" onClick={() => setQrCodeSent(false)}>
								<FaTimes />
							</span>
						</p>
					) : null}
					<HandleError error={store.es.error} dispatch={dispatch} message={store.es.msg} noSpace />
				</div>
				<div className="col-md-9">
					<p>
						{hwBadge.type_id === 1 && (
							<b>
								{t("badge.__pinCode__")} {hwBadge.value}
							</b>
						)}
						{hwBadge.type_id === 2 && (
							<b>
								{t("badge.__qrCode__")} {hwBadge.value}
							</b>
						)}
						<br />
						{hwBadge.active ? null : (
							<span className="red">
								<FaToggleOn className="mr-2" />
								{t("common.__inactive__")}
							</span>
						)}
					</p>
					<p>
						<Link to={`/user/hwbagde/${hwBadge.id}`}>
							<span className={` ${displayValidityTypeColor(hwBadge.validity.type)}`}>{hwBadge.restriction}</span>
						</Link>{" "}
						<span className="meta">{handleValidityDetails(hwBadge.validity.type, hwBadge.validity.values, userLocale)}</span>
					</p>
				</div>
				<div className="col-md-3">
					<div className="right">
						{hwBadge.type_id === 2 && (
							<>
								<a
									href={`${url.BASEURL}/user_badges/generate/pdf/${hwBadge.value}`}
									download={`badge-${hwBadge.value}`}
									className="table-icon">
									<FaPrint />
								</a>
								<a
									href={`${url.BASEURL}/user_badges/generate/pdf/dymo/${hwBadge.value}`}
									download={`badge-${hwBadge.value}`}
									className="table-icon">
									<FaTag />
								</a>
								<FaRegEnvelope className="table-icon mr-2" role="button" onClick={() => sendQrCode(hwBadge.id)} />
							</>
						)}
						<FaUnlink role="button" className="table-icon" onClick={() => removeBadge(hwBadge.id)} />
					</div>
				</div>
			</div>
		</div>
	) : null;
}
