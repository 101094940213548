const MessagesReducer = (state, action) => {
	switch (action.type) {
		case "LOADING_MESSAGES":
			return {
				...state,
				loading: true,
				error: false,
				success: false
			};

		case "MSG_STARTDATE":
			return {
				...state,
				startDate: action.payload
			};

		case "MSG_ENDDATE":
			return {
				...state,
				endDate: action.payload
			};

		case "MSGBOX_FILTER":
			return {
				...state,
				filter: action.payload
			};

		case "GET_MESSAGES": {
			const messages = action.payload;
			const allMsg = messages.map(function (message) {
				return {
					...message,
					search: `${message.subject} ${message.message}`,
					userName: `${message.user.first_name} ${message.user.last_name}`,
					familyName: message.family_user.last_name
						? `${message.family_user.last_name.toUpperCase()} ${message.family_user.first_name}`
						: null,
					seniorName: message.senior.last_name
						? `${message.senior.last_name.toUpperCase()} ${message.senior.first_name}`
						: message.targets
						? message.targets.length === 1
							? `${message.targets[0].senior.last_name.toUpperCase()} ${message.targets[0].senior.first_name}`
							: "group"
						: null
				};
			});
			return {
				...state,
				messages: allMsg,
				loading: false,
				error: false,
				success: false
			};
		}

		case "EDIT_MESSAGE": {
			const updatedMessage = action.payload;
			const messageUpdated = state.messages.map(message => {
				if (message.id === updatedMessage.id) {
					return {
						...updatedMessage,
						search: `${message.subject} ${message.message}`,
						userName: `${message.user.first_name} ${message.user.last_name}`,
						familyName: message.family_user.last_name
							? `${message.family_user.last_name.toUpperCase()} ${message.family_user.first_name}`
							: null,
						seniorName: message.senior.last_name
							? `${message.senior.last_name.toUpperCase()} ${message.senior.first_name}`
							: message.targets
							? message.targets.every(elt => elt.senior.id === message.targets[0].senior.id)
								? `${message.targets[0].senior.last_name.toUpperCase()} ${message.targets[0].senior.first_name}`
								: null
							: null
					};
				}
				return message;
			});
			return {
				...state,
				messages: messageUpdated
			};
		}

		case "ERROR_LOADING_MESSAGES":
			return {
				...state,
				error: true,
				success: false,
				loading: false,
				msg: action.payload
			};

		case "DISPLAY_SUCCESS":
			return {
				...state,
				error: false,
				success: true,
				msg: action.payload
			};

		case "DISPLAY_ERROR":
			return {
				...state,
				error: true,
				success: false,
				msg: action.payload
			};

		case "RESET_ES":
			return {
				...state,
				error: false,
				success: false,
				msg: null
			};

		default:
			return state;
	}
};

export default MessagesReducer;
