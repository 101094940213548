import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as p from "../../constants/Permissions";

const PermissionsManager = ({ permissions, setPermissions, isAdminOrSuperAdmin }) => {
	const { t } = useTranslation("common");
	const [warningMsg, setWarningMsg] = useState(false);

	useEffect(() => {
		if (permissions.includes(p.CAN_INITIATE_MESSAGES_TO_FAMILY)) {
			if (!permissions.includes(p.ALL_FAMILY_USER_MESSAGES) || !permissions.includes(p.MY_FAMILY_USER_MESSAGES)) {
				setWarningMsg(true);
			}
		}
	}, [permissions]);

	const handlePermissions = permissionSelected => {
		const permission = parseInt(permissionSelected);
		if (permissions.includes(permission)) {
			const perms = permissions.filter(p => permission !== p);
			setPermissions(perms);
		} else {
			if (permission === p.ALL_FAMILY_USER_MESSAGES) {
				setWarningMsg(false);
				if (permissions.includes(p.MY_FAMILY_USER_MESSAGES)) {
					const newPermsList = permissions.filter(elt => elt !== p.MY_FAMILY_USER_MESSAGES);
					const perms = [...newPermsList, permission];
					setPermissions(perms);
				} else {
					const perms = [...permissions, permission];
					setPermissions(perms);
				}
			} else if (permission === p.MY_FAMILY_USER_MESSAGES) {
				setWarningMsg(false);
				if (permissions.includes(p.ALL_FAMILY_USER_MESSAGES)) {
					const newPermsList = permissions.filter(elt => elt !== p.ALL_FAMILY_USER_MESSAGES);
					const perms = [...newPermsList, permission];
					setPermissions(perms);
				} else {
					const perms = [...permissions, permission];
					setPermissions(perms);
				}
			} else if (permission === p.CAN_INITIATE_MESSAGES_TO_FAMILY) {
				if (permissions.includes(p.ALL_FAMILY_USER_MESSAGES) || permissions.includes(p.MY_FAMILY_USER_MESSAGES)) {
					setWarningMsg(false);
					const perms = [...permissions, permission];
					setPermissions(perms);
				} else {
					setWarningMsg(true);
					const perms = [...permissions, permission];
					setPermissions(perms);
				}
			} else {
				const perms = [...permissions, permission];
				setPermissions(perms);
			}
		}
	};

	const handleControlPanelPermission = () => {
		setPermissions([p.CONTROL_PANEL, p.HOME_BLOG_WRITER, p.ALL_FAMILY_USER_MESSAGES]);
		if (permissions.includes(p.CONTROL_PANEL)) {
			const perms = permissions.filter(permission => permission !== p.CONTROL_PANEL);
			setPermissions(perms);
		} else {
			const perms = [p.CONTROL_PANEL, p.HOME_BLOG_WRITER, p.ALL_FAMILY_USER_MESSAGES];
			setPermissions(perms);
		}
	};

	return (
		<div data-testid="permission-manager-block">
			<h5 className="blue mb-3">{t("healthworker.__addPermission__")}</h5>
			<table className="table table-bordered table-hover">
				<tbody>
					<tr>
						<th scope="row">{t("common.__home__")} </th>
						<td colSpan="4">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_UPDATE_HOME)}
								onChange={() => handlePermissions(p.CAN_UPDATE_HOME)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__homePermissionWarning__")}</span>
							<br />
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_MANAGE_HOME_DOCUMENTS)}
								onChange={() => handlePermissions(p.CAN_MANAGE_HOME_DOCUMENTS)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__homeDocumentPermissionWarning__")}</span>
						</td>
					</tr>
					<tr>
						<th scope="row">
							{t("permissions.__unitsAndRooms__")}
							<br />
							<p className="meta">{t("permissions.__unitsAndRoomsWarning__")}</p>
						</th>
						<td colSpan="4">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_MANAGE_HOME_UNIT)}
								onChange={() => handlePermissions(p.CAN_MANAGE_HOME_UNIT)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__createUpdate__")}</span>
						</td>
					</tr>
					<tr>
						<th scope="row">
							{t("permissions.__usersAndBadges__")}
							<br />
							<p className="meta">{t("permissions.__createUpdateUser__")}</p>
						</th>
						<td colSpan="2">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_MANAGE_USER_AND_BADGE)}
								onChange={() => handlePermissions(p.CAN_MANAGE_USER_AND_BADGE)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__createUpdate__")}</span>
						</td>
						<td colSpan="2">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_MANAGE_PERMISSIONS)}
								onChange={() => handlePermissions(p.CAN_MANAGE_PERMISSIONS)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__managePermissions__")}</span>
						</td>
					</tr>
					<tr>
						<th scope="row">{t("homeBoard.__seniors__")}</th>
						<td>
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_CREATE_SENIOR)}
								onChange={() => handlePermissions(p.CAN_CREATE_SENIOR)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__create__")}</span>
						</td>
						<td>
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_UPDATE_SENIOR)}
								onChange={() => handlePermissions(p.CAN_UPDATE_SENIOR)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__update__")}</span>
						</td>
						<td colSpan="2">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_DELETE_SENIOR)}
								onChange={() => handlePermissions(p.CAN_DELETE_SENIOR)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__delete__")}</span>
						</td>
					</tr>
					<tr>
						<th scope="row">{t("senior.__documents__")}</th>
						<td colSpan="4">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_MANAGE_SENIOR_DOCUMENTS)}
								onChange={() => handlePermissions(p.CAN_MANAGE_SENIOR_DOCUMENTS)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__seniorPermissionWarning__")}</span>
						</td>
					</tr>
					<tr>
						<th scope="row">
							{t("permissions.__timeline__")}
							<br />
							<p className="meta">{t("permissions.__timelineWarning__")}</p>
						</th>
						<td colSpan="2">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_MANAGE_TIMELINE_FILTERS)}
								onChange={() => handlePermissions(p.CAN_MANAGE_TIMELINE_FILTERS)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__manage__")}</span>
						</td>
						<td colSpan="2">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_READ_ACTIVITIES)}
								onChange={() => handlePermissions(p.CAN_READ_ACTIVITIES)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__read__")}</span>
						</td>
					</tr>
					<tr>
						<th scope="row">
							{t("common.__mailbox__")}
							<br />
							<span className="meta">
								- {t("permissions.__generalMailBoxWarning__")}
								<br />- {t("permissions.__privateMailBoxWarning__")}
							</span>
						</th>
						<td colSpan="2">
							<input
								type="checkbox"
								checked={permissions.includes(p.ALL_FAMILY_USER_MESSAGES)}
								onChange={() => handlePermissions(p.ALL_FAMILY_USER_MESSAGES)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__general__")}</span>
							<br />
							<input
								type="checkbox"
								checked={permissions.includes(p.MY_FAMILY_USER_MESSAGES)}
								onChange={() => handlePermissions(p.MY_FAMILY_USER_MESSAGES)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__private__")}</span>
						</td>
						<td colSpan="2">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_INITIATE_MESSAGES_TO_FAMILY)}
								onChange={() => handlePermissions(p.CAN_INITIATE_MESSAGES_TO_FAMILY)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__sendMsgToFamily__")}</span>
							{warningMsg ? (
								<p className="red">
									Sélectionnez aussi un type de messagerie afin de consulter et répondre aux messages des familles.
								</p>
							) : null}
						</td>
					</tr>
					<tr>
						<th scope="row">{t("news.__news__")}</th>
						<td colSpan="2">
							<input
								type="checkbox"
								checked={permissions.includes(p.HOME_BLOG_WRITER)}
								onChange={() => handlePermissions(p.HOME_BLOG_WRITER)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__create__")}</span>
						</td>
						<td colSpan="2">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_POST_MODIFY_HOME_BLOG)}
								onChange={() => handlePermissions(p.CAN_POST_MODIFY_HOME_BLOG)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__editRemovePost__")}</span>
						</td>
					</tr>
					<tr>
						<th scope="row">{t("calendar.__calendar__")}</th>
						<td colSpan="4">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_MANAGE_HOME_CALENDAR)}
								onChange={() => handlePermissions(p.CAN_MANAGE_HOME_CALENDAR)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__createEvents__")}</span>
						</td>
					</tr>
					<tr>
						<th scope="row">{t("moderation.__moderation__")}</th>
						<td colSpan="2">
							<p>{t("permissions.__addedByFamily__")} </p>
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_MODERATE_SENIOR_AVATAR)}
								onChange={() => handlePermissions(p.CAN_MODERATE_SENIOR_AVATAR)}
							/>
							<span className="meta-label-checkbox">{t("moderation.__avatars__")}</span>
							<br />
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_MODERATE_SENIOR_LIFE_STORIES)}
								onChange={() => handlePermissions(p.CAN_MODERATE_SENIOR_LIFE_STORIES)}
							/>
							<span className="meta-label-checkbox">{t("moderation.__bios__")}</span>
							<br />
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_MODERATE_PHOTO_FRAME)}
								onChange={() => handlePermissions(p.CAN_MODERATE_PHOTO_FRAME)}
							/>
							<span className="meta-label-checkbox">{t("moderation.__photoFrame__")}</span>
						</td>
						<td colSpan="2">
							<p>{t("permissions.__addedByUser__")} </p>
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_MODERATE_HOME_BLOG)}
								onChange={() => handlePermissions(p.CAN_MODERATE_HOME_BLOG)}
							/>
							<span className="meta-label-checkbox">{t("news.__news__")}</span>
						</td>
					</tr>
					<tr>
						<th scope="row">{t("notifications.__notificationsRules__")}</th>
						<td colSpan="2">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_MANAGE_CHECKER_RULE)}
								onChange={() => handlePermissions(p.CAN_MANAGE_CHECKER_RULE)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__createRules__")}</span>
						</td>
						<td colSpan="2">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_CHECK_NOTIFICATIONS)}
								onChange={() => handlePermissions(p.CAN_CHECK_NOTIFICATIONS)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__checkRules__")}</span>
						</td>
					</tr>
					<tr>
						<th scope="row">
							{t("hds.__hdsRepartition__")}
							<br />
							<p className="meta">{t("permissions.__manageHDS__")}</p>
						</th>
						<td colSpan="4">
							<input
								type="checkbox"
								checked={permissions.includes(p.HW_BUDGET_DISTRIBUTION)}
								onChange={() => handlePermissions(p.HW_BUDGET_DISTRIBUTION)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__createUpdate__")}</span>
						</td>
					</tr>
					<tr>
						<th scope="row">
							{t("memo.__memo__")}
							<br />
							<p className="meta">{t("permissions.__memoWarning__")}</p>
						</th>
						<td colSpan="4">
							<input
								type="checkbox"
								checked={permissions.includes(p.CAN_READ_ALL_MEMOT)}
								onChange={() => handlePermissions(p.CAN_READ_ALL_MEMOT)}
							/>
							<span className="meta-label-checkbox">{t("permissions.__readAllMemo__")}</span>
						</td>
					</tr>
					<tr>
						<th scope="row">
							{t("permissions.__controlPanel__")}
							<br />
							<p className="meta">{t("permissions.__controlPanelWarning__")}</p>
						</th>
						<td colSpan="4">
							<input type="checkbox" checked={permissions.includes(p.CONTROL_PANEL)} onChange={handleControlPanelPermission} />
							<span className="meta-label-checkbox">{t("permissions.__controlPanel__")}</span>
						</td>
					</tr>
					{isAdminOrSuperAdmin ? (
						<tr>
							<th scope="row">{t("diaper.__connectedDiapers__")}</th>
							<td colSpan="4">
								<input
									type="checkbox"
									checked={permissions.includes(p.DIAPER_APPLICATION)}
									onChange={() => handlePermissions(p.DIAPER_APPLICATION)}
								/>
								<span className="meta-label-checkbox">Gestion des protections connectées</span>
							</td>
						</tr>
					) : null}
				</tbody>
			</table>
		</div>
	);
};

export default PermissionsManager;
