import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HomeStoreInterface } from "../../interfaces/HomeInterface";
import MyInputSelect from "../../utils/Form/MyInputSelect";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

interface Props {
	homeId: number | null;
	groupId: number | null;
	tenantId: number | null;
	isSuperAdmin: boolean;
	newUserRole: string | null;
	setNewUserRole: (arg: string) => void;
	editMode?: boolean;
	homeStore: HomeStoreInterface;
}

const AssignUserRole = ({
	homeId,
	groupId,
	tenantId,
	isSuperAdmin,
	newUserRole,
	setNewUserRole,
	editMode,
	homeStore
}: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const groups = homeStore ? homeStore.home.groups : [];
	const allHomes = homeStore ? homeStore.home.allHomes : [];
	const [tenants, setTenants] = useState(homeStore && homeStore.home.tenants ? homeStore.home.tenants : []);

	useEffect(() => {
		if (isSuperAdmin && homeStore.home.tenants.length < 1) {
			http
				.get(`${url.BASEURL}/tenants`)
				.then(res => setTenants(res.data.tenants))
				.catch(() => setTenants([]));
		}
	}, [isSuperAdmin]);

	return (
		<div className="header-details" data-testid="add-user-role-block">
			<h5 className="blue">{t("healthworker.__addAffectation__")}</h5>
			<div className="mt-3 mb-2">
				{isSuperAdmin ? (
					<>
						<input
							type="checkbox"
							checked={newUserRole === "SUPER_ADMIN"}
							onChange={(): void => setNewUserRole("SUPER_ADMIN")}
						/>
						<span className="label-checkbox mr-5">
							<b>Super Admin</b>
						</span>
					</>
				) : null}
				{editMode ? (
					isSuperAdmin ? (
						<>
							<input type="checkbox" checked={newUserRole === "ADMIN"} onChange={(): void => setNewUserRole("ADMIN")} />
							<span className="label-checkbox mr-5">
								<b>{t("common.__tenant__")}</b>
							</span>
						</>
					) : null
				) : (
					<>
						<input type="checkbox" checked={newUserRole === "ADMIN"} onChange={(): void => setNewUserRole("ADMIN")} />
						<span className="label-checkbox mr-5">
							<b>{t("common.__tenant__")}</b>
						</span>
					</>
				)}
				<input
					type="checkbox"
					checked={newUserRole === "GROUP_MANAGER"}
					onChange={(): void => setNewUserRole("GROUP_MANAGER")}
				/>
				<span className="label-checkbox mr-5">
					<b>{t("home.__group__")}</b>
				</span>
				<input
					type="checkbox"
					checked={newUserRole === "HOME_MANAGER"}
					onChange={(): void => setNewUserRole("HOME_MANAGER")}
				/>
				<span className="label-checkbox">
					<b>{t("common.__home__")}</b>
				</span>
			</div>
			<div className="mt-4 mb-2">
				{newUserRole === "ADMIN" && isSuperAdmin ? (
					<MyInputSelect
						label={t("common.__tenant__")}
						name="tenant"
						behavior={{ required: true }}
						list={tenants}
						value={tenantId}
					/>
				) : null}
				{newUserRole === "GROUP_MANAGER" ? (
					<MyInputSelect
						label={t("common.__groupSelection__")}
						name="group"
						behavior={{ required: true }}
						list={groups}
						value={groupId}
					/>
				) : null}
				{newUserRole === "HOME_MANAGER" ? (
					<MyInputSelect
						label={t("common.__homeSelection__")}
						name="home"
						behavior={{ required: true }}
						list={allHomes}
						value={homeId}
						data-testid="add-user-role-home-select"
					/>
				) : null}
			</div>
		</div>
	);
};

export default AssignUserRole;
