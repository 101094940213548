import React from "react";
import ForgotPassword from "../components/UserSettings/ForgotPassword";
import { useParams } from "react-router-dom";

const ReinitPassword = (): JSX.Element => {
	const { src } = useParams<{ src: string }>();
	const userComeFromEventApp = src === "event";

	return (
		<div className="login-container mx-auto text-center">
			{userComeFromEventApp ? null : process.env.REACT_APP_EFORLINK_LOGO === "true" ? (
				<img src="/img/Logo-eforlink.png" alt="eForLink" className="login-logo mb-5" />
			) : (
				<img src="/img/amd/amdlink.png" alt="amd link" className="amd-login-logo mb-5" />
			)}

			<ForgotPassword fromWelcomePage={false} userComeFromEventApp={userComeFromEventApp} />
		</div>
	);
};

export default ReinitPassword;
