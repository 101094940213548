import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useFilters, useExpanded, useSortBy, usePagination, useRowSelect } from "react-table";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "./Table.scss";

function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
	const { t } = useTranslation("common");

	return (
		<div className="form-group">
			<input
				className="form-control form-control-sm form-customized"
				value={filterValue || ""}
				onChange={e => {
					setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
				}}
				placeholder={t("common.__search__")}
			/>
		</div>
	);
}

export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
	const { t } = useTranslation("common");

	// Calculate the options for filtering
	// using the preFilteredRows
	const options = useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach(row => {
			options.add(row.values[id]);
		});
		return [...options.values()];
	}, [id, preFilteredRows]);

	// Render a multi-select box
	return (
		<div className="form-group">
			<select
				className="form-control form-control-sm form-customized customDropdown"
				value={filterValue}
				onChange={e => {
					setFilter(e.target.value || undefined);
				}}>
				<option value="" className="form-customized">
					{t("common.__filters__")}
				</option>
				{options.map((option, i) => (
					<option key={i} value={option} className="form-customized">
						{option}
					</option>
				))}
			</select>
		</div>
	);
}

// Create a default prop getter
const defaultPropGetter = () => ({});

export function Table({ columns, data, getRowProps = defaultPropGetter }) {
	const { t } = useTranslation("common");

	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter
		}),
		[]
	);

	const filterTypes = useMemo(
		() => ({
			text: (rows, id, filterValue) => {
				return rows.filter(row => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
						: true;
				});
			}
		}),
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page, // Instead of using 'rows', we'll use page,
		canPreviousPage,
		canNextPage,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		// rows,
		setPageSize,
		pageOptions,
		state: {
			pageIndex,
			pageSize
			// sortBy,
			// groupBy,
			// expanded,
			// filters,
			// selectedRowIds,
		}
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes,
			// autoResetSelectedRows: !skipReset,
			initialState: { pageSize: 25 },
			autoResetPage: false
		},
		useFilters,
		useSortBy,
		useExpanded,
		usePagination,
		useRowSelect
	);

	// Render the UI for your table
	return (
		<div className="table-responsive">
			<table {...getTableProps()} className="table custom-table">
				<thead>
					{headerGroups.map((headerGroup, i) => (
						<tr {...headerGroup.getHeaderGroupProps()} key={i}>
							{headerGroup.headers.map((column, i) => (
								<th {...column.getHeaderProps()} key={i}>
									<div className="mb-2">
										<span {...column.getSortByToggleProps()}>
											{column.render("Header")}
											{column.render("Header") ? (
												column.isSorted ? (
													column.isSortedDesc ? (
														<FaAngleDown className="blue ml-2" />
													) : (
														<FaAngleUp className="blue ml-2" />
													)
												) : (
													<FaAngleDown className="check-icon ml-2" />
												)
											) : null}
										</span>
									</div>
									<div className="filter-options">{column.canFilter ? column.render("Filter") : null}</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps(getRowProps(row))} key={i}>
								{row.cells.map((cell, i) => {
									return (
										<td
											{...cell.getCellProps([
												{
													style: {
														minWidth: cell.column.minWidth,
														width: cell.column.width,
														maxWitdh: cell.column.maxWidth
													}
												}
											])}
											key={i}>
											{cell.render("Cell")}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>

			<div className="table-pagination form-inline">
				<button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="table-button">
					{"<<"}
				</button>{" "}
				<button onClick={() => previousPage()} disabled={!canPreviousPage} className="table-button">
					{"<"}
				</button>{" "}
				<button onClick={() => nextPage()} disabled={!canNextPage} className="table-button">
					{">"}
				</button>{" "}
				<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="table-button">
					{">>"}
				</button>{" "}
				<span>
					{t("common.__page__")}
					<strong>
						{pageIndex + 1} {t("common.__on__")} {pageOptions.length}
					</strong>
				</span>
				<select
					className="form-control form-control-sm ml-3"
					defaultValue={pageSize}
					onBlur={e => {
						setPageSize(Number(e.target.value));
					}}>
					{[25, 50, 100].map(pageSize => (
						<option key={pageSize} value={pageSize}>
							{t("common.__nbItems__")} : {pageSize}
						</option>
					))}
				</select>
			</div>
		</div>
	);
}
