import React from "react";
import { Link } from "react-router-dom";
import { getActivityMood } from "../../utils/Activities/Smiley";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import { FaAngleDoubleRight, FaAngleRight, FaVideo } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import Loader from "../../layout/Loader";

export default function TimelineContainer({ userState, loading, data, display }) {
	const { t } = useTranslation("common");

	const allColumns = [
		{
			Header: t("common.__date__"),
			accessor: "date",
			Filter: SelectColumnFilter,
			width: 50,
			minWidth: 40,
			Cell: row => {
				return (
					<p>
						<b>{handleDate(row.row.original.dt, "p", userState.userConnected.locale)}</b>
						<br />
						<span className="meta">{handleDate(row.row.original.dt, "P", userState.userConnected.locale)}</span>
					</p>
				);
			}
		},
		{
			Header: t("healthworker.__teamMember__"),
			accessor: "hw_name",
			Filter: SelectColumnFilter,
			width: 100,
			minWidth: 90,
			Cell: row =>
				row.row.values.hw_name ? (
					<Link to={`/user/healthworkeractivities/${row.row.original.hw.id}`}>
						<span className="category-outline-badge grey">
							<b>{row.row.values.hw_name}</b>
						</span>
					</Link>
				) : null
		},
		{
			Header: t("common.__senior__"),
			accessor: "s_name",
			Filter: SelectColumnFilter,
			width: 100,
			minWidth: 90,
			Cell: row =>
				row.row.values.s_name ? (
					<Link to={`/senior/senioractivities/${row.row.original.s.id}`}>
						<span className="category-outline-badge blue">
							<b>{row.row.values.s_name}</b>
						</span>
					</Link>
				) : null
		},
		{
			Header: t("common.__category__"),
			accessor: "aid_title",
			Filter: SelectColumnFilter,
			Cell: row => (
				<>
					{row.row.original.aid > 5 ? (
						<>
							<p>
								<b>{row.row.values.aid_title}</b>
							</p>
							<p className="mr-2 ml-1">
								<FaAngleRight /> {row.row.original.aid_action}
								<br />
								{row.row.original.did_string ? (
									<span className="meta red ml-1">
										<FaAngleDoubleRight /> {row.row.original.did_string}
									</span>
								) : null}
							</p>
						</>
					) : (
						<p className="mt-1">{row.row.original.aid_action}</p>
					)}
				</>
			)
		},
		{
			Header: t("activities.__mood__"),
			accessor: "mood",
			Filter: SelectColumnFilter,
			width: 40,
			minWidth: 30,
			Cell: row => (
				<>
					{row.row.original.aid < 6 ? <div className="center">{getActivityMood(row.row.original.aid)}</div> : null}
					{row.row.original.aid === 17 ? (
						<div className="center">
							<FaVideo style={{ height: 24, width: "auto", marginTop: 10 }} />
						</div>
					) : null}
				</>
			)
		}
	];

	const seniorColumns = [
		{
			Header: t("common.__date__"),
			accessor: "date",
			Filter: SelectColumnFilter,
			width: 50,
			minWidth: 40,
			Cell: row => {
				return (
					<p>
						<b>{handleDate(row.row.original.dt, "p", userState.userConnected.locale)}</b>
						<br />
						<span className="meta">{handleDate(row.row.original.dt, "P", userState.userConnected.locale)}</span>
					</p>
				);
			}
		},
		{
			Header: t("healthworker.__teamMember__"),
			accessor: "hw_name",
			Filter: SelectColumnFilter,
			width: 100,
			minWidth: 90,
			Cell: row =>
				row.row.values.hw_name ? (
					<Link to={`/user/healthworkeractivities/${row.row.original.hw.id}`}>
						<span className="category-outline-badge grey">
							<b>{row.row.values.hw_name}</b>
						</span>
					</Link>
				) : null
		},
		{
			Header: t("common.__category__"),
			accessor: "aid_title",
			Filter: SelectColumnFilter,
			Cell: row => (
				<>
					<p>
						<b>{row.row.values.aid_title}</b>
					</p>
					<p>
						<span className="mr-2">
							<FaAngleDoubleRight /> {row.row.original.aid_action}
						</span>
						{row.row.original.did_string ? (
							<span className="red">
								<FaAngleRight /> {row.row.original.did_string}
							</span>
						) : null}
					</p>
				</>
			)
		},
		{
			Header: t("activities.__mood__"),
			accessor: "mood",
			Filter: SelectColumnFilter,
			width: 40,
			minWidth: 30,
			Cell: row => (
				<>
					{row.row.original.aid < 6 ? <div className="center">{getActivityMood(row.row.original.aid)}</div> : null}
					{row.row.original.aid === 17 ? (
						<div className="center">
							<FaVideo style={{ height: 24, width: "auto", marginTop: 10 }} />
						</div>
					) : null}
				</>
			)
		}
	];

	const workerColumns = [
		{
			Header: t("common.__date__"),
			accessor: "date",
			Filter: SelectColumnFilter,
			width: 50,
			minWidth: 40,
			Cell: row => {
				return (
					<p>
						<b>{handleDate(row.row.original.dt, "p", userState.userConnected.locale)}</b>
						<br />
						<span className="meta">{handleDate(row.row.original.dt, "P", userState.userConnected.locale)}</span>
					</p>
				);
			}
		},
		{
			Header: t("common.__senior__"),
			accessor: "s_name",
			Filter: SelectColumnFilter,
			width: 100,
			minWidth: 90,
			Cell: row =>
				row.row.values.s_name ? (
					<Link to={`/senior/senioractivities/${row.row.original.s.id}`}>
						<span className="category-outline-badge blue">
							<b>{row.row.values.s_name}</b>
						</span>
					</Link>
				) : null
		},
		{
			Header: t("common.__category__"),
			accessor: "aid_title",
			Filter: SelectColumnFilter,
			Cell: row => (
				<>
					<p>
						<b>{row.row.values.aid_title}</b>
					</p>
					<p>
						<span className="mr-2">
							<FaAngleDoubleRight /> {row.row.original.aid_action}
						</span>
						{row.row.original.did_string ? (
							<span className="red">
								<FaAngleRight /> {row.row.original.did_string}
							</span>
						) : null}
					</p>
				</>
			)
		},
		{
			Header: t("activities.__mood__"),
			accessor: "mood",
			Filter: SelectColumnFilter,
			width: 40,
			minWidth: 30,
			Cell: row => (
				<>
					{row.row.original.aid < 6 ? <div className="center">{getActivityMood(row.row.original.aid)}</div> : null}
					{row.row.original.aid === 17 ? (
						<div className="center">
							<FaVideo style={{ height: 24, width: "auto", marginTop: 10 }} />
						</div>
					) : null}
				</>
			)
		}
	];

	const columns = display => {
		switch (display) {
			case "senior":
				return seniorColumns;
			case "worker":
				return workerColumns;
			default:
				return allColumns;
		}
	};

	return loading ? (
		<Loader />
	) : data.length > 0 ? (
		<div className="mt-3">
			<Table
				columns={columns(display)}
				data={data}
				getRowProps={row => ({
					style: {
						borderTop: row.original.mood ? "solid 2px #778899" : null
					}
				})}
			/>
		</div>
	) : display === "senior" ? (
		<div className="form-card">
			<h5>{t("permissions.__timeline__")}</h5>{" "}
			<p className="mb-3 meta">
				<FaAngleRight /> {t("common.__noElement__")} {t("common.__selectNewDate__")}
			</p>
		</div>
	) : (
		<p className="mt-4">
			<FaAngleRight /> {t("common.__noElement__")} {t("common.__selectNewDate__")}
		</p>
	);
}
