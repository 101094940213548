import React, { useState } from "react";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

interface Props {
	secondaryNav: JSX.Element;
	component: JSX.Element;
}

const PageWithSecondaryNavLayout = ({ secondaryNav, component }: Props): JSX.Element => {
	const [isOpen, setIsOpen] = useState(window.innerWidth > 700 ? true : false);

	return (
		<div className="layout-with-secondary-nav">
			{isOpen ? (
				<div className="d-flex">
					<div className="my-secondary-nav">
						<FaAngleDoubleLeft
							className="secondary-nav-icon"
							role="button"
							onClick={(): void => setIsOpen(prevIsOpen => !prevIsOpen)}
						/>
						<div className="space"></div>
						{secondaryNav}
					</div>
					<div className="col">{component}</div>
				</div>
			) : (
				<div className="d-flex">
					<div className="small-secondary-nav">
						<FaAngleDoubleRight
							className="secondary-nav-icon"
							role="button"
							onClick={(): void => setIsOpen(prevIsOpen => !prevIsOpen)}
						/>
					</div>
					<div className="col">{component}</div>
				</div>
			)}
		</div>
	);
};
export default PageWithSecondaryNavLayout;
