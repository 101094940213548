import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleRight } from "react-icons/fa";
import { displayCategory } from "./RulesDetails";
import EditHomeRule from "./EditHomeRule";
import HandleError from "../../../layout/HandleError";
import ModalContainer from "../../../utils/ModalContainer";
import DisplayRule from "./DisplayRule";
import http from "../../../services/Interceptor";
import * as constant from "../../../constants/Url";
import NoData from "../../../layout/NoData";

export default function HomeRulesSettings({ store, dispatch, homeId, canManageRules }) {
	const { t } = useTranslation("common");
	const [allRules, setAllRules] = useState([]);
	const [homeRules, setHomeRules] = useState([]);
	const [homeUnitRules, setHomeUnitRules] = useState([]);
	const [seniorRules, setSeniorRules] = useState([]);
	const [rulesConcern, setRulesConcern] = useState(1);
	const [rulesError, setRulesError] = useState(false);
	const [show, setShow] = useState({ show: false, info: { rule: null }, title: t("rules.__editNotificationRules__") });
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${constant.HOMESURL}/${homeId}/checker_rules`)
				.then(res => {
					setRulesError(false);
					const rules = res.data.checker_rules;
					const fullData = rules.map(function (rule) {
						return {
							...rule,
							category: displayCategory(rule.rule.actions[0].action_id, rule.rule.type)
						};
					});
					setHomeUnitRules(fullData.filter(rule => rule.home_unit_id !== null));
					setSeniorRules(fullData.filter(rule => rule.senior_id !== null));
					setHomeRules(fullData.filter(rule => rule.senior_id === null && rule.home_unit_id === null));
					setAllRules(fullData);
				})
				.catch(() => setRulesError(true));
		}
	}, [homeId, update]);

	const handleRules = rulesConcern => {
		return (
			<div className="mt-4">
				{rulesConcern === 1 ? (
					<>
						<h5 className="blue mb-4">
							<FaAngleRight className="mr-2 blue" />
							{t("rules.__homeRules__")}
						</h5>
						<DisplayRule
							canManageRules={canManageRules}
							rules={homeRules}
							store={store}
							removeRule={removeRule}
							setShow={setShow}
						/>
					</>
				) : null}
				{rulesConcern === 2 ? (
					<>
						<h5 className="blue mb-4">
							<FaAngleRight className="mr-2 blue" />
							{t("rules.__homeUnitRules__")} :
						</h5>
						<DisplayRule
							canManageRules={canManageRules}
							rules={homeUnitRules}
							store={store}
							removeRule={removeRule}
							setShow={setShow}
						/>
					</>
				) : null}
				{rulesConcern === 3 ? (
					<>
						<h5 className="blue mb-4">
							<FaAngleRight className="mr-2 blue" />
							{t("rules.__seniorRules__")} :
						</h5>
						<DisplayRule
							canManageRules={canManageRules}
							rules={seniorRules}
							store={store}
							removeRule={removeRule}
							setShow={setShow}
						/>
					</>
				) : null}
			</div>
		);
	};

	const removeRule = id => {
		dispatch({
			type: "RESET_ES"
		});
		http
			.delete(`${constant.BASEURL}/checker_rules/${id}`)
			.then(() => {
				setUpdate(prevUpdate => !prevUpdate);
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: <>{t("rules.__removeRuleSuccess__")}</>
				});
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__delError__")} />

			{rulesError ? (
				<NoData />
			) : allRules.length === 0 ? (
				<div className="mt-4">
					<h5>
						<FaAngleRight className="mr-2" />
						{t("common.__noElement__")}
					</h5>
					<p className="meta">{t("notifications.__whyToAddRule__")}</p>
				</div>
			) : (
				<div className="mt-4">
					<div className="btn-group btn-group-sm right" role="group" aria-label="Filtres">
						<button
							type="button"
							className={rulesConcern === 1 ? "btn btn-secondary" : "btn btn-outline-secondary"}
							onClick={() => setRulesConcern(1)}>
							{t("common.__home__")}
						</button>
						<button
							type="button"
							className={rulesConcern === 2 ? "btn btn-secondary" : "btn btn-outline-secondary"}
							onClick={() => setRulesConcern(2)}>
							{t("common.__aHomeUnit__")}
						</button>
						<button
							type="button"
							className={rulesConcern === 3 ? "btn btn-secondary" : "btn btn-outline-secondary"}
							onClick={() => setRulesConcern(3)}>
							{t("common.__senior__")}
						</button>
					</div>
					{handleRules(rulesConcern)}
				</div>
			)}
			<ModalContainer show={show} setShow={setShow}>
				<EditHomeRule
					show={show}
					setShow={setShow}
					update={update}
					setUpdate={setUpdate}
					dispatch={dispatch}
					store={store}
				/>
			</ModalContainer>
		</>
	);
}
