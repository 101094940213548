import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import MyInputText from "../../../utils/Form/MyInputText";
import http from "../../../services/Interceptor";
import * as constant from "../../../constants/Url";

export default function EditType({ editMode, setEditMode, homeDispatch }) {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const [error, setError] = useState(false);
	const type = editMode.homeType;

	const onSubmit = data => {
		setError(false);
		const typeData = {
			home_type: {
				name: data.name,
				description: data.description
			}
		};
		http
			.put(`${constant.BASEURL}/home_types/${type.id}`, typeData)
			.then(res => {
				homeDispatch({
					type: "EDIT_HOME_TYPE",
					payload: res.data.home_type
				});
				homeDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__updateHomeTypeSuccess__")
				});
			})
			.then(() => setEditMode({ edit: false, homeType: null }))
			.catch(() => setError(true));
	};

	return (
		<div className="small-form">
			<h5>{t("home.__updateHomeType__")}</h5>

			{error && (
				<p className="error-msg mt-2">
					<span className="right" role="button" tabIndex="0" onClick={() => setError(false)}>
						<FaTimes />
					</span>
					{t("common.__errorOccured__")}
				</p>
			)}
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MyInputText label="Type" name="name" behavior={{ required: true }} value={type.name} />
					<MyInputText label="Description" name="description" behavior={{ required: true }} value={type.description} />
					<button
						className="btn btn-outline-secondary btn-sm mr-2"
						type="button"
						onClick={() => setEditMode({ edit: false, homeType: null })}>
						{t("common.__cancel__")}
					</button>
					<button className="btn btn-primary btn-sm" type="submit">
						{t("common.__save__")}
					</button>
				</form>
			</FormProvider>
		</div>
	);
}
