import React from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import HandleLocale from "../HandleLocale";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";

interface Props {
	userLocale: string;
	startDate: Date;
	setStartDate?: (date: Date) => void;
	endDate: Date;
	setEndDate?: (date: Date) => void;
	dispatch?: React.Dispatch<any>;
}

const RangePicker = ({ userLocale, startDate, setStartDate, endDate, setEndDate, dispatch }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const locale = HandleLocale(userLocale);

	return (
		<div className="d-flex px-2">
			<DatePicker
				dateFormat="PPP"
				selected={startDate}
				onChange={(date: Date): void =>
					setStartDate ? setStartDate(date) : dispatch && dispatch({ type: "START_DATE", payload: date })
				}
				selectsStart
				startDate={startDate}
				endDate={endDate}
				locale={locale}
				className="form-control form-control-sm"
			/>
			<span className="ml-1 mr-1 mt-1">{t("common.__toward__")}</span>
			<DatePicker
				dateFormat="PPP"
				selected={endDate}
				onChange={(date: Date): void =>
					setEndDate ? setEndDate(date) : dispatch && dispatch({ type: "END_DATE", payload: date })
				}
				selectsEnd
				startDate={startDate}
				endDate={endDate}
				minDate={startDate}
				locale={locale}
				className="form-control form-control-sm"
			/>
		</div>
	);
};

export default RangePicker;
