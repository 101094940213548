import React from "react";
import { useTranslation } from "react-i18next";
import { FaAngleRight } from "react-icons/fa";
import { UserInterface } from "../interfaces/UserInterface";
import OneWeekDiaperChangeStatistics from "../components/HomeManagerBoard/OneWeekDiaperChangeStatistics";
import HomeCounters from "../components/HomeManagerBoard/HomeCounters";
import Shortcuts from "../components/HomeManagerBoard/Shortcuts";
import Statistics from "../components/HomeManagerBoard/Statistics";
import LastNotifications from "../components/HomeManagerBoard/LastNotifications";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	groupId: number | null;
	tenantId: number | null;
	userState: UserInterface;
}

const MyHome_ManagerBoard = ({ homeId, userState, groupId, tenantId }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	const isSuperAdmin = userState.userConnected.is(p.SUPER_ADMIN);
	const isAdmin = userState.userConnected.is(p.ADMIN);
	const isDiaperManager = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]);
	const isHomeManager = userState.userConnected.home_id ? true : false;
	const isGroupManager = userState.userConnected.group_id ? true : false;

	return (
		<>
			{isHomeManager ? null : (
				<>
					<HomeCounters
						homeId={homeId}
						userState={userState}
						groupId={groupId}
						tenantId={tenantId}
						isSuperAdmin={isSuperAdmin}
					/>
				</>
			)}
			{isGroupManager ? null : <Shortcuts userState={userState} />}
			{homeId ? (
				<div className="d-flex">
					<div style={{ width: "100%" }}>
						<Statistics homeId={homeId} userState={userState} />
						{isDiaperManager ? <OneWeekDiaperChangeStatistics homeId={homeId} userState={userState} /> : null}
					</div>
					<LastNotifications userState={userState} homeId={homeId} />
				</div>
			) : isGroupManager || isAdmin ? (
				<div className="big-card">
					<FaAngleRight className="mr-2" />
					<strong>{t("homeBoard.__statistics__")}</strong> : {t("common.__noHome__")}.
				</div>
			) : null}
		</>
	);
};
export default MyHome_ManagerBoard;
