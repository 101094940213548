import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserInterface } from "../interfaces/UserInterface";
import { TabletTabs } from "../utils/Tabs/TabsToDisplay";
import Tabs from "../utils/Tabs/Tabs";
import useDateBeforeOrAfter from "../hooks/useDateBeforeOrAfter";
import SelectHomeInput from "../layout/SelectHomeInput";
import HeaderWithRangeContainer from "../layout/HeaderWithRangeContainer";
import Memo from "../components/MemoT/Memo";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_Memo = ({ homeId, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const userLocale = userState.userConnected.locale;
	const today = new Date();
	const last30Days = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 30 });
	const [startDate, setStartDate] = useState(last30Days);
	const [endDate, setEndDate] = useState(today);

	return homeId ? (
		<>
			<Tabs tabsToDisplay={TabletTabs} currentTab={"MEMO"} />
			<div className="big-card">
				<HeaderWithRangeContainer
					title={t("memo.__memo__")}
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
					userLocale={userLocale}
				/>
				<Memo userState={userState} homeId={homeId} startDate={startDate} endDate={endDate} />
			</div>
		</>
	) : (
		<SelectHomeInput />
	);
};
export default MyHome_Memo;
