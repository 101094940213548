import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fromToUrl } from "@e4ia/e4link_modules.datetime";
import HandleError from "../../layout/HandleError";
import HandleSuccess from "../../layout/HandleSuccess";
import MessagesBoxContainer from "./MessagesBoxContainer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import "./Message.scss";

export default function MessagesBoxSupervisor({
	userState,
	msgState,
	msgDispatch,
	notificationsDispatch,
	startDate,
	endDate,
	msgToDisplay
}) {
	const { t } = useTranslation("common");
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		if (startDate && endDate) {
			const fromTo = fromToUrl(startDate, endDate);
			http
				.get(`${url.MESSAGES_URL}/titles/${fromTo}`)
				.then(res => {
					const messages = res.data.family_user_messages;
					const messagesToDisplay = messages.filter(message => message.type > 0 && message.type < 17);
					const ordredMessages = messagesToDisplay.sort((a, b) => b.updated_at.localeCompare(a.updated_at));
					msgDispatch({
						type: "GET_MESSAGES",
						payload: ordredMessages
					});
				})
				.catch(() => {
					msgDispatch({
						type: "ERROR_LOADING_MESSAGES",
						payload: t("common.__noData__")
					});
				});
		}

		// eslint-disable-next-line
	}, [update, startDate, endDate]);

	return (
		<>
			<HandleError error={msgState.error} dispatch={msgDispatch} message={msgState.msg} />
			<HandleSuccess success={msgState.success} dispatch={msgDispatch} message={msgState.msg} />
			<MessagesBoxContainer
				userState={userState}
				msgDispatch={msgDispatch}
				notificationsDispatch={notificationsDispatch}
				msgToDisplay={msgToDisplay}
				setUpdate={setUpdate}
			/>
		</>
	);
}
