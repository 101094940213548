import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../../utils/Form/MyInputText";
import HandleError from "../../../layout/HandleError";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

const DisplayHealthWorkersType = ({ editMode, setEditMode, store, dispatch }) => {
	const { t } = useTranslation("common");
	const healthWorkerType = editMode.type;
	const typeId = healthWorkerType.id;
	const methods = useForm();
	const { handleSubmit } = methods;

	useEffect(() => {
		return function cleanup() {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	const onSubmit = data => {
		dispatch({
			type: "HIDE_ERROR"
		});
		const type = {
			health_worker_type: {
				name: data.name,
				description: data.description
			}
		};
		http
			.put(`${url.BASEURL}/health_worker_types/${typeId}`, type)
			.then(res => {
				dispatch({
					type: "EDIT_HEALTHWORKER_TYPE",
					payload: res.data.health_worker_type
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("healthworker.__typeUpdated__")
				});
			})
			.then(() => setEditMode({ display: false, type: null }))
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<FormProvider {...methods}>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__editError__")} />

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-group">
					<MyInputText
						label={t("common.__type__")}
						name="name"
						behavior={{ required: true }}
						value={healthWorkerType.name}
					/>
					{healthWorkerType.description ? (
						<MyInputText
							label={t("common.__description__")}
							name="description"
							behavior={{ required: true }}
							value={healthWorkerType.description}
						/>
					) : (
						<MyInputText label={t("common.__description__")} name="description" behavior={{ required: false }} />
					)}
				</div>
				<button
					className="btn btn-outline-secondary btn-sm mr-2"
					type="button"
					onClick={() => setEditMode({ display: false, type: null })}>
					{t("common.__cancel__")}
				</button>
				<button className="btn btn-primary btn-sm" type="submit">
					{t("common.__save__")}
				</button>
			</form>
		</FormProvider>
	);
};

export default DisplayHealthWorkersType;
