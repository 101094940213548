import React from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import { Toolbar } from "./Toolbar";
import "./styles.scss";

export function RichTextarea({ initialContent, setPostContent }) {
	const extensions = [StarterKit, Link, Highlight];

	const editor = useEditor({
		extensions,
		content: initialContent,
		onUpdate: ({ editor }) => {
			setPostContent(editor.getHTML());
		}
	});

	return (
		<div id="rich-editor" className="mt-4 mb-4 post-content">
			<Toolbar editor={editor} />
			<div className="editor-wrapper">
				<EditorContent editor={editor} />
			</div>
		</div>
	);
}
