import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleRight, FaInfoCircle, FaTimes } from "react-icons/fa";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../../utils/Form/MyInputText";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

interface Props {
	show?: { show: boolean; info: null };
	setShow?: (show: { show: boolean; info: null }) => void;
	partnerId: { home_partner_id: number; remote_id: number; remote_url: string } | null;
	homeId: number;
	setSyncIsDone: (syncIsDone: boolean) => void;
}

interface InputValues {
	amdconnect: string;
	remoteId: string;
	remoteUrl: string;
}

const AmdProfileSyncInput = ({ show, setShow, partnerId, homeId, setSyncIsDone }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [error, setError] = useState(false);
	const [displayAmdHomeInfo, setDisplayAmdHomeInfo] = useState(false);
	const methods = useForm<InputValues>();
	const { handleSubmit } = methods;

	const onSubmit = (values: InputValues): void => {
		setError(false);
		const data = {
			home_partner_id: values.amdconnect,
			remote_id: values.remoteId,
			remote_url: values.remoteUrl,
			home_id: homeId
		};
		http
			.post(`${url.HOMESURL}/amd_profile/partner_id`, data)
			.then(() => {
				if (setShow) {
					setShow({ show: false, info: null });
				}
				setSyncIsDone(true);
			})
			.catch(() => setError(true));
	};

	return (
		<FormProvider {...methods}>
			{error ? (
				<p className="error-msg">
					<span className="right ml-2" role="button" tabIndex={0} onClick={(): void => setError(false)}>
						<FaTimes />
					</span>
					{t("common.__errorOccured__")}
				</p>
			) : (
				<br />
			)}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="d-flex justify-content-between">
					<div className="mr-2">
						<p>
							<b>{t("diaper.__enterHomePartnerId__")} :</b>
						</p>
						<p className="meta mb-3">{t("diaper.__homePartnerIdInfo__")}</p>
					</div>
					{show ? null : (
						<FaInfoCircle
							className="moderation-card-icon-ok"
							onClick={(): void => setDisplayAmdHomeInfo(prevState => !prevState)}
						/>
					)}
				</div>
				{displayAmdHomeInfo ? (
					<div className="header-details p-3">
						<p>
							<b>{t("diaper.__howToFindAmdProfileInfo__")}</b>
						</p>
						<p>
							AMD Profil <FaAngleRight /> Etablissements <FaAngleRight /> Fiche de l'établissement
						</p>
						<img src="/img/diaper/amd_connect_home_id.png" className="img-fluid mt-2" alt="extrait de la page AMD Profil" />
					</div>
				) : null}

				<MyInputText
					label="ID Etablissement:"
					name="amdconnect"
					behavior={{ required: true }}
					value={partnerId && partnerId.home_partner_id ? partnerId.home_partner_id : null}
				/>
				<MyInputText
					label="Code site :"
					name="remoteId"
					behavior={{ required: true }}
					value={partnerId && partnerId.remote_id ? partnerId.remote_id : null}
				/>
				<MyInputText
					label="URL site :"
					name="remoteUrl"
					behavior={{ required: true }}
					value={partnerId && partnerId.remote_url ? partnerId.remote_url : null}
				/>
				<button type="submit" className="btn btn-primary btn-sm">
					{t("common.__save__")}
				</button>
			</form>
		</FormProvider>
	);
};
export default AmdProfileSyncInput;
