import http from "./Interceptor";
import * as constant from "../constants/Url";

export const getAvailableSeniors = (homeId, onSuccess, onResponse) => {
	if (homeId) {
		http
			.get(`${constant.HOMESURL}/${homeId}/seniors/available`)
			.then(res => onSuccess(res.data.seniors))
			.catch(() => onResponse());
	}
};

//**************//
/// MODERATION ///
//**************//

export const settingsModeration = (homeId, data, onSuccess, onResponse) => {
	http
		.put(`${constant.HOMESURL}/${homeId}`, data)
		.then(res => onSuccess(res.data.home))
		.catch(err => onResponse(err));
};

export const getAvatarsModeration = (homeId, onSuccess, onResponse) => {
	http
		.get(`${constant.HOMESURL}/${homeId}/seniors/avatars/to/moderate`)
		.then(res => onSuccess(res.data.senior_avatars))
		.catch(err => onResponse(err));
};

export const getPhotoModeration = (homeId, onSuccess, onResponse) => {
	http
		.get(`${constant.HOMESURL}/${homeId}/seniors/photo_frame_items/to/moderate`)
		.then(res => onSuccess(res.data.items))
		.catch(err => onResponse(err));
};

export const getLifestoriesModeration = (homeId, onSuccess, onResponse) => {
	http
		.get(`${constant.HOMESURL}/${homeId}/seniors/life_stories/to/moderate`)
		.then(res => onSuccess(res.data.senior_life_stories))
		.catch(err => onResponse(err));
};

export const sendAvatarModeration = (avatarId, data, onSuccess, onResponse) => {
	http
		.put(`${constant.SENIORSURL}/avatars/${avatarId}/moderate`, data)
		.then(res => onSuccess(res))
		.catch(err => onResponse(err));
};

export const pictureModeration = (pictId, data, onSuccess, onResponse) => {
	http
		.put(`${constant.SENIORSURL}/photo_frame_items${pictId}/moderate`, data)
		.then(res => onSuccess(res))
		.catch(err => onResponse(err));
};

export const lifestoriesModeration = (lifestoryId, data, onSuccess, onResponse) => {
	http
		.put(`${constant.SENIORSURL}/life_stories/${lifestoryId}/moderate`, data)
		.then(res => onSuccess(res))
		.catch(err => onResponse(err));
};

//*********************//
/// SENIOR DOCUMENTS ///
//********************//

export const sendSeniorDocument = (seniorId, data, onSuccess, onResponse) => {
	http
		.post(`${constant.SENIOR_DOCUMENT}/${seniorId}/documents`, data)
		.then(res => onSuccess(res.data.senior_documents))
		.catch(err => onResponse(err));
};

//***********//
/// BADGES ///
//***********//

export const updateHealthWorker = (id, data, onSuccess, onResponse) => {
	http
		.put(`${constant.BASEURL}/users/${id}`, data)
		.then(res => {
			onSuccess(res.data.user);
		})
		.catch(() => {
			onResponse();
		});
};

export const getBadgesAvailable = (homeId, onSuccess, onResponse) => {
	http
		.get(`${constant.HOMESURL}/${homeId}/user_badges/available`)
		.then(res => onSuccess(res.data.user_badges))
		.catch(() => onResponse());
};

//*************//
///  DEVICES  ///
//***********//

export const getAvailableDevices = (homeId, onSuccess, onResponse) => {
	if (homeId) {
		http
			.get(`${constant.HOMESURL}/${homeId}/devices/available`)
			.then(res => {
				onSuccess(res.data.devices);
			})
			.catch(() => onResponse());
	}
};
