import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { handleModelIcon } from "./Illustrations";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import Select from "react-select";
import DateTimePicker from "../../utils/DatePicker/DateTimePicker";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import HandleError from "../../layout/HandleError";

export default function AddProtectionToSenior({ userLocale, senior, setShow, diapers, setUpdate }) {
	const { t } = useTranslation("common");
	const dotation = senior.provisions;
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [error, setError] = useState(false);
	const [changeReview, setChangeReview] = useState({
		dirty_bed: 0,
		diaper_saturated: 0,
		presence_of_stools: 0,
		presence_of_redness: 0,
		torn_off: 0,
		draw_sheet_changed: 0,
		bring_to_wc: 0
	});

	const handleDiapersList = (diaperId, timing) => {
		const diaper = diapers.find(diaper => diaper.id === diaperId);
		return diaper ? (
			<div className="d-flex justify-content-between">
				<p>
					<b className="mr-2">{diaper.name}</b>
				</p>
				{timing ? (
					<p className="red">
						<b>&gt; {timing}</b>
					</p>
				) : null}
				<div>{handleModelIcon(diaper)}</div>
			</div>
		) : null;
	};

	const diapersList = diapers.map(diaper => {
		return {
			...diaper,
			value: diaper.id,
			label: handleDiapersList(diaper.id, null)
		};
	});

	const dotationWithLabel =
		dotation.length > 0
			? dotation.map(elt => {
					return {
						...elt.diaper,
						value: elt.diaper_id,
						label: handleDiapersList(elt.diaper_id, elt.begin_time),
						start_time: elt.start_time,
						begin_time: elt.begin_time
					};
			  })
			: null;

	const now = new Date();
	const nowTime = now.getHours() * 60 + now.getMinutes();
	const dotations = dotationWithLabel ? dotationWithLabel.reverse() : null;
	const firstDotationToDisplay = dotations ? dotations.find(elt => nowTime >= elt.start_time) : null;
	const otherDotations = dotationWithLabel
		? dotationWithLabel.filter(elt => elt.value !== firstDotationToDisplay.value)
		: [];
	const orderedDotations = firstDotationToDisplay ? [firstDotationToDisplay, ...otherDotations] : [...otherDotations];
	const orderedDotationValues = orderedDotations.length > 0 ? orderedDotations.map(elt => elt.value) : orderedDotations;
	const otherDiapers = diapersList.filter(elt => !orderedDotationValues.includes(elt.value));
	const diaperList = [...orderedDotations, ...otherDiapers];
	const [diaperToAdd, setDiaperToAdd] = useState(firstDotationToDisplay ? firstDotationToDisplay.value : null);

	const onSubmit = e => {
		e.preventDefault();
		if (diaperToAdd) {
			setError(false);
			const changeDate = handleDate(selectedDate, "yyyy-MM-dd' 'HH:mm:ss");
			const data = {
				diaper_change: {
					diaper_id: diaperToAdd,
					senior_id: senior.id,
					done_at: changeDate,
					diaper_sensor_id: senior.sensor_id,
					dirty_bed: changeReview.dirty_bed,
					diaper_saturated: changeReview.diaper_saturated,
					presence_of_stools: changeReview.presence_of_stools,
					presence_of_redness: changeReview.presence_of_redness,
					torn_off: changeReview.torn_off,
					draw_sheet_changed: changeReview.draw_sheet_changed,
					bring_to_wc: changeReview.bring_to_wc
				}
			};
			http
				.post(`${url.BASEURL}/rht/senior/diaper/change`, data)
				.then(() => {
					setUpdate(prevState => !prevState);
					setShow({ show: false, resident: null, success: true });
				})
				.catch(() => setError(true));
		} else {
			setError(true);
		}
	};

	return (
		<form onSubmit={onSubmit}>
			<HandleError error={error} setError={setError} noSpace />
			<div className="form-group mt-3">
				{dotation.length > 0 ? (
					<Select
						defaultValue={diaperList[0]}
						name="diapers"
						options={diaperList}
						className="basic-multi-select"
						classNamePrefix="select"
						onChange={e => setDiaperToAdd(e.value)}
						isSearchable={false}
					/>
				) : (
					<Select
						placeholder={t("diaper.__selectDiaper__")}
						name="diapers"
						options={diapersList}
						className="basic-multi-select"
						classNamePrefix="select"
						onChange={e => setDiaperToAdd(e.value)}
						isSearchable={false}
					/>
				)}
			</div>
			<div className="row">
				<div className="col-5">{t("diaper.__dirtyBed__")}</div>
				<div className="col-7">
					<div className="btn-group btn-group-sm mb-1" role="group" aria-label="lit souillé">
						<button
							type="button"
							onClick={() => setChangeReview({ ...changeReview, dirty_bed: 0 })}
							className={changeReview.dirty_bed === 0 ? "btn btn-primary" : "btn btn-outline-secondary"}>
							{t("common.__no__")}
						</button>
						<button
							type="button"
							className={changeReview.dirty_bed === 1 ? "btn btn-primary" : "btn btn-outline-secondary"}
							onClick={() => setChangeReview({ ...changeReview, dirty_bed: 1 })}>
							{t("common.__yes__")}
						</button>
					</div>
				</div>
				<div className="col-5">{t("diaper.__diaperSaturated__")}</div>
				<div className="col-7">
					<div className="btn-group btn-group-sm mb-1" role="group" aria-label="Basic example">
						<button
							type="button"
							onClick={() => setChangeReview({ ...changeReview, diaper_saturated: 0 })}
							className={changeReview.diaper_saturated === 0 ? "btn btn-primary" : "btn btn-outline-secondary"}>
							{t("common.__no__")}
						</button>
						<button
							type="button"
							onClick={() => setChangeReview({ ...changeReview, diaper_saturated: 1 })}
							className={changeReview.diaper_saturated === 1 ? "btn btn-primary" : "btn btn-outline-secondary"}>
							{t("common.__yes__")}
						</button>
						<button
							type="button"
							onClick={() => setChangeReview({ ...changeReview, diaper_saturated: 2 })}
							className={changeReview.diaper_saturated === 2 ? "btn btn-primary" : "btn btn-outline-secondary"}>
							{t("diaper.__half__")}
						</button>
					</div>
				</div>
				<div className="col-5">{t("diaper.__presenceOfStools__")}</div>
				<div className="col-7">
					<div className="btn-group btn-group-sm mb-1" role="group" aria-label="lit souillé">
						<button
							type="button"
							onClick={() => setChangeReview({ ...changeReview, presence_of_stools: 0 })}
							className={changeReview.presence_of_stools === 0 ? "btn btn-primary" : "btn btn-outline-secondary"}>
							{t("common.__no__")}
						</button>
						<button
							type="button"
							className={changeReview.presence_of_stools === 1 ? "btn btn-primary" : "btn btn-outline-secondary"}
							onClick={() => setChangeReview({ ...changeReview, presence_of_stools: 1 })}>
							{t("common.__yes__")}
						</button>
					</div>
				</div>
				<div className="col-5">{t("diaper.__presenceOfRedness__")}</div>
				<div className="col-7">
					<div className="btn-group btn-group-sm mb-1" role="group" aria-label="lit souillé">
						<button
							type="button"
							onClick={() => setChangeReview({ ...changeReview, presence_of_redness: 0 })}
							className={changeReview.presence_of_redness === 0 ? "btn btn-primary" : "btn btn-outline-secondary"}>
							{t("common.__no__")}
						</button>
						<button
							type="button"
							className={changeReview.presence_of_redness === 1 ? "btn btn-primary" : "btn btn-outline-secondary"}
							onClick={() => setChangeReview({ ...changeReview, presence_of_redness: 1 })}>
							{t("common.__yes__")}
						</button>
					</div>
				</div>
				<div className="col-5">{t("diaper.__diaperTornOff__")}</div>
				<div className="col-7">
					<div className="btn-group btn-group-sm mb-1" role="group" aria-label="lit souillé">
						<button
							type="button"
							onClick={() => setChangeReview({ ...changeReview, torn_off: 0 })}
							className={changeReview.torn_off === 0 ? "btn btn-primary" : "btn btn-outline-secondary"}>
							{t("common.__no__")}
						</button>
						<button
							type="button"
							className={changeReview.torn_off === 1 ? "btn btn-primary" : "btn btn-outline-secondary"}
							onClick={() => setChangeReview({ ...changeReview, torn_off: 1 })}>
							{t("common.__yes__")}
						</button>
					</div>
				</div>
				{senior.drawsheet ? (
					<>
						<div className="col-5">{t("diaper.__drawSheetChanged__")}</div>
						<div className="col-7">
							<div className="btn-group btn-group-sm mb-1" role="group" aria-label="lit souillé">
								<button
									type="button"
									onClick={() => setChangeReview({ ...changeReview, draw_sheet_changed: 0 })}
									className={changeReview.draw_sheet_changed === 0 ? "btn btn-primary" : "btn btn-outline-secondary"}>
									{t("common.__no__")}
								</button>
								<button
									type="button"
									className={changeReview.draw_sheet_changed === 1 ? "btn btn-primary" : "btn btn-outline-secondary"}
									onClick={() => setChangeReview({ ...changeReview, draw_sheet_changed: 1 })}>
									{t("common.__yes__")}
								</button>
							</div>
						</div>
					</>
				) : null}
				<div className="col-5">{t("diaper.__bringToWc__")}</div>
				<div className="col-7">
					<div className="btn-group btn-group-sm mb-1" role="group" aria-label="mise aux WC">
						<button
							type="button"
							onClick={() => setChangeReview({ ...changeReview, bring_to_wc: 0 })}
							className={changeReview.bring_to_wc === 0 ? "btn btn-primary" : "btn btn-outline-secondary"}>
							{t("common.__no__")}
						</button>{" "}
						<button
							type="button"
							className={changeReview.bring_to_wc === 1 ? "btn btn-primary" : "btn btn-outline-secondary"}
							onClick={() => setChangeReview({ ...changeReview, bring_to_wc: 1 })}>
							{t("common.__yes__")}
						</button>
					</div>
				</div>

				<div className="col-5">{t("diaper.__drawSheetChanged__")}</div>
				<div className="col-7">
					<div className="btn-group btn-group-sm mb-2" role="group" aria-label="lit souillé">
						<button
							type="button"
							onClick={() => setChangeReview({ ...changeReview, draw_sheet_changed: 0 })}
							className={changeReview.draw_sheet_changed === 0 ? "btn btn-primary" : "btn btn-outline-secondary"}>
							{t("common.__no__")}
						</button>
						<button
							type="button"
							className={changeReview.draw_sheet_changed === 1 ? "btn btn-primary" : "btn btn-outline-secondary"}
							onClick={() => setChangeReview({ ...changeReview, draw_sheet_changed: 1 })}>
							{t("common.__yes__")}
						</button>
					</div>
				</div>
				<div className="col-5">{t("common.__date__")} :</div>
				<div className="col-7">
					<DateTimePicker userLocale={userLocale} startDate={selectedDate} setStartDate={setSelectedDate} />
				</div>
			</div>

			<button className="btn btn-primary btn-sm mt-2" type="submit">
				{t("common.__save__")}
			</button>
		</form>
	);
}
