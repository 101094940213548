import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaVideo } from "react-icons/fa";
import { Link } from "react-router-dom";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

interface Props {
	visioData: { id: number };
	red: boolean;
}

export default function VisioButton({ visioData, red }: Props): JSX.Element | null {
	const { t } = useTranslation("common");
	const [visioTime, setVisioTime] = useState<{ subject: string; room: string } | null>(null);

	useEffect(() => {
		if (visioData.id) {
			http
				.get(`${url.HOMESURL}/controlpanel/visio/${visioData.id}`)
				.then(res => setVisioTime(res.data))
				.catch(() => setVisioTime(null));
		}
	}, [visioData]);

	return visioTime ? (
		<Link to={`/livemeeting/${visioTime.subject}/${visioTime.room}`}>
			<button type="button" className={red ? "visio-tag red" : "visio-tag grey"}>
				<FaVideo className="mr-2" />
				<b>{t("common.__startVisio__")}</b>
			</button>
		</Link>
	) : null;
}
