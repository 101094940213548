import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import HandleError from "../../../layout/HandleError";
import HeaderContainer from "../../../layout/HeaderContainer";
import DisplayName from "../../../utils/DisplayName";
import * as p from "../../../constants/Permissions";

const RoomDetails = ({ userState, devicesFromHome, roomDetails, store, dispatch }) => {
	const { t } = useTranslation("common");
	const seniorsFromHome = store.seniors.seniorsFromHome;
	const homeUnits = store.home.homeUnits;
	const canManageHomeUnit = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]);
	const history = useHistory();

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("home.__theRooms__")}
				addButton={t("home.__addRoom__")}
				link={"/manage/room/addroom/add"}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]}
				userState={userState}
			/>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__noData__")} />
			<div className="header-details">
				<h5>
					{t("common.__room__")} {roomDetails.name}
				</h5>
				<p>
					{roomDetails.home_unit_id ? (
						<>
							{t("common.__homeUnit__")} : {DisplayName(roomDetails.home_unit_id, homeUnits)}{" "}
						</>
					) : null}
					{roomDetails.floor === 0 ? (
						<>
							- {t("home.__floor__")} : {t("common.__rdc__")}
						</>
					) : roomDetails.floor ? (
						<>
							- {t("home.__floor__")} : {roomDetails.floor}
						</>
					) : null}
				</p>
			</div>
			<div className="form-card">
				<h5 className="blue">{t("common.__assignment__")}:</h5>
				{roomDetails.senior_id ? (
					<p>
						{t("common.__senior__")} :
						<Link to={`/senior/profile/${roomDetails.senior_id}`} className="category-outline-badge blue">
							<b> {DisplayName(roomDetails.senior_id, seniorsFromHome)}</b>
						</Link>
					</p>
				) : (
					<p className="meta">{t("home.__noSeniorAssignedToRoom__")}</p>
				)}
				{!roomDetails.device_id ? (
					<p className="meta">{t("home.__noDeviceAssignedToRoom__")}</p>
				) : (
					<>
						<p>
							{t("common.__device__")} : {DisplayName(roomDetails.device_id, devicesFromHome)}
						</p>
						<p className="meta">
							{t("home.__deviceSetUp__")} : {roomDetails.setup}
						</p>
					</>
				)}
			</div>

			<button type="button" className="btn btn-sm btn-outline-secondary mr-2" onClick={() => history.goBack()}>
				{t("common.__back__")}
			</button>
			{canManageHomeUnit ? (
				<Link to={`/manage/room/editroom/${roomDetails.id}`}>
					<button type="button" className="btn btn-sm btn-primary">
						{t("common.__update__")}
					</button>
				</Link>
			) : null}
		</div>
	);
};

export default RoomDetails;
