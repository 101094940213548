import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { SeniorInterface, UserInterface } from "../interfaces/UserInterface";
import useDiaperStore from "../hooks/useDiaperStore";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import SelectHomeInput from "../layout/SelectHomeInput";
import SeniorsSaturationStatistics from "../components/DiaperConnected/SaturationStatistics/SeniorsSaturationStatistics";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const DiaperSaturationStatistics = ({ homeId, userState }: Props): JSX.Element => {
	const isDiaperManager = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]);
	const { diaperStore } = useDiaperStore({ userState, homeId });

	const today = new Date();

	const [startDate, setStartDate] = useState(
		new Date(localStorage.getItem("startDate") || new Date(new Date().setDate(new Date().getDate() - 1)).toISOString())
	);

	const [endDate, setEndDate] = useState(new Date(localStorage.getItem("endDate") || today.toISOString()));
	const [startTime, setStartTime] = useState(new Date(localStorage.getItem("startTime") || today.toISOString()));

	const [endTime, setEndTime] = useState(new Date(localStorage.getItem("endTime") || today.toISOString()));

	const seniorsWithSensor = diaperStore.seniors
		.filter((senior: any) => senior.sensor_id)
		.sort((a: any, b: any) => a.name.localeCompare(b.name));

	const seniors = seniorsWithSensor.map((senior: any) => {
		return {
			label: senior.name + " (" + senior.sensor_mac_address + ")",
			value: senior.id
		};
	});
	const seniorsSaturation = seniorsWithSensor.map((senior: any) => {
		return {
			label: senior.name + " (" + senior.sensor_mac_address + ")",
			value: senior.id
		};
	});

	let selectedS: any;
	const rawData = localStorage.getItem("selectedSenior");

	if (rawData) {
		try {
			selectedS = JSON.parse(rawData);
		} catch (error) {
			selectedS = null;
		}
	} else {
		selectedS = null;
	}

	const matchingSensor = seniorsSaturation.filter((senior: { value: any }) => {
		const nameParts = senior.value ? senior.value : null;
		const selectedName = selectedS && selectedS.value ? selectedS.value : null;

		return nameParts === selectedName ? selectedS.value : false;
	});

	const storedSelectedSenior = matchingSensor[0];
	const [selectedSenior, setSelectedSenior] = useState<SeniorInterface | null>(
		storedSelectedSenior ? storedSelectedSenior : null
	);

	const [delay, setDelay] = useState([5, 10, 15]);
	const [saturationValues, setSaturationValues] = useState([]);
	const [statistics, setStatistics] = useState({});

	useEffect(() => {
		window.scrollTo(0, 0);
		if (homeId && isDiaperManager) {
			const startDateFormatted = format(startDate, "yyyy-MM-dd");
			const endDateFormatted = format(endDate, "yyyy-MM-dd");
			const startTimeFormatted = format(startTime, "HH:mm:ss");
			const endTimeFormatted = format(endTime, "HH:mm:ss");

			if (selectedSenior && selectedSenior.value) {
				const data = {
					from: `${startDateFormatted} ${startTimeFormatted}`,
					to: `${endDateFormatted} ${endTimeFormatted}`,
					senior_id: selectedSenior.value,
					separators: delay
				};
				http
					.post(`${url.BASEURL}/rht/report/average_delay`, data)
					.then(res => {
						setSaturationValues(res.data.values);

						setStatistics(res.data);
					})
					.catch(() => {
						setSaturationValues([]);
						setStatistics([]);
					});
			} else {
				const data = {
					from: `${startDateFormatted} ${startTimeFormatted}`,
					to: `${endDateFormatted} ${endTimeFormatted}`,
					home_id: homeId,
					separators: delay
				};

				http
					.post(`${url.BASEURL}/rht/report/average_delay`, data)
					.then(res => {
						setSaturationValues(res.data.values);

						setStatistics(res.data);
					})
					.catch(() => {
						setSaturationValues([]);
						setStatistics([]);
					});
			}
		}

		// eslint-disable-next-line
	}, [homeId, startDate, endDate, selectedSenior, startTime, endTime, isDiaperManager]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					homeId ? (
						<SeniorsSaturationStatistics
							userState={userState}
							saturationValues={saturationValues}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
							statistics={statistics}
							homeId={homeId}
							delay={delay}
							setDelay={setDelay}
							setSaturationValues={setSaturationValues}
							setStatistics={setStatistics}
							seniorsWithSensor={seniors}
							selectedSenior={selectedSenior}
							setSelectedSenior={setSelectedSenior}
							startTime={startTime}
							setStartTime={setStartTime}
							endTime={endTime}
							setEndTime={setEndTime}
						/>
					) : (
						<SelectHomeInput />
					)
				}
			/>
		</AccessControl>
	);
};
export default DiaperSaturationStatistics;
