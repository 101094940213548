import React, { useCallback, useMemo } from "react";
import Header from "../layout/HeaderNav";
import Footer from "./Footer";
import MainNav from "../layout/MainNav";
import { Redirect } from "react-router-dom";
import * as p from "../constants/Permissions";
import SelectGroupAndHomeInput from "./SelectGroupAndHomeInput";

export default function PageLayout({
	userState,
	userDispatch,
	homeStore,
	homeDispatch,
	homeId,
	setHomeId,
	groupId,
	setGroupId,
	tenantId,
	setTenantId,
	socket,
	children
}) {
	const isAdmin = useMemo(() => userState.userConnected.is(p.ADMIN), []);
	const isSuperAdmin = useMemo(() => userState.userConnected.is(p.SUPER_ADMIN), []);
	const hasGroupAccess = useMemo(() => userState.userConnected.group_id, []);

	const redirectToLogin = useCallback(() => {
		return <Redirect to="/" />;
	}, []);

	return userState.isLogged ? (
		<div className="page-layout">
			<Header
				userState={userState}
				homeId={homeId}
				userDispatch={userDispatch}
				setHomeId={setHomeId}
				setGroupId={setGroupId}
				homeDispatch={homeDispatch}
				socket={socket}
			/>
			<div className="d-flex">
				<div className="responsive-visibility">
					<div className={isSuperAdmin || isAdmin ? "admin-main-nav" : "my-main-nav"}>
						<MainNav userState={userState} homeId={homeId} mainResponsiveMenu={false} />
					</div>
				</div>
				<div className="main-content">
					{isAdmin || isSuperAdmin || hasGroupAccess ? (
						<div className="mb-3">
							<SelectGroupAndHomeInput
								setHomeId={setHomeId}
								homeId={homeId}
								groupId={groupId}
								setGroupId={setGroupId}
								isAdmin={isAdmin}
								hasGroupAccess={hasGroupAccess}
								isSuperAdmin={isSuperAdmin}
								tenantId={tenantId}
								setTenantId={setTenantId}
								homeStore={homeStore}
								homeDispatch={homeDispatch}
							/>
						</div>
					) : null}
					<div className="content">{children}</div>
				</div>
			</div>
			<Footer />
		</div>
	) : (
		redirectToLogin()
	);
}
