import React, { useState } from "react";
import { FaBell, FaEye, FaGenderless, FaSync } from "react-icons/fa";
import { Tooltip } from "../../Buttons/Tooltip";

export function DailyChangeVisualisationContainer({ seniorProvision, eventsList }) {
	const hoursInDay = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
	const [displayDotation, setDisplayDotation] = useState(false);

	const displayEvent = eventType => {
		switch (eventType) {
			case "CHANGE":
				return <FaSync className="daily-change-icon blue" />;
			case "SATURATION":
				return <FaBell className="daily-change-icon red" />;
			case "CONTROL":
				return <FaEye className="daily-change-icon" />;
			default:
				return <FaGenderless className="daily-change-icon white" />;
		}
	};

	const handleEvent = hour => {
		const hourToCompare = hour.length === 1 ? `0${hour}` : hour;
		const eventsToDisplayForThisHour = eventsList.filter(event => event.time.split(":")[0] === hourToCompare);
		const eventsBeforeHalfAnHour = eventsToDisplayForThisHour.filter(event => event.time.split(":")[1] <= 30);
		const eventsAfterHalfAnHour = eventsToDisplayForThisHour.filter(event => event.time.split(":")[1] > 30);
		return (
			<div className="d-flex justify-content-around">
				<span>
					{eventsBeforeHalfAnHour.length > 0
						? eventsBeforeHalfAnHour.map((event, idx) => (
								<Tooltip key={idx} text={event.time}>
									{displayEvent(event.type)}
								</Tooltip>
						  ))
						: " "}
				</span>
				<span>
					{eventsAfterHalfAnHour.length > 0
						? eventsAfterHalfAnHour.map((event, idx) => (
								<Tooltip key={idx} text={event.time}>
									{displayEvent(event.type)}
								</Tooltip>
						  ))
						: " "}
				</span>
			</div>
		);
	};

	const displayDotations = seniorProvision => {
		const dotationsTiming = seniorProvision.map(dotation => dotation.hour);
		const dotationsTimingForADay = [0, ...dotationsTiming, 24];
		const dotationsTimingGrid = dotationsTimingForADay.map(n => n + 1);

		const lastDotationOfTheDay = seniorProvision[seniorProvision.length - 1];
		const diapersDotationListToDisplay =
			seniorProvision[0].hour > 0 ? [lastDotationOfTheDay, ...seniorProvision] : seniorProvision;

		const dotationOfTheDay = diapersDotationListToDisplay.map((dotation, idx) => {
			return displayDotation ? (
				<div
					style={{
						gridColumn: `${dotationsTimingGrid[idx]} / ${dotationsTimingGrid[idx + 1]}`,
						padding: "0.25rem 0 0 0",
						marginRight: "1px",
						backgroundColor: "white",
						height: "auto",
						border: `2px solid ${
							!dotation.diaper.color || dotation.diaper.color === "#FFFFFF" ? "#f6f9fd" : dotation.diaper.color
						}`,
						borderRadius: "7px",
						cursor: "pointer"
					}}
					key={idx}
					onClick={() => setDisplayDotation(false)}>
					<p className="meta center">{dotation.diaper.name}</p>
				</div>
			) : (
				<div
					style={{
						gridColumn: `${dotationsTimingGrid[idx]} / ${dotationsTimingGrid[idx + 1]}`,
						padding: "0.25rem 0 0 0",
						backgroundColor:
							!dotation.diaper.color || dotation.diaper.color === "#FFFFFF" ? "#f6f9fd" : dotation.diaper.color,
						height: "0.6rem",
						border: "2px solid white",
						cursor: "pointer"
					}}
					key={idx}
					onClick={() => setDisplayDotation(true)}></div>
			);
		});
		return dotationOfTheDay;
	};

	return (
		<div className="daily-change-container">
			<div className="daily-change-grid-container">
				{hoursInDay.map(hour => (
					<div key={hour}>
						<div className="daily-change-hours mt-2">{hour}h</div>
					</div>
				))}
				{seniorProvision.length > 0 ? displayDotations(seniorProvision) : null}
				{hoursInDay.map(hour => (
					<div key={hour} className="daily-change-hours mt-2">
						{handleEvent(`${hour}`)}
					</div>
				))}
			</div>
		</div>
	);
}
