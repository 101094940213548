import React from "react";
import { useParams } from "react-router-dom";
import { SeniorInterface, UserInterface } from "../interfaces/UserInterface";
import { SeniorTabs } from "../utils/Tabs/TabsToDisplay";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import Loader from "../layout/Loader";
import SeniorActivities from "../components/Seniors/Activities/SeniorActivities";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const Seniors_Timeline = ({ userState, homeId }: Props): JSX.Element => {
	const { seniorId } = useParams<{ seniorId?: string }>();
	const { store } = useStoreData({ userState, homeId });
	const seniors = store.seniors.seniorsFromHome;
	const seniorSelected = seniorId ? seniors.find((senior: SeniorInterface) => senior.id === parseInt(seniorId)) : null;

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_READ_ACTIVITIES]}>
			{seniorSelected ? (
				<>
					<Tabs tabsToDisplay={SeniorTabs} currentTab={"SENIORS"} />
					<SeniorActivities
						senior={seniorSelected}
						seniorId={seniorId}
						healthWorkers={store.users.healthWorkers}
						userState={userState}
					/>
				</>
			) : (
				<Loader />
			)}
		</AccessControl>
	);
};
export default Seniors_Timeline;
