import React, { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { FaUserPlus } from "react-icons/fa";
import HandleError from "../../../layout/HandleError";

export default function IncoRecipientsAddUser({
	healthworkers,
	homeId,
	setRecipientsListUpdated,
	setAddUser,
	recipientsIds
}) {
	const { t } = useTranslation("common");
	const [newRecipientsList, setNewRecipientsList] = useState([]);
	const [error, setError] = useState(false);

	const availableRecipients = useMemo(
		() => healthworkers.filter(healthworker => !recipientsIds.includes(healthworker.id)),
		[healthworkers, recipientsIds]
	);

	const availableRecipientsOptions = useMemo(
		() =>
			availableRecipients.map(healthworker => ({
				value: healthworker.id,
				label:
					(healthworker.first_name === "badge_general" ? "" : healthworker.first_name) +
					" " +
					healthworker.last_name.toUpperCase() +
					" - (" +
					healthworker.email +
					")"
			})),
		[availableRecipients]
	);

	const handleRecipientsList = useCallback(
		e => {
			const newRecipientsIds = e.map(data => data.value);
			setNewRecipientsList([...recipientsIds, ...newRecipientsIds]);
		},
		[recipientsIds]
	);

	const submitRecipientsList = () => {
		setError(false);
		const data = {
			user_ids: newRecipientsList
		};
		http
			.put(`${url.HOMESURL}/${homeId}/rht/saturation/notification/recipients`, data)
			.then(() => {
				setRecipientsListUpdated(prevState => !prevState);
				setAddUser(false);
			})
			.catch(() => setError(true));
	};

	return (
		<div className=" mb-3">
			<HandleError error={error} setError={setError} noSpace />

			<p>
				<FaUserPlus className="mr-3" />
				<b>{t("common.__selectRecipient__")} :</b>
			</p>
			<Select
				placeholder={t("healthworker.__teamMember__")}
				name="healthworker"
				options={availableRecipientsOptions}
				className="basic-multi-select mb-2 mt-3"
				classNamePrefix="select"
				onChange={e => handleRecipientsList(e)}
				isClearable
				isMulti
			/>
			<button type="button" className="btn btn-sm btn-primary mt-2" onClick={() => submitRecipientsList()}>
				{t("common.__add__")}
			</button>
		</div>
	);
}
