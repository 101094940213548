import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HealthWorkersTabs } from "../utils/Tabs/TabsToDisplay";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import HeaderContainer from "../layout/HeaderContainer";
import SelectHomeInput from "../layout/SelectHomeInput";
import HealthWorkersListByHome from "../components/HealthWorkers/HealthWorkersListByHome";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const HealthWorkers = ({ homeId, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { store, dispatch } = useStoreData({ userState, homeId });

	useEffect(() => {
		return function cleanup(): void {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]}>
			<Tabs tabsToDisplay={HealthWorkersTabs} currentTab={"HEALTHWORKERS"} />
			<div className="big-card">
				<HeaderContainer
					title={t("healthworker.__team__")}
					addButton={t("healthworker.__addAccount__")}
					link={"/user/adduser/newuser"}
					permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]}
					userState={userState}
				/>
				{homeId ? (
					<HealthWorkersListByHome userState={userState} store={store} dispatch={dispatch} homeId={homeId} />
				) : (
					<SelectHomeInput />
				)}
			</div>
		</AccessControl>
	);
};
export default HealthWorkers;
