import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

const MyInputTextarea = props => {
	const { t } = useTranslation("common");
	const {
		register,
		formState: { errors }
	} = useFormContext();

	return (
		<div className="form-group mb-3">
			<label htmlFor={props.name} className="form-label">
				{props.label}
			</label>
			<textarea
				id={props.name}
				name={props.name}
				{...register(props.name, { required: props.behavior.required })}
				cols="10"
				rows={props.rows}
				className="form-control"
			/>
			{props.behavior.required ? (
				<small className="form-text text-muted right" id={`${props.name}Error`}>
					{errors[props.name] ? (
						<span className="error">{t("common.__inputRequired__")}</span>
					) : (
						<span className="required">{t("common.__required__")}</span>
					)}
				</small>
			) : null}
		</div>
	);
};

export default MyInputTextarea;
