import React from "react";
import { useTranslation } from "react-i18next";
import { handleModelIcon } from "../Illustrations";

export const DisplayDiaperModal = ({ show }) => {
	const { t } = useTranslation("common");
	const diaper = show.info.diaper;

	return (
		<>
			<h5 className="blue">{diaper.name}</h5>
			<table className="table table-bordered">
				<tbody>
					<tr>
						<th scope="row">{t("news.__pictSize__")}</th>
						<td>{diaper.size}</td>
					</tr>
					<tr>
						<th scope="row">{t("diaper.__saturation__")}</th>
						<td>
							<span className="mr-3">{diaper.saturation}</span> {handleModelIcon(diaper)}
						</td>
					</tr>
					<tr>
						<th scope="row">{t("diaper.__saturationThreshold__")}</th>
						<td>{diaper.threshold}</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};
