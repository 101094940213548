import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegEdit } from "react-icons/fa";
import { SeniorInterface } from "../../interfaces/UserInterface";
import { Tooltip } from "./Tooltip";

interface Props {
	setEditMode: (data: { edit: boolean; senior: SeniorInterface }) => void;
	seniorSelected: SeniorInterface;
}

const EditIcon = ({ setEditMode, seniorSelected }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<Tooltip text={t("common.__update__")}>
			<FaRegEdit
				className="board-card-icon"
				role="button"
				onClick={(): void => setEditMode({ edit: true, senior: seniorSelected })}
			/>
		</Tooltip>
	);
};
export default EditIcon;
