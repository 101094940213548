import React from "react";
import { UserInterface } from "../interfaces/UserInterface";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import MainBoard from "../components/UserBoards/MainBoard";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_GeneralView = ({ userState, homeId }: Props): JSX.Element => {
	return (
		<PageWithSecondaryNavLayout
			secondaryNav={<HomesSecondaryNav userState={userState} />}
			component={<MainBoard userState={userState} homeId={homeId} />}
		/>
	);
};
export default MyHome_GeneralView;
