import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { sub } from "date-fns";
import { NotificationsContext } from "../context/NotificationsContext";
import { MailBoxTabs } from "../utils/Tabs/TabsToDisplay";
import { SeniorInterface, UserInterface } from "../interfaces/UserInterface";
import HeaderWithRangeContainer from "../layout/HeaderWithRangeContainer";
import SelectedFamilyMessages from "../components/Messages/SelectedFamilyMessages";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
}

const MessagesBox_SelectedSenior = ({ userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const history = useHistory<{ senior: SeniorInterface }>();
	const { senior } = history.location.state;
	const userLocale = userState.userConnected.locale;
	const canInitiateMessagesToFamily = userState.userConnected.is_in([
		p.CAN_INITIATE_MESSAGES_TO_FAMILY,
		p.SUPER_ADMIN,
		p.ADMIN
	]);
	const { msgState, msgDispatch, notificationsDispatch } = useContext(NotificationsContext);
	const today = new Date();
	const last31Days = sub(today, { days: 31 });
	const [startDate, setStartDate] = useState(msgState.startDate ? msgState.startDate : last31Days);
	const [endDate, setEndDate] = useState(msgState.endDate ? msgState.endDate : today);

	const canManageMessages = userState.userConnected.is_in([
		p.ADMIN,
		p.SUPER_ADMIN,
		p.ALL_FAMILY_USER_MESSAGES,
		p.MY_FAMILY_USER_MESSAGES
	]);

	useEffect(() => {
		if (startDate) {
			msgDispatch({
				type: "MSG_STARTDATE",
				payload: startDate
			});
		}
	}, [startDate]);

	useEffect(() => {
		if (endDate) {
			msgDispatch({
				type: "MSG_ENDDATE",
				payload: endDate
			});
		}
	}, [endDate]);

	useEffect(() => {
		return function cleanup() {
			msgDispatch({
				type: "RESET_ES"
			});
		};
	}, []);

	return (
		<>
			{canInitiateMessagesToFamily ? <Tabs tabsToDisplay={MailBoxTabs} currentTab={"MESSAGES"} /> : null}

			<div className="big-card">
				<HeaderWithRangeContainer
					title={t("common.__mailbox__")}
					startDate={msgState.startDate}
					setStartDate={setStartDate}
					endDate={msgState.endDate}
					setEndDate={setEndDate}
					userLocale={userLocale}
				/>
				{canManageMessages ? (
					<SelectedFamilyMessages
						userState={userState}
						senior={senior}
						msgState={msgState}
						msgDispatch={msgDispatch}
						notificationsDispatch={notificationsDispatch}
						startDate={startDate}
						endDate={endDate}
					/>
				) : null}
			</div>
		</>
	);
};

export default MessagesBox_SelectedSenior;
