import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { HealthWorkersTabs } from "../utils/Tabs/TabsToDisplay";
import { UserInterface } from "../interfaces/UserInterface";
import { HomeStoreInterface } from "../interfaces/HomeInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import Tabs from "../utils/Tabs/Tabs";
import EditHealthWorker from "../components/HealthWorkers/EditHealthWorker";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
	homeStore: HomeStoreInterface;
}

const HealthWorkers_EditHealthWorkers = ({ homeId, userState, homeStore }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { id } = useParams<{ id: string }>();
	const { store, dispatch } = useStoreData({ userState, homeId });
	const [userToEdit, setUserToEdit] = useState(null);

	useEffect(() => {
		return function cleanup(): void {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (id) {
			http
				.get(`${url.BASEURL}/users/${id}`)
				.then(res => setUserToEdit(res.data.user))
				.catch(() => setUserToEdit(null));
		}
	}, [id]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]}>
			<Tabs tabsToDisplay={HealthWorkersTabs} currentTab={"HEALTHWORKERS"} />
			<div className="big-card">
				<h2>{t("common.__update__")}</h2>
				<EditHealthWorker
					userToEdit={userToEdit}
					store={store}
					dispatch={dispatch}
					userState={userState}
					homeStore={homeStore}
				/>
			</div>
		</AccessControl>
	);
};
export default HealthWorkers_EditHealthWorkers;
