import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import {
	FaAngleRight,
	FaCalendarDay,
	FaCamera,
	FaCheck,
	FaExclamationCircle,
	FaTimes,
	FaUsersCog,
	FaVideo
} from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import VideoPlayer from "../../utils/VideoPlayer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export const DisplayVideoModal = ({ show }) => {
	return (
		<div className="video-container mt-3">
			<VideoPlayer uriVideo={show.info.video.path} contentType={show.info.video.content_type} />
		</div>
	);
};

export const DisplayPictureModal = ({ show }) => {
	return show.info.picture ? (
		show.info.type === "blob" ? (
			<img src={show.info.picture} className="slideshow" alt="post illustration" />
		) : show.info.type === "initial" ? (
			<img src={`/storages/blog_media${show.info.picture}`} className="slideshow" alt="post illustration" />
		) : null
	) : null;
};

// export const DisplayPicturesModal = ({ showPictures, setShowPictures }) => {
// 	useEffect(() => {
// 		if (showPictures.pictures) {
// 			setSelectedImg(showPictures.pictures[0]);
// 		}
// 	}, [showPictures.pictures]);
// 	const pictures = showPictures.pictures;
// 	const [selectedImg, setSelectedImg] = useState(null);
// 	const content = showPictures.show && (
// 		<div className="overlay">
// 			<div className="dialog">
// 				<div className="close-modal" role="button" onClick={() => setShowPictures({ show: false, pictures: null })}>
// 					<FaTimes />
// 				</div>
// 				{selectedImg ? (
// 					<div className="center mt-5">
// 						<img src={`/storages/blog_media${selectedImg.path}`} className="slideshow" alt="post illustration" />
// 					</div>
// 				) : null}
// 				<div className="slideshow-background">
// 					{pictures.map((pict, index) => (
// 						<img
// 							src={`/storages/blog_media${pict.path}`}
// 							key={index}
// 							className="preview-pict-slideshow fake-link ml-2"
// 							onClick={() => setSelectedImg(pict)}
// 							alt="other post illustrations"
// 						/>
// 					))}
// 				</div>
// 			</div>
// 		</div>
// 	);
// 	return content;
// };

export const DisplayModerationPostModal = ({ show, notificationsDispatch, userLocale }) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [error, setError] = useState(false);
	const post = show.info.post;

	const handleMedias = medias => {
		const videoList = medias.filter(media => media.content_type.split("/")[0] === "video");
		const photoCounter = medias.length - videoList.length;

		return (
			<>
				<span className="mr-4">
					<FaVideo className="mr-1" /> {videoList.length}
				</span>
				<span className="mr-4">
					<FaCamera className="mr-1" /> {photoCounter}
				</span>
			</>
		);
	};

	const newsValidation = (id, boolean) => {
		const data = {
			accepted: boolean
		};
		setError(false);
		http
			.put(`${url.BASEURL}/homes/blog_posts/${id}/moderate`, data)
			.then(() => {
				notificationsDispatch({
					type: "MODERATE_BLOG"
				});
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth"
				});
				history.push(`/blog/news`);
			})
			.catch(() => setError(true));
	};

	return (
		<>
			{error ? (
				<p className="error-msg mt-2">
					<span className="right" role="button" tabIndex="0" onClick={() => setError(false)}>
						<FaTimes />
					</span>
					{t("common.__errorOccured__")}
				</p>
			) : null}
			<div className="header-details">
				<p>
					{t("news.__title__")} : <b>{post.title}</b>
				</p>
				<p className="meta">
					<span className="mr-4">
						<FaCalendarDay className="mr-1" /> {handleDate(post.date, "P", userLocale)}
					</span>
					{handleMedias(post.medias)}
				</p>
				{post.focused_on ? (
					<p className="meta mt-2 mb-4">
						<FaUsersCog className="mr-2 blue" style={{ height: "1rem", width: "auto" }} />
						{post.seniors_focused_on.map(senior => (
							<Link to={`/senior/profile/${senior.id}`} key={senior.id}>
								<span className="category-outline-badge blue mr-2">
									<b>
										{senior.last_name.toUpperCase()} {senior.first_name}
									</b>
								</span>
							</Link>
						))}
					</p>
				) : null}
			</div>
			<div className="center">
				<button type="button" className="btn btn-sm btn-outline-secondary mr-2" onClick={() => history.push(`/blog/news`)}>
					{t("news.__dontModerateNow__")}
				</button>
				<button type="button" className="btn btn-sm btn-primary" onClick={() => newsValidation(post.id, true)}>
					<FaCheck /> {t("news.__publishPost__")}
				</button>
			</div>
		</>
	);
};

export const DisplayRemovePostModal = ({ show, setShow, blogDispatch, redirection }) => {
	const { t } = useTranslation("common");
	const [error, setError] = useState(false);
	const history = useHistory();

	const removePost = id => {
		setError(false);
		http
			.delete(`${url.HOMESURL}/blog_posts/${id}`)
			.then(() => {
				blogDispatch({
					type: "DELETE_NEWS",
					payload: id
				});
				setShow({ show: false, info: { postId: null, postTitle: null }, title: " " });
				if (redirection) {
					history.push("/blog/news");
				}
			})
			.catch(() => setError(true));
	};

	const closeModal = () => {
		setError(false);
		setShow({ show: false, info: { postId: null, postTitle: null }, title: " " });
	};

	return (
		<>
			{error ? (
				<p className="error-msg mt-2">
					<span className="right" role="button" tabIndex="0" onClick={() => setError(false)}>
						<FaTimes />
					</span>
					{t("common.__errorOccured__")} <b>{t("common.__delError__")}</b>
				</p>
			) : (
				<br />
			)}

			<p>
				<FaAngleRight className="mr-2" />
				<strong>{t("news.__postSelected__")} :</strong> {show.info.postTitle}
			</p>

			<p className="mt-3 red">
				<FaExclamationCircle className="social-icon mr-2" />
				{t("news.__removePostWarning__")}
			</p>
			<div className="center mt-4">
				<button type="button" className="btn btn-sm btn-outline-secondary mr-2" onClick={() => closeModal()}>
					{t("common.__cancel__")}
				</button>
				<button type="button" className="btn btn-sm btn-danger" onClick={() => removePost(show.info.postId)}>
					<FaCheck /> {t("calendar.__remove__")}
				</button>
			</div>
		</>
	);
};
