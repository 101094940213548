import React from "react";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import AddHomeUnit from "../components/Home/HomeUnit/AddHomeUnit";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_AddHomeUnit = ({ userState, homeId }: Props): JSX.Element => {
	const { store, dispatch } = useStoreData({ userState, homeId });

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<HomesSecondaryNav userState={userState} />}
				component={<AddHomeUnit store={store} userState={userState} homeId={homeId} dispatch={dispatch} />}
			/>
		</AccessControl>
	);
};
export default MyHome_AddHomeUnit;
