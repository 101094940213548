import React from "react";
import { useTranslation } from "react-i18next";

const RgpdContainer = (): JSX.Element => {
	const { t } = useTranslation("rgpd");

	return (
		<>
			<p>{t("rgpd.__intro__")}</p>
			<h3>{t("rgpd.__1__ruleTitle__")}</h3>
			<p>{t("rgpd.__1__rule__")}</p>
			<h3>{t("rgpd.__2__ruleTitle__")}</h3>
			<p>{t("rgpd.__2__rule__1__")}</p>
			<h3 className="center">
				<a href="mailto:rgpd@e4-ia.fr">
					<b>rgpd@e4-ia.fr</b>
				</a>
			</h3>
			<p>{t("rgpd.__2__rule__2__")}</p>
			<h3>{t("rgpd.__3__ruleTitle__")}</h3>
			<p>{t("rgpd.__3__rule__1__")}</p>
			<p>{t("rgpd.__3__rule__2__")}</p>
			<h3>{t("rgpd.__4__ruleTitle__")}</h3>
			<p>{t("rgpd.__4__rule__")}</p>
			<h3>{t("rgpd.__5__ruleTitle__")}</h3>
			<p>{t("rgpd.__5__rule__1__")}</p>
			<ul>
				<li>
					<p>{t("rgpd.__5__rule__2__")}</p>
				</li>
				<li>
					<p>{t("rgpd.__5__rule__3__")}</p>
				</li>
			</ul>
			<h3>{t("rgpd.__6__ruleTitle__")}</h3>
			<p>{t("rgpd.__6__rule__1__")}</p>
			<p>{t("rgpd.__6__rule__2__")}</p>
			<p>{t("rgpd.__6__rule__3__")}</p>
			<p>{t("rgpd.__6__rule__4__")}</p>
			<h3>{t("rgpd.__7__ruleTitle__")}</h3>
			<p>{t("rgpd.__7__rule__")}</p>
			<h3>{t("rgpd.__8__ruleTitle__")}</h3>
			<p>{t("rgpd.__8__rule__intro__")}</p>
			<h3>{t("rgpd.__8__rule__1__title__")}</h3>
			<p>{t("rgpd.__8__rule__1__")}</p>
			<h3>{t("rgpd.__8__rule__2__title__")}</h3>
			<p>{t("rgpd.__8__rule__2__1__")}</p>
			<p>{t("rgpd.__8__rule__2__2__")}</p>
			<p>{t("rgpd.__8__rule__2__3__")}</p>
			<p>{t("rgpd.__8__rule__2__4__")}</p>
			<p>{t("rgpd.__8__rule__2__5__")}</p>
			<p>{t("rgpd.__8__rule__2__6__")}</p>
			<h3>{t("rgpd.__8__rule__3__title__")}</h3>
			<p>{t("rgpd.__8__rule__3__")}</p>
			<h3>{t("rgpd.__8__rule__4__title__")}</h3>
			<p>{t("rgpd.__8__rule__4__1__")}</p>
			<p>{t("rgpd.__8__rule__4__2__")}</p>
			<ul>
				<li>
					<p>{t("rgpd.__8__rule__4__3__")}</p>
				</li>
				<li>
					<p>{t("rgpd.__8__rule__4__4__")}</p>
				</li>
				<li>
					<p>{t("rgpd.__8__rule__4__5__")}</p>
				</li>
			</ul>
			<h3>{t("rgpd.__9__ruleTitle__")}</h3>
			<p>{t("rgpd.__9__rule__")}</p>
		</>
	);
};
export default RgpdContainer;
