import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { UserInterface } from "../interfaces/UserInterface";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
}

const AdminSecondaryNav = ({ userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { component } = useParams<{ component: string }>();
	const location = useLocation();
	const isSuperAdmin = userState.userConnected.is(p.SUPER_ADMIN);

	return (
		<>
			{isSuperAdmin ? (
				<>
					<div className="space"></div>
					<h3>Super Admin</h3>
					<Link to="/support/types">
						<p className="secondary-nav-link">
							{location.pathname === "/support/types" ? (
								<>
									<FaRegArrowAltCircleRight className="mr-2" />
									<b>{t("home.__homeTypes__")}</b>
								</>
							) : (
								t("home.__homeTypes__")
							)}
						</p>
					</Link>
					<Link to="/support/healthworkertypes">
						<p className="secondary-nav-link">
							{location.pathname === "/support/healthworkertypes" ? (
								<>
									<FaRegArrowAltCircleRight className="mr-2" />
									<b>Types de personnel</b>
								</>
							) : (
								"Types de personnel"
							)}
						</p>
					</Link>
					<Link to="/support/tenant/admintenants">
						<p className="secondary-nav-link">
							{component === "tenant" ? (
								<>
									<FaRegArrowAltCircleRight className="mr-2" />
									<b>{t("common.__tenants__")}</b>
								</>
							) : (
								t("common.__tenants__")
							)}
						</p>
					</Link>
					<Link to="/support/devicesactions">
						<p className="secondary-nav-link">
							{location.pathname === "/support/devicesactions" ? (
								<>
									<FaRegArrowAltCircleRight className="mr-2" />
									<b>Etat des tablettes</b>
								</>
							) : (
								"Etat des tablettes"
							)}
						</p>
					</Link>
					<Link to="/support/checkvisio">
						<p className="secondary-nav-link">
							{location.pathname === "/support/checkvisio" ? (
								<>
									<FaRegArrowAltCircleRight className="mr-2" />
									<b>Visio à la demande</b>
								</>
							) : (
								"Visio à la demande"
							)}
						</p>
					</Link>
				</>
			) : null}
			<div className="space"></div>
			<h3>Admin</h3>
			<Link to="/support/homegroups">
				<p className="secondary-nav-link">
					{location.pathname === "/support/homegroups" ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<b>{t("home.__groups__")}</b>
						</>
					) : (
						t("home.__groups__")
					)}
				</p>
			</Link>
			<Link to="/support/organisation/allhomes">
				<p className="secondary-nav-link">
					{component === "organisation" ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<b>{t("home.__homes__")}</b>
						</>
					) : (
						t("home.__homes__")
					)}
				</p>
			</Link>
			<Link to="/support/devices">
				<p className="secondary-nav-link">
					{location.pathname === "/support/devices" ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<b>{t("device.__devices__")}</b>
						</>
					) : (
						t("device.__devices__")
					)}
				</p>
			</Link>

			<div className="space"></div>
			<h3>Support</h3>
			<Link to="/support/adminuserdebug">
				<p className="secondary-nav-link">
					{location.pathname === "/support/adminuserdebug" ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<b>{t("users.__users__")}</b>
						</>
					) : (
						t("users.__users__")
					)}
				</p>
			</Link>
			<Link to="/support/adminfamilydebug">
				<p className="secondary-nav-link">
					{location.pathname === "/support/adminfamilydebug" ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<b>Comptes Family</b>
						</>
					) : (
						"Comptes Family"
					)}
				</p>
			</Link>

			<Link to="/support/admin">
				<p className="secondary-nav-link">
					{location.pathname === "/support/admin" ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<b>{t("device.__devicesData__")}</b>
						</>
					) : (
						t("device.__devicesData__")
					)}
				</p>
			</Link>
			<Link to="/support/admindiapers/saturation">
				<p className="secondary-nav-link">
					{location.pathname === "/support/admindiapers/saturation" ||
					location.pathname === "/support/admindiapers/change" ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<b>Suivi INCO</b>
						</>
					) : (
						"Suivi INCO"
					)}
				</p>
			</Link>
		</>
	);
};

export default AdminSecondaryNav;
