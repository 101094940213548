import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { UserInterface } from "../interfaces/UserInterface";
import useAmdGoals from "../hooks/useAmdGoals";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const DiaperSecondaryNav = ({ homeId, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { component } = useParams<{ component: string }>();
	const hasAmdGoals = useAmdGoals({ homeId, userState });

	return (
		<>
			<h3 className="mt-4">{t("diaper.__incoManagement__")}</h3>
			<Link to="/inco/cc/diaperalert/sensor">
				<p className="secondary-nav-link mt-1">
					{component === "cc" ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<b>{t("diaper.__seniorMonitoring__")}</b>
						</>
					) : (
						t("diaper.__seniorMonitoring__")
					)}
				</p>
			</Link>
			<Link to="/inco/sat/saturationdiapers/0">
				<p className="secondary-nav-link">
					{component === "sat" ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<b>{t("diaper.__saturationMonitoring__")}</b>
						</>
					) : (
						t("diaper.__saturationMonitoring__")
					)}
				</p>
			</Link>
			<Link to="/inco/chg/changediaperstat/0">
				<p className="secondary-nav-link">
					{component === "chg" ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<b>{t("diaper.__changeMonitoring__")}</b>
						</>
					) : (
						t("diaper.__changeMonitoring__")
					)}
				</p>
			</Link>
			{process.env.REACT_APP_FOR_GOHY === "true" ? null : hasAmdGoals && hasAmdGoals.isAmdCustomer ? (
				<Link to="/inco/dotation/dotationstat">
					<p className="secondary-nav-link">
						{component === "dotation" ? (
							<>
								<FaRegArrowAltCircleRight className="mr-2" />
								<b>{t("diaper.__provisionMonitoring__")}</b>
							</>
						) : (
							t("diaper.__provisionMonitoring__")
						)}
					</p>
				</Link>
			) : null}
			<h3 className="mt-4">{t("common.__params__")}</h3>
			<Link to="/inco/diaper/homediapers">
				<p className="secondary-nav-link">
					{component === "diaper" ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<b>{t("diaper.__diapers__")}</b>
						</>
					) : (
						t("diaper.__diapers__")
					)}
				</p>
			</Link>
			<Link to="/inco/sensor/diapersensors">
				<p className="secondary-nav-link">
					{component === "sensor" ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<b>{t("diaper.__sensors__")}</b>
						</>
					) : (
						t("diaper.__sensors__")
					)}
				</p>
			</Link>
			<Link to="/inco/inco/settings">
				<p className="secondary-nav-link">
					{component === "inco" ? (
						<>
							<FaRegArrowAltCircleRight className="mr-2" />
							<b>{t("common.__settings__")}</b>
						</>
					) : (
						t("common.__settings__")
					)}
				</p>
			</Link>
		</>
	);
};

export default DiaperSecondaryNav;
