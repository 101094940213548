import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DisplayModerationPostModal } from "./NewsMediaModal";
import { FaTimes } from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { Progressbar } from "@e4ia/e4link_modules.progressbar";
import ModalContainer from "../../utils/ModalContainer";
import PostManager from "./PostManagement/PostManager";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function EditNews({
	userState,
	post,
	blogStore,
	blogDispatch,
	homeId,
	seniorsFromHome,
	isBlogModerator,
	notificationsDispatch,
	homeUnits
}) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [uploadSize, setUploadSize] = useState(0);
	const [medias, setMedias] = useState([]);
	const [initialMedias, setInitialMedias] = useState(post.medias);
	const initialDate = handleDate(post.date, "yyyy-MM-dd");
	const [selectedDate, setSelectedDate] = useState(initialDate);
	const [title, setTitle] = useState(post.title);
	const [postContent, setPostContent] = useState(post.body);
	const [showModerationPost, setShowModerationPost] = useState({ show: false, info: { post: null }, title: "" });
	const initialSeniorsSelectedIds = post.seniors_focused_on.map(senior => senior.id);
	const initialSeniorsSelected = seniorsFromHome.filter(senior => initialSeniorsSelectedIds.includes(senior.id));
	const [seniorsSelected, setSeniorsSelected] = useState(initialSeniorsSelectedIds);
	const [contentError, setContentError] = useState(false);
	const [editMedias, setEditMedias] = useState(false);
	const [progress, setProgress] = useState({ size: 0, maxSize: 0 });

	const onSubmit = e => {
		e.preventDefault();
		if (postContent.length > 4 && title.length >= 3) {
			blogDispatch({
				type: "LOADING_NEWS"
			});
			setContentError(false);
			const postDate = handleDate(selectedDate, "yyyy-MM-dd' 'HH:mm:ss");

			const blogPostData = new FormData();

			if (editMedias) {
				const existingMedias = initialMedias.map(media => media.path_original);
				for (let i = 0; i < medias.length; i++) {
					blogPostData.append("medias[]", medias[i]);
				}
				for (let i = 0; i < existingMedias.length; i++) {
					blogPostData.append("existing_medias[]", existingMedias[i]);
				}
			}

			blogPostData.append("title", title);
			blogPostData.append("body", postContent);
			blogPostData.append("date", postDate);
			blogPostData.append("home_id", homeId);
			blogPostData.append("senior_ids", JSON.stringify(seniorsSelected));

			http
				.put(
					editMedias ? `${url.HOMESURL}/blog_posts/${post.id}` : `${url.HOMESURL}/blog_posts/${post.id}/without_medias`,
					blogPostData,
					{
						onUploadProgress: progressEvent => {
							setProgress({ size: progressEvent.loaded, maxSize: progressEvent.total + 1000 });
						}
					}
				)
				.then(res => {
					blogDispatch({
						type: "EDIT_NEWS",
						payload: res.data.blog_post
					});
					if (isBlogModerator && post.accepted === null) {
						setShowModerationPost({ show: true, info: { post: res.data.blog_post }, title: t("news.__moderateThePost__") });
					} else {
						window.scrollTo({
							top: 0,
							left: 0,
							behavior: "smooth"
						});
						history.push(`/blog/news`);
					}
				})
				.catch(() => {
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth"
					});
					blogDispatch({
						type: "ERROR_NEWS"
					});
				});
		} else {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
			setContentError(true);
		}
	};

	return (
		<>
			{blogStore.error ? <p className="error-msg">{t("common.__editError__")}</p> : <br />}
			{contentError ? (
				<p className="error-msg">
					<span className="right" role="button" tabIndex="0" onClick={() => setContentError(false)}>
						<FaTimes />
					</span>
					{t("news.__addNewsContentError__")}
				</p>
			) : null}

			{blogStore.loading ? (
				<div className="header-details center">
					<Progressbar
						size={progress.size}
						maxSize={progress.maxSize}
						isLoader
						alertMessage={t("news.__addNewsLoading__")}
					/>
				</div>
			) : (
				<form onSubmit={onSubmit} encType="multipart/form-data">
					<PostManager
						medias={medias}
						setMedias={setMedias}
						editMedias={editMedias}
						setEditMedias={setEditMedias}
						seniors={seniorsFromHome}
						postContent={postContent}
						setPostContent={setPostContent}
						title={title}
						setTitle={setTitle}
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						initialSeniorsSelected={initialSeniorsSelected}
						setSeniorsSelected={setSeniorsSelected}
						initialMedias={initialMedias}
						setInitialMedias={setInitialMedias}
						uploadSize={uploadSize}
						setUploadSize={setUploadSize}
						seniorsSelected={seniorsSelected}
						homeUnits={homeUnits}
					/>
				</form>
			)}
			<ModalContainer show={showModerationPost} setShow={setShowModerationPost}>
				<DisplayModerationPostModal
					notificationsDispatch={notificationsDispatch}
					userLocale={userState.userConnected.locale}
					show={showModerationPost}
				/>
			</ModalContainer>
		</>
	);
}
