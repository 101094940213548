import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../../utils/Form/MyInputText";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import HandleError from "../../../layout/HandleError";
import { useTranslation } from "react-i18next";
import MyInputSelect from "../../../utils/Form/MyInputSelect";

const EditHome = ({ homeId, homeStore, homeDispatch, isAdmin, isSuperAdmin, tenantId }) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const myHome = homeStore.home.myHome;
	const methods = useForm();
	const { handleSubmit } = methods;

	const [groupsFromTenant, setGroupsFromTenant] = useState([]);

	useEffect(() => {
		if (isSuperAdmin && tenantId) {
			http
				.get(`${url.BASEURL}/tenants/${tenantId}/home_groups`)
				.then(res => {
					setGroupsFromTenant(res.data.home_groups);
				})
				.catch(() => setGroupsFromTenant([]));
		}
	}, [tenantId, isSuperAdmin]);

	const onSubmit = data => {
		const homeData = {
			home: {
				name: data.name,
				address: data.address,
				latitude: data.latitude,
				longitude: data.longitude,
				phone: data.phone,
				url: data.url,
				group_id: isSuperAdmin ? data.group : myHome.group_id
			}
		};
		http
			.put(`${url.BASEURL}/homes/${homeId}`, homeData)
			.then(res => {
				homeDispatch({
					type: "GET_MY_HOME",
					payload: res.data.home
				});
				homeDispatch({
					type: "UPDATE_HOME",
					payload: res.data.home
				});
				homeDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__updateHomeSuccess__")
				});
			})
			.then(() => history.push("/manage/me/myHome"))
			.catch(() => {
				homeDispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-md-6">
					<h2>{t("common.__home__")}</h2>
				</div>
				<div className="col-md-6">
					<button className="btn btn-sm btn-outline-primary right" type="button" onClick={() => history.goBack()}>
						{t("common.__back__")}
					</button>
				</div>
			</div>

			<div className="small-form">
				<h5 className="blue">{t("home.__updateHome__")}</h5>
				<HandleError error={homeStore.es.error} dispatch={homeDispatch} message={t("common.__updateError__")} />

				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form-group">
							<MyInputText label={t("common.__name__")} name="name" behavior={{ required: true }} value={myHome.name} />
							{isAdmin ? (
								<>
									<MyInputText
										label={t("home.__address__")}
										name="address"
										behavior={{ required: true }}
										value={myHome.address}
									/>
									<div className="row">
										<div className="col">
											<MyInputText
												label={t("home.__latitude__")}
												name="latitude"
												behavior={{ required: false }}
												value={myHome.latitude}
											/>
										</div>
										<div className="col">
											<MyInputText
												label={t("home.__longitude__")}
												name="longitude"
												behavior={{ required: false }}
												value={myHome.longitude}
											/>
										</div>
									</div>
								</>
							) : null}
							{isSuperAdmin ? (
								tenantId ? (
									<MyInputSelect
										label={t("common.__groupSelection__")}
										name="group"
										behavior={{ required: true }}
										list={groupsFromTenant}
										value={myHome.group_id}
									/>
								) : (
									<div className="header-details">
										Pour changer l'établissement de groupe, veuillez sélectionner une organisation.
									</div>
								)
							) : null}
							<div className="row">
								<div className="col">
									<MyInputText
										label={t("common.__phone__")}
										name="phone"
										behavior={{ required: false, maxLength: 15, minLength: 7, pattern: /^[0-9+-]*$/ }}
										value={myHome.phone}
									/>
								</div>
								<div className="col">
									<MyInputText label={t("home.__website__")} name="url" behavior={{ required: false }} value={myHome.url} />
								</div>
							</div>

							<button className="btn btn-primary btn-sm" type="submit">
								{t("common.__save__")}
							</button>
						</div>
					</form>
				</FormProvider>
			</div>
		</div>
	);
};

export default EditHome;
