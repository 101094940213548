import React, { useEffect } from "react";
import { UserInterface } from "../interfaces/UserInterface";
import useDiaperStore from "../hooks/useDiaperStore";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import DiaperSensorsList from "../components/DiaperConnected/Sensors/DiaperSensorsList";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number;
	userState: UserInterface;
}

const DiaperSensors = ({ userState, homeId }: Props): JSX.Element => {
	const { diaperStore, diaperDispatch } = useDiaperStore({ userState, homeId });
	const isAdmin = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN]);

	useEffect(() => {
		return function cleanup(): void {
			diaperDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					<DiaperSensorsList
						homeId={homeId}
						userState={userState}
						isAdmin={isAdmin}
						diaperStore={diaperStore}
						diaperDispatch={diaperDispatch}
					/>
				}
			/>
		</AccessControl>
	);
};
export default DiaperSensors;
