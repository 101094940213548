import React from "react";
import { useTranslation } from "react-i18next";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

export default function HealthWorkerTypeBudgetBarChart({ data }) {
	const { t } = useTranslation("common");

	const filteredData = [
		{
			name: t("hds.__accomodation__"),
			Objectif: data.reference.percentage.accomodation,
			Données: data.computed.percentage.accomodation
		},
		{
			name: t("hds.__dependency__"),
			Objectif: data.reference.percentage.dependency,
			Données: data.computed.percentage.dependency
		},
		{
			name: t("hds.__care__"),
			Objectif: data.reference.percentage.care,
			Données: data.computed.percentage.care
		}
	];

	return (
		<div className="row">
			<div className="col-md-7">
				<ResponsiveContainer>
					<BarChart
						// width={400}
						// height={400}
						data={filteredData}
						margin={{ top: 10, right: 5, left: 0, bottom: 10 }}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="name" />
						<YAxis />
						<Tooltip />
						<Bar dataKey="Objectif" barSize={20} stroke="#00a0e3" fill="#00a0e3" fillOpacity={0.75} radius={[5, 5, 0, 0]} />
						<Bar dataKey="Données" barSize={20} stroke="#FA5456" fill="#FA5456" fillOpacity={0.75} radius={[5, 5, 0, 0]} />
						<Legend height={40} verticalAlign="top" />
					</BarChart>
				</ResponsiveContainer>
			</div>
			<div className="col-md-5">
				<div className="header-details">
					<div className="row">
						<div className="col-md-4"></div>
						<div className="col-md-4 center">
							<b>{t("hds.__data__")}</b>
						</div>
						<div className="col-md-4 center">
							<b>{t("hds.__goals__")}</b>
						</div>

						<div className="col-md-4">{t("hds.__accomodation__")}</div>
						<div className="col-md-4 center">
							<b>{data.computed.percentage.accomodation} %</b>
						</div>
						<div className="col-md-4 center">{data.reference.percentage.accomodation} %</div>

						<div className="col-md-4">{t("hds.__dependency__")}</div>
						<div className="col-md-4 center">
							<b>{data.computed.percentage.dependency} %</b>
						</div>
						<div className="col-md-4 center">{data.reference.percentage.dependency} %</div>

						<div className="col-md-4">{t("hds.__care__")}</div>
						<div className="col-md-4 center">
							<b>{data.computed.percentage.care} %</b>
						</div>
						<div className="col-md-4 center">{data.reference.percentage.care} %</div>

						<div className="col-md-12">
							<br />
							<div className="separator"></div>
						</div>
						<div className="col-md-12">
							<h5>{t("hds.__actionCounter__")}</h5>
						</div>

						<div className="col-md-7">{t("hds.__accomodation__")}</div>
						<div className="col-md-5">{data.computed.count.accomodation}</div>
						<div className="col-md-7">{t("hds.__dependency__")}</div>
						<div className="col-md-5"> {data.computed.count.dependency}</div>
						<div className="col-md-7">{t("hds.__care__")}</div>
						<div className="col-md-5">{data.computed.count.care}</div>
					</div>
				</div>
			</div>
		</div>
	);
}
